import {
  PROFILE_LOADING,
  PROFILE_SET_PROFILE,
  PROFILE_SET_COMPANY
} from "./constants";
import { _fethProfile,_updateProfile, _fetchCompany} from "./services";
import {loginRemoveToken} from "../auth/actions";

export function profileSetLoading(loading) {
  return {
    type: PROFILE_LOADING,
    payload: loading,
  };
}
export function profileSetData(params) {
  return {
    type: PROFILE_SET_PROFILE,
    payload: params,
  };
}
export function profileSetCompany(params) {
	return {
	  type: PROFILE_SET_COMPANY,
	  payload: params,
	};
  }
export const fethProfile = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth;
		dispatch(profileSetLoading(true));
		_fethProfile(token).then(async (res) => {
			dispatch(profileSetData(res));
			resolve(res)
		}).catch((err) => {
			if(err?.response?.status === 401){
                dispatch(loginRemoveToken());
            }
			reject(err)
		}).finally(() => {
			dispatch(profileSetLoading(false));
		})
	})
};
export const fetchCompany = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth;
		_fetchCompany(token).then(async (res) => {
			dispatch(profileSetCompany(res));
			resolve(res)
		}).catch((err) => {
			if(err?.response?.status === 401){
                dispatch(loginRemoveToken());
            }
			reject(err)
		})
	})
};
export const updateProfile = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth;
		_updateProfile(formData, token).then(async (res) => {
			dispatch(fethProfile());
			resolve(res);
		}).catch((err) => {
			if(err?.response?.status === 401){
                dispatch(loginRemoveToken());
            }
			reject(err)
		})
	})
};