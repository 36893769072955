import React from "react";
import "./mainViewWhite.css";
import { Dropdown } from "rsuite";
import { IonPage, IonContent } from "@ionic/react";
import { Link } from "react-router-dom";

// icons
import { FiPlus, FiList, FiClipboard, FiBriefcase, FiLayers, FiUsers, FiFileText } from "react-icons/fi";

// components
import SideBar from "../../components/template/sideBar/SideBar";

//redux
// import { connect } from "react-redux";

const MainViewWhite: React.FC<{ children: any }> = ({ children }) => {
  return (
    <IonPage className="mainViewWhite">
      <IonContent className="mainViewWhite-Content" fullscreen>
      <SideBar />
        <div className="mainViewWhite-Content-body">
          {/* header */}

          <div className="mainViewWhite-Content-body-inner">{children}</div>
        </div>
        {/*  */}
        <div className="mob-only">
          <div className="d-flex dashboard_bottomTab ">
            <Link
              className={`dashboard_bottomTab_item`}
              onClick={() => {
                // setTab(1);
              }}
              to="/dashboard/jobs"
            >
              <FiList />
              <p>Job List</p>
            </Link>
            <div className="dashboard_bottomTab_item">
              <Dropdown
                trigger={["click"]}
                className="actionDropPage"
                placement="topEnd"
                renderTitle={() => (
                  <div className="btn-plus-mobile">
                    <FiPlus />
                  </div>
                )}
              >
                <div className="dropDownRowSpaced ">
                  <div className="d-flex">
                    <FiBriefcase />
                    <h6>New Job</h6>
                  </div>
                </div>
                <div className="dropDownRowSpaced">
                  <div className="d-flex">
                    <FiFileText />
                    <h6>New Invoice</h6>
                  </div>
                </div>
                <div className="dropDownRowSpaced">
                  <div className="d-flex">
                    <FiUsers />
                    <h6>New Contact</h6>
                  </div>
                </div>
                <div className="dropDownRowSpaced" style={{ border: 0 }}>
                  <div className="d-flex">
                    <FiLayers />
                    <h6>New Item</h6>
                  </div>
                </div>
              </Dropdown>
            </div>
            <Link
              className={`dashboard_bottomTab_item`}
              onClick={() => {
                // setTab(1);
              }}
              to="/dashboard/task"
            >
              <FiClipboard />
              <p>Tasks</p>
            </Link>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MainViewWhite;
