import React, { useState, useEffect } from "react";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";
import "./StockItems.scss";
import Swal from "sweetalert2";
// component
import {
  PageHeader,
  StockItemsItems,
  StockItemsModal,
} from "../../components/stockItems";
import Loader from "../../components/loader/Loader";
import Paging from "../../components/common/paging/Paging";
import ExcelExport from "../../components/common/excelExport/ExcelExport";
import StockFilters from "../../components/stockItems/stockFilters/StockFilters";
import SortPicker from "../../components/common/SortPicker";
//import helpers
import { showAlert } from "../../helpers";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_ITEMS } from "../../gql/stockItems/queries";
import { UPDATE_STOCK_ITEM_MUTATION } from "../../gql/stockItems/mutation";
//redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";
import { itemState, setItems } from "../../toolkit/items/items.slice";

//types
import StockItemType from "../../components/stockItems/stockItemsModal/StockItems.type";

//functions
import {
  BuildStockSearchQuery,
  BuildStockExportColumns,
  BuildStockExportData,
  BuildStockFilterQuery,
  BuildStockSortQuery,
} from "./StockItems.functions";
const StockItems: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { items } = useSelector(itemState);
  const {
    auth: { user },
  } = useSelector(authState);

  const defaultCondition = {
    created_by: { _eq: user.id },
    active: { _eq: true },
  };
  const [modal, setModal] = useState(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [queryCondition, setQueryCondition] = useState<any>(defaultCondition);
  const [orderCondition, setOrderCondition] = useState<any>({
    created_at: "desc",
  });
  const [sort, setSort] = useState("");
  const [stockFilters, stockSetFilter] = useState<any>({});

  const { data, refetch, error, loading } = useQuery(GET_ALL_ITEMS, {
    variables: {
      limit: limit,
      offset: offset,
      where: queryCondition,
      order: orderCondition,
    },
  });
  const [updateStockItemMutation] = useMutation(UPDATE_STOCK_ITEM_MUTATION);
  const handlePage = (page: number) => {
    setActivePage(page);
    setOffset(limit * page - limit);
  };
  const removeCustomer = (id: string) => {
    Swal.fire({
      title: "Are you sure want to remove this stock item?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
      heightAuto: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateStockItemMutation({
          variables: {
            object: {
              active: false,
            },
            id,
          },
        });
        refetch();
        showAlert({
          title: "Success!",
          message: `Customer removed successfully!`,
          type: "success",
        });
      }
    });
  };
  useEffect(() => {
    if (error) {
    } else {
      dispatch(setItems(data));
      if (typeof data?.items_aggregate?.aggregate?.count !== "undefined") {
        setTotalPages(
          Math.ceil(data?.items_aggregate?.aggregate?.count / limit)
        );
      }
    }
  }, [error, data]);
  useEffect(() => {
    const conditionTemp = BuildStockSearchQuery(search, null);
    setQueryCondition({
      ...defaultCondition,
      ...conditionTemp,
    });
  }, [search]);
  useEffect(() => {
    if (sort.length > 0) {
      const _sortQuery = BuildStockSortQuery(sort);
      setOrderCondition(_sortQuery);
    } else {
      setOrderCondition({ created_at: "desc" });
    }
  }, [sort]);
  useEffect(() => {
    if (Object.keys(stockFilters).length) {
      const conditionTemp = BuildStockFilterQuery(stockFilters, null);
      setQueryCondition({
        ...defaultCondition,
        ...conditionTemp,
      });
    } else {
      setOrderCondition({ created_at: "desc" });
    }
  }, [stockFilters]);
  return (
    <FlexboxGrid className="StockItems">
      <PageHeader />
      <div className="StockItems_body">
        <div>
          <div className="StockItems_body_top">
            <div className="space-between">
              <div className="d-flex">
                <SortPicker setSort={setSort} />
                <StockFilters
                  stockFilters={stockFilters}
                  stockSetFilter={stockSetFilter}
                />
              </div>

              <div className="d-flex">
                <div className="JobsOverview_body_top_search">
                  <div className="searchIcon">
                    <FiSearch />
                  </div>
                  <input
                    type="text"
                    className="joboverview-searchInput"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div
                  className="StockItems_body_top_btn blue_btn"
                  onClick={() => {
                    setModal(true);
                    setIsEdited(false);
                    setEditedId("");
                  }}
                >
                  <FiPlus />
                </div>

                <div>
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader export"
                    placement="bottomEnd"
                    renderTitle={() => (
                      <FiMoreVertical
                        style={{
                          color: "#304a50",
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  >
                    <div
                      className="dropDownRowSpaced"
                      style={{ border: 0, padding: 5 }}
                    >
                      <div className="d-flex dropDownRowSpaced-option">
                        <FiUpload />
                        <ExcelExport
                          columns={BuildStockExportColumns()}
                          data={BuildStockExportData(items)}
                          filename={`repaircms_items_${Date.now()}`}
                          fileExtension="xlsx"
                        >
                          <h6>Export to Excel</h6>
                        </ExcelExport>
                      </div>
                    </div>
                    <div
                      className="dropDownRowSpaced"
                      style={{ border: 0, padding: 5 }}
                    >
                      <div className="d-flex dropDownRowSpaced-option">
                        <FiUpload />
                        <ExcelExport
                          columns={BuildStockExportColumns()}
                          data={BuildStockExportData(items)}
                          filename={`repaircms_items_${Date.now()}`}
                          fileExtension="csv"
                        >
                          <h6>Export to CSV</h6>
                        </ExcelExport>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="StockItems_body_bottom">
            {loading || typeof items === "undefined" ? (
              <Loader />
            ) : (
              <>
                {items.map((item: StockItemType, i: number) => (
                  <StockItemsItems
                    key={i}
                    id={item.id}
                    item={item}
                    setModal={setModal}
                    setEditedId={setEditedId}
                    setIsEdited={setIsEdited}
                    removeCustomer={removeCustomer}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <div className="table-footer">
          <Paging
            activePage={activePage}
            pages={totalPages}
            onPageClick={handlePage}
          />
        </div>
      </div>
      {modal && (
        <StockItemsModal
          Close={() => {
            setModal(false);
          }}
          refetch={refetch}
          isEdited={isEdited}
          editedId={editedId}
        />
      )}
    </FlexboxGrid>
  );
};

export default StockItems;
