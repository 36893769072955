import "../../../pages/services/Services.scss";
import React, { useState, useEffect } from "react";

// icons
import { FaRegBuilding, FaAngleDown, FaPrint, FaEye } from "react-icons/fa";
import {
  Popover,
  Dropdown,
  IconButton,
  ButtonGroup,
  Whisper,
  Button,
} from "rsuite";

const options = [
  "Create a merge commit",
  "Squash and merge",
  "Rebase and merge",
];

const PageHeader: React.FC<{
  onClick: Function;
  loading: boolean;
  headerText1: string;
  headerText2: string;
  simpleSaveBtn: boolean;
  saveDropdown: boolean;
  handelPrint: () => void;
}> = ({
  onClick,
  loading,
  headerText1,
  headerText2,
  simpleSaveBtn,
  saveDropdown,
  handelPrint,
}) => {
  const [action, setAction] = useState(0);
  return (
    <div className="SettingsPageHeader">
      <div className="PageHeader-left">
        <FaRegBuilding />
        <h2>
          <span style={{ opacity: 0.6 }}> {headerText1} </span>| {headerText2}
        </h2>
      </div>
      {simpleSaveBtn == true ? (
        <button
          className="bt-primary"
          style={{ maxWidth: 80 }}
          onClick={() => onClick()}
          disabled={loading}
        >
          Save
        </button>
      ) : null}
      {saveDropdown == true ? (
        <div className="bt-primary pageHeader-save" style={{ maxWidth: 80 }}>
          <a onClick={() => onClick()}>Save</a> |{" "}
          <Dropdown
            className="page-header-drop-down"
            title={<FaAngleDown />}
            placement="bottomEnd"
          >
            <Dropdown.Item>
              {" "}
              <FaEye /> Preview
            </Dropdown.Item>
            <Dropdown.Item onClick={handelPrint}>
              <FaPrint /> Print
            </Dropdown.Item>
          </Dropdown>
        </div>
      ) : null}
    </div>
  );
};

export default PageHeader;
