import { Dropdown } from "rsuite";
import "../../../pages/services/Services.scss";

// icons
import { FiFileText, FiChevronDown } from "react-icons/fi";

const PageHeader = () => {
  return (
    <div className="PageHeader">
      <div className="PageHeader-left">
        <FiFileText />
        <h2>Invoice</h2>
      </div>
    </div>
  );
};

export default PageHeader;
