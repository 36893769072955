import React, { useState, useEffect, useRef } from "react";
import {
  FlexboxGrid,
  SelectPicker,
  Uploader,
  Message,
  Loader,
  Toggle,
} from "rsuite";
import FifthTab from "../../components/customerContact/customerContactModal/tabs/FifthTab";
import TagInput from "../../components/template/tagInput/TagInput";

import "./EmailTemplateSettings.scss";
import { v4 as uuid } from "uuid";

import { FaTrashAlt } from "react-icons/fa";
import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint,
} from "react-to-print";
import SearchDropDown from "../../components/template/searchDropDown/SearchDropDown";
import SelectBox from "../../components/template/selectBox/SelectBox";

import Attach from "../../assets/attach.png";
import Send from "../../assets/send.png";
import plug from "../../assets/plug.png";
import emailTemp from "../../assets/emailTemp.png";

// component
import {
  PageHeader,
  CompnaySettingsAddress,
  CompnaySettingsContact,
  CompnaySettingsTAX,
  CompnaySettingsBank,
  CompnaySettingsLogo,
  CompnaySettingsReceipt,
} from "../../components/compnaySettings";

import InputBox from "../../components/template/inputBox/InputBox";

import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { nullFormat } from "numeral";

const ReceiptsSettings: React.FC<{}> = (props) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selectedReceipt, selectedSelectedReceipt] = useState("Quotation");
  const [selectedEncryption, setSelectedEncryption] = useState("Encryption");
  const [dob, setDob] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [selected, setSelected2] = useState("");
  const [selectedFile, setSelectedFile] = useState([
    {
      name: "Attachments",
    },
  ]);

  const [model, setModel] = useState<string>("");

  const handleModelChange = (model: any) => {
    setModel(model);
  };

  const [items, setItems] = React.useState([
    {
      id: "1",
      value: "Quotation",
      label: "Quotation",
    },
  ]);
  const [encryptionModes, setEncryption] = React.useState([
    {
      id: "1",
      name: "Encryption",
    },
    {
      id: "2",
      name: "Encryption1  ",
    },
  ]);

  const renderMenu = (menu: any) => {
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          {/* <SpinnerIcon spin />  */}
          Loading...
        </p>
      );
    }
    return menu;
  };

  const handelSelect = (e: any) => {
    console.log(e, "is the value");
    selectedSelectedReceipt(e);
  };

  const handelSelectFile = (e: any) => {
    setSelectedFile(e.target.files);
    console.log(e.target.files);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handelSelectEncryptions = (value: string, key: string) => {
    setSelectedEncryption(value);
  };
  return (
    <>
      <PageHeader
        onClick={() => null}
        loading={btnLoading}
        headerText1="Settings"
        headerText2="Email"
        simpleSaveBtn={true}
        saveDropdown={false}
        handelPrint={handlePrint}
      />
      <div className="emailTemplateContainer">
        <div className="dropdown_receipts">
          <SelectPicker
            placeholder="Quotation"
            defaultValue={"|"}
            data={items}
            renderMenu={renderMenu}
            searchable={false}
            onSelect={handelSelect}
          />
        </div>

        <div ref={componentRef}></div>
        {/* <CompnaySettingsReceipt recepitType={selectedReceipt} /> */}
        <div className="emailTemplatePage">
          <p className="heading">Email Template</p>
          <div className="form">
            <div className="inputContainer">
              <InputBox labelText="Subject" handleChange={() => null} />
            </div>
            <div className="inputContainer">
              <InputBox labelText="cc" handleChange={() => null} />
            </div>
            <div className="inputContainer">
              <div className="upload-btn-wrapper">
                <button className="btn">
                  <img src={Attach} alt="" />
                </button>
                <p>{selectedFile ? selectedFile[0].name : "Attachments"}</p>
                <input type="file" name="myfile" onChange={handelSelectFile} />
              </div>
            </div>
            <div className="inputContainer">
              <div className="d-flex space-between">
                <div className="testEmail-input">
                  <InputBox
                    labelText="Email for testing"
                    handleChange={() => null}
                  />
                </div>
                <button className="testEmail-btn">
                  <img src={Send} alt="" />
                </button>
              </div>
            </div>

            <div className="emailTemplateTextEditor">
              <Froala
                // ref={ref}
                model={model}
                onModelChange={handleModelChange}
                tag="textarea"
                config={{
                  documentReady: false,
                  fontSizeSelection: true,
                  toolbarButtons: [
                    "fontSize",
                    "bold",
                    "italic",
                    "underline",
                    "textColor",
                    "formatOL",
                    "formatUL",
                    "insertTable",
                    "insertLink",
                    "insertHR",
                    "alignLeft",
                    "alignRight",
                    "alignCenter",
                    "outdent",
                    "indent",
                  ],
                  // pluginsEnabled: ["quickInsert"],
                  quickInsertTags: [""],
                }}
              />
            </div>
          </div>
        </div>
        <div className="emailTemplatePageContainer">
          <div className="emailTemplatePage emailTemplatePageSmall">
            <p className="heading">Email Server</p>
            <div className="toggle" style={{ marginTop: 35 }}>
              <div className="CustomerContactModal_inner_blueShow">
                <img src={emailTemp} alt="" />
                <div className="f1">
                  <h6>Enable outgoing custom mail server</h6>
                  <p>Use credentials from your provider</p>
                </div>
                <Toggle
                  size="md"
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </div>
            </div>
            <div className="form">
              <div className="inputContainer">
                <InputBox labelText="Email" handleChange={() => null} />
              </div>
              <div className="inputContainer">
                <InputBox
                  labelText="Email sender name"
                  handleChange={() => null}
                />
              </div>
              <div className="inputContainer">
                <InputBox labelText="SMTP-Server" handleChange={() => null} />
              </div>
              <div className="inputContainer">
                <InputBox labelText="Username" handleChange={() => null} />
              </div>
              <div className="inputContainer">
                <InputBox labelText="SMTP-Password" handleChange={() => null} />
              </div>
              <div className="inputContainer">
                <div className="d-flex space-between">
                  <div className="testEmail-input emailTemplatePageSmallSelect">
                    {/* <SelectBox
                      selectedValue={selectedEncryption}
                      labelText="Encryption"
                    >
                      {encryptions.map((item, i: number) => (
                        <div
                          className="selectBox-option"
                          onClick={() => {
                            handelSelectEncryptions(item.name, "encryptions");
                          }}
                          key={i}
                        >
                          {item.name}
                        </div>
                      ))}
                    </SelectBox> */}
                    <SearchDropDown
                        labelText="Encryption"
                        data={encryptionModes}
                        selectedOption={setSelected2}
                        onMouseLeave={() => null}
                      />
                  </div>
                  <div className="testEmail-input port">
                    <InputBox labelText="port" handleChange={() => null} />
                  </div>
                  <button className="testEmail-btn">
                    <img src={plug} alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="connection-container">
              <div className="connection">
                <p>Connections Test</p>
                <p>Succecfully on 20.02.2022 | 13:52</p>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptsSettings;
