import React from "react";
import TagInput from "../../../template/tagInput/TagInput";
interface ServiceTagsProps {
	tags: Array<string>;
	setTags: any;
}
const ServiceTags = ({ setTags, tags }: ServiceTagsProps) => {
	return (
		<div>
			<div className="ServicesModal_inner_flex_start">
				<div className="col">
					<h5 className="ServicesModal_tab_body_header">Tags</h5>
				</div>
			</div>
			<div className="ServicesModal_inner_flex_start">
				<div className="col">
					<TagInput
						labelText="Add Tags"
						handleChange={(value) => {
							if (value.length) {
								setTags((tags: any) => [...tags, value])
							}
						}}
						visualData={tags}
						setTags={setTags}
					/>
				</div>
			</div>
		</div>
	)
}
export default ServiceTags;