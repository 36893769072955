import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./JobModal.css";

// icons
import {
  FaRegFileAlt,
  FaBarcode,
  FaTag,
  FaUserAlt,
  FaExclamationTriangle,
  FaPrint,
  FaTabletAlt,
  FaShippingFast,
  FaHeadSideCough,
} from "react-icons/fa";
import { FiX, FiBriefcase, FiUsers, FiList } from "react-icons/fi";
import { RiFileList3Line } from "react-icons/ri";
import {IoArrowBackCircleOutline} from "react-icons/io5"

// component
import JobModalSteps from "../jobModalSteps/JobModalSteps";
import JobModalPricingSteps from "../jobModalPricingSteps/JobModalPricingSteps";

const JobModal: React.FC<{ openModal: boolean; onClose: Function; }> = ({
  openModal,
  onClose,
}) => {
  const [step, setStep] = useState(1);
  const history = useHistory();
  

  return (
    <>
      <div className="desk-only">
        <div
          className={`JobModal_container ${
            openModal === false ? "d-none" : ""
          } `}
          
        >
           <div
            className="Back"
            onClick={() => {
              setStep(1);
            }}
             
            
          >
            
            <IoArrowBackCircleOutline color="rgb(255 255 255)" />
            <span>Back</span>
          </div>
          
          <div
            className="close"
            onClick={() => {
              onClose();
              setStep(1);
            }}
          >

            <FiX color="#304A50" />
          </div>
          <div className="JobModal_body">
            {step === 1 && (
              <>
                <div className="s1">
                  <h2 style={{ marginBottom: 70, fontSize: 40 }}>
                    Select Your Process
                  </h2>
                  <div className="s1-flex">
                    <div
                      className="s1-item"
                      onClick={() => {
                        history.push("/invoices");
                        onClose();
                      }}
                    >
                      <div className="s1-item_icon">
                        <FaRegFileAlt />
                      </div>
                      <p>New Invoice</p>
                    </div>
                    <div
                      className="s1-item spcl"
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      <div className="s1-item_icon">
                        <img
                          src="/assets/theme/search.png"
                          style={{ width: 70 }}
                        />
                      </div>
                      <p>Pricing Finder</p>
                      <h6>Express Job</h6>
                    </div>
                    <div
                      className="s1-item"
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      <div className="s1-item_icon">
                        <FiBriefcase />
                      </div>
                      <p>New Job</p>
                    </div>
                  </div>
                  <div className="s1-flex">
                    <div className="s1-item" onClick={() => {
                      history.push("/subcriptions");
                      onClose();
                    }}>
                      <div className="s1-item_icon">
                        <RiFileList3Line />
                      </div>
                      <p>New Subscription</p>
                    </div>
                    <div className="s1-item" onClick={() => {
                      history.push("/contacts/customer");
                      onClose();
                    }}>
                      <div className="s1-item_icon">
                        <FiUsers />
                      </div>
                      <p>New Contact</p>
                    </div>
                    <div className="s1-item" onClick={() => {
                      history.push("/services");
                      onClose();
                    }}>
                      <div className="s1-item_icon">
                        <FiList />
                      </div>
                      <p>New Service</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 2 && <JobModalSteps />}
            {step === 3 && <JobModalPricingSteps />}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobModal;
