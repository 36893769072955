import React, { useState } from "react";
import "../../../pages/services/Services.scss";
import { Toggle } from "rsuite";

// icons
import { FiX, FiUploadCloud, FiSearch, FiCheck } from "react-icons/fi";
import { IoStarOutline } from "react-icons/io5";

// components
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

const UsersSettingsModal: React.FC<{ Close: any }> = ({ Close }) => {
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");
  const [selected3, setSelected3] = useState("");
  const [selected4, setSelected4] = useState("");
  const [tab, setTab] = useState(1);
  const [salutation, setSalutation] = useState<any>([
    {
      id: 1,
      name: "Mr.",
    },
    {
      id: 2,
      name: "Mrs.",
    },
  ]);
  const [title, setTitles] = useState<any>([
    {
      id: 1,
      name:"Mr",
    },
    {
      id: 2,
      name:"Mrs",
    }
  ])
  const [language, setLanguage] = useState<any>([
    {
      id: 1,
      name: "English",
    },
    {
      id: 2,
      name: "Germany"
    }
  ])
  const [location, setLocation] = useState<any>([
    {
      id: 1,
      name: "Turkey",
    },
    {
      id: 2,
      name: "Pakistan"
    }
  ])
  const [rule, setRule] = useState<any>([
    {
      id: 1,
      name: "Manager",
    },
    {
      id: 2,
      name: "Customer"
    }
  ])

  
  

  return (
    <>
      <div className="desk-only">
        <div className="UsersSettingsModal">
          <div className="UsersSettingsModal_inner">
            <div className="UsersSettingsModal_inner_top">
              <div className="UsersSettingsModal_inner_flex_start">
                <div className="col">
                  <h5 className="">New User</h5>
                </div>
                <div className="close" onClick={Close}>
                  <FiX />
                </div>
              </div>
            </div>
            <div
              className="UsersSettingsModal_inner_flex_start"
              style={{ marginBottom: 15 }}
            >
              <div className="col">
                <div className="" style={{ marginBottom: 15 }}>
                  <div className="UsersSettingsModal_inner_flex_start">
                    <div className="col">
                      {/* <SelectBox
                        selectedValue={selected}
                        labelText="Salutation"
                      >
                        <div
                          className=""
                          onClick={() => {
                            setSelected("Mr.");
                          }}
                        >
                          <div className="d-flex-start"> Mr.</div>
                        </div>
                        <div
                          className=""
                          onClick={() => {
                            setSelected("Mrs.");
                          }}
                        >
                          <div className="d-flex-start"> Mrs.</div>
                        </div>
                      </SelectBox> */}
                       <div>
                       <SearchDropDown
                        labelText="Salutation"
                        data={salutation}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                      />
                       </div>
                      {/*  */}
                    </div>
                    <div className="col">
                      {/* <SelectBox selectedValue={selected1} labelText="Title">
                        <div
                          className=""
                          onClick={() => {
                            setSelected1("Mr.");
                          }}
                        >
                          <div className="d-flex-start"> Mr.</div>
                        </div>
                        <div
                          className=""
                          onClick={() => {
                            setSelected1("Mrs.");
                          }}
                        >
                          <div className="d-flex-start"> Mrs.</div>
                        </div>
                      </SelectBox> */}
                       <SearchDropDown
                        labelText="Title"
                        data={title}
                        selectedOption={setSelected1}
                        onMouseLeave={() => null}
                      />
                      {/*  */}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ marginBottom: 15 }}>
                    <InputBox
                      labelText="First Name"
                      handleChange={() => null}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ marginBottom: 15 }}>
                    <InputBox labelText="Password" handleChange={() => null} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="UsersSettingsModal_inner_flex_start">
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      <InputBox
                        labelText="ID"
                        valueProp="S0001"
                        handleChange={() => null}
                      />
                    </div>
                  </div>
                  <div className="col">
                    {/*  */}
                    {/* <SelectBox selectedValue={selected2} labelText="Language">
                      <div
                        className=""
                        onClick={() => {
                          setSelected2("English");
                        }}
                      >
                        <div className="d-flex-start"> English</div>
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected2("Germany");
                        }}
                      >
                        <div className="d-flex-start"> Germany</div>
                      </div>
                    </SelectBox> */}
                     <SearchDropDown
                        labelText="Language"
                        data={language}
                        selectedOption={setSelected2}
                        onMouseLeave={() => null}
                      />
                    {/*  */}
                  </div>
                </div>
                <div className="UsersSettingsModal_inner_flex_start">
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      <InputBox
                        labelText="Last Name"
                        handleChange={() => null}
                      />
                    </div>
                  </div>
                </div>
                <div className="UsersSettingsModal_inner_flex_start">
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      <InputBox
                        labelText="Password"
                        handleChange={() => null}
                      />
                    </div>
                  </div>
                </div>
                <div className="UsersSettingsModal_inner_flex_start">
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      {/*  */}
                      {/* <SelectBox selectedValue={selected3} labelText="Location">
                        <div
                          className=""
                          onClick={() => {
                            setSelected3("Germany");
                          }}
                        >
                          <div className="d-flex-start"> Germany</div>
                        </div>
                        <div
                          className=""
                          onClick={() => {
                            setSelected3("India");
                          }}
                        >
                          <div className="d-flex-start"> India</div>
                        </div>
                      </SelectBox> */}
                      <SearchDropDown
                        labelText="Location"
                        data={location}
                        selectedOption={setSelected3}
                        onMouseLeave={() => null}
                      />
                      {/*  */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      <InputBox
                        labelText="Short Name"
                        valueProp="Ruf"
                        handleChange={() => null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="UsersSettingsModal_inner_flex_start">
              <div className="col">
                <div className="UsersSettingsModal_tabs">
                  <div
                    className={`UsersSettingsModal_tab ${
                      tab === 1 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Roles & Rights
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="UsersSettingsModal_tab_body">
              {tab === 1 && (
                <>
                  <div
                    className="UsersSettingsModal_inner_flex_start"
                    style={{ width: "50%" }}
                  >
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        {/*  */}
                        {/* <SelectBox selectedValue={selected4} labelText="Role">
                          <div
                            className=""
                            onClick={() => {
                              setSelected4("Manager");
                            }}
                          >
                            <div className="d-flex-start"> Manager</div>
                          </div>
                          <div
                            className=""
                            onClick={() => {
                              setSelected4("Customer");
                            }}
                          >
                            <div className="d-flex-start"> Customer</div>
                          </div>
                        </SelectBox> */}
                              <SearchDropDown
                        labelText="Role"
                        data={rule}
                        selectedOption={setSelected4}
                        onMouseLeave={() => null}
                      />
                        {/*  */}
                      </div>
                    </div>
                  </div>
                  <div className="UsersSettingsModal_inner_flex_start">
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/logo-mini.png" alt="" />
                          <div className="f1">
                            <h6>Access To RepairCMS</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/usersSettings/4.png" alt="" />
                          <div className="f1">
                            <h6>Access To Invoices</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UsersSettingsModal_inner_flex_start">
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/usersSettings/1.png" alt="" />
                          <div className="f1">
                            <h6>Access To Turnover View</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/usersSettings/3.png" alt="" />
                          <div className="f1">
                            <h6>Access To Stock</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UsersSettingsModal_inner_flex_start">
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/usersSettings/6.png" alt="" />
                          <div className="f1">
                            <h6>Access To Settings</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/usersSettings/5.png" alt="" />
                          <div className="f1">
                            <h6>Export Data</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UsersSettingsModal_inner_flex_start">
                    <div className="col">
                      <div className="" style={{ marginTop: 15 }}>
                        <div className="UsersSettingsModal_inner_blueShow">
                          <img src="assets/usersSettings/2.png" alt="" />
                          <div className="f1">
                            <h6>Access To Jobs</h6>
                            {/* <p>Customer Ticketbooking via WebWidget</p> */}
                          </div>
                          <Toggle
                            size="md"
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div style={{ width: "100%" }}></div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/*  */}
            {/* <div
              className="d-flex"
              style={{ justifyContent: "flex-end", marginTop: 15 }}
            >
              <div className="bt-secondary" style={{ flex: 0 }} onClick={Close}>
                Abort
              </div>
              <div className="bt-primary" style={{ flex: 0 }}>
                Save
              </div>
            </div> */}
            <div className="d-flex CustomerContactModal_btns">
              <div
                className="bt-secondary"
                style={{ flex: 0 }}
              >
                Abort
              </div>
              <button
                className= "bt-primary"
                style={{ flex: 0 }}
               
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersSettingsModal;
