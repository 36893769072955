import React from "react";
import {
	Form,
	FormGroup,
	FormControl,
	ButtonToolbar,
	Button,
} from "rsuite";

interface SupportProps {
	passwordState: {
		password: string;
		confPassword: string;
	};
	setPassword: Function;
	handleSubmit: Function;
	errMsg: String;
}

const ForgotPasswordChangePassword = ({
	passwordState,
	setPassword,
	handleSubmit,
	errMsg
}: SupportProps) => {
	const submitHandler = async () => {
		handleSubmit()
	};
	return (
		<>
			<Form fluid className="auth_Form" onSubmit={submitHandler}>
				<h1 className="auth_Form_title">Change Your Password</h1>
				<h3 className="auth_Form_title_sub">You can login in RepairCMS with this new password.</h3>
				<FormGroup>
					{/* <ControlLabel>Password</ControlLabel> */}
					<FormControl
						className="authFormInput"
						name="password"
						type="password"
						placeholder="Password"
						value={passwordState.password}
						onChange={(value: string)=>{
							setPassword({...passwordState, password: value})
						}}
						required
						minLength={8}
					/>
					<FormControl
						className="authFormInput"
						name="confPassword"
						type="password"
						placeholder="Confirm Password"
						value={passwordState.confPassword}
						onChange={(value: string)=>{
							setPassword({...passwordState, confPassword: value})
						}}
						required
						minLength={8}
					/>
				</FormGroup>
				<FormGroup>
					<ButtonToolbar>
						<Button appearance="primary" className="authBtn" type="submit">
							Continue
						</Button>
					</ButtonToolbar>
				</FormGroup>
			</Form>
		</>
	);
};

export default ForgotPasswordChangePassword;
