
import "../../../pages/services/Services.scss";

// icons
import { CgProfile } from "react-icons/cg";

const PageHeader = () => {
  return (
    <div className="SettingsPageHeader">
      <div className="PageHeader-left">
        <CgProfile />
        <h2>
          <span style={{ opacity: 0.6 }}> Profile</span>
        </h2>
      </div>
    </div>
  );
};

export default PageHeader;
