import React, { useState, useEffect } from "react";
import "../../../pages/services/Services.scss";
import { useMutation } from "@apollo/client";
import { v4 as uuid } from "uuid";

// icons
// import { AiFillPlusCircle } from "react-icons/ai";
import { FiX, FiPlus } from "react-icons/fi";

// components
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import PlusButton from "../../template/plusButton/PlusButton";
import FirstTab from "./tabs/FirstTab";
import SecondTab from "./tabs/SecondTab";
import ThirdTab from "./tabs/ThirdTab";
import FourthTab from "./tabs/FourthTab";
import FifthTab from "./tabs/FifthTab";
import InvoiceTab from "./tabs/InvoiceTab";
import { showAlert } from "../../../helpers";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

import {
  CREATE_CUSTOMER_MUTATION,
  UPDATE_CUSTOMER_MUTATION,
} from "../../../gql/customers/mutation";
import { authState } from "../../../toolkit/auth/auth.slice";
import { customerState } from "../../../toolkit/customers/customers.slice";
import { useSelector } from "react-redux";

//import validators
import validateCreateCustomer from "../../../validators/customers/create";

import { title, shortid } from "../../../helpers";

const CustomerContactModal: React.FC<{
  Close: any;
  isEdited?: boolean;
  editedId?: string;
  refetch: Function;
  userType: string;
}> = ({ Close, isEdited, editedId, refetch, userType }) => {
  const [errors, setErrors] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");
  const [newName, setNewName] = useState(false);
  const [tab, setTab] = useState(1);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [customerNo, setCustomerNo] = useState<string>("");
  const [nickName, setNickName] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [iban, setIban] = useState<string>("");
  const [bic, setBic] = useState<string>("");
  const [paymentCondition, setPaymentCondition] = useState<string>("");
  const [discount, setDiscount] = useState<string>("");
  const [discountType, setDiscountType] = useState<string>("");
  const [vatId, setVatId] = useState<string>("");

  const [salutation, setSalutation] = useState<any>([
    {
      id: 1,
      name: "Mr.",
    },
    {
      id: 2,
      name: "Mrs.",
    },
  ]);
  
  const [typeModes, setMypeMode] = useState<any>([
    {
      id: 1,
      name: " Private",
    },
    {
      id: 2,
      name: "Public",
    },
    {
      id: 3,
      name: "Organization",
    },
  ]);
 
  const [titles, setTitles] = useState<any>([
    {
      id: 1,
      name: "Dr.",
    },
    {
      id: 2,
      name: "Prof.",
    },
    {
      id: 3,
      name: "Major",
    },
    {
      id: 4,
      name: "Hon.",
    },
    {
      id: 5,
      name: "Sr.",
    },
    {
      id: 6,
      name: "Jr.",
    },
  ]);
  const [addresses, setAddresses] = useState<any>([
    {
      newName: "",
      newNo: "",
      zip: "",
      city: "",
      country: "",
      id: uuid(),
    },
  ]);
  const [phones, setPhones] = useState([
    {
      phone: "",
      type: "",
      id: uuid(),
    },
  ]);
  const [emails, setEmails] = useState([
    {
      email: "",
      type: "",
      id: uuid(),
    },
  ]);
  const [websites, setWebsites] = useState([
    {
      website: "",
      type: "",
      id: uuid(),
    },
  ]);
  const [tags, setTags] = useState([]);

  const {
    auth: { user },
  } = useSelector(authState);
  const { customers } = useSelector(customerState);

  const [createCustomerMutation, { data, loading, error }] = useMutation(
    CREATE_CUSTOMER_MUTATION
  );
  const [updateCustomerMutation, { error: errorM }] = useMutation(
    UPDATE_CUSTOMER_MUTATION
  );

  const handleClick = async () => {
    const { errors, isValid } = validateCreateCustomer({
      customerNo,
      type: selected2,
      firstName,
      lastName,
    });
    if (isValid && user.id) {
      try {
        setBtnLoading(true);
        if (!isEdited) {
          await createCustomerMutation({
            variables: {
              object: {
                first_name: firstName,
                last_name: lastName,
                id: uuid(),
                type: selected2,
                created_at: new Date(),
                addresses,
                phones,
                emails,
                websites,
                created_by: user.id,
                // customer_number: customerNo,
                notes,
                salutation: selected,
                iban,
                bic,
                payment_condition: paymentCondition,
                discount: discount || 0,
                discount_type: discountType,
                dob: dob || null,
                tags,
                position,
                organization,
                title: selected1,
                user_type: userType,
                vat_id: vatId,
              },
            },
          });
        } else {
          await updateCustomerMutation({
            variables: {
              object: {
                first_name: firstName,
                last_name: lastName,
                type: selected2,
                updated_at: new Date(),
                addresses,
                phones,
                emails,
                websites,
                // customer_number: customerNo,
                notes,
                salutation: selected,
                iban,
                bic,
                payment_condition: paymentCondition,
                discount,
                discount_type: discountType,
                dob,
                tags,
                position,
                organization,
                title: selected1,
                user_type: userType,
                vat_id: vatId,
              },
              id: editedId,
            },
          });
        }
        refetch();
        handleClose();
        showAlert({
          title: "Success!",
          message: `${userType === "customer" ? "Customer" : "Supplier"} ${
            isEdited ? "updated" : "created"
          } successfully!`,
          type: "success",
        });
      } catch (err) {
        showAlert({
          title: "Failed!",
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        setBtnLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

  const addNewAddress = (remove: boolean, id: string) => {
    if (!remove) {
      const addressesObj = {
        newName: "",
        newNo: "",
        zip: "",
        city: "",
        country: "",
        id: uuid(),
      };
      setAddresses([...addresses, addressesObj]);
    } else {
      setAddresses([...addresses.filter((el: any) => el.id !== id)]);
    }
  };

  const handlePhoneNumbers = (remove: boolean, id: string) => {
    if (!remove) {
      const phoneObj = {
        phone: "",
        type: "",
        id: uuid(),
      };
      setPhones([...phones, phoneObj]);
    } else {
      setPhones([...phones.filter((el: any) => el.id !== id)]);
    }
  };

  const handleAddEmails = (remove: boolean, id: string) => {
    if (!remove) {
      const emailObj = {
        email: "",
        type: "",
        id: uuid(),
      };
      setEmails([...emails, emailObj]);
    } else {
      setEmails([...emails.filter((el: any) => el.id !== id)]);
    }
  };

  const handleAddWebsites = (remove: boolean, id: string) => {
    if (!remove) {
      const websiteObj = {
        website: "",
        type: "",
        id: uuid(),
      };
      setWebsites([...websites, websiteObj]);
    } else {
      setWebsites([...websites.filter((el: any) => el.id !== id)]);
    }
  };

  const resetState = () => {
    setSelected1("");
    setFirstName("");
    setLastName("");
    setAddresses([
      {
        newName: "",
        newNo: "",
        zip: "",
        city: "",
        country: "",
        id: uuid(),
      },
    ]);
    setPhones([
      {
        phone: "",
        type: "",
        id: uuid(),
      },
    ]);
    setEmails([
      {
        email: "",
        type: "",
        id: uuid(),
      },
    ]);
    setWebsites([
      {
        website: "",
        type: "",
        id: uuid(),
      },
    ]);
    setTags([]);
    setDiscountType("");
    setDiscount("");
    setPaymentCondition("");
    setBic("");
    setIban("");
    setDob("");
    setNotes("");
    setOrganization("");
    setPosition("");
    setCustomerNo("");
    setSelected("");
    setSelected2("");
  };

  const handleClose = () => {
    resetState();
    Close();
  };
  useEffect(() => {
    if (editedId) {
      const selectedUser = customers.find((el: any) => el.id === editedId);
      if (Object.keys(selectedUser).length > 0) {
        const {
          first_name,
          last_name,
          addresses,
          bic,
          customer_number,
          discount,
          discount_type,
          dob,
          emails,
          iban,
          notes,
          organization,
          payment_condition,
          phones,
          position,
          reverse_charge_invoice_active,
          salutation,
          tags,
          title,
          type,
          websites,
          vat_id,
        } = selectedUser;
        setSelected1(title);
        setFirstName(first_name);
        setLastName(last_name);
        setAddresses(addresses);
        setPhones(phones);
        setEmails(emails);
        setWebsites(websites);
        setTags(tags);
        setDiscountType(discount_type);
        setDiscount(discount);
        setPaymentCondition(payment_condition);
        setBic(bic);
        setIban(iban);
        setDob(dob);
        setNotes(notes);
        setOrganization(organization);
        setPosition(position);
        setCustomerNo(customer_number);
        setSelected(salutation);
        setSelected2(type);
        setVatId(vat_id);
      }
    } else {
      resetState();
      setCustomerNo("Autogenerate");
    }
  }, [editedId]);

  return (
    <>
      <div className="desk-only">
        <div className="CustomerContactModal">
          <div className="CustomerContactModal_inner">
            <div className="CustomerContactModal_inner_top">
              <div className="CustomerContactModal_inner_flex_start">
                <div className="col">
                  <h5 className="">
                    {isEdited ? "Edit" : "New"}{" "}
                    {userType === "customer" ? "Customer" : "Supplier"}
                  </h5>
                </div>
                <div className="close" onClick={handleClose}>
                  <FiX />
                </div>
              </div>
            </div>

            {/* Top Sections */}
            <div
              className="CustomerContactModal_inner_flex_start"
              style={{ marginBottom: 15 }}
            >
              <div className="col">
                <div className="" style={{ marginBottom: 15 }}>
                  <div className="CustomerContactModal_inner_flex_start">
                    <div className="col">
                      <SearchDropDown
                        labelText="Salutation"
                        data={salutation}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                      />
                      {/*  */}
                    </div>
                    <div className="col">
                      <SearchDropDown
                        labelText="Title"
                        data={titles}
                        selectedOption={setSelected1}
                        onMouseLeave={() => null}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ marginBottom: 15 }}>
                    <InputBox
                      labelText="First Name*"
                      valueProp={firstName}
                      handleChange={setFirstName}
                      isError={errors.firstName?.length > 0}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ marginBottom: 15 }}>
                    <InputBox
                      labelText="Organization"
                      valueProp={organization}
                      handleChange={setOrganization}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="CustomerContactModal_inner_flex_start"
                  style={{ marginBottom: 15, marginTop: 0 }}
                >
              

                 
                  <div className="col">
                    {/*  */}
                    {/* <SelectBox
                      selectedValue={selected2}
                      labelText="Type*"
                      isError={errors.type?.length > 0}
                    >
                      {["Private", "Public", "Organization"].map(
                        (type: string, i: number) => (
                          <div
                            className=""
                            onClick={() => {
                              setSelected2(type);
                            }}
                          >
                            <div className="d-flex-start">{type}</div>
                          </div>
                        )
                      )}
                    </SelectBox> */}
                    <SearchDropDown
                        labelText="Type"
                        data={typeModes}
                        selectedOption={setSelected2}
                        onMouseLeave={() => null}
                      />
                    {/*  */}
                  </div>
                  <div className="col">
                    <div
                      className=""
                     
                    >
                      <InputBox
                        labelText="Customer Number*"
                        valueProp={customerNo}
                        handleChange={setCustomerNo}
                        isError={errors.customerNo?.length > 0}
                        disabledInput={true}
                      />
                    </div>
                  </div>
                
                </div>

                <div className="CustomerContactModal_inner_flex_start">
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      <InputBox
                        labelText="Last Name*"
                        valueProp={lastName}
                        handleChange={setLastName}
                        isError={errors.lastName?.length > 0}
                      />
                    </div>
                  </div>
                </div>

                <div className="CustomerContactModal_inner_flex_start">
                  <div className="col">
                    <div className="" style={{ marginBottom: 15 }}>
                      <InputBox
                        labelText="Position"
                        valueProp={position}
                        handleChange={setPosition}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="CustomerContactModal_inner_flex_start"
              style={{ marginBottom: 15 }}
            >
              <div className="col">
                <div
                  className="CustomerContactModal_inner_flex_start"
                  style={{ marginBottom: 15 }}
                >
                  <PlusButton
                    labelText={"New Name"}
                    btnClicks={() => {
                      setNewName(!newName);
                    }}
                    notFill={false}
                  />
                  {/* <PlusButton
                    labelText={"New Address"}
                    btnClicks={() => {
                      // null;
                    }}
                  /> */}
                </div>
              </div>
            </div>

            {newName && (
              <div
                className="CustomerContactModal_inner_flex_start"
                style={{ marginBottom: 15 }}
              >
                <div className="col">
                  <div className="col">
                    <InputBox
                      labelText="New Name"
                      valueProp={nickName}
                      handleChange={setNickName}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Top Section */}

            {/*  */}
            <div className="CustomerContactModal_inner_flex_start">
              <div className="col">
                <div className="CustomerContactModal_tabs">
                  <div
                    className={`CustomerContactModal_tab ${
                      tab === 1 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Address
                  </div>
                  <div
                    className={`CustomerContactModal_tab ${
                      tab === 2 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    Contact Details
                  </div>
                  <div
                    className={`CustomerContactModal_tab ${
                      tab === 3 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(3);
                    }}
                  >
                    Notes
                  </div>
                  <div
                    className={`CustomerContactModal_tab ${
                      tab === 4 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(4);
                    }}
                  >
                    Payment Details
                  </div>
                  <div
                    className={`CustomerContactModal_tab ${
                      tab === 5 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(5);
                    }}
                  >
                    {userType === "customer" ? "Invoices" : "Deliveries"}
                  </div>
                  <div
                    className={`CustomerContactModal_tab ${
                      tab === 6 ? "active" : ""
                    }`}
                    onClick={() => {
                      setTab(6);
                    }}
                  >
                    Others
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            {/*  */}
            <div className="CustomerContactModal_tab_body">
              {tab === 1 && (
                <FirstTab
                  addresses={addresses}
                  setAddresses={setAddresses}
                  addNewAddress={addNewAddress}
                />
              )}
              {tab === 2 && (
                <SecondTab
                  emails={emails}
                  setEmails={setEmails}
                  phones={phones}
                  setPhones={setPhones}
                  websites={websites}
                  setWebsites={setWebsites}
                  handlePhoneNumbers={handlePhoneNumbers}
                  handleAddEmails={handleAddEmails}
                  handleAddWebsites={handleAddWebsites}
                />
              )}
              {tab === 4 && (
                <ThirdTab
                  iban={iban}
                  setIban={setIban}
                  bic={bic}
                  setBic={setBic}
                  paymentCondition={paymentCondition}
                  setPaymentCondition={setPaymentCondition}
                  discount={discount}
                  setDiscount={setDiscount}
                  discountType={discountType}
                  setDiscountType={setDiscountType}
                  vatId={vatId}
                  setVatId={setVatId}
                />
              )}
              {tab === 3 && <FourthTab notes={notes} setNotes={setNotes} />}
              {tab === 5 && <InvoiceTab customerId={editedId} />}
              {tab === 6 && (
                <FifthTab
                  dob={dob}
                  setDob={setDob}
                  tags={tags}
                  setTags={setTags}
                />
              )}
            </div>
            {/*  */}
            <div className="d-flex CustomerContactModal_btns">
              <div
                className="bt-secondary"
                style={{ flex: 0 }}
                onClick={handleClose}
              >
                Abort
              </div>
              <button
                className={btnLoading ? "bt-secondary" : "bt-primary"}
                style={{ flex: 0 }}
                onClick={handleClick}
                disabled={btnLoading}
              >
                Save
              </button>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerContactModal;
