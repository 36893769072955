import React, { useState } from "react";
import "../../detailModal/DetailModal.css";
import {
  FiArrowLeftCircle,
  FiChevronDown,
  FiEye,
  FiPrinter,
  FiMail,
} from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Dropdown } from "rsuite";

const Header: React.FC<{ back: any; name: string; chatToggle: any }> = ({
  back,
  name,
  chatToggle,
}) => {
  return (
    <div className="DetailModal-main-middle-view-header">
      <div className="DetailModal-main-middle-view-header-back" onClick={back}>
        <FiArrowLeftCircle />
      </div>
      <div className="DetailModal-main-middle-view-header-title-spaced">
        <div className="DetailModal-main-middle-view-header-row">
          <div className="DetailModal-main-middle-view-header-title-text">
            {name}
          </div>
        </div>
        <div className="">
          <div className="DetailModal-main-middle-view-header-row">
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader"
              placement="bottomEnd"
              renderTitle={() => (
                <div className="bttn-chevrom">
                  <div>Save</div>
                  <FiChevronDown />
                </div>
              )}
            >
              <div className="dropDownRowSpaced">
                <div className="d-flex">
                  <FiEye />
                  <h6>Preview</h6>
                </div>
              </div>
              <div className="dropDownRowSpaced">
                <div className="d-flex">
                  <FiPrinter />
                  <h6>Print</h6>
                </div>
              </div>
              <div className="dropDownRowSpaced" onClick={chatToggle}>
                <div className="d-flex">
                  <FiMail />
                  <h6>Send with Email</h6>
                </div>
              </div>
              <div
                className="dropDownRowSpaced"
                onClick={chatToggle}
                style={{ borderBottom: 0 }}
              >
                <div className="d-flex">
                  <IoLogoWhatsapp />
                  <h6>Send with Whatsapp</h6>
                </div>
              </div>
            </Dropdown>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
