import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ChatBoxChatBody: React.FC<{children: any}> = ({children}) => {
  return (
    <div className="DetailModal-main-middle-view-chat-right-body">
      {children}
    </div>
  );
};

export default ChatBoxChatBody;
