import React, { useState } from "react";
import "../detailModal/DetailModal.css";

//helper
import { showAlert } from "../../../helpers/index";
//redux
import { connect } from "react-redux";
import {uploadActiveJobFile} from "../../../stores/activeJobs/actions";

const UploadModal: React.FC<{
  close: Function;
  uploadActiveJobFile: Function;
}> = ({ close, uploadActiveJobFile }) => {
  const [thumbnail, setThumbnail] = useState<any>(null)
  const [file, setFile] = useState<any>(null)
  const [btnLoading, setBtnLoading] = useState<boolean>(false)

  const handleChangeFile = (e: any) => {
    const file = e.target.files[0]
    setFile(file)
    if(file.type === "application/pdf"){
      setThumbnail("/assets/bg/pdf_icon.png")
    }else{
      setThumbnail(URL.createObjectURL(file))
    }
  }
  const handleUploadFile = async() =>{
    try{
      setBtnLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      await uploadActiveJobFile(formData)
      showAlert({ title: "Success!", message: "File Uploaded Successfully!", type: "success" })
      close()
    }catch(err){
      showAlert({ title: "Error!", message: "Something Went Wrong", type: "danger" })
    }finally{
      setBtnLoading(false)
    }
  }
  return (
    <>
      <div className="backdrop">
        <div className="DetailModal-card-bg card_modal upload_modal">
          <div className="DetailModal-card-header card_modal">
            <h6>Upload file</h6>
          </div>
          <div className='DetailModal-card-body-row' style={{ marginTop: 20, marginBottom: 20 }} >
            <div className='DetailModal-main-middle-view-body-row-col' >
              <div className="boc">
                <img src='/assets/2.png' />
              </div>
              <h6>
                Scan QR Code to upload photo
                with your mobile
              </h6>
            </div>
            <div className='DetailModal-main-middle-view-body-row-col' >
              <div className="boc">
                <label htmlFor="upload-btn">
                  {thumbnail ? <img src={thumbnail} /> : <img src='/assets/1.png' />}
                </label>
                <input
                  type="file"
                  id="upload-btn"
                  style={{ display: "none" }}
                  onChange={(e) => handleChangeFile(e)}
                  accept=".jpeg,.png,.jpg,.pdf"
                />
              </div>
              <h6>
                upload files from your device
                <div style={{ fontWeight: 400 }} >
                  Allowed file types: .png, .jpg, .jpeg, .pdf
                </div>
              </h6>
            </div>
          </div>

          <div className='d-flex' style={{ justifyContent: "flex-end" }} >
            <button className="btn btn-secondary" onClick={() => close()}>Abort</button>
            <button 
              className="btn btn-primary" 
              onClick={() => handleUploadFile()}
              disabled={btnLoading}
            > 
              Save & Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
  loading: false
});
const mapDispatchToProps = {
  uploadActiveJobFile
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);