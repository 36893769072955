import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";

// icons
import {
  FiChevronRight,
  FiChevronLeft,
  FiUploadCloud,
} from "react-icons/fi";


import SelectedServicesSection from "../selectedServicesSection/SelectedServicesSection";
const NewStep6: React.FC<{
  ChangeStep: Function;
  ChangeStepBack: Function;
}> = ({ ChangeStep, ChangeStepBack }) => {
  const [tab, setTab] = useState(1);
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper start">
          <SelectedServicesSection />
          <div className="stepFormWrapper-right">
            <h4 style={{ textAlign: "center", marginBottom: 10 }}>Signature</h4>
            <div className="stepFormWrapper-right_tab">
              <div
                className={`stepFormWrapper-right_tab-item ${tab === 1 ? "active" : ""
                  }`}
                onClick={() => {
                  setTab(1);
                }}
              >
                Draw
              </div>
              <div
                className={`stepFormWrapper-right_tab-item ${tab === 2 ? "active" : ""
                  }`}
                onClick={() => {
                  setTab(2);
                }}
              >
                upload
              </div>
            </div>
            {tab === 1 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <div className="JobModal_upload">
                      <SignatureCanvas
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 200,
                          className: "sigCanvas",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {tab === 2 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <div className="JobModal_upload">
                      <FiUploadCloud />
                      <p style={{ textAlign: "center" }}>Drag here..</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default NewStep6;
