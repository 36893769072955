import React, { useState } from "react";
import { FlexboxGrid } from "rsuite";
import "./DashBoard.css";
import { useParams } from "react-router-dom";

// component
import PageHeader from "../../components/dashboard/pageHeader/PageHeader";
import Turnover from "../../components/dashboard/turnover/Turnover";
import Unpaid from "../../components/dashboard/unpaid/Unpaid";
import Progress from "../../components/dashboard/progress/Progress";
import ChatNotification from "../../components/dashboard/chatNotification/ChatNotification";
import QuickTasks from "../../components/dashboard/quickTasks/QuickTasks";
import Stocks from "../../components/dashboard/stocks/Stocks";
import Manufacturers from "../../components/dashboard/manufacturers/Manufacturers";

const Dashbaord: React.FC<{ id: any }> = () => {
  let { id } = useParams<{ id: any }>();
  const [tab, setTab] = useState("jobs");

  React.useEffect(() => {
    if (id) {
      setTab(id);
    }
  }, [id]);

  return (
    <FlexboxGrid className="DashbaordPage">
      {/* pageheader */}
      <PageHeader />
      <div className="DashbaordPage_body">
        <div className="desk-only">
          <div className="DashbaordPage_body_row reverse">
            <div className="DashbaordPage_body_row_col mobile">
              {/* QuickTasks */}
              <QuickTasks />
              {/* chat notifications */}
              <ChatNotification />
            </div>
            <div className="DashbaordPage_body_row_col mobile">
              <div className="d-flex">
                <Turnover />
              </div>
              <div className="DashbaordPage_row">
                <div className="flex-1" style={{ paddingRight: 10 }}>
                  {/* stock */}
                  <Stocks />
                </div>
                <div className="flex-1" style={{ paddingLeft: 10, flex: 1.5 }}>
                  {/* stock */}
                  <Unpaid />
                </div>
              </div>
              <div className="DashbaordPage_row">
                <Manufacturers />
              </div>
              {/* PROGRESS OVERVIEW */}
              <Progress />
            </div>
          </div>
        </div>
        <div className="mob-only">
          {tab == "jobs" && (
            <>
              <Turnover />
              <div className="DashbaordPage_row">
                <div className="flex-1" style={{ paddingRight: 10 }}>
                  {/* stock */}
                  <Stocks />
                </div>
                <div className="flex-1" style={{ paddingLeft: 10, flex: 1.5 }}>
                  {/* stock */}
                  <Unpaid />
                </div>
              </div>
              <Manufacturers />
              <Progress />
              <ChatNotification />
            </>
          )}
          {tab == "task" && <QuickTasks />}
        </div>
      </div>

    </FlexboxGrid>
  );
};

export default Dashbaord;
