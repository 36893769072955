import React, { useState } from "react";
import "../detailModal/DetailModal.css";

// compoent
import ChatBoxWrapper from "../component/chatBox/ChatBoxWrapper";
import RepairWorkFlowBoxes from "../component/repairWorkFlowBoxes/RepairWorkFlowBoxes";

//
import Header from "../component/template/Header";

const RepairWorkflow: React.FC<{
  goback: any;
  setNotes: any;
  setQuotation: any;
  setInvoice: any;
  setServiceReceipt: any;
  completeModal: any;
  returnedClient: any;
  setJobRecipt: any;
  clickFunction: any;
}> = ({
  goback,
  setNotes,
  setQuotation,
  setInvoice,
  setServiceReceipt,
  completeModal,
  returnedClient,
  setJobRecipt,
  clickFunction,
}) => {
    return (
      <>
        {/* main view header */}
        <Header
          back={clickFunction}
          name={"Workflow Job-ID: 5922001"}
          chatToggle={() => { }}
        />
        <div className="DetailModal-main-middle">
          {/* main view */}
          <div className="DetailModal-main-middle-view">
            {/* main view body */}
            <div className="DetailModal-main-middle-view-body no-bg">
              <div className="DetailModal-main-middle-view-body-row-spaced">
                {/*  */}
                <RepairWorkFlowBoxes
                  clickFunction={setJobRecipt}
                  name="Job Receipt"
                  image="/assets/icon/1.png"
                />
                <RepairWorkFlowBoxes
                  clickFunction={setQuotation}
                  name="Quotation"
                  image="/assets/icon/2.png"
                />
                <RepairWorkFlowBoxes
                  clickFunction={setInvoice}
                  name="Invoice"
                  image="/assets/icon/3.png"
                />
                <RepairWorkFlowBoxes
                  clickFunction={setServiceReceipt}
                  name="Service Receipt"
                  image="/assets/icon/4.png"
                />
                <RepairWorkFlowBoxes
                  clickFunction={completeModal}
                  name="Set Complete"
                  image="/assets/icon/5.png"
                />
                <RepairWorkFlowBoxes
                  clickFunction={returnedClient}
                  name="Return To Client"
                  image="/assets/icon/6.png"
                />
              </div>
              {/* chat */}
              <ChatBoxWrapper />
              {/* chat */}
            </div>
            {/* main view body */}
          </div>
        </div>
      </>
    );
  };

export default RepairWorkflow;
