import React from "react";
// icons
import { AiFillPlusCircle } from "react-icons/ai";
import {FiPlusCircle} from "react-icons/fi"

const PlusButton: React.FC<{
  labelText: any;
  btnClicks?: any;
  notFill?: any;
}> = ({ labelText, btnClicks, notFill }) => {
  return (
    <div
      className="StockItemsModal_inner_flex_start "
      onClick={btnClicks}
      style={{
        flexWrap: "wrap",
        color: "#2589F6",
        alignItems:"center",
        cursor: "pointer",
        marginTop: 15,
        width: "fit-content",
        marginRight: 25,
      }}
    >
      {notFill !== true && (
        <AiFillPlusCircle
          style={{ marginRight: 10, marginTop: -5, width: 25, height: 25 }}
        />
      )}
      {notFill === true && (
        <FiPlusCircle
          style={{ marginRight: 10, marginTop: -5, width: 25, height: 25 }}
        />
      )}
      {labelText}
    </div>
  );
};
export default PlusButton;
