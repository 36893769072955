import React, { useState, useEffect } from "react";
import { FlexboxGrid } from "rsuite";
import "./CompnaySettings.scss";
import { v4 as uuid } from "uuid";

// component
import {
  PageHeader,
  CompnaySettingsAddress,
  CompnaySettingsContact,
  CompnaySettingsTAX,
  CompnaySettingsBank,
  CompnaySettingsLogo,
} from "../../components/compnaySettings";
import Loader from "../../components/loader/Loader";

//helpers
import { showAlert } from "../../helpers";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setCompanySettings,
  companySettingsState,
} from "../../toolkit/companySettings/companySettings.slice";
import { authState } from "../../toolkit/auth/auth.slice";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_COMPANY_SETTING } from "../../gql/companySettings/queries";
import {
  CREATE_COMPANY_SETTINGS_MUTATION,
  UPDATE_COMPANY_SETTINGS_MUTATION,
} from "../../gql/companySettings/mutation";
import { uploadImageApi } from "../../toolkit/companySettings/companySettings.api";

const CompnaySettings: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const { user, token } = useSelector(authState).auth;
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const { companySettings } = useSelector(companySettingsState).companySettings;

  const [createCompanySettingsMutation] = useMutation(
    CREATE_COMPANY_SETTINGS_MUTATION
  );
  const [updateCompanySettingsMutation] = useMutation(
    UPDATE_COMPANY_SETTINGS_MUTATION
  );

  const { data, loading, refetch } = useQuery(GET_COMPANY_SETTING, {
    variables: {
      createdBy: user.id,
    },
  });

  const handleSaveCompanyDetails = async () => {
    try {
      setBtnLoading(true);
      if (companySettings.id) {
        //update
        const { id, created_by, created_at, ...object } = companySettings;
        let company_logo = object.company_logo;
        if (file) {
          let formdata = new FormData();
          formdata.append("uploads", file);
          const resp: any = await uploadImageApi(formdata, token);
          if (
            Array.isArray(resp.data?.uploaded_files) &&
            resp.data?.uploaded_files?.length
          ) {
            company_logo = resp.data.uploaded_files[0]?.uploaded_file_url;
          }
        }
        await updateCompanySettingsMutation({
          variables: {
            object: {
              ...object,
              company_logo,
            },
            id,
          },
        });
      } else {
        //insert
        await createCompanySettingsMutation({
          variables: {
            object: {
              ...companySettings,
              default_tax_rate: parseFloat(companySettings.default_tax_rate),
              id: uuid(),
              created_by: user.id,
            },
          },
        });
      }
      refetch();
      showAlert({
        title: "Success!",
        message: `Company details ${
          companySettings.id ? "updated" : "created"
        } successfully.`,
        type: "success",
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something went wrong",
        type: "danger",
      });
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    if (Array.isArray(data?.company_details) && data.company_details?.length) {
      const { __typename, ...object } = data.company_details[0];
      dispatch(setCompanySettings(object));
    }
  }, [data]);
  return (
    <>
      <PageHeader
        onClick={handleSaveCompanyDetails}
        loading={btnLoading}
        headerText1="Settings"
        headerText2="Company Profile"
        simpleSaveBtn={true}
        saveDropdown={false}
        handelPrint={() => null}
      />
      <FlexboxGrid className="CompnaySettings">
        <CompnaySettingsAddress />
        <CompnaySettingsLogo onFileChange={setFile} />
        <CompnaySettingsContact />
        <CompnaySettingsTAX />
        <CompnaySettingsBank />
      </FlexboxGrid>
    </>
  );
};

export default CompnaySettings;
