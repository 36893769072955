import { IonImg } from "@ionic/react";
import "../../../pages/dashBoard/DashBoard.css";
import { FiChevronRight, FiPlus } from "react-icons/fi";
import { Placeholder } from "rsuite";
//redux
import { connect } from "react-redux";
import { fetchStockValue } from "../../../stores/stats/actions";
import { useEffect } from "react";
const Stocks: React.FC<{
  fetchStockValue: Function
  stockValue: any
  loading: boolean
}> = (props) => {
  const { Paragraph } = Placeholder;
  useEffect(()=>{
    // props.fetchStockValue()
  }, [])
  return (
    <div className="DashbaordPage_body_card">
      <div className="DashbaordPage_Header_wrapper">
        <p className="DashbaordPage_Header" >STOCK VALUE</p>
      </div>
      <div className="DashbaordPage_row">
        <div className="DashbaordPage_row_col_2">
        {props.loading ? (
            <Paragraph rows={2} rowMargin={8} active/>
          ) : (
          <div className="DashbaordPage_row">
            <h2 className="MONTH_text" style={{textAlign: 'left', marginTop: 0}} >€ {props.stockValue?.stockValue?.toLocaleString() || "0.00"}</h2>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  loading: false,
  stockValue: {},
});
const mapDispatchToProps = {
  fetchStockValue
};
export default connect(mapStateToProps, mapDispatchToProps)(Stocks);