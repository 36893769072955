import { IonImg } from "@ionic/react";
import "../../../pages/dashBoard/DashBoard.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Placeholder } from "rsuite";
import { getRandomColor } from "../../../helpers";
//redux
import { connect } from "react-redux";
import { fetchTopManufacturers } from "../../../stores/stats/actions";
import { useEffect, useState } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};
const Manufacturers: React.FC<{
  fetchTopManufacturers: Function;
  manufacturer: any;
  loading: boolean;
}> = (props) => {
  const { Paragraph } = Placeholder;
  const [labels, setLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<number[]>([]);
  const [bgColorData, setBGColorData] = useState<string[]>([
    "rgb(37, 137, 246)",
    "rgb(254, 198, 54)",
    "rgb(74, 183, 84)",
    "rgb(255, 95, 95)",
    "rgb(184, 67, 67)",
    "rgb(54, 224, 82)",
    "rgb(0, 132, 69)",
    "#ccc",
  ]);
  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: chartData,
        backgroundColor: bgColorData,
        borderWidth: 1,
      },
    ],
  };
  const getPercentage = (index: number) => {
    return parseInt(
      (
        (chartData[index] / props.manufacturer?.totalRepairCount) *
        100
      ).toString()
    );
  };
  const renderLabels = () => {
    var i: number,
      j,
      temporary,
      chunk = 4;
    let jsx: any[] = [];
    for (i = 0, j = labels.length; i < j; i += chunk) {
      temporary = labels.slice(i, i + chunk);
      jsx.push(
        <div className="" style={{ flex: 1 }} key={i}>
          {temporary.map((manufacturer: any, index: number) => {
            let actualIndex: number = i >= 4 ? i + index : index;
            return (
              <div
                className="DashbaordPage_row"
                style={{ marginBottom: 10 }}
                key={index}
              >
                <div
                  className="Manufacturers_color"
                  style={{ background: bgColorData[actualIndex] }}
                ></div>
                <div className="Manufacturers_name">{manufacturer}</div>
                <div className="Manufacturers_percentage">
                  ({chartData?.length > 0 ? getPercentage(actualIndex) : 0}%)
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return jsx;
  };
  useEffect(() => {
    // props.fetchTopManufacturers();
  }, []);
  useEffect(() => {
    if (Array.isArray(props.manufacturer.deviceCounts)) {
      let chartDataTemp = props.manufacturer.deviceCounts.map(
        (item: any) => item.repairCount
      );
      let manufacturerNames: any[] = [];
      props.manufacturer.deviceCounts.map((item: any) => {
        if (item._id?.length) {
          manufacturerNames.push(item._id[0]);
        }
      });
      setChartData(chartDataTemp);
      setLabels(manufacturerNames);
    }
  }, [props.manufacturer]);
  return (
    <div className="DashbaordPage_body_card">
      <div className="DashbaordPage_Header_wrapper">
        <p className="DashbaordPage_Header">TOP MANUFACTURERS</p>
        <div className="right"></div>
      </div>
      {props.loading ? (
        <Paragraph rows={5} rowMargin={12} rowHeight={18} active />
      ) : (
        <>
          <div
            className="PopularItems_flex"
            style={{ backgroundColor: "#fff" }}
          >
            <FiChevronLeft style={{ color: "#304a50", cursor: "pointer" }} />
            <div
              className="DashbaordPage_row_col"
              style={{ flex: 1, height: 130, maxWidth: 135 }}
            >
              <img src="/assets/theme/13.1.png" style={{ height: 104 }} />
              <Pie data={data} options={options} />
            </div>
            <div className="DashbaordPage_row_col" style={{ flex: 2 }}>
              <div className="before_data">
                <h4>YOU HAVE NO STATISTICS</h4>
                <h6>
                  <AiFillPlusCircle /> &nbsp; Book your first job
                </h6>
              </div>
              <div className="DashbaordPage_row">{renderLabels()}</div>
            </div>
            <FiChevronRight style={{ color: "#304a50", cursor: "pointer" }} />
          </div>
          <div className="d-flex-center">
            <div className="dot-active"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  manufacturer: {},
  loading: false,
});
const mapDispatchToProps = {
  fetchTopManufacturers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Manufacturers);
