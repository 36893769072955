import React, { useState, useEffect } from "react";
import { Toggle } from "rsuite";
import { v4 as uuid } from "uuid";

// icons
import { FiX } from "react-icons/fi";

//helpers
import { showAlert, formatPriceWithVat } from "../../../helpers";

// components
import StockItemsModalImagesWrapper from "./StockItemsModal.imagesWrapper";
import StockItemsModalTop from "./StockItemsModal.top";
import StockItemsModalBarcode from "./StockItemsModal.barcode";
import StockItemsModalSettings from "./StockItemsModal.settings";
import StockItemsModalSerial from "./StockItemsModal.serial";
import StockItemsModalSuppliers from "./StockItemsModal.suppliers";

//types
import StockItemType, { SupplierPriceType, BarcodeType, StockImageType } from "./StockItems.type";

//import validators
import validateCreateStockItem from "../../../validators/stocks/create";

//graphql
import { useMutation } from "@apollo/client";
import { CREATE_STOCK_ITEM_MUTATION, UPDATE_STOCK_ITEM_MUTATION } from "../../../gql/stockItems/mutation";

//redux
import { uploadImageApi } from "../../../toolkit/companySettings/companySettings.api";
import { authState } from "../../../toolkit/auth/auth.slice";
import { itemState } from "../../../toolkit/items/items.slice";
import { useSelector } from "react-redux";

const StockItemsModal: React.FC<{
  Close: any;
  refetch: Function;
  isEdited?: boolean;
  editedId?: string;
}> = ({ Close, refetch, isEdited, editedId, }) => {
  const { token } = useSelector(authState).auth;
  const { items } = useSelector(itemState);
  const [tab, setTab] = useState(1);
  const [state, setState] = useState<StockItemType>({
    id: uuid(),
    name: "",
    stock: "0",
    stock_unit: "pcs",
    manufacturer: "",
    category: "",
    manufacturer_no: "",
    physical_location: "",
    color: "",
    conditions: "",
    vat: "0",
    description: "",
    purchase_price_excl_vat: "0",
    purchase_price_incl_vat: "0",
    selling_price_excl_vat: "0",
    selling_price_incl_vat: "0",
    supplier_prices: [],
    barcodes: [],
    serial_numbers: [],
    images: [],
    stock_manage_on: false,
    stock_alert_on: false,
    minimum_stock: "",
    reminder_type: "",
    item_no: ""
  })
  const [files, setFiles] = useState<File[]>([])
  const [errors, setErrors] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [createStockItemMutation, { error, data }] = useMutation(CREATE_STOCK_ITEM_MUTATION);
  const [updateStockItemMutation] = useMutation(UPDATE_STOCK_ITEM_MUTATION);

  const formatPayload = async() =>{
    let payload: any = { ...state }
    if (payload.manufacturer?.length === 0) {
      payload.manufacturer = null
    }
    if (payload.color?.length === 0) {
      payload.color = null
    }
    if (payload.category?.length === 0) {
      payload.category = null
    }
    payload.stock = parseInt(payload.stock)
    payload.vat = parseInt(payload.vat)
    payload.purchase_price_excl_vat = parseInt(payload.purchase_price_excl_vat)
    payload.purchase_price_incl_vat = parseInt(payload.purchase_price_incl_vat)
    payload.selling_price_excl_vat = parseInt(payload.selling_price_excl_vat)
    payload.selling_price_incl_vat = parseInt(payload.selling_price_incl_vat)
    payload.minimum_stock = parseInt(payload.minimum_stock)
    payload.item_no = undefined
    if (files.length) {
      let formdata = new FormData();
      files.map((file: File) => {
        formdata.append("uploads", file);
      })
      const resp: any = await uploadImageApi(formdata, token);
      if (Array.isArray(resp.data?.uploaded_files) && resp.data?.uploaded_files?.length) {
        if(isEdited){
          let images:any[] = [...state.images.filter((image: StockImageType) => image.link.includes(process.env.REACT_APP_API_URL as string))]
          resp.data.uploaded_files.map((item: any, i: number) => {
            images.push({
              id: uuid(),
              link: item.uploaded_file_url,
              favourite: false,
              name: `Image ${images.length +1}`
            })
          })
          payload.images = images;
        }else{
          let images:any[] = [...payload.images]
          resp.data.uploaded_files.map((item: any, i: number) => {
            // images[i].link = item.uploaded_file_url
            images[i] = { ...images[i], link: item.uploaded_file_url }
          })
          payload.images = images;
        }
      }
    }
    return payload;
  }
  const handleCreateItem = async () => {
    const { errors, isValid } = validateCreateStockItem(state)
    if (isValid) {
      try {
        setBtnLoading(true)
        let payload = await formatPayload()
        payload.id = undefined
        if(isEdited){
          await updateStockItemMutation({
            variables: {
              id: editedId,
              object: payload
            }
          })
        }else{
          await createStockItemMutation({
            variables: {
              object: payload
            }
          });
        }
        refetch();
        showAlert({
          title: "Success!",
          message: `Stock ${isEdited ? "updated" : "created"} successfully!`,
          type: "success",
        });
        Close();
      } catch (err) {
        showAlert({
          title: "Failed!",
          message: "Something went wrong",
          type: "danger",
        });
        console.log(err)
      } finally {
        setBtnLoading(false)
      }
    } else {
      setErrors(errors)
    }
  }

  const handleClose = () => {
		resetState();
		Close();
	}
  const resetState = () => {
    setState({
      id: uuid(),
      name: "",
      stock: "0",
      stock_unit: "pcs",
      manufacturer: "",
      category: "",
      manufacturer_no: "",
      physical_location: "",
      color: "",
      conditions: "",
      vat: "0",
      description: "",
      purchase_price_excl_vat: "0",
      purchase_price_incl_vat: "0",
      selling_price_excl_vat: "0",
      selling_price_incl_vat: "0",
      supplier_prices: [],
      barcodes: [],
      serial_numbers: [],
      images: [],
      stock_manage_on: false,
      stock_alert_on: false,
      minimum_stock: "",
      reminder_type: ""
    })
  }
  useEffect(() => {
    if (editedId && items?.length) {
      const selectedItem = items.find((el: any) => el.id === editedId);
      if (Object.keys(selectedItem).length > 0) {
        setState({
          ...state,
          id: selectedItem.id,
          name: selectedItem.name,
          stock: String(selectedItem.stock),
          stock_unit: selectedItem.stock_unit,
          manufacturer: selectedItem.manufacturer,
          category: selectedItem.category,
          manufacturer_no: selectedItem.manufacturer_no,
          physical_location: selectedItem.physical_location,
          color: selectedItem.color,
          conditions: selectedItem.conditions,
          vat: String(selectedItem.vat),
          description: selectedItem.description,
          purchase_price_excl_vat: String(selectedItem.purchase_price_excl_vat),
          purchase_price_incl_vat: String(selectedItem.purchase_price_incl_vat),
          selling_price_excl_vat: String(selectedItem.selling_price_excl_vat),
          selling_price_incl_vat: String(selectedItem.selling_price_incl_vat),
          supplier_prices: selectedItem.supplier_prices,
          barcodes: selectedItem.barcodes,
          serial_numbers: [],
          images: selectedItem.images,
          stock_manage_on: selectedItem.stock_manage_on,
          stock_alert_on: selectedItem.stock_alert_on,
          minimum_stock: String(selectedItem.minimum_stock),
          reminder_type: selectedItem.reminder_type,
          item_no: selectedItem.item_no
        })
      }
    } else {
      resetState();
    }
  }, [editedId, items]);
  return (
    <>
      <div className="desk-only">
        <div className="StockItemsModal">
          <div className="StockItemsModal_inner">
            {/* header */}
            <div className="StockItemsModal_inner_top">
              <div className="StockItemsModal_inner_flex_start">
                <div className="col">
                  <h5 className="">{isEdited ? "Edit Item" : "New Item"}</h5>
                </div>
                <div className="close" onClick={handleClose}>
                  <FiX />
                </div>
              </div>
            </div>
            {/* header */}

            <StockItemsModalTop
              state={state}
              setState={setState}
              errors={errors}
              isEdited={isEdited || false}
            />

            {/*  */}
            <div className="StockItemsModal_inner_flex_start">
              <div className="col">
                <div className="StockItemsModal_tabs">
                  <div
                    className={`StockItemsModal_tab ${tab === 1 ? "active" : ""}`}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Supplier Prices
                  </div>
                  <div
                    className={`StockItemsModal_tab ${tab === 2 ? "active" : ""}`}
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    Barcode
                  </div>
                  <div
                    className={`StockItemsModal_tab ${tab === 3 ? "active" : ""}`}
                    onClick={() => {
                      setTab(3);
                    }}
                  >
                    Serial Numbers
                  </div>
                  <div
                    className={`StockItemsModal_tab ${tab === 4 ? "active" : ""}`}
                    onClick={() => {
                      setTab(4);
                    }}
                  >
                    Images
                  </div>
                  <div
                    className={`StockItemsModal_tab ${tab === 5 ? "active" : ""}`}
                    onClick={() => {
                      setTab(5);
                    }}
                  >
                    Stock Settings
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="StockItemsModal_tab_body">
              {tab === 1 && (
                <>
                  <StockItemsModalSuppliers
                    supplierPrices={state.supplier_prices}
                    onChange={(supplier_prices: SupplierPriceType[]) => setState({ ...state, supplier_prices })}
                  />
                </>
              )}
              {tab === 2 && (
                <>
                  <StockItemsModalBarcode
                    barcodes={state.barcodes}
                    onChange={(barcodes: BarcodeType[]) => setState({ ...state, barcodes })}
                  />
                </>
              )}
              {tab === 3 && (
                <>
                  <StockItemsModalSerial />
                </>
              )}
              {tab === 4 && (
                <>
                  <StockItemsModalImagesWrapper
                    images={state.images}
                    files={files}
                    onFileChange={setFiles}
                    onChange={(images: StockImageType[]) => setState({ ...state, images })}
                  />
                </>
              )}
              {tab === 5 && (
                <>
                  <StockItemsModalSettings
                    state={state}
                    setState={setState}
                  />
                </>
              )}
            </div>
            {/*  */}
            <div className="d-flex StockItemsModal_btns" style={{ justifyContent: "flex-end", marginTop: 15 }}>
              <div className="" style={{ marginRight: 10 }} >
                <div className="d-flex-start mb-1">
                  <input type="checkbox" style={{ marginRight: 5 }} />
                  <p>{isEdited ? "Update" : "Add"} &amp; Create Next</p>
                </div>
                <div className="d-flex">
                  <input type="checkbox" style={{ marginRight: 5 }} />
                  <p>Duplicate &amp; Create Next</p>
                </div>
              </div>
              <div className="bt-secondary" style={{ flex: 0 }} onClick={handleClose}>
                Abort
              </div>
              <button
                className={btnLoading ? "bt-secondary" : "bt-primary"}
                style={{ flex: 0 }}
                onClick={handleCreateItem}
                disabled={btnLoading}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockItemsModal;
