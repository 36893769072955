import { createSlice } from "@reduxjs/toolkit";
//types
import StockItemType from "../../components/stockItems/stockItemsModal/StockItems.type";

interface StateType {
  items: StockItemType[];
  errorMsg: string;
}

const initialState: StateType = {
  items: [],
  errorMsg: "",
};

const itemSlice: any = createSlice({
  name: "items",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      return {
        ...state,
        items: payload?.items,
      };
    },
  },
});

export const itemState = (state: any) => state.items;
export const { setItems } = itemSlice.actions;
export default itemSlice.reducer;
