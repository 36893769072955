import React, { useState } from "react";
import "../detailModal/DetailModal.css";

const Reference: React.FC<{}> = ({}) => {
  return (
    <>
      <div className="DetailModal-card">
        <div className="DetailModal-card-header">
          <h6>
            <span>Reference:</span> Something Refernce
          </h6>
        </div>
      </div>
    </>
  );
};

export default Reference;
