import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// component
import InputBox from "../../template/inputBox/InputBox";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  companySettingsState,
  setCompanySettings
} from "../../../toolkit/companySettings/companySettings.slice";

const CompnaySettingsContactStep1: React.FC<{ twickEdit?: any }> = ({
  twickEdit,
}) => {
  const dispatch = useDispatch();
  const { companySettings } = useSelector(companySettingsState).companySettings;
  const handleChange = (value: string, key: string) => {
    dispatch(setCompanySettings({
      ...companySettings,
      [key]: value
    }))
  }
  return (
    <>
      <div className="CompnaySettingsGeneralDetails_details">
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox
              handleChange={(value) => handleChange(value, "phone")}
              labelText="Telefon"
              valueProp={companySettings.phone}
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox
              labelText="FAX"
              handleChange={(value) => handleChange(value, "fax")}
              valueProp={companySettings.fax}
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox
              handleChange={(value) => handleChange(value, "email")}
              valueProp={companySettings.email}
              labelText="Email" />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox
              handleChange={(value) => handleChange(value, "website")}
              valueProp={companySettings.website}
              labelText="Website" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnaySettingsContactStep1;
