import { gql } from "@apollo/client";

export const GET_PROFILE_SETTINGS = gql`
  query GetProfileSettings($createdBy: uuid!) {
    profile_settings(where: {created_by: {_eq: $createdBy}}) {
      id
      language
      timezone
      date_format
      time_format
      currency
      background
    }
  }
`;
