import React, { useState, useEffect } from "react";
import { IonImg, IonContent } from "@ionic/react";
import { FlexboxGrid } from "rsuite";
import { useHistory } from "react-router-dom";
import "./ForgotPassword.css";

//redux
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordApi, verifyForgotPasswordApi, resetPasswordApi } from "../../toolkit/auth/auth.api";
import { authState, resetMessage } from "../../toolkit/auth/auth.slice";

// component
import SelectBox from "../../components/template/selectBox/SelectBox";
import ForgotPasswordSendOTP from "./stages/SendOTP";
import ForgotPasswordVerifyOTP from "./stages/VerifyOTP";
import ChangePassword from "./stages/ChangePassword";

//helpers
import { showAlert } from "../../helpers";
const ForgotPassword: React.FC<{
  resetPassword: Function;
}> = (props) => {
  const dispatch = useDispatch();
  const { auth: { accessToken, accessKey, message, errorMsg } } = useSelector(authState);

  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [otp, setOTP] = useState<string>("");
  const [errMsg, setErrMsg] = useState<String>("");
  const [selected, setSelected] = useState<String>("");
  const [passwordState, setPasswordState] = useState<{
    password: string;
    confPassword: string;
  }>({
    password: "",
    confPassword: ""
  });
  const [step, setStep] = useState<number>(1);

  const handleSendOTP = async (email: string) => {
    try {
      setEmail(email);
      await dispatch(forgotPasswordApi({ email }));
    } catch (err: any) {
      setErrMsg("Something went wrong");
    }
  };
  const handleVerifyOTP = async (e: React.SyntheticEvent<Element, Event>) => {
    try {
      e.preventDefault()
      setOTP(email);
      dispatch(verifyForgotPasswordApi({ email, otp }));
    } catch (err: any) {
      setErrMsg("Something went wrong");
    }
  };
  const handleResetPassword = async () => {
    try {
      dispatch(resetPasswordApi({
        password: passwordState.password,
        key: accessKey,
        accessToken: accessToken
      }));
    } catch (err: any) {
      setErrMsg("Something went wrong");
    }
  }
  useEffect(() => {
    if (message) {
      setErrMsg("");
      if (step === 1) {
        showAlert({
          title: "Success!",
          message: "We have send OTP in your email to reset your password.",
          type: "success",
        });
        setStep(2);
      } else if (step === 2) {
        setStep(3);
      } else if (step === 3) {
        showAlert({
          title: "Success!",
          message: "Password changed successfully. Please login with the new password.",
          type: "success",
        });
        history.push("/");
        dispatch(resetMessage());
      }
    }
    if (errorMsg) {
      setErrMsg(errorMsg);
    }else{
      setErrMsg("");
    }
  }, [message, errorMsg]);
  useEffect(()=>{
    dispatch(resetMessage());
  }, [step])
  return (
    <IonContent className="RegisterPage">
      <FlexboxGrid className="auth_Flex">
        <div className="auth_Flex_card">
          <FlexboxGrid.Item className="auth_Flex_Right ">
            <IonImg src="/assets/logo.png" className="auth_logo" />
            <h2 className="desk-only">"We will either find a way, or make one”</h2>
            <h3 className="desk-only">Hanibal</h3>
            <IonImg src="/assets/bg/auth.png" className="auth_logo_lg desk-only" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className="auth_Flex_Left">
            <>
              <div className="" style={{ width: 170, marginBottom: 15 }}>
                <SelectBox selectedValue={selected} labelText="Language">
                  <div
                    className=""
                    onClick={() => {
                      setSelected("English");
                    }}
                  >
                    English
                  </div>
                  <div
                    className=""
                    onClick={() => {
                      setSelected("Germany");
                    }}
                  >
                    Germany
                  </div>
                </SelectBox>
              </div>
              {step === 1 && (
                <ForgotPasswordSendOTP
                  handleSubmit={handleSendOTP}
                  errMsg={errMsg}
                />
              )}
              {step === 2 && (
                <ForgotPasswordVerifyOTP
                  onVerifyOTP={handleVerifyOTP}
                  errMsg={errMsg}
                  otp={otp}
                  setOTP={setOTP}
                />
              )}
              {step === 3 && (
                <ChangePassword
                  handleSubmit={handleResetPassword}
                  errMsg={errMsg}
                  passwordState={passwordState}
                  setPassword={setPasswordState}
                />
              )}
            </>
          </FlexboxGrid.Item>
        </div>
      </FlexboxGrid>
    </IonContent>
  );
};
export default ForgotPassword;
