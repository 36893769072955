import React, { useState } from "react";
import "../../detailModal/DetailModal.css";
import { FiPhone, FiChevronLeft, FiMaximize } from "react-icons/fi";

// components
import ChatBoxMenu from "./ChatBoxMenu";
import ChatActivityLog from "./ChatActivityLog";
import ChatBoxBody from "./ChatBoxBody";
import ChatBoxSend from "./ChatBoxSend";
import ChatBoxNote from "./ChatBoxNote";
import ChatBoxSearch from "./ChatBoxSearch";
import ChatBoxChatBody from "./ChatBoxChatBody";
import ChatBoxRightChat from "./ChatBoxRightChat";
import ChatBoxLeftChat from "./ChatBoxLeftChat";
import ChatBoxStatus from "./ChatBoxStatus";
import ChatBoxSearchCall from "./ChatBoxSearchCall";
import ChatBoxLeftChatCallList from "./ChatBoxLeftChatCallList";
import ChatBoxLeftChatVoiceMail from "./ChatBoxLeftChatVoiceMail";

const ChatBoxWrapper: React.FC<{}> = ({}) => {
  const [tab, setTab] = useState(1);
  const [inner, setInner] = useState("menu");
  const [menu, setMenu] = useState(1);
  const [callTab, setCallTab] = useState(1);
  const [called, setCalled] = useState(false);
  return (
    <>
      <div className="desk-only">
        <div className="DetailModal-main-middle-view-wait">
          <div className="d-flex" style={{ justifyContent: "space-between", padding: "0 10px 10px" }}>
            <div className="d-flex">
              <div
                className={`DetailModal-main-middle-view-tab-text ${tab === 1 ? "active" : ""}`}
                onClick={() => {
                  setTab(1);
                }}
              >
                Communication
              </div>
              <div
                className={`DetailModal-main-middle-view-tab-text ${tab === 2 ? "active" : ""}`}
                onClick={() => {
                  setTab(2);
                }}
              >
                Actvity Log
              </div>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-divider" style={{ margin: 0 }}></div>

          <div className="DetailModal-main-middle-view-chat">
            {tab === 1 && (
              <>
                <div className="DetailModal-main-middle-view-chat-left">
                  <h6>Channels</h6>
                  <div
                    className={`DetailModal-main-middle-view-chat-left-menu ${menu === 1 ? "active" : ""}`}
                    onClick={() => {
                      setMenu(1);
                    }}
                  >
                    <div className="d-flex-start">
                      {menu === 1 ? <img src="/assets/bg/1.png" alt="" /> : <img src="/assets/bg/1.1.png" alt="" />}
                      <h5>All </h5>
                    </div>
                    <h2>15</h2>
                  </div>
                  <div
                    className={`DetailModal-main-middle-view-chat-left-menu ${menu === 2 ? "active" : ""}`}
                    onClick={() => {
                      setMenu(2);
                    }}
                  >
                    <div className="d-flex-start">
                      {menu === 2 ? <img src="/assets/bg/2.png" alt="" /> : <img src="/assets/bg/2.2.png" alt="" />}
                      <h5>Email/Chat </h5>
                    </div>
                    <h2>15</h2>
                  </div>
                  <div
                    className={`DetailModal-main-middle-view-chat-left-menu ${menu === 3 ? "active" : ""}`}
                    onClick={() => {
                      setMenu(3);
                    }}
                  >
                    <div className="d-flex-start">
                      {menu === 3 ? <img src="/assets/bg/3.png" alt="" /> : <img src="/assets/bg/3.3.png" alt="" />}
                      <h5>Mobile-SMS </h5>
                    </div>
                    <h2>15</h2>
                  </div>
                  <div
                    className={`DetailModal-main-middle-view-chat-left-menu ${menu === 4 ? "active" : ""}`}
                    onClick={() => {
                      setMenu(4);
                    }}
                  >
                    <div className="d-flex-start">
                      {menu === 4 ? <img src="/assets/bg/4.png" alt="" /> : <img src="/assets/bg/4.4.png" alt="" />}
                      <h5>WhatsApp </h5>
                    </div>
                    <h2>15</h2>
                  </div>
                  <div
                    className={`DetailModal-main-middle-view-chat-left-menu ${menu === 5 ? "active" : ""}`}
                    onClick={() => {
                      setMenu(5);
                    }}
                  >
                    <div className="d-flex-start">
                      {menu === 5 ? <img src="/assets/bg/callMenuActive.png" alt="" /> : <img src="/assets/bg/callMenu.png" alt="" />}
                      <h5>Call </h5>
                    </div>
                    <h2>15</h2>
                  </div>
                </div>
                <ChatBoxBody>
                  {menu === 5 ? (
                    <>
                      <ChatBoxSearchCall />
                    </>
                  ) : (
                    <>{/* <ChatBoxSearch /> */}</>
                  )}
                  <ChatBoxChatBody>
                    <>
                      {menu === 1 && (
                        <>
                          <ChatBoxLeftChatCallList />
                          <ChatBoxLeftChatVoiceMail />
                        </>
                      )}
                      {menu === 5 ? (
                        <>
                          {callTab === 2 && (
                            <div className="keypad_tab">
                              {called === false ? (
                                <>
                                  <input type="text" className="number_pad_input" value={"+91 7003555843"} />
                                  <div className="number_pad">
                                    <div className="number_pad_btn">
                                      <h5>1</h5>
                                      <p></p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>2</h5>
                                      <p>ABC</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>3</h5>
                                      <p>DEF</p>
                                    </div>

                                    <div className="number_pad_btn">
                                      <h5>4</h5>
                                      <p>GHI</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>5</h5>
                                      <p>JKL</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>6</h5>
                                      <p>MNO</p>
                                    </div>

                                    <div className="number_pad_btn">
                                      <h5>7</h5>
                                      <p>PQRS</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>8</h5>
                                      <p>TUV</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>9</h5>
                                      <p>WXYZ</p>
                                    </div>

                                    <div className="number_pad_btn">
                                      <h5>*</h5>
                                      <p>,</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>0</h5>
                                      <p>+</p>
                                    </div>
                                    <div className="number_pad_btn">
                                      <h5>#</h5>
                                      <p></p>
                                    </div>
                                  </div>
                                  <div
                                    className="number_pad_call_btn"
                                    onClick={() => {
                                      setCalled(!called);
                                    }}
                                  >
                                    <FiPhone />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <img src="/assets/bg/dp.png" alt="" />
                                  <h6 className="CallName">Jane Doe</h6>
                                  <br />
                                  <h4 className="CallNumber">+91 700355843</h4>
                                  <h4 className="CallTime">01 : 55</h4>
                                  <br />
                                  <div className="d-flex">
                                    <div className="">
                                      <img src="/assets/bg/chat.png" alt="" className="callBtnImages" />
                                    </div>
                                    <div className="">
                                      <img src="/assets/bg/mute.png" alt="" className="callBtnImages" />
                                    </div>
                                    <div
                                      className=""
                                      onClick={() => {
                                        setCalled(!called);
                                      }}
                                    >
                                      <img src="/assets/bg/endCall.png" alt="" className="callBtnImages" />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          {callTab === 1 && (
                            <>
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatCallList />
                            </>
                          )}
                          {callTab === 3 && (
                            <>
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                              <ChatBoxLeftChatVoiceMail />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <ChatBoxLeftChat />
                          <ChatBoxRightChat />
                          <ChatBoxLeftChat />
                          <ChatBoxStatus />
                          <ChatBoxNote />
                        </>
                      )}
                    </>
                  </ChatBoxChatBody>
                  {menu === 5 ? (
                    <div className="DetailModal-main-middle-view-chat-right-send">
                      <div className="DetailModal-main-middle-view-chat-right-send-tabs">
                        <div className={`DetailModal-main-middle-view-chat-right-send-tab ${callTab === 1 ? "active" : ""}`} onClick={() => setCallTab(1)}>
                          {callTab === 1 ? <img src="/assets/bg/tabCallListActive.png" alt="" /> : <img src="/assets/bg/tabCallList.png" alt="" />}
                          <p>Call List</p>
                        </div>
                        <div className={`DetailModal-main-middle-view-chat-right-send-tab ${callTab === 2 ? "active" : ""}`} onClick={() => setCallTab(2)}>
                          {callTab === 2 ? <img src="/assets/bg/tabKeypadActive.png" alt="" /> : <img src="/assets/bg/tabKeypad.png" alt="" />}
                          <p>Keypad</p>
                        </div>
                        <div className={`DetailModal-main-middle-view-chat-right-send-tab ${callTab === 3 ? "active" : ""}`} onClick={() => setCallTab(3)}>
                          {callTab === 3 ? <img src="/assets/bg/tabVoiceMailActive.png" alt="" /> : <img src="/assets/bg/tabVoiceMail.png" alt="" />}
                          <p>Voicemail</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ChatBoxSend />
                  )}
                </ChatBoxBody>
              </>
            )}
            {tab === 2 && <ChatActivityLog />}
          </div>
        </div>
      </div>
      <div className="mob-only">
        <div className="DetailModal-main-middle-view-wait">
          <div className="d-flex" style={{ justifyContent: "space-between", padding: "0 5px 0px" }}>
            <>
              {inner === "menu" ? (
                <div className="d-flex">
                  <div
                    className={`DetailModal-main-middle-view-tab-text ${tab === 1 ? "active" : ""}`}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Communication
                  </div>
                  <div
                    className={`DetailModal-main-middle-view-tab-text ${tab === 2 ? "active" : ""}`}
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    Actvity Log
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <FiChevronLeft
                    onClick={() => {
                      setInner("menu");
                    }}
                  />
                  <img
                    src="/assets/bg/1.1.png"
                    alt=""
                    style={{ height: 15, margin: "5px" }}
                    onClick={() => {
                      setInner("menu");
                    }}
                  />
                  <div
                    className="DetailModal-main-middle-view-tab-text"
                    style={{ borderWidth: 0, padding: 0 }}
                    onClick={() => {
                      setInner("menu");
                    }}
                  >
                    {inner}
                  </div>
                </div>
              )}
            </>
            <FiMaximize />
          </div>
          <div className="DetailModal-main-middle-view-divider" style={{ margin: 0 }}></div>
          <div className="DetailModal-main-middle-view-chat">
            {tab === 1 && (
              <>
                {inner === "menu" ? (
                  <>
                    <div className="DetailModal-main-middle-view-chat-left">
                      <h6>Channels</h6>
                      <div
                        className={`DetailModal-main-middle-view-chat-left-menu ${menu === 1 ? "active" : ""}`}
                        onClick={() => {
                          setMenu(1);
                          setInner("All");
                        }}
                      >
                        <div className="d-flex-start">
                          {menu === 1 ? <img src="/assets/bg/1.png" alt="" /> : <img src="/assets/bg/1.1.png" alt="" />}
                          <h5>All </h5>
                        </div>
                        <h2>15</h2>
                      </div>
                      <div
                        className={`DetailModal-main-middle-view-chat-left-menu ${menu === 2 ? "active" : ""}`}
                        onClick={() => {
                          setMenu(2);
                          setInner("Email/Chat");
                        }}
                      >
                        <div className="d-flex-start">
                          {menu === 2 ? <img src="/assets/bg/2.png" alt="" /> : <img src="/assets/bg/2.2.png" alt="" />}
                          <h5>Email/Chat </h5>
                        </div>
                        <h2>15</h2>
                      </div>
                      <div
                        className={`DetailModal-main-middle-view-chat-left-menu ${menu === 3 ? "active" : ""}`}
                        onClick={() => {
                          setMenu(3);
                          setInner("Mobile-SMS");
                        }}
                      >
                        <div className="d-flex-start">
                          {menu === 3 ? <img src="/assets/bg/3.png" alt="" /> : <img src="/assets/bg/3.3.png" alt="" />}
                          <h5>Mobile-SMS </h5>
                        </div>
                        <h2>15</h2>
                      </div>
                      <div
                        className={`DetailModal-main-middle-view-chat-left-menu ${menu === 4 ? "active" : ""}`}
                        onClick={() => {
                          setMenu(4);
                          setInner("WhatsApp");
                        }}
                      >
                        <div className="d-flex-start">
                          {menu === 4 ? <img src="/assets/bg/4.png" alt="" /> : <img src="/assets/bg/4.4.png" alt="" />}
                          <h5>WhatsApp </h5>
                        </div>
                        <h2>15</h2>
                      </div>
                      <div
                        className={`DetailModal-main-middle-view-chat-left-menu ${menu === 5 ? "active" : ""}`}
                        onClick={() => {
                          setMenu(5);
                          setInner("Call");
                        }}
                      >
                        <div className="d-flex-start">
                          {menu === 5 ? <img src="/assets/bg/callMenuActive.png" alt="" /> : <img src="/assets/bg/callMenu.png" alt="" />}
                          <h5>Call </h5>
                        </div>
                        <h2>15</h2>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="">
                    <ChatBoxBody>
                      {menu === 5 ? (
                        <>
                          <ChatBoxSearchCall />
                        </>
                      ) : (
                        <>{/* <ChatBoxSearch /> */}</>
                      )}
                      <ChatBoxChatBody>
                        <>
                          {menu === 1 && (
                            <>
                              <ChatBoxLeftChatCallList />
                              <ChatBoxLeftChatVoiceMail />
                            </>
                          )}
                          {menu === 5 ? (
                            <>
                              {callTab === 2 && (
                                <div className="keypad_tab">
                                  {called === false ? (
                                    <>
                                      <input type="text" className="number_pad_input" value={"+91 7003555843"} />
                                      <div className="number_pad">
                                        <div className="number_pad_btn">
                                          <h5>1</h5>
                                          <p></p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>2</h5>
                                          <p>ABC</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>3</h5>
                                          <p>DEF</p>
                                        </div>

                                        <div className="number_pad_btn">
                                          <h5>4</h5>
                                          <p>GHI</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>5</h5>
                                          <p>JKL</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>6</h5>
                                          <p>MNO</p>
                                        </div>

                                        <div className="number_pad_btn">
                                          <h5>7</h5>
                                          <p>PQRS</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>8</h5>
                                          <p>TUV</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>9</h5>
                                          <p>WXYZ</p>
                                        </div>

                                        <div className="number_pad_btn">
                                          <h5>*</h5>
                                          <p>,</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>0</h5>
                                          <p>+</p>
                                        </div>
                                        <div className="number_pad_btn">
                                          <h5>#</h5>
                                          <p></p>
                                        </div>
                                      </div>
                                      <div
                                        className="number_pad_call_btn"
                                        onClick={() => {
                                          setCalled(!called);
                                        }}
                                      >
                                        <FiPhone />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <img src="/assets/bg/dp.png" alt="" />
                                      <h6 className="CallName">Jane Doe</h6>
                                      <br />
                                      <h4 className="CallNumber">+91 700355843</h4>
                                      <h4 className="CallTime">01 : 55</h4>
                                      <br />
                                      <div className="d-flex">
                                        <div className="">
                                          <img src="/assets/bg/chat.png" alt="" className="callBtnImages" />
                                        </div>
                                        <div className="">
                                          <img src="/assets/bg/mute.png" alt="" className="callBtnImages" />
                                        </div>
                                        <div
                                          className=""
                                          onClick={() => {
                                            setCalled(!called);
                                          }}
                                        >
                                          <img src="/assets/bg/endCall.png" alt="" className="callBtnImages" />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                              {callTab === 1 && (
                                <>
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                  <ChatBoxLeftChatCallList />
                                </>
                              )}
                              {callTab === 3 && (
                                <>
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                  <ChatBoxLeftChatVoiceMail />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <ChatBoxLeftChat />
                              <ChatBoxRightChat />
                              <ChatBoxLeftChat />
                              <ChatBoxStatus />
                              <ChatBoxNote />
                            </>
                          )}
                        </>
                      </ChatBoxChatBody>
                      {menu === 5 ? (
                        <div className="DetailModal-main-middle-view-chat-right-send">
                          <div className="DetailModal-main-middle-view-chat-right-send-tabs">
                            <div className={`DetailModal-main-middle-view-chat-right-send-tab ${callTab === 1 ? "active" : ""}`} onClick={() => setCallTab(1)}>
                              {callTab === 1 ? <img src="/assets/bg/tabCallListActive.png" alt="" /> : <img src="/assets/bg/tabCallList.png" alt="" />}
                              <p>Call List</p>
                            </div>
                            <div className={`DetailModal-main-middle-view-chat-right-send-tab ${callTab === 2 ? "active" : ""}`} onClick={() => setCallTab(2)}>
                              {callTab === 2 ? <img src="/assets/bg/tabKeypadActive.png" alt="" /> : <img src="/assets/bg/tabKeypad.png" alt="" />}
                              <p>Keypad</p>
                            </div>
                            <div className={`DetailModal-main-middle-view-chat-right-send-tab ${callTab === 3 ? "active" : ""}`} onClick={() => setCallTab(3)}>
                              {callTab === 3 ? <img src="/assets/bg/tabVoiceMailActive.png" alt="" /> : <img src="/assets/bg/tabVoiceMail.png" alt="" />}
                              <p>Voicemail</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <ChatBoxSend />
                      )}
                    </ChatBoxBody>
                  </div>
                )}
              </>
            )}

            {tab === 2 && <ChatActivityLog />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBoxWrapper;
