import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

import { FiSearch, FiPlus } from "react-icons/fi";

const ChatBoxSearchCall: React.FC<{}> = ({}) => {
  return (
    <>
      <div className="desk-only">
        <div className="d-flex-start" style={{ width: "525px", padding: "7px", justifyContent: "space-between" }}>
          <div className="ChatBoxSearchCall_dp">
            <img src="/assets/bg/dp.png" alt="" />
            <div className="ChatBoxSearchCall_dp_details">
              <h5>Jane Doe</h5>
              <p>Private</p>
            </div>
          </div>
          <div className="ChatBoxSearchCall_numbers">
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
            <div className="ChatBoxSearchCall_number active">+91 7003555843</div>
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
          </div>
          <div className="bt-plus">
            <FiPlus />
          </div>
        </div>
      </div>
      <div className="mob-only">
        <div className="d-flex-start" style={{ width: "100vw", padding: "7px", justifyContent: "space-between" }}>
          <div className="ChatBoxSearchCall_dp">
            <img src="/assets/bg/dp.png" alt="" />
            <div className="ChatBoxSearchCall_dp_details">
              <h5>Jane Doe</h5>
              <p>Private</p>
            </div>
          </div>
          <div className="ChatBoxSearchCall_numbers">
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
            <div className="ChatBoxSearchCall_number active">+91 7003555843</div>
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
            <div className="ChatBoxSearchCall_number">+31 1256874569</div>
          </div>
          <div className="bt-plus">
            <FiPlus />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBoxSearchCall;
