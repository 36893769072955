import React, { useState, useEffect } from "react";
import "./NewStep.css"
//import {} from "rsuite";
import {Button,Modal} from "react-bootstrap"
import { Checkbox } from "rsuite";
import { BsCheckCircle } from "react-icons/bs";
// icons
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import SelectedServicesSection from "../selectedServicesSection/SelectedServicesSection";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  jobState,
  setSelectedServices,
} from "../../../toolkit/jobs/jobs.slice";
import { shadow } from "@ionic/core/dist/types/utils/transition/ios.transition";

const NewStep2: React.FC<{
  ChangeStep: Function;
  ChangeStepBack: Function;
}> = ({ ChangeStep, ChangeStepBack }) => {
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [condition, setSelectedCondition] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [isHoverin, setIsHoverin] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isHoveri, setIsHoveri] = useState(false);
  const [isHovera, setIsHovera] = useState(false);
  const [isHovr, setIsHovr] = useState(false);
  const [Hoveren, setIsHoveren] = useState(false);
  const [IsHoverins, setIsHovar] = useState(false);
  const [IsHoveringi, setIsHoveringi] = useState(false);
  
  const handleMouseEnter = () => {
    
    setIsHovering(!isHovering);
    
    
  
  };
  const handleMousebutton = () => {
    
    setIsHover(!isHover);
  
  };
  const handleMousebuttn = () => {
    
    setIsHoverin(!isHoverin);
  
  };
  const handleMousebutn = () => {
    
    setIsHoveri(!isHoveri);
  
  };
  const handleMousebtn = () => {
    
    setIsHovera(!isHovera);
  
  };
  const handleMousebton = () => {
    
    setIsHovr(!isHovr);
  
  };
  const handleMousebtoon = () => {
    
    setIsHoveren(!Hoveren);
  
  };
  const handleMouse = () => {
    
    setIsHovar(!IsHoverins);
  
  };
  const handleMouseEnterButton = () => {
    
    setIsHoveringi(!IsHoveringi);
  
  };
  
  
  
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    
    setShow(true)
    
    
  };
  
  const [showForm, setShowForm] = useState(false);

  const closePassword = () => setShowForm(false);
  const showPassword = () =>{
    setShowForm(true);
    setShow(false);
  } 

  
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([])

  const closeModall = () =>{
      setShowModal(false);
      setIsHoveringi(false);
      setIsHovar(false);
      setIsHoveren(false);
      setIsHovr(false);
      setIsHovera(false);
      setIsHoveri(false);
      setIsHoverin(false);
      setIsHover(false);
      setIsHovering(false);

  }       
  const showModall = () =>{

    setShowModal(true);
    setShow(false);
  } 
  useEffect(() => {

  }, []);

  
  

  const [conditions, setConditions] = useState<any>([
    {
      id: 1,
      name: "condition 1",
    },
  ]);
  const [selectedDevice, setSelectedDevice] = useState<any>([
    {
      id: 1,
      name: "Apple"
    },
    {
      id: 2,
      name: "Apple1"
    }
      
  ])
  const [selectedDeviceModel, setSelectedDeviceModel] = useState<any>([
    {
      id: 1,
      name: "iPhone SE 2020"
    },
    {
      id: 2,
      name: "iPhone SE 2022"
    }
      
  ])

  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper">
          <SelectedServicesSection />
          <div className="stepFormWrapper-right">
            <div className="JobModal_row">
              <div className="col">
                <div className="" style={{ marginBottom: 15 }}>
                  {/* <SelectBox selectedValue={selected} labelText="Apple">
                    <div
                      className=""
                      onClick={() => {
                        setSelected("Apple");
                      }}
                    >
                      <div className="d-flex-start">Apple</div>
                    </div>
                  </SelectBox> */}
                  <SearchDropDown
                        labelText="Apple"
                        data={selectedDevice}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                        
                      />
                </div>
              </div>
              <div className="col">
                <div className="" style={{ marginBottom: 15 }}>
                  {/* <SelectBox
                    selectedValue={selected1}
                    labelText="iPhone SE 2020"
                  >
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("iPhone SE 2020");
                      }}
                    >
                      <div className="d-flex-start">iPhone SE 2020</div>
                    </div>
                  </SelectBox> */}
                  <SearchDropDown
                        labelText="iPhone SE 2020"
                        data={selectedDeviceModel}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                        
                      />
                </div>
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <InputBox
                  labelText="IMEI / Serial No. (15 digits)"
                  handleChange={() => null}
                />
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
              <span onClick={handleShow}>     <InputBox
                  labelText="Device Security"
                  handleChange={() => null}
                /></span>
                 <>

<Modal className="First" show={show}  onHide={handleClose} animation={false} style={{width:200,borderRadius:5,marginLeft:1150,marginTop:280,position:"absolute",background:"white",}} 
>
  {/* add securitymodal */}
  <Modal.Body >
    <p  className="p"style={{cursor:"pointer"}}><b>No Security</b></p><br></br>
    <p className="p"style={{cursor:"pointer"}}onClick={showPassword}><b>Password</b></p><br></br>
   <span onClick={showModall}> <p className="p" style={{cursor:"pointer"}}><b>Security Pattern</b></p></span>
  </Modal.Body>
 
</Modal>

</>
              </div>
            </div>
            <>
     

     <Modal className="Pattern" show={showModal} onHide={closeModall} animation={false} style={{width:300, marginLeft:1050, marginTop:280,position:"absolute",background:"white"}} aria-labelledby="contained-modal-title-vcenter"
     centered>
       {/* add securitymodal */}
      
       <Modal.Body  >
       <span style={{color:"white",cursor:"pointer",fontWeight:"20",marginLeft:230}} onClick={closeModall}>
         <button style={{width:30 ,background:"black"}}>X</button>   
          </span>
          <span style={{textAlign:"center" ,color:"black"}}><h3>Security Pattern</h3></span><br></br>
          <span style={{marginLeft:50,}}><button className="Btn" style={{width:32,height:32,marginLeft:15,borderRadius:50,color:"white",backgroundColor: isHovering ?'blue' : 'white'}}onClick={handleMouseEnter}>1</button>
          <button  className="Btn" style={{width:32,height:32,marginTop:20 ,marginLeft:25,borderRadius:50,color:"white",backgroundColor: isHovr ?'blue' : 'white'}}onClick={handleMousebton}>2</button>
          <button className="Btn" style={{width:32,height:32,marginTop:20 ,marginLeft:25,borderRadius:50,color:"white",backgroundColor: isHover ?'blue' : 'white'}}onClick={handleMousebutton}>3</button></span><br></br>
          <span style={{marginLeft:50}}><button className="Btn" style={{width:32,height:32,marginLeft:15 ,borderRadius:50,color:"white",backgroundColor: Hoveren ?'blue' : 'white'}}onClick={handleMousebtoon}>4</button>
          <button className="Btn" style={{width:32,height:32,marginTop:20 ,marginLeft:25,borderRadius:50,color:"white",backgroundColor: IsHoverins ?'blue' : 'white'}}onClick={handleMouse}>5</button>
          <button className="Btn" style={{width:32,height:32,marginTop:20 ,marginLeft:25,borderRadius:50,color:"white",backgroundColor: isHovera ?'blue' : 'white'}}onClick={handleMousebtn}>6</button></span><br></br>
          <span style={{marginLeft:50}}><button className="Btn" style={{width:32,height:32,marginLeft:15,borderRadius:50,color:"white",backgroundColor: isHoverin ?'blue' : 'white'}}onClick={handleMousebuttn}>7</button>
          <button className="Btn" style={{width:32,height:32,marginTop:20 ,marginLeft:25,borderRadius:50,color:"white",backgroundColor: isHoveri ?'blue' : 'white'}}onClick={ handleMousebutn}>8</button>
          <button className="Btn" style={{width:32,height:32,marginTop:20 ,marginLeft:25,borderRadius:50,color:"white",backgroundColor:  IsHoveringi ?'blue' : 'white'}}onClick={ handleMouseEnterButton} value={9}>9</button></span><br></br>
          <Button className="btn" type="submit" style={{textAlign:"center", width:90,height:40,borderRadius:25,marginTop:30,marginLeft:100,}}>  Save </Button>
       </Modal.Body>
      
     </Modal> 
    
   </>
   <>

      <Modal className="third" show={showForm} onHide={closePassword} animation={false} style={{width:215,marginLeft:1050,marginTop:280,background:"white", position:"absolute"}} aria-labelledby="contained-modal-title-vcenter"
      centered>
        {/* add securitymodal */}
        <Modal.Body >
         <input type="text" name="name" style={{width:180,marginLeft:20}}></input><br></br>
        <button type="submit" style={{ color:"blue",fontSize:40 ,marginLeft:78,marginTop:8,backgroundColor:"white"}}><BsCheckCircle /></button> 
        </Modal.Body>
       
      </Modal>
     
    </>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <SearchDropDown
                  labelText="Conditions"
                  data={conditions}
                  selectedOption={setSelectedCondition}
                  onMouseLeave={() => null}
                />
              </div>
            </div>

            <div className="JobModal_row" style={{ marginTop: 165 }}>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewStep2;
