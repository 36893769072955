import React, { useState, useEffect } from "react";
import "../../../pages/services/Services.scss";
import OtpInput from "react-otp-input";

// icons
import { FiX } from "react-icons/fi";

// component
import InputBox from "../../template/inputBox/InputBox";

//helpers
import { showAlert } from "../../../helpers";

//redux
import { useDispatch, useSelector } from "react-redux";
import { changeEmailApi, updateEmailApi, fetchProfileApi } from "../../../toolkit/profile/profile.api";
import { authState } from "../../../toolkit/auth/auth.slice";
import { profileState } from "../../../toolkit/profile/profile.slice";

const ChangeEmailModal: React.FC<{ close: any }> = ({ close }) => {
  const dispatch = useDispatch();
  const { token } = useSelector(authState).auth
  const { errorMsg } = useSelector(profileState).profile

  const [state, setState] = useState({
    new_email: "",
    conf_email: "",
    password: "",
    errorMsg: ""
  })
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const [otp, setOTP] = useState<string>("")
  const [showOTPBox, setShowOTPBox] = useState<boolean>(false)

  const reset = () => {
    setState({
      new_email: "",
      conf_email: "",
      password: "",
      errorMsg: ""
    })
  }
  const handleClose = () => {
    reset();
    close();
  }

  const handleChangeEmail = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    try {
      setBtnLoading(true)
      const resp = await dispatch(changeEmailApi({ formData: state, token }));
      if (resp.type === "profile/changeEmail/fulfilled") {
        setShowOTPBox(true);
      }
    } catch (err) {
      showAlert({
        title: "Failed!",
        message: `Something went wrong!`,
        type: "danger",
      });
    } finally {
      setBtnLoading(false)
    }
  }

  const handleVerifyOTP = async () => {
    if (otp.length !== 4) {
      setState({ ...state, errorMsg: "Please enter the OTP" })
    } else {
      const reqParam = {
        new_email: state.new_email,
        otp,
      };
      setBtnLoading(true);
      const resp = await dispatch(updateEmailApi({ formData: reqParam, token }));
      if (resp.type === "profile/updateEmail/fulfilled") {
        showAlert({
          title: "Success!",
          message: `Email changed successfully!`,
          type: "success",
        });
        handleClose();
        dispatch(fetchProfileApi(token))
      }else{
        setBtnLoading(false);
      }
    }
  }
  useEffect(() => {
    if (errorMsg?.length) {
      setState({ ...state, errorMsg })
    } else {
      setState({ ...state, errorMsg: "" })
    }
  }, [errorMsg])
  return (
    <div className="ChangeEmailModal">
      <form onSubmit={handleChangeEmail}>
        <div className="ChangeEmailModal_inner">
          <div className="ChangeEmailModal_inner_top">
            <h2>Change Email</h2>
            <div
              className="ChangeEmailModal_inner_top_cancel"
              onClick={() => {
                handleClose();
              }}
            >
              <FiX />
            </div>
          </div>
          {showOTPBox ? (
            <>
              <div className="flex-row mt-1">
                <div className="flex-row_col">
                  <OtpInput
                    value={otp}
                    onChange={setOTP}
                    numInputs={4}
                    inputStyle="login_otp"
                    hasErrored={true}
                    shouldAutoFocus={true}
                    isInputNum={true}
                    isInputSecure={false}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-row_col">
                  <p className={state.errorMsg.length > 0 ? "text-danger" : ""}>
                    {state.errorMsg.length > 0 ? state.errorMsg :
                      "Please enter the OTP from your new email."}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex-row mt-1">
                <div className="flex-row_col">
                  <InputBox
                    labelText="New Email"
                    handleChange={(new_email) => setState({ ...state, new_email })}
                    valueProp={state.new_email}
                    required={true}
                    type="email"
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-row_col">
                  <InputBox
                    handleChange={(conf_email) => setState({ ...state, conf_email })}
                    valueProp={state.conf_email}
                    labelText="Repeat New Email"
                    required={true}
                    type="email"
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-row_col">
                  <InputBox
                    handleChange={(password) => setState({ ...state, password })}
                    valueProp={state.password}
                    labelText="Password"
                    required={true}
                    type="password"
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-row_col">
                  <p className={state.errorMsg.length > 0 ? "text-danger" : ""}>
                    {state.errorMsg.length > 0 ? state.errorMsg :
                      "You have to verfy your email before login to your account with new email"}
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="flex-row">
            <div
              className="flex-row_col"
              onClick={() => {
                handleClose();
              }}
            >
              <div className="bt-secondary">Abort</div>
            </div>
            <div className="flex-row_col">
              {showOTPBox ? (
                <button
                  className="bt-primary"
                  type="button"
                  disabled={btnLoading}
                  onClick={handleVerifyOTP}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="bt-primary"
                  type="submit"
                  disabled={btnLoading}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangeEmailModal;
