import React, { useState, useEffect } from "react";
import { Toggle } from "rsuite";

import SelectBox from "../../../template/selectBox/SelectBox";
import InputBox from "../../../template/inputBox/InputBox";
import SearchDropDown from "../../../template/searchDropDown/SearchDropDown";



interface SupportedProps {
  iban: string;
  setIban: (i: string) => void;
  bic: string;
  setBic: (b: string) => void;
  paymentCondition: string;
  setPaymentCondition: (b: string) => void;
  discount: string;
  setDiscount: (b: string) => void;
  discountType: string;
  setDiscountType: (d: string) => void;
  vatId: String;
  setVatId: (d: string) => void;
}

const ThirdTab = ({
  iban,
  setIban,
  bic,
  setBic,
  paymentCondition,
  setPaymentCondition,
  discount,
  setDiscount,
  discountType,
  setDiscountType,
  vatId,
  setVatId
}: SupportedProps) => {
  const [selectedCountry, setSelectedCountry] = useState<any>("");

const [paymentsType, setPaymentsType] = useState<any>([
  {
    id: 1,
    name: "Private"
  },
  {
    id: 2,
    name: "Public"
  }
    
])
  
  return (
    <>
      <div className="CustomerContactModal_inner_flex_start">
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">Bank Details</h5>
        </div>
      </div>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15, width: "80%" }}
      >
        <div className="col">
          <InputBox labelText="IBAN" valueProp={iban} handleChange={setIban} />
        </div>
        <div style={{ width: 250 }}>
          <InputBox labelText="BIC" valueProp={bic} handleChange={setBic} />
        </div>
      </div>

      <div className="CustomerContactModal_inner_flex_start">
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">
            Terms of Payments
          </h5>
        </div>
      </div>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15, width: "80%" }}
      >
        <div className="col">
          <InputBox
            labelText="Payment Conditions"
            valueProp={paymentCondition}
            handleChange={setPaymentCondition}
          />
        </div>
        <div style={{ width: 120, marginRight: 10, marginTop:1, }}>
          <InputBox
            labelText="Discount"
            valueProp={discount}
            handleChange={setDiscount}
          />
        </div>
        <div style={{ width: 120, }} >
          {/*  */}
          <SelectBox selectedValue={discountType} labelText="Type">
            <div
              className=""
              onClick={() => {
                setDiscountType("$");
              }}
            >
              <div className="d-flex-start">$</div>
            </div>
            <div
              className=""
              onClick={() => {
                setDiscountType("%");
              }}
            >
              <div className="d-flex-start">%</div>
            </div>
          </SelectBox>
          {/* <SearchDropDown
                        labelText="Type"
                        data={paymentsType}
                        selectedOption={setSelectedCountry}
                        onMouseLeave={() => null}
                      /> */}

                
          {/*  */}
        </div>
      </div>

      <div className="CustomerContactModal_inner_flex_start">
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">TAX Details</h5>
        </div>
      </div>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15, width: "90%" }}
      >
        <div className="col">
          <InputBox 
            labelText="Vat ID" 
            valueProp={vatId} 
            handleChange={setVatId} 
          />
        </div>
        <div className="col">
          <div className="" style={{ marginTop: 15 }}>
            <div className="CustomerContactModal_inner_blueShow">
              <img src="assets/bg/112.png" alt="" />
              <div className="f1">
                <h6>Reverse Charge Regulation On Invoice</h6>
                <p>Liability to pay the VAT is shifted to the recipient</p>
              </div>
              <Toggle size="md" checkedChildren="ON" unCheckedChildren="OFF" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdTab;
