import axios from "axios";

// export const API_URL = "https://stage-v2.repaircms.com/api/";
// export const UPLOAD_URL = "https://app.repaircms.com/uploads/";
export const APP_URL = "https://app.repaircms.com/";
export const GOOGLE_OAUTH = process.env.REACT_APP_GOOGLE_OAUTH || "";
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || "";
export const APPLE_APP_ID = process.env.REACT_APP_APPLE_APP_ID || "";
export const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI || "";
export const API_URL  = 'http://localhost:8000/';
export const UPLOAD_URL  = 'http://localhost:8000/uploads/';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
