import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icons
import { FiEdit2 } from "react-icons/fi";

// component
import ProfileGeneralDetailsStep2 from "./ProfileGeneralDetails.step2";
import ProfileGeneralDetailsStep1 from "./ProfileGeneralDetails.step1";

const ProfileGeneralDetails = () => {
  const [edit, setEdit] = useState(false);

  return (
    <div className="ProfileGeneralDetails">
      <div className="flex-1 d-flex-start">
        <h2>My Profile</h2>
        {edit === false && (
          <div
            className="ProfileGeneralDetails_edit"
            onClick={() => {
              setEdit(!edit);
            }}
          >
            <FiEdit2 />
          </div>
        )}
      </div>

      {edit === false && <ProfileGeneralDetailsStep2 />}

      {edit === true && <ProfileGeneralDetailsStep1 twickEdit={() => {setEdit(false)}} />}
    </div>
  );
};

export default ProfileGeneralDetails;
