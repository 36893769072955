import React, { useState } from "react";
import { Dropdown } from "rsuite";
import "../../../pages/services/Services.scss";

// icon
import { FiMoreHorizontal } from "react-icons/fi";

import ServiceType, { ServiceImageType } from "../servicesModal/Services.type";
const ServicesItems: React.FC<{
  setModal: (m: boolean) => void;
  id: string;
  item: ServiceType;
  setEditedId: (i: string) => void;
  setIsEdited: (i: boolean) => void;
  removeService: Function;
}> = ({ id, item, setModal, setEditedId, setIsEdited, removeService }) => {
  const onEdit = () => {
    setModal(true);
    setEditedId(id);
    setIsEdited(true);
  }
  const getFavouriteImage = () => {
    if (Array.isArray(item.images) && item.images?.length) {
      const favouritePic: ServiceImageType[] = item.images.filter((image: ServiceImageType) => image.favourite)
      if (favouritePic.length) {
        return favouritePic[0].link
      } else {
        return "/assets/software.png"
      }
    } else {
      return "/assets/software.png"
    }
  }
  return (
    <>
      <div className="desk-only">
        <div className="ServicesItems">
          <div className="ServicesItems_left" onClick={onEdit}>
            <img src={getFavouriteImage()} alt="" />
            <div className="ServicesItems_left_1">
              <p>Sevice Name</p>
              <h6>{item.name}</h6>
            </div>
            <div className="ServicesItems_left_2">
              <p>Manufacturer</p>
              <h6>{item.manufacturer_rel?.name || "N/A"}</h6>
            </div>
            <div className="ServicesItems_left_3">
              <p>Model</p>
              <h6>{item.model_rel?.name || "N/A"}</h6>
            </div>
            <div className="ServicesItems_left_4">
              <p style={{ paddingBottom: 10 }} >
                {item.description.length > 20 ? `${item.description.slice(0, 20)}...` : item.description}
                {item.items_aggregate?.aggregate?.count > 0 ? (
                  <span>{item.items_aggregate.aggregate.count} x Assigned Item(s)</span>
                ) : (
                  <span>No Assigned Items</span>
                )}
              </p>
            </div>
          </div>
          <div className="ServicesItems_right">
            <div className="" onClick={onEdit}>
              <h5>€ {item.price_incl_vat}</h5>
              <p>Incl. {item.vat}% VAT</p>
            </div>
            {/* <FiMoreHorizontal /> */}
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/icon/dotsHorizontal.png" />}
            >
              <div className="dropDownProfile">
                <span className="dropDownProfile-Link" onClick={onEdit}>Edit</span>
                <span className="dropDownProfile-Link" onClick={()=> removeService(id)}>Remove</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesItems;
