import React from "react";

import "./invoicetext.scss";
interface Props {
  textData: string;
  setTextData: Function;
}

const InvoiceText = ({ textData, setTextData }: Props) => {
  return (
    <textarea className="textarea" value={textData} onChange={(e) => setTextData(e.target.value)} />
  );
};

export default InvoiceText;
