import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import {
  FiChevronDown,
  FiUploadCloud,
  FiX,
  FiTrash2,
  FiChevronUp,
  FiArrowLeftCircle,
  FiMaximize,
  FiPlus
} from "react-icons/fi";
import downloadjs from "downloadjs";
import Swal from "sweetalert2";

import UploadModal from '../uploadModal/UploadModal';
import GalleryModal from '../galleryModal/GalleryModal';

//config
import { UPLOAD_URL } from "../../../config/index";
//helpers
import { isValidUrl, isValidImage, getExtension, showAlert } from "../../../helpers/index";

//redux
import { connect } from "react-redux";
import { deleteActiveJobFile } from "../../../stores/activeJobs/actions";

const FileUpload: React.FC<{
  goback: any;
  job: any;
  deleteActiveJobFile: Function
}> = ({ goback, job, deleteActiveJobFile }) => {
  const [openFiles, setOpenFiles] = useState(true);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [gallery, setGallery] = useState(false);

  const onClickFile = (file: string) => {
    if (getExtension(file) === "pdf") {
      downloadjs(isValidUrl(file) ? file : `${UPLOAD_URL}${file}`)
    } else {
      setGallery(!gallery)
    }
  }
  const handleDeleteFile = (file: string) => {
    Swal.fire({
      title: 'Are you sure want to delete this file?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: "warning",
      heightAuto: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        let filename = isValidUrl(file) ? file.split('/').pop() : file
        await deleteActiveJobFile(filename)
        showAlert({ title: "Success!", message: "File Deleted Successfully!", type: "success" })
      }
    }).catch((err) => {
      showAlert({ title: "Error!", message: "Something Went Wrong", type: "danger" })
    })
  }
  return (
    <>
      <div className="desk-only">
        {openUploadModal && (
          <UploadModal close={() => setOpenUploadModal(false)} />
        )}
        {gallery && (
          <GalleryModal
            onClose={() => setGallery(false)}
            files={job.files.filter((file: string) => isValidImage(file))}
          />
        )}
        <div className="DetailModal-card">
          <div className="DetailModal-card-header">
            <h6>Files Uploaded</h6>
            <div
              className="d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              {/* <FiMaximize className="DetailModal-card-iconbtn cursor-pointer" /> */}
              <div onClick={() => { setOpenUploadModal(!openUploadModal) }}>
                <FiPlus className="DetailModal-card-iconbtn cursor-pointer" />
              </div>
              {openFiles ? (
                <FiChevronUp
                  className="cursor-pointer"
                  onClick={() => setOpenFiles(!openFiles)}
                />
              ) : (
                <FiChevronDown
                  className="cursor-pointer"
                  onClick={() => setOpenFiles(!openFiles)}
                />
              )}
            </div>
          </div>
          {openFiles && (
            <div className="DetailModal-card-body">
              <div className="DetailModal-card-body-row">
                {/* <div className="DetailModal-uploads">
                  <input type="file" id="upload1" />
                  <label htmlFor="upload1" className="DetailModal-uploads-img">
                    <FiUploadCloud />
                    <span>Upload</span>
                  </label>
                </div> */}
                {job.files?.map((file: string, i: number) => (
                  <div
                    className="DetailModal-uploads-img"
                    key={i}
                  >
                    {getExtension(file) === "pdf" ? (
                      <img
                        src={"/assets/bg/pdf_icon.png"}
                        alt="Image"
                        onClick={() => onClickFile(file)}
                      />
                    ) : (
                      <img
                        src={isValidUrl(file) ? file : `${UPLOAD_URL}${file}`}
                        alt="Image"
                        onClick={() => onClickFile(file)}
                      />
                    )}
                    <button onClick={() => handleDeleteFile(file)}>
                      <FiX />
                    </button>
                  </div>
                ))}
                {(job.files?.length === 0) && (
                  <p>No files found!</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mob-only">
        <div className="DetailModal-main-middle">
          {/* main view */}
          <div className="DetailModal-main-middle-view">
            {/* main view header */}
            <div className="DetailModal-main-middle-view-header">
              <div
                className="DetailModal-main-middle-view-header-back"
                onClick={goback}
              >
                <FiArrowLeftCircle />
              </div>
              <div className="DetailModal-main-middle-view-header-title-spaced">
                <div className="DetailModal-main-middle-view-header-row">
                  <div className="DetailModal-main-middle-view-header-title-text">
                    Information
                  </div>
                </div>
                <div className="desk-only">
                  <div className="DetailModal-main-middle-view-header-row">
                    <button className="btn-blue">Create An Information</button>
                  </div>
                </div>
              </div>
            </div>
            {/* main view header */}
            {/* main view body */}
            <div
              className="DetailModal-main-middle-view-body"
              style={{ padding: 0 }}
            >
              <div className="DetailModal-card-body">
                <div className="DetailModal-card-body-row">
                  <div className="DetailModal-uploads">
                    <input type="file" id="upload1" />
                    <label
                      htmlFor="upload1"
                      className="DetailModal-uploads-img"
                    >
                      <FiUploadCloud />
                      <span>Upload</span>
                    </label>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiTrash2 />
                    </button>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiTrash2 />
                    </button>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiTrash2 />
                    </button>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiTrash2 />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* main view body */}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
  loading: false,
});
const mapDispatchToProps = {
  deleteActiveJobFile
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);