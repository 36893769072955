import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// component
import InputBox from "../../template/inputBox/InputBox";
// import SelectBox from "../../template/selectBox/SelectBox";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  companySettingsState,
  setCompanySettings
} from "../../../toolkit/companySettings/companySettings.slice";

const CompnaySettingsBankStep1: React.FC<{ twickEdit?: any }> = ({
  twickEdit,
}) => {
  const dispatch = useDispatch();
  const { companySettings } = useSelector(companySettingsState).companySettings;
  const handleChange = (value:string, key:string) =>{
    dispatch(setCompanySettings({
      ...companySettings,
      [key]: value
    }))
  }
  return (
    <>
      <div className="CompnaySettingsGeneralDetails_details">
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              labelText="Bank Name" 
              handleChange={(value) => handleChange(value, "bank_name")} 
              valueProp={companySettings.bank_name}
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "bank_iban")} 
              valueProp={companySettings.bank_iban}
              labelText="IBAN" 
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "bank_bic")} 
              valueProp={companySettings.bank_bic}
              labelText="BIC" 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnaySettingsBankStep1;
