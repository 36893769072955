import { createSlice } from "@reduxjs/toolkit";
//types
import ServiceType from "../../components/services/servicesModal/Services.type";

interface StateType {
  services: ServiceType[];
  errorMsg: string;
}

const initialState: StateType = {
  services: [],
  errorMsg: "",
};

const servicesSlice: any = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices: (state, { payload }) => {
      return {
        ...state,
        services: payload?.services,
      };
    },
  },
});

export const serviceState = (state: any) => state.services;
export const { setServices } = servicesSlice.actions;
export default servicesSlice.reducer;