import React, { useState } from "react";
import "../detailModal/DetailModal.css";

const JobType: React.FC<{}> = ({}) => {

  return (
    <>
      <div className="DetailModal-card">
        <div className="DetailModal-card-header">
          <h6>
            <span>Job Type:</span> Express
          </h6>
        </div>
      </div>
    </>
  );
};

export default JobType;
