import React, { useState } from "react";

// icons
import { FaRunning, FaShippingFast, FaHeadSideCough } from "react-icons/fa";

const Step1: React.FC<{ ChangeStep: Function }> = ({ ChangeStep }) => {
  return (
    <>
      <div className="desk-only">
        <div className="s1">
          <h2>Select a repair option</h2>
          <div className="s1-flex">
            <div
              className="s1-item"
              onClick={() => {
                ChangeStep();
              }}
            >
              <div className="s1-item_icon">
                <FaRunning />
              </div>
              <p>DEFAULT</p>
            </div>
            <div
              className="s1-item"
              onClick={() => {
                ChangeStep();
              }}
            >
              <div className="s1-item_icon">
                <FaShippingFast />
              </div>
              <p>EXPRESS</p>
            </div>
            <div
              className="s1-item"
              onClick={() => {
                ChangeStep();
              }}
            >
              <div className="s1-item_icon">
                <FaHeadSideCough />
              </div>
              <p>CLAIM</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default Step1;
