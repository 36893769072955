import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quickTasks: [],
  errorMsg: "",
};

const dashboardSlice: any = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setQuickTasks: (state, { payload }) => {
      return {
        ...state,
        quickTasks: payload?.tasks,
      };
    },
  },
});

export const dashboardState = (state: any) => state.customers;
export const { setQuickTasks } = dashboardSlice.actions;

export default dashboardSlice.reducer;
