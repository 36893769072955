import { Dropdown } from "rsuite";
import "../../../pages/services/Services.scss";

// icons
import { FiUser, FiLayers, FiChevronDown } from "react-icons/fi";
import {FaUserTie} from 'react-icons/fa';

const PageHeader: React.FC<{title:string}> = ({title}) => {
  return (
    <div className="PageHeader">
      <div className="PageHeader-left">
        <FaUserTie />
        <h2>
          <span style={{opacity: 0.6}}>Contact</span> | {title}
        </h2>
      </div>
    </div>
  );
};

export default PageHeader;
