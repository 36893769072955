import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { FiChevronRight, FiChevronLeft, FiX } from "react-icons/fi";

//config
import {UPLOAD_URL} from "../../../config/index";
//helpers
import {isValidUrl} from "../../../helpers/index";
const GalleryModal: React.FC<{
  files: string[],
  onClose: Function
}> = ({
  onClose,
  files
}) => {
  const [active, setActive] = useState(0);
  const img = [
    {index: 1, img: 'assets/bg/1.jpeg'},
    {index: 2, img: 'assets/theme/4.png'},
    {index: 3, img: 'assets/theme/7.png'},
    {index: 4, img: 'assets/theme/5.png'},
  ]

  const next = () => {
    setActive( (active === (files.length - 1)) ? 0 : active + 1);
  }

  const prev = () => {
    setActive( active === 0 ? (files.length - 1) : active - 1);
  }

  return (
    <>
      <div className="backdrop gallery_modal">
          <button className="btn-close" onClick={() => onClose()}>
            <FiX />
          </button>
        <div className="DetailModal-card-bg card_modal gallery_modal">
          {files.map((file:string, i:number) => (
            <img src={isValidUrl(file) ? file : `${UPLOAD_URL}${file}`} className={`gallery_modal_img_lg ${active === i ? 'active' : ''} `} key={i} />
          ))}
          <div className="gallery_modal_chevron_left" onClick={() =>  prev()} >
            <FiChevronLeft className="gallery_modal_chevron_icon" />
          </div>
          <div className="gallery_modal_chevron_right" onClick={() =>  next()} >
            <FiChevronRight className="gallery_modal_chevron_icon" />
          </div>
          <div className="gallery_modal_img_ribbon" >
          {files.map((file:string, i:number) => (
            <img src={isValidUrl(file) ? file : `${UPLOAD_URL}${file}`} className={`gallery_modal_img_thumb ${active === i ? 'active' : ''}`} key={i} onClick={() => setActive(i)}/>
          ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryModal;
