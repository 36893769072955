import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ChatBoxStatus: React.FC<{}> = ({}) => {
  return (
    <div className="DetailModal-main-middle-view-chat-status">
      <div className="DetailModal-main-middle-view-chat-status-line"></div>
      <div className="DetailModal-main-middle-view-chat-status-inner">
        <div className="DetailModal-main-middle-view-chat-status-flex">
          <img
            src="/assets/icon/2.png"
            className="DetailModal-main-middle-view-chat-status-img"
          />
          <h5>Quotation</h5>
        </div>
        <div className="DetailModal-main-middle-view-chat-status-flex-spaced">
          <h6>Today</h6>
          <div className="DetailModal-main-middle-view-chat-status-flex-end">
            <h6>15:40 </h6>
            <img
              src="/assets/bg/8.png"
              className="DetailModal-main-middle-view-chat-status-img-icon"
            />
          </div>
        </div>
      </div>
      <div className="DetailModal-main-middle-view-chat-status-line"></div>
    </div>
  );
};

export default ChatBoxStatus;
