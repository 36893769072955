import React, { useState, useEffect } from "react";
import "./CustomerContact.scss";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

// icons
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";

//import helpers
import { showAlert } from "../../helpers";

// component
import {
  PageHeader,
  CustomerContactItems,
  CustomerContactModal,
  ContactFilters,
} from "../../components/customerContact";
import Loader from "../../components/loader/Loader";
import Paging from "../../components/common/paging/Paging";
import ExcelExport from "../../components/common/excelExport/ExcelExport";
import SortPicker from "../../components/common/SortPicker";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  customerState,
  setCustomer,
} from "../../toolkit/customers/customers.slice";
import { useQuery, useMutation } from "@apollo/client";

import { GET_ALL_CUSTOMER } from "../../gql/customers/queries";
import { UPDATE_CUSTOMER_MUTATION } from "../../gql/customers/mutation";
import { authState } from "../../toolkit/auth/auth.slice";

//functions
import {
  BuildCustomerSearchQuery,
  BuildContactExportColumns,
  BuildContactExportData,
  BuildContactFilterQuery,
  BuildContactSortQuery,
} from "./CustomerContact.functions";

//types
import CustomerTypes from "./CustomerContact.types";

const CustomerContact: React.FC<{}> = (props) => {
  const params = useParams<{ type: string }>();
  const dispatch = useDispatch();
  const { customers } = useSelector(customerState);
  const {
    auth: { user },
  } = useSelector(authState);
  const defaultCondition = {
    created_by: { _eq: user.id },
    active: { _eq: true },
    user_type: { _eq: params.type },
  };
  const [modal, setModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<string>("");
  const [queryCondition, setQueryCondition] = useState<any>(defaultCondition);
  const [search, setSearch] = useState<string>("");
  const [orderCondition, setOrderCondition] = useState<any>({
    created_at: "desc",
  });
  const [sort, setSort] = useState("");
  const [contactFilters, contactSetFilter] = useState<any>({});

  const limit = 10;

  const { data, refetch, error, loading } = useQuery(GET_ALL_CUSTOMER, {
    variables: {
      limit: limit,
      offset: offset,
      where: queryCondition,
      order: orderCondition,
    },
  });
  const [updateCustomerMutation, { error: errorM, data: updateData }] =
    useMutation(UPDATE_CUSTOMER_MUTATION);

  useEffect(() => {
    if (error) {
      setErrorMsg(error.message);
    } else {
      dispatch(setCustomer(data));
      if (typeof data?.customers_aggregate?.aggregate?.count !== "undefined") {
        setTotalPages(
          Math.ceil(data?.customers_aggregate?.aggregate?.count / limit)
        );
      }
    }
  }, [error, data]);

  const handlePage = (page: number) => {
    setActivePage(page);
    setOffset(limit * page - limit);
  };

  const removeCustomer = (id: string) => {
    Swal.fire({
      title: "Are you sure want to remove this customer?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
      heightAuto: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateCustomerMutation({
          variables: {
            object: {
              active: false,
            },
            id,
          },
        });
        refetch();
        showAlert({
          title: "Success!",
          message: `Customer removed successfully!`,
          type: "success",
        });
      }
    });
  };

  useEffect(() => {
    if (updateData) {
      refetch();
    }
  }, [updateData]);
  useEffect(() => {
    const conditionTemp = BuildCustomerSearchQuery(search, null);
    setQueryCondition({
      ...defaultCondition,
      ...conditionTemp,
    });
  }, [search]);
  useEffect(() => {
    setQueryCondition({
      ...defaultCondition,
      user_type: { _eq: params.type },
    });
  }, [params.type]);

  useEffect(() => {
    if (sort.length > 0) {
      const _sortQuery = BuildContactSortQuery(sort);
      setOrderCondition(_sortQuery);
    } else {
      setOrderCondition({ created_at: "desc" });
    }
  }, [sort]);
  useEffect(() => {
    if (Object.keys(contactFilters).length) {
      const conditionTemp = BuildContactFilterQuery(contactFilters, null);
      setQueryCondition({
        ...defaultCondition,
        ...conditionTemp,
      });
    } else {
      setOrderCondition({ created_at: "desc" });
    }
  }, [contactFilters]);

  return (
    <FlexboxGrid className="CustomerContact">
      <PageHeader
        title={params.type === "customer" ? "Customers" : "Suppliers"}
      />
      <div className="CustomerContact_body">
        <div>
          <div className="CustomerContact_body_top">
            <div className="CustomerContact_body_top1">
              <div className="CustomerContact_body_top_btn1">
                <SortPicker setSort={setSort} />
              </div>
              <div>
                <ContactFilters
                  contactFilters={contactFilters}
                  contactSetFilter={contactSetFilter}
                />
              </div>
            </div>
            <div className="CustomerContact_body_top2">
              <div className="JobsOverview_body_top_search">
                <div className="searchIcon">
                  <FiSearch />
                </div>
                <input
                  type="text"
                  className="joboverview-searchInput"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div
                className="CustomerContact_body_top_btn2"
                onClick={() => {
                  setModal(true);
                  setIsEdited(false);
                  setEditedId("");
                }}
              >
                <FiPlus className="add_icon" />
              </div>
              <div>
                <Dropdown
                  trigger={["click"]}
                  className="actionDropPageHeader export"
                  placement="bottomEnd"
                  renderTitle={() => (
                    <FiMoreVertical
                      style={{
                        color: "#304a50",
                        fontSize: 20,
                        cursor: "pointer",
                      }}
                    />
                  )}
                >
                  <div
                    className="dropDownRowSpaced"
                    style={{ border: 0, padding: 5 }}
                  >
                    <div className="dropDownRowSpaced-option">
                      <FiUpload />
                      <ExcelExport
                        columns={BuildContactExportColumns()}
                        data={BuildContactExportData(customers)}
                        filename={`repaircms_customers_${Date.now()}`}
                        fileExtension="xlsx"
                      >
                        <h6>Export to Excel</h6>
                      </ExcelExport>
                    </div>
                  </div>
                  <div
                    className="dropDownRowSpaced"
                    style={{ border: 0, padding: 5 }}
                  >
                    <div className="dropDownRowSpaced-option">
                      <FiUpload />
                      <ExcelExport
                        columns={BuildContactExportColumns()}
                        data={BuildContactExportData(customers)}
                        filename={`repaircms_customers_${Date.now()}`}
                        fileExtension="csv"
                      >
                        <h6>Export to CSV</h6>
                      </ExcelExport>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="CustomerContact_body_bottom">
            {loading || typeof customers === "undefined" ? (
              <Loader />
            ) : customers.length > 0 ? (
              <>
                {customers.map(
                  ({
                    id,
                    first_name,
                    last_name,
                    emails,
                    addresses,
                    active,
                    created_at,
                    phones,
                    type,
                    organization,
                  }: CustomerTypes) => (
                    <CustomerContactItems
                      key={id}
                      id={id}
                      first_name={first_name}
                      last_name={last_name}
                      emails={emails}
                      addresses={addresses}
                      active={active}
                      created_at={created_at}
                      phones={phones}
                      type={type}
                      organization={organization}
                      setModal={setModal}
                      setEditedId={setEditedId}
                      setIsEdited={setIsEdited}
                      removeCustomer={removeCustomer}
                    />
                  )
                )}
              </>
            ) : (
              <>
                <p className="text-center mt-25" style={{ fontSize: "1rem" }}>
                  No {params.type}s found!
                </p>
              </>
            )}
          </div>
        </div>

        <div className="table-footer">
          <Paging
            activePage={activePage}
            pages={totalPages}
            onPageClick={handlePage}
          />
        </div>
      </div>

      {/* Modal */}
      {modal && (
        <CustomerContactModal
          isEdited={isEdited}
          editedId={editedId}
          Close={() => {
            setModal(false);
          }}
          refetch={refetch}
          userType={params.type}
        />
      )}
      {/* Modal */}
    </FlexboxGrid>
  );
};

export default CustomerContact;
