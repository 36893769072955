// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { loginRemoveToken } from "../auth/auth.slice";
import apiURL from "../../config/index";

export const uploadImageApi = (formData: FormData, token: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiURL.post("upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};

// export const fetchCompanySettingsApi: any = createAsyncThunk(
//   "settings/recipts",
//   async (token, thunkAPI) => {
//     try {
//       const response = await apiURL.get("/", {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return response.data;
//     } catch (err: any) {
//       return thunkAPI.rejectWithValue({
//         err: "Network Error",
//       });
//     }
//   }
// );
