import React, { useState } from "react";

// icons
import {
  FaRunning,
  FaBarcode,
  FaTag,
  FaUserAlt,
  FaExclamationTriangle,
  FaPrint,
  FaTabletAlt,
  FaShippingFast,
  FaHeadSideCough,
} from "react-icons/fa";
import { FiX, FiUser,FiPrinter } from "react-icons/fi";

// component
import NewStep1 from "../newStep1/NewStep1";
import NewStep2 from "../newStep2/NewStep2";
import NewStep3 from "../newStep3/NewStep3";
import NewStep4 from "../newStep4/NewStep4";
import NewStep5 from "../newStep5/NewStep5";
import NewStep6 from "../newStep6/NewStep6";
import NewStep7 from "../newStep7/NewStep7";

const JobModalPricingSteps: React.FC<{}> = ({}) => {
  const [step, setStep] = useState(1);

  return (
    <>
      <div className="desk-only">
        <div className="JobModal_top level2">
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 1 ? "active" : ""
              } ${step > 1 ? "prev" : ""}`}
            >
              <FaRunning color="#fff" />
            </div>
            <p
              className={`${step === 1 ? "active" : ""} ${
                step > 1 ? "prev" : ""
              }`}
            >
              Service
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 1 ? "active" : ""} ${
              step > 1 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 2 ? "active" : ""
              } ${step > 2 ? "prev" : ""}`}
            >
              <FaTabletAlt color="#fff" />
            </div>
            <p
              className={`${step === 2 ? "active" : ""} ${
                step > 2 ? "prev" : ""
              }`}
            >
              Device
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 2 ? "active" : ""} ${
              step > 2 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 3 ? "active" : ""
              } ${step > 3 ? "prev" : ""}`}
            >
              <FaTag color="#fff" />
            </div>
            <p
              className={`${step === 3 ? "active" : ""} ${
                step > 3 ? "prev" : ""
              }`}
            >
              Contact
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 3 ? "active" : ""} ${
              step > 3 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 4 ? "active" : ""
              } ${step > 4 ? "prev" : ""}`}
            >
              <FaBarcode color="#fff" />
            </div>
            <p
              className={`${step === 4 ? "active" : ""} ${
                step > 4 ? "prev" : ""
              }`}
            >
              Defect
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 4 ? "active" : ""} ${
              step > 4 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 7 ? "active" : ""
              } ${step > 5 ? "prev" : ""}`}
            >
              <FiPrinter color="#fff" />
            </div>
            <p
              className={`${step === 7 ? "active" : ""} ${
                step > 5 ? "prev" : ""
              }`}
            >
              Print
            </p>
          </div>

          {/*  */}
        </div>
        <div className="JobModal_bottom">
          {step === 1 && (
            <NewStep1
              ChangeStep={() => {
                setStep(2);
              }}
            />
          )}
          {step === 2 && (
            <NewStep2
              ChangeStep={() => {
                setStep(3);
              }}
              ChangeStepBack={() => {
                setStep(1);
              }}
            />
          )}
          {step === 3 && (
            <NewStep3
              ChangeStep={() => {
                setStep(4);
              }}
              ChangeStepBack={() => {
                setStep(2);
              }}
            />
          )}
          {step === 4 && (
            <NewStep4
              ChangeStep={() => {
                setStep(5);
              }}
              ChangeStepBack={() => {
                setStep(3);
              }}
            />
          )}
          {step === 5 && (
            <NewStep5
              ChangeStep={() => {
                setStep(6);
              }}
              ChangeStepBack={() => {
                setStep(4);
              }}
            />
          )}
          {step === 6 && (
            <NewStep6
              ChangeStep={() => {
                setStep(7);
              }}
              ChangeStepBack={() => {
                setStep(5);
              }}
            />
          )}
          {step === 7 && (
            <NewStep7
              ChangeStep={() => {
                setStep(7);
              }}
              ChangeStepBack={() => {
                setStep(6);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default JobModalPricingSteps;
