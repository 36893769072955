import React, { useState, useEffect } from "react";
import "../../../pages/dashBoard/DashBoard.css";
import { Loader, Placeholder } from "rsuite";
import { FiRefreshCw } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

//redux
import { connect } from "react-redux";
import { fetchProgressOverview } from "../../../stores/stats/actions";

//=======ChatJS configurations
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
};
//=======ChatJS configurations

const Progress: React.FC<{
  fetchProgressOverview: Function;
  progressOverview: any;
  loading: boolean;
}> = (props) => {
  const { Paragraph } = Placeholder;
  const [chartData, setChartData] = useState<number[]>([]);
  const [haveData, setHaveData] = useState<boolean>(false);
  const refetch = () => {
    // props.fetchProgressOverview()
  };
  useEffect(() => {
    // props.fetchProgressOverview()
  }, []);
  const labels = [
    "Booked in",
    "Repair in progress",
    "Quotation - Confirmed",
    "Quotation - Rejected",
    "Waiting for Parts",
    "Completed",
    "Returned Devices",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: chartData,
        backgroundColor: [
          "#2589F6",
          "#FEC636",
          "#4AB754",
          "#FF5F5F",
          "#B84343",
          "#36E052",
          "#008445",
        ],
      },
    ],
  };
  useEffect(() => {
    if (Object.keys(props.progressOverview)?.length) {
      const {
        bookedInCount,
        completedCount,
        confirmedCount,
        inProgressCount,
        onHoldCount,
        rejectedCount,
        returnedCount,
      } = props.progressOverview;
      setChartData([
        bookedInCount,
        inProgressCount,
        confirmedCount,
        rejectedCount,
        onHoldCount,
        completedCount,
        returnedCount,
      ]);
    }
  }, [props.progressOverview]);
  return (
    <div className="DashbaordPage_body_card Dashboard_Progress ">
      <div className="DashbaordPage_Header_wrapper">
        <p className="DashbaordPage_Header">PROGRESS OVERVIEW</p>
        <div className="right pointer" onClick={refetch}>
          <FiRefreshCw />
        </div>
      </div>
      <div className="divider-horizontal"></div>
      {/*  */}

      {haveData === false ? (
        <div className="before_data two">
          <img src="/assets/theme/14.png" alt="" />
          <h4>YOU HAVE NO PROGRESS</h4>
          <h6>
            <AiFillPlusCircle /> &nbsp; Book your first job
          </h6>
        </div>
      ) : (
        <>
          <div className="DashbaordPage_row Dashboard_Progress_left ">
            <div className="DashbaordPage_row_col_2">
              {/*  */}
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#2589F6" }}
                  ></div>
                  <div className="PROGRESS_item_text">Booked in</div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.bookedInCount
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#FEC636" }}
                  ></div>
                  <div className="PROGRESS_item_text">Repair in Progress</div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.inProgressCount
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#4AB754" }}
                  ></div>
                  <div className="PROGRESS_item_text">
                    Quotation - Confirmed
                  </div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.confirmedCount
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#FF5F5F" }}
                  ></div>
                  <div className="PROGRESS_item_text">Quotation - Rejected</div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.rejectedCount
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#B84343" }}
                  ></div>
                  <div className="PROGRESS_item_text">Waiting for Parts</div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.onHoldCount
                    )}
                  </div>
                </div>
              </div>
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#36E052" }}
                  ></div>
                  <div className="PROGRESS_item_text">Completed</div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.completedCount
                    )}
                  </div>
                </div>
              </div>
              <div className="PROGRESS_item">
                <div className="PROGRESS_item_left">
                  <div
                    className="PROGRESS_item_dot"
                    style={{ backgroundColor: "#008445" }}
                  ></div>
                  <div className="PROGRESS_item_text">Returned Devices</div>
                </div>
                <div className="PROGRESS_item_right">
                  <div className="PROGRESS_item_text">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      props.progressOverview?.returnedCount
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
            <div className="DashbaordPage_row_col_1">
              <div className="Progress_flex_column">
                {/* <img
              src="/assets/bg/bar.png"
              style={{ width: "80%", marginLeft: "10%", marginTop: "3%" }}
            /> */}
                <div className="Chart">
                  {props.loading ? (
                    <Paragraph rows={4} rowMargin={8} active rowHeight={14} />
                  ) : (
                    <Bar options={options} data={data} />
                  )}
                </div>
                <div className="divider-horizontal desk-only"></div>
                <div className="PROGRESS_item_return">
                  <h6>Total returned repairs today</h6>
                  <p className="PROGRESS_item_return_steep">14</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  progressOverview: {},
  loading: false,
});

const mapDispatchToProps = {
  fetchProgressOverview,
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
