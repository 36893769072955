import React, { useState } from "react";
import { Dropdown } from "rsuite";
import { FiUser, FiPhone, FiMoreVertical, FiTrash2 } from "react-icons/fi";

// components
import ConvertTicket from "../convertTicket/ConvertTicket";

const EstimatedTicketsItems = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className="EstimatedTicketsItems_cards">
        <div className="EstimatedTicketsItems_cards_circle_top">
          <img src="/assets/bg/walking_man.png" alt="" className="walkingImage" />
          <img src="/assets/bg/walking_man_white.png" alt="" className="walkingImage2" />
        </div>
        <div className="EstimatedTicketsItems_cards_flex_center_column">
          <img src="/assets/bg/wrold.png" alt="" className="EstimatedTicketsItems_cards_logo" />
          <div className="EstimatedTicketsItems_cards_text_bold">Booked on Website A</div>
          <div className="EstimatedTicketsItems_cards_text_regular">For Shop Texas</div>
          <div className="EstimatedTicketsItems_cards_text_regular">05.12.20 | 09:31</div>
        </div>
        <div className="EstimatedTicketsItems_cards_bg">
          <div className="EstimatedTicketsItems_cards_flex_center_column">
            <div className="EstimatedTicketsItems_cards_text_bold">Ticket ID: 1048010</div>
            <div className="EstimatedTicketsItems_cards_text_bold">iPhone Xs 64GB Black</div>
            <div className="EstimatedTicketsItems_cards_text_regular">IMEI/SN: 9743007900813</div>
          </div>
        </div>
        <div className="EstimatedTicketsItems_cards_description_on_hover">
          <div className="EstimatedTicketsItems_cards_bg ">
            <div className="EstimatedTicketsItems_cards_flex_center_column">
              <div className="EstimatedTicketsItems_cards_text_bold">Broken LCD</div>
            </div>
          </div>
        </div>
        <div className="EstimatedTicketsItems_cards_on_hover">
          <div className="EstimatedTicketsItems_cards_flex_center_column ">
            <FiUser style={{ color: "#304a50", fontSize: 18 }} />
            <div className="EstimatedTicketsItems_cards_text_bold">Mr. Jack Wolfsinb</div>
          </div>
        </div>
        <div className="EstimatedTicketsItems_cards_hovered">
          <div className="EstimatedTicketsItems_cards_text_bold">Company Details</div>
          <div className="EstimatedTicketsItems_cards_text_regular">
            Lora Janes <br /> Hoveradress 32A B <br /> 52511 Monsterbay <br />
            lora.janes@mastercompany.ltd <br /> +40 4124 41455600
          </div>
        </div>
        <div className="EstimatedTicketsItems_cards_description_hovered">
          <div className="EstimatedTicketsItems_cards_text_bold">Description</div>
          <div className="EstimatedTicketsItems_cards_text_regular">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum
          </div>
        </div>
        <div className="EstimatedTicketsItems_cards_flex_center">
          <div
            className="EstimatedTicketsItems_cards_btn"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Convert To Job
          </div>
          <div className="EstimatedTicketsItems_cards_btn_trans">
            <FiPhone />
          </div>
          <Dropdown
            trigger={["click"]}
            placement="topEnd"
            style={{ width: 30 }}
            renderTitle={() => (
              <button className="AllTicketsPage-view-item-right-btn">
                <FiMoreVertical style={{ color: "#304a50", fontSize: 20 }} />
              </button>
            )}
          >
            <Dropdown.Item icon={<FiTrash2 style={{ marginRight: 10 }} />}>Move to trash</Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      {modal && (
        <ConvertTicket
          close={() => {
            setModal(!modal);
          }}
        />
      )}
    </>
  );
};

export default EstimatedTicketsItems;
