import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { FiArrowLeftCircle, FiCheck, FiSend, FiTrash2 } from "react-icons/fi";

const Chat: React.FC<{ goback: any }> = ({ goback }) => {
  return (
    <div className="DetailModal-main-middle">
      {/* main view */}
      <div className="DetailModal-main-middle-view">
        {/* main view header */}
        <div className="DetailModal-main-middle-view-header">
          <div
            className="DetailModal-main-middle-view-header-back"
            onClick={goback}
          >
            <FiArrowLeftCircle />
          </div>
          <div className="DetailModal-main-middle-view-header-title-spaced">
            <div className="DetailModal-main-middle-view-header-title-text">
              John
            </div>
            <div className="DetailModal-main-middle-view-header-row"></div>
          </div>
        </div>
        {/* main view header */}
        {/* main view body */}
        <div className="DetailModal-main-middle-view-body">
          <div className="DetailModal-main-middle-view-body-chat">
            {/*  */}
            {[1, 2, 3, 4, 5].map((item, i) => (
              <div
                className="DetailModal-main-middle-view-body-chat-left"
                key={i}
              >
                <img
                  src="/assets/bg/Rectangle.png"
                  className="DetailModal-main-middle-view-body-chat-left-img"
                />
                <div className="DetailModal-main-middle-view-body-chat-left-wrapper">
                  <div className="DetailModal-main-middle-view-body-chat-left-wrapper-msg">
                    Lorem Ipsum Praesent consectetur ornare urna ut.
                  </div>
                  <div className="DetailModal-main-middle-view-body-chat-left-wrapper-spaced">
                    <div className="DetailModal-main-middle-view-body-chat-left-wrapper-row">
                      <div className="DetailModal-main-middle-view-body-chat-left-wrapper-row-time">
                        3.35 PM
                      </div>
                      <FiCheck />
                    </div>
                    <div className="DetailModal-main-middle-view-body-chat-left-wrapper-row-time">
                      08/02/2021
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/*  */}
            {/*  */}
            {[1, 2, 3, 4, 5].map((item, i) => (
              <div
                className="DetailModal-main-middle-view-body-chat-right"
                key={i}
              >
                <img
                  src="/assets/bg/Rectangle.png"
                  className="DetailModal-main-middle-view-body-chat-right-img"
                />
                <div className="DetailModal-main-middle-view-body-chat-right-wrapper">
                  <div className="DetailModal-main-middle-view-body-chat-right-wrapper-msg">
                    Lorem Ipsum Praesent consectetur ornare urna ut.
                  </div>
                  <div className="DetailModal-main-middle-view-body-chat-right-wrapper-spaced">
                    <div className="DetailModal-main-middle-view-body-chat-right-wrapper-row">
                      <div className="DetailModal-main-middle-view-body-chat-right-wrapper-row-time">
                        3.35 PM
                      </div>
                      <FiCheck />
                    </div>
                    <div className="DetailModal-main-middle-view-body-chat-right-wrapper-row-time">
                      08/02/2021
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/*  */}
          </div>
          {/*  */}
          {/* <div className="chat-summary">
            <div className="chat-summary-header">Cost Estimate</div>
          </div> */}
          {/*  */}
          <div className="chat-send">
            <input
              type="text"
              className="chat-send-input"
              placeholder="Write message"
            />
            <button className="chat-send-btn">
              <FiSend />
            </button>
          </div>
        </div>
        {/* main view body */}
      </div>
    </div>
  );
};

export default Chat;
