import React, { useState, useEffect } from "react";
import "../../../pages/dashBoard/DashBoard.css";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { Placeholder } from "rsuite";

//
import QuickTasksItems from "./QuickTasksItems";
import QuickTasksInput from "./QuickTasksInput";

//redux
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { useSelector } from "react-redux";

import { GET_ALL_TASKS } from "../../../gql/dashboard/query";
import { UPDATE_TASK_MUTATION } from "../../../gql/dashboard/mutation";
import { authState } from "../../../toolkit/auth/auth.slice";

const QuickTasks: React.FC<{}> = (props) => {
  const { Paragraph } = Placeholder;
  const [showInputBox, setShowInputBox] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [quickTasks, setQuickTasks] = useState([]);
  const {
    auth: { user },
  } = useSelector(authState);

  const { data, refetch, error, loading } = useQuery(GET_ALL_TASKS, {
    variables: {
      limit: 10,
      userId: user.id,
    },
  });

  const [updateQuickTask] = useMutation(UPDATE_TASK_MUTATION);

  const handleSelectTaskForEdit = (task: any) => {
    setSelectedTask(task);
    setShowInputBox(true);
  };

  useEffect(() => {
    if (data?.quick_tasks.length > 0) {
      setQuickTasks(data?.quick_tasks);
    }else{
      setQuickTasks([])
      setShowInputBox(false)
    }
  }, [data]);

  const updateChecked = (checked: boolean, taskId: string) => {
    updateQuickTask({
      variables: {
        object: {
          checked: checked,
        },
        id: taskId,
      },
    });
  };

  return (
    <div className="DashbaordPage_body_card_trans" style={{ height: 350 }}>
      <div className="DashbaordPage_Header_wrapper">
        <p className="DashbaordPage_Header" style={{ color: "#fff" }}>
          QUICK TASKS
        </p>
        <div className="right">
          <button
            className="btn btn-blue"
            onClick={() => setShowInputBox(!showInputBox)}
          >
            {" "}
            <FiPlus /> Add new task
          </button>
        </div>
      </div>
      <div
        className="divider-horizontal"
        style={{ backgroundColor: "#fff4" }}
      ></div>
      {/*  */}
      {showInputBox && (
        <QuickTasksInput 
          task={selectedTask} 
          refetch={refetch} 
          close={()=>setShowInputBox(false)}
        />
      )}
      {/*  */}
      <>
        <div
          className="QuickTasks_input_body"
          style={{ height: showInputBox ? 185 : 235 }}
        >
          <>
            {loading ? (
              <Paragraph
                rows={6}
                rowMargin={15}
                active
                rowHeight={18}
                className="QuickTasks_input_body_loader"
              />
            ) : (
              <>
                {quickTasks.map((task: any, i: number) => (
                  <QuickTasksItems
                    key={i}
                    task={task}
                    setSelectedTask={handleSelectTaskForEdit}
                    updateChecked={updateChecked}
                    refetch={refetch}
                  />
                ))}
                {quickTasks.length === 0 && (
                  <div className="before_data one">
                    <img src="/assets/theme/11.png" alt="" />
                    <h4 className="text-white">GET STARTED</h4>
                    <h6 onClick={()=>setShowInputBox(true)}>
                      <AiFillPlusCircle /> &nbsp;{" "}
                      <span style={{ color: '#fff' }} >Start with your first priorities</span>
                    </h6>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </>
    </div>
  );
};

export default QuickTasks;
