import React, { useState } from "react";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import "./UsersSettings.scss";

// icons
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";

// component
import { PageHeader, UsersSettingsItems, UsersSettingsModal } from "../../components/usersSettings";

const UsersSettings: React.FC<{}> = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <PageHeader />
      <FlexboxGrid className="UsersSettings">
        <div className="UsersSettings_body">
          <div className="UsersSettings_body_top">
            <div className="UsersSettings_body_top_btn">
              <Dropdown trigger={["click"]} className="actionDropPageHeader export sort" placement="bottomEnd" renderTitle={() => <img src="/assets/bg/filter.png" />}>
                <div className="sort_drop">
                  <h5>Filter</h5>
                  <Checkbox>Job Type</Checkbox>
                  <Checkbox>Location</Checkbox>
                  <Checkbox>Account</Checkbox>
                  <Checkbox>Channels</Checkbox>
                  <div className="d-flex">
                    <button className="bt-secondary">Abort</button>
                    <button className="bt-primary">Apply</button>
                  </div>
                </div>
              </Dropdown>
            </div>

            <div className="UsersSettings_body_top_btn">
              <Dropdown trigger={["click"]} className="actionDropPageHeader export sort" placement="bottomEnd" renderTitle={() => <img src="/assets/bg/sort.png" />}>
                <div className="sort_drop">
                  <h5>Sort</h5>
                  <Checkbox>1 - 10 (ascending)</Checkbox>
                  <Checkbox>10 - 1 (descending)</Checkbox>
                  <Checkbox>A - Z (ascending)</Checkbox>
                  <Checkbox>Z - A (descending)</Checkbox>
                  <div className="d-flex">
                    <button className="bt-secondary">Abort</button>
                    <button className="bt-primary">Apply</button>
                  </div>
                </div>
              </Dropdown>
            </div>

            <div
              className="UsersSettings_body_top_btn"
              onClick={() => {
                setModal(true);
              }}
            >
              <FiPlus style={{ color: "#2589F6" }} />
            </div>

            <div className="UsersSettings_body_top_search">
              <input type="text" placeholder="" />
              <FiSearch />
            </div>
            <div>
              <Dropdown
                trigger={["click"]}
                className="actionDropPageHeader export"
                placement="bottomEnd"
                renderTitle={() => <FiMoreVertical style={{ color: "#304a50", fontSize: 20, cursor: "pointer" }} />}
              >
                <div className="dropDownRowSpaced" style={{ border: 0 }}>
                  <div className="d-flex">
                    <FiUpload />
                    <h6>Export to Excel</h6>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>

          <div className="UsersSettings_body_bottom">
            <UsersSettingsItems />
          </div>
        </div>

        {modal && (
          <UsersSettingsModal
            Close={() => {
              setModal(false);
            }}
          />
        )}
      </FlexboxGrid>
    </>
  );
};

export default UsersSettings;
