import { Dropdown } from "rsuite";
import "../../../pages/services/Services.scss";

// icons
import { FiList, FiLayers, FiChevronDown } from "react-icons/fi";

const PageHeader = () => {
  return (
    <div className="PageHeader">
      <div className="PageHeader-left">
        <FiList />
        <h2>Services</h2>
      </div>

      {/* <div className="desk-only">
        <div className="PageHeader-right">
          <Dropdown
            trigger={["click"]}
            className="actionDropPageHeader"
            placement="bottomEnd"
            renderTitle={() => (
              <div className="bttn-chevrom">
                <div>Action</div>
                <FiChevronDown />
              </div>
            )}
          >
            <div className="dropDownRowSpaced">
              <div className="d-flex">
                <FiList />
                <h6>New Service</h6>
              </div>
            </div>

            <div className="dropDownRowSpaced" style={{ border: 0 }}>
              <div className="d-flex">
                <FiLayers />
                <h6>New Item</h6>
              </div>
            </div>
          </Dropdown>
        </div>
      </div> */}
    </div>
  );
};

export default PageHeader;
