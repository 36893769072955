import React, { useState, useEffect, useRef } from "react";
import "./TagInput.css";

// icons
import { FiX } from "react-icons/fi";

const TagInput: React.FC<{
  labelText?: any;
  valueProp?: any;
  disabledInput?: any;
  icon?: any;
  handleChange: (e: string) => void;
  visualData?: any;
  setTags?: any;
}> = ({
  labelText,
  valueProp,
  disabledInput,
  icon,
  handleChange,
  visualData = [],
  setTags
}) => {
  const [value, setValue] = useState("");
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    }
  }, [valueProp]);

  const keyPress = (key: string) => {
    if (key === "Enter") {
      handleChange(inputRef.current?.value);
      setValue("");
    }
  };

  return (
    <div
      className={`tagInput w-100 ${value !== "" && "active"} ${
        disabledInput ? "disabled" : ""
      }`}
    >
      {visualData.length > 0 &&
        visualData.map((el: string, idx: number) => (
          <div className="tagInput_input_right" key={`${el}-${idx}`}>
            {el}
            <div className="tagInput_input_right_close" onClick={()=> {
              setTags([...visualData.filter((e: string) => e !== el )])
            }}>
              <FiX />
            </div>
          </div>
        ))}
      <input
        ref={inputRef}
        type="text"
        placeholder={labelText}
        className="tagInput_input"
        readOnly={disabledInput}
        value={valueProp ? valueProp : value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
        onBlur={(e) => {
          handleChange(e.target.value);
          setValue("");
        }}
        onKeyPress={(e) => keyPress(e.key)}
      />
    </div>
  );
};

export default TagInput;
