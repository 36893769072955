import React, { useState, useEffect } from "react";
import "./InputBoxBotton.css";

const InputBoxBotton: React.FC<{
  labelText: any;
  valueProp?: any;
  disabledInput?: any;
  icon?: any;
  btnClicks?: any;
}> = ({ labelText, valueProp, disabledInput, icon, btnClicks }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    }
  }, [valueProp]);

  return (
    <div
      className={`InputBoxBotton ${value !== "" && "active"} ${
        disabledInput ? "disabled" : ""
      }`}
    >
      <input
        type="text"
        className="InputBoxBotton_input"
        readOnly={disabledInput}
        value={valueProp ? valueProp : value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
      />
      <div
        className="InputBoxBotton_input_right"
        onClick={() => {
          btnClicks()
        }}
      >
        {icon}
      </div>
      <label className="InputBoxBotton_label">{labelText}</label>
    </div>
  );
};

export default InputBoxBotton;
