import { gql } from "@apollo/client";

export const GET_ALL_INDUSTRIES = gql`
  query MyQuery {
    enum_industries {
      comment
      value
    }
  }
`;
