import React, { useState } from "react";
import "../../../pages/services/Services.scss";

//helpers
import {formatLanguage, formatTimeFormats} from "../../../helpers/";

//redux
import {useSelector} from "react-redux";
import {profileState} from "../../../toolkit/profile/profile.slice";

const ProfileGeneralLanguageStep2 = () => {
  const {profileSettings} = useSelector(profileState).profile;
  return (
    <div className="ProfileGeneralDetails_details">
      <p>{formatLanguage(profileSettings.language) || "English"}</p>
      <p>Time Zone ({profileSettings.timezone || "Default"})</p>
      <p>Date Format ({profileSettings.date_format || "Default"})</p>
      <p>Time Format ({formatTimeFormats(profileSettings.time_format) || "Default"})</p>
      <p>Currency ({profileSettings.currency || "$"})</p>
    </div>
  );
};

export default ProfileGeneralLanguageStep2;
