import React, { useState } from "react";
import { Toggle } from "rsuite";

// icons
import { FiLayers, FiBell } from "react-icons/fi";

// component
import InputBox from "../../template/inputBox/InputBox";
import SelectBox from "../../template/selectBox/SelectBox";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

//types
import StockItemType from "./StockItems.type";

const StockItemsModalSettings: React.FC<{
  state: StockItemType;
  setState: Function;
}> = ({ state, setState }) => {
  const [selected1, setSelected1] = useState("");
  const[selectedConditions, setselectedConditions] = useState("");
  const [conditionsMode, setConditionsModeMode] = useState([
    {
      id: 1,
      name: "Hourly"
    },
    {
      id: 2,
      name: "Daily"
    },
    {
      id: 2,
      name: "Weekly"
    },
    
    
  ])
  
  return (
    <>
      <br />
      <div
        className="StockItemsModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
          <div className="StockItemsModal_inner_blueShow">
            <FiLayers style={{ marginRight: 5 }} />
            <div className="f1">
              <h6>Enable Stock Managment</h6>
              <p>Stock vallue can be changed when book in Items</p>
            </div>
            <Toggle
              size="md"
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(checked: boolean) => setState({ ...state, stock_manage_on: checked })}
              defaultChecked={state.stock_manage_on}
            />
          </div>
        </div>
        <div className="col"></div>
      </div>
      <div
        className="StockItemsModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
          <div className="StockItemsModal_inner_blueShow">
            <FiBell style={{ marginRight: 5 }} />
            <div className="f1">
              <h6>Stock Alert </h6>
              <p>Get an notification when defined value has been reached</p>
            </div>
            <Toggle
              size="md"
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(checked: boolean) => setState({ ...state, stock_alert_on: checked })}
              defaultChecked={state.stock_alert_on}
            />
          </div>
        </div>
        <div className="col">
          <div className="StockItemsModal_inner_flex_start">
            <div className="col">
              <InputBox
                labelText={"Stock Value"}
                handleChange={(value) => setState({ ...state, minimum_stock: value })}
                valueProp={state.minimum_stock}
              />
            </div>
            <div className="col"  >
              <SelectBox selectedValue={state.reminder_type} labelText="Conditions">
                  {["Hourly", "Daily", "Weekly"].map((item: string, i: number) => (
                    <div
                      className="d-flex-start"
                      key={i}
                      onClick={() => setState({ ...state, reminder_type: item })}
                    >
                      {item}
                    </div>
                  ))}
              </SelectBox>
              {/* <SearchDropDown
                        labelText="Conditions"
                        data={conditionsMode}
                        selectedOption={setselectedConditions}
                        onMouseLeave={() => null}
                      /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockItemsModalSettings;
