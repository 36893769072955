import React, { useState } from "react";

// icons
import { FiChevronRight } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";
import { FaNetworkWired } from "react-icons/fa";
import { CgArrowsExpandLeft } from "react-icons/cg";

const Step4: React.FC<{ ChangeStep: Function }> = ({ ChangeStep }) => {
  // const [add, setAdd] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedAccessories, setSelectedAccessories] = useState("");
  const [selectedMemory, setSelectedMemory] = useState("");

  console.log(selectedMemory, "selectedMemory");

  const [colors, setColors] = useState([
    {
      id: 1,
      name: "orange",
    },
    {
      id: 2,
      name: "green",
    },
    {
      id: 3,
      name: "blue",
    },
  ]);

  const [accessories, setAccessories] = useState([
    {
      id: 1,
      name: "accessories 1",
    },
    {
      id: 2,
      name: "accessories 2",
    },
    {
      id: 3,
      name: "accessories 3",
    },
  ]);

  const [conditions, setConditions] = useState([
    {
      id: 1,
      name: "Conditions 1",
    },
    {
      id: 2,
      name: "Conditions 2",
    },
    {
      id: 3,
      name: "Conditions 3",
    },
  ]);
  const [memory, setMemory] = useState([
    {
      id: 1,
      name: "32gb",
    },
    {
      id: 2,
      name: "64gb",
    },
    {
      id: 3,
      name: "128gb",
    },
  ]);
  console.log(selectedAccessories, "accer");

  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper">
          <div className="stepFormWrapper-left">
            <img
              src="/assets/icon/iLogo.png"
              alt=""
              style={{ width: 120, height: 120, objectFit: "contain" }}
            />
            <h5 style={{ marginTop: 15 }}>Apple SE 2020</h5>
            <p style={{ textAlign: "center", lineHeight: 1.4 }}>
              <b> IMEI: </b> 125389386491937 <br />
              <b> Storage: </b> 256 GB <br />
              <b> Color: </b> Black <br />
            </p>
          </div>
          <div className="stepFormWrapper-right">
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <InputBox
                  labelText="IMEI / Serial No. (15 digits)"
                  handleChange={() => null}
                />
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <SearchDropDown
                  labelText="Memory"
                  data={memory}
                  selectedOption={setSelectedMemory}
                  onMouseLeave={() => null}
                />
              </div>
              <div className="col">
                <SearchDropDown
                  labelText="Color"
                  data={colors}
                  selectedOption={setSelectedColor}
                  onMouseLeave={() => null}
                />
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <InputBox labelText="Provider" handleChange={() => null} />
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <InputBox
                  labelText="Device Security"
                  handleChange={() => null}
                />
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <SearchDropDown
                  labelText="Conditions"
                  data={conditions}
                  selectedOption={setSelectedCondition}
                  onMouseLeave={() => null}
                />
              </div>
            </div>
            <div className="JobModal_row" style={{ marginBottom: 15 }}>
              <div className="col">
                <SearchDropDown
                  labelText="Accessories"
                  data={accessories}
                  selectedOption={setSelectedAccessories}
                  onMouseLeave={() => null}
                />
              </div>
            </div>

            <div
              className="bt-primary JobModal-btn"
              onClick={() => {
                ChangeStep();
              }}
            >
              Next <FiChevronRight style={{ marginLeft: 5 }} />
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default Step4;
