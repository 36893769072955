import { createSlice } from "@reduxjs/toolkit";

interface CompanySettingsType {
  organization: string | null;
  street: string | null;
  street_no: string | null;
  city: string | null;
  zip: string | null;
  country: string | null;
  company_logo: string | null;
  phone: string | null;
  fax: string | null;
  email: string | null;
  website: string | null;
  registration_no: string | null;
  tax_uid: string | null;
  tax_id: string | null;
  default_tax_rate: string | null;
  ceo: string | null;
  bank_name: string | null;
  bank_iban: string | null;
  bank_bic: string | null;
  opening_hours: string | null;
  created_by: string | null;
  id: string;
}
interface StateType {
  errorMsg: string;
  companySettings: CompanySettingsType;
}

const initialState: StateType = {
  companySettings: {
    organization: "",
    street: "",
    street_no: "",
    city: "",
    zip: "",
    country: "",
    company_logo: "",
    phone: "",
    fax: "",
    email: "",
    website: "",
    registration_no: "",
    tax_uid: "",
    tax_id: "",
    default_tax_rate: "0",
    ceo: "",
    bank_name: "",
    bank_iban: "",
    bank_bic: "",
    opening_hours: "",
    created_by: "",
    id: "",
  },
  errorMsg: "",
};

const companySettingsSlice: any = createSlice({
  name: "companySettings",
  initialState,
  reducers: {
    setCompanySettings: (state, { payload }) => {
      return {
        ...state,
        companySettings: payload
      };
    },
  },
});

export const companySettingsState = (state: any) => state;
export const {setCompanySettings} = companySettingsSlice.actions
export default companySettingsSlice.reducer;