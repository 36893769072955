import React, { useState } from "react";

import InvoiceModalTableItems from "./InvoiceModal.table.item";

interface Props {
  serviceData: any;
  setProductData: Function;
}

const InvoiceService = ({ serviceData, setProductData }: Props) => {
  const [searchedService, setSearchedService] = useState<string | number>();

  return (
    <>
      {serviceData.map((el: any, idx: number) => (
        <InvoiceModalTableItems
          key={`product-${idx}`}
          position={idx}
          suggestionItems={[""]}
          id={el.id}
          setProductData={setProductData}
          setSearchedText={setSearchedService}
        />
      ))}
    </>
  );
};

export default InvoiceService;
