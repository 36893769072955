import React, { useState } from "react";
import { Dropdown } from "rsuite";
import "./statusItem.scss";

// icon
import { FiLock } from "react-icons/fi";

const StatusItem: React.FC<{
  setModal: (m: boolean) => void;
  id: string;
  setEditedId: (i: string) => void;
  setIsEdited: (i: boolean) => void;
  handelEditClick: () => void;
}> = ({ id, setModal, setEditedId, setIsEdited, handelEditClick }) => {
  const onEdit = () => {
    setModal(true);
    setEditedId(id);
    setIsEdited(true);
  };

  const [statusColor, setStatusColor] = useState("red");

  return (
    <>
      <div className="desk-only">
        <div className="ServicesItems" onClick={handelEditClick}>
          <div className=" StatusItem_left" onClick={onEdit}>
            <div
              className="status-color"
              style={{ backgroundColor: statusColor }}
            />
            <div className=" StatusItems_left_1">
              <p>Status Name</p>
              <h6>Booked In</h6>
            </div>
            <div className="ServicesItems_left_2">
              <p>Category</p>
              <h6>System</h6>
            </div>
          </div>
          <div className="StatusItems_right">
            <div className="lock" onClick={onEdit}>
              <FiLock />
            </div>
            {/* <FiMoreHorizontal /> */}
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => (
                <img className="asdf" src="/assets/icon/dotsHorizontal.png" />
              )}
            >
              <div className="dropDownProfile">
                <span className="dropDownProfile-Link" onClick={onEdit}>
                  Edit
                </span>
                <span className="dropDownProfile-Link">Remove</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusItem;
