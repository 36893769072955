import React, { useEffect, useState } from "react";

// icons

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_ITEMS } from "../../../gql/stockItems/queries";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../../../toolkit/items/items.slice";
import { authState } from "../../../toolkit/auth/auth.slice";

// components
import InvoiceModalTableItemsAdd from "./InvoiceModal.table.itemAdd";
import InvoiceProduct from "./Invoice.product.item";
import InvoiceService from "./Invoice.service.item";
import InvoiceText from "./Invoice.text.item";

const InvoiceModalTable: React.FC<{
  productData: any[];
  setProductData: Function;
  serviceData: any[];
  setServiceData: Function;
  textData: string;
  setTextData: Function;
  onClickText: boolean;
  setOnClickText: Function;
  amount: number
}> = ({
  productData,
  setProductData,
  serviceData,
  setServiceData,
  textData,
  setTextData,
  onClickText,
  setOnClickText,
  amount
}) => {
  const dispatch = useDispatch();
  const {
    auth: { user },
  } = useSelector(authState);
  const defaultCondition = {
    created_by: { _eq: user.id },
    active: { _eq: true }
  }
  
  const [queryCondition, setQueryCondition] = useState<any>(defaultCondition);
  const { data, refetch, error, loading } = useQuery(GET_ALL_ITEMS, {
    variables: { 
      limit: 10, 
      offset: 0, 
      where: queryCondition
    },
  });

  useEffect(() => {
    if (error) {
    } else {
      dispatch(setItems(data));
    }
  }, [error, data]);

  return (
    <>
      <div className="InvoiceModal_body_inner_tabs">
        <button>Incl. VAT</button>
        <button className="active">Excl. VAT</button>
      </div>

      <div className="InvoiceModal_body_inner_table header ">
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ maxWidth: 50 }}
        >
          <h4 className="InvoiceModal_body_inner_table_whiteText">Pos.</h4>
        </div>
        <div className="InvoiceModal_body_inner_table_col_2">
          <h4 className="InvoiceModal_body_inner_table_whiteText">Item</h4>
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <h4 className="InvoiceModal_body_inner_table_whiteText ">Unit</h4>
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <h4 className="InvoiceModal_body_inner_table_whiteText ">
            Price (Netto)
          </h4>
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <h4 className="InvoiceModal_body_inner_table_whiteText ">VAT</h4>
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <h4 className="InvoiceModal_body_inner_table_whiteText  ">Amount</h4>
        </div>
      </div>
      {/*  */}
      {productData.length > 0 && (
        <InvoiceProduct
          productData={productData}
          setProductData={setProductData}
        />
      )}
      {serviceData.length > 0 && (
        <InvoiceService
          serviceData={serviceData}
          setProductData={setProductData}
        />
      )}
      {onClickText && (
        <InvoiceText textData={textData} setTextData={setTextData} />
      )}
      <InvoiceModalTableItemsAdd
        setProductData={setProductData}
        setServiceData={setServiceData}
        setOnClickText={setOnClickText}
        amount={amount}
      />
    </>
  );
};

export default InvoiceModalTable;
