import React, { useState } from "react";
import {
  FiChevronDown,
  FiHome,
  FiMoreHorizontal,
  FiEye,
  FiRefreshCw,
  FiPrinter,
  FiCheckCircle,
  FiTrash2,
} from "react-icons/fi";
import "../../../pages/dashBoard/DashBoard.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Dropdown } from "rsuite";

// modal
import DetailModal from "../../detailModalWrapper/detailModal/DetailModal";

const AllTicketItems = () => {
  const [modal, setModal] = useState(false);
  const percentage = 66;

  return (
    <>
      <div
        className="AllTicketsPage-view-item"
        onClick={() => {
          setModal(!modal);
        }}
      >
        <div
          className="AllTicketsPage-view-item-left AllTicketsPage-view-item-flex"
          // style={{ width: 225 }}
          onClick={() => {
            setModal(!modal);
          }}
        >
          <img src="/assets/bg/home-blue.png" alt="" />
          <div style={{ marginLeft: 7 }}>
            <h6>Booked In</h6>
            <div
              className="AllTicketsPage-view-item-flex-spaced"
            // style={{ width: 155 }}
            >
              <p>Shop Texas</p>
              <p style={{ paddingLeft: 7 }} >05.12.20 | 09:31</p>
            </div>
          </div>
          <div className="AllTicketsPage-view-item-left"></div>
        </div>
        <div className="AllTicketsPage-view-item_horizontal"></div>
        <div
          className="AllTicketsPage-view-item-left AllTicketsPage-view-item-flex"
          // style={{ width: 350 }}
          onClick={() => {
            setModal(!modal);
          }}
        >
          <div className="bars" style={{ flex: 1 }}>
            <h6>Job ID: 1048010 </h6>
            <div className="AllTicketsPage-view-item-flex-spaced">
              <p>Warranty | Box No: 14C</p>
            </div>
          </div>
          <div className="bars" style={{ flex: 1, borderRight: 0 }}>
            <h6 style={{ width: 132 }} >iPhone Xs 64GB Black </h6>
            <div className="AllTicketsPage-view-item-flex-spaced">
              <p>IMEI/SN: 9743007900813</p>
            </div>
          </div>
        </div>
        <div className="AllTicketsPage-view-item_horizontal"></div>
        <div
          className="AllTicketsPage-view-item-left AllTicketsPage-view-item-flex"
          onClick={() => {
            setModal(!modal);
          }}
        // style={{ width: 200 }}
        >
          <div className="AllTicketsPage-view-item-left_chat_pp">W</div>
          <div style={{ marginLeft: 7 }}>
            <h6>World Victory LTD</h6>
            <div className="AllTicketsPage-view-item-flex-spaced">
              <p>Mr. Jack Wolfskinbell</p>
            </div>
          </div>
        </div>
        <div className="AllTicketsPage-view-item-right">
          <div className="AllTicketsPage-view-item_status">Express</div>
          <CircularProgressbar
            className="AllTicketsPage-view-item-right-progress"
            value={percentage}
            text={`${percentage}%`}
          />

          <Dropdown
            trigger={["click"]}
            // className="w-100"
            placement="bottomEnd"
            style={{ width: 30 }}
            renderTitle={() => (
              <button className="AllTicketsPage-view-item-right-btn">
                <FiMoreHorizontal />
              </button>
            )}
          >
            <Dropdown.Menu
              icon={<FiRefreshCw style={{ marginRight: 10 }} />}
              title="Change Status"
            >
              <Dropdown.Item>Booked In</Dropdown.Item>
              <Dropdown.Item>Waiting For Parts</Dropdown.Item>
              <Dropdown.Item>Archive</Dropdown.Item>
              <Dropdown.Item>Picked Up</Dropdown.Item>
            </Dropdown.Menu>
            <Dropdown.Menu
              icon={<FiPrinter style={{ marginRight: 10 }} />}
              title="Print"
            >
              <Dropdown.Item>Waybill</Dropdown.Item>
              <Dropdown.Item>New Group</Dropdown.Item>
            </Dropdown.Menu>
            <Dropdown.Item icon={<FiCheckCircle style={{ marginRight: 10 }} />}>
              Complete Job
            </Dropdown.Item>
            <Dropdown.Item icon={<FiTrash2 style={{ marginRight: 10 }} />}>
              Delete Ticket
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <DetailModal openModal={modal} onClose={() => setModal(false)} />
    </>
  );
};

export default AllTicketItems;
