import { gql } from "@apollo/client";

export const GET_ALL_ITEM_CATEGORIES = gql`
  query GetItemCategories($where: item_categories_bool_exp, $limit: Int!, $offset: Int!) {
    item_categories(where: $where, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;
