import React, { useState } from "react";
// import "../detailModal/DetailModal.css";
import { Button, Toggle } from "rsuite";
import { FiX, FiUser, FiChevronDown, FiSearch, FiCheck } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";

const ConvertTicket: React.FC<{ close: any }> = ({ close }) => {
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");

  return (
    <div className="ConvertTicket_modal">
      <div className="ConvertTicket_modal-body">
        <div className="ConvertTicket_modal-header">
          <h4 className="ConvertTicket_modal-header_text">Convert Ticket ID: 1048010 To Job </h4>
          <div className="close" onClick={close} >
            <FiX />
          </div>
        </div>
        <div className="ConvertTicket_modal-bodyInner">
          <div className="ConvertTicket_modal-bodyInner-flex-start">
            <div className="ConvertTicket_modal-bodyInner-header_left">
              <div className="d-flex-start">
                <FiUser />
                <div className="">
                  <h6>Mr. Jack Wolsin</h6>
                  <p>Private</p>
                </div>
              </div>
            </div>
            <div className="ConvertTicket_modal-bodyInner-header_right">
              <p>Apple iPhone SE 2020 128 Black</p>
              <p>IMEI: 123456789012345 </p>
            </div>
          </div>
        </div>
        <div className="ConvertTicket_modal-bodyInner">
          <div className="ConvertTicket_modal-left desk-only">
            <div className="ConvertTicket_modal-qr">
              <img src="/assets/qr.png" alt="" />
            </div>
            <div className="ConvertTicket_modal-qr-text">Scan this QR Code to upload images</div>
            <div className="DetailModal-card-body">
              <div className="DetailModal-card-body-row">
                <div className="DetailModal-uploads-img">
                  <img src="/assets/bg/upload1.png" alt="" />
                  <button>
                    <FiX />
                  </button>
                </div>
                <div className="DetailModal-uploads-img">
                  <img src="/assets/bg/upload1.png" alt="" />
                  <button>
                    <FiX />
                  </button>
                </div>
                <div className="DetailModal-uploads-img">
                  <img src="/assets/bg/upload1.png" alt="" />
                  <button>
                    <FiX />
                  </button>
                </div>
                <div className="DetailModal-uploads-img">
                  <img src="/assets/bg/upload1.png" alt="" />
                  <button>
                    <FiX />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="ConvertTicket_modal-right">
            <div className="ConvertTicket_modal_box">
              <div className="">Symptom: Broken LCD, earspeaker defect</div>
              <FiChevronDown />
            </div>
            <div className="ConvertTicket_modal_spaced">
              <h4>Suggested Items:</h4>
              <div className="ConvertTicket_modal_search JobsOverview_body_top_search">
                <input type="text" placeholder="Search & Add Items" />
                <FiSearch />
              </div>
            </div>
            <div className="">
              <div className="ConvertTicket_modal_item">
                <div className="ConvertTicket_modal_item_left">
                  <h5>Apple iPhone SE 2020 LCD Black</h5>
                  <div className="ConvertTicket_modal-bodyInner-flex-start">
                    <h6>Item No. : 12353 </h6>
                    <h6> Location : 5B </h6>
                    <h6 style={{ borderRight: 0 }}> Stock Level : 15Pcs </h6>
                  </div>
                </div>
                <div className="ConvertTicket_modal_item_right">
                  <div className="ConvertTicket_modal_item_right_Reserved">
                    Reserved <FiX style={{ color: "#fff" }} />
                  </div>
                </div>
              </div>
              <div className="ConvertTicket_modal_item">
                <div className="ConvertTicket_modal_item_left">
                  <h5>Apple iPhone SE 2020 LCD Black</h5>
                  <div className="ConvertTicket_modal-bodyInner-flex-start">
                    <h6>Item No. : 12353 </h6>
                    <h6> Location : 5B </h6>
                    <h6 style={{ borderRight: 0 }}> Stock Level : 15Pcs </h6>
                  </div>
                </div>
                <div className="ConvertTicket_modal_item_right">
                  <div className="botn">
                    <FiCheck style={{ color: "#0f0" }} />
                  </div>
                  <div className="botn">
                    <FiX style={{ color: "#f00" }} />
                  </div>
                </div>
              </div>
            </div>
            <TextAreaBox labelText="Internal Notes"  handleChange={(value) => {}} 
              valueProp={""}/>
            <input type="file" style={{ marginTop: 10 }} />
            <div className="mob-only">
              <div className="DetailModal-card-body">
                <div className="DetailModal-card-body-row">
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiX />
                    </button>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiX />
                    </button>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiX />
                    </button>
                  </div>
                  <div className="DetailModal-uploads-img">
                    <img src="/assets/bg/upload1.png" alt="" />
                    <button>
                      <FiX />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="desk-only">
              <div className="ConvertTicket_modal-bodyInner-flex-start">
                <>
                  <div style={{ flex: 1, margin: "10px 0 10px" }}>
                    <SelectBox selectedValue={selected} labelText="Set Status">
                      <div
                        className=""
                        onClick={() => {
                          setSelected("All");
                        }}
                      >
                        All
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected("Email/Chat");
                        }}
                      >
                        Email/Chat
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected("Mobile-SMS");
                        }}
                      >
                        Mobile-SMS
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected("WhatsApp");
                        }}
                      >
                        WhatsApp
                      </div>
                    </SelectBox>
                  </div>
                  <div style={{ flex: 1, margin: "10px 10px 10px" }}>
                    <SelectBox selectedValue={selected1} labelText="Priority">
                      <div
                        className=""
                        onClick={() => {
                          setSelected1("All");
                        }}
                      >
                        All
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected1("Email/Chat");
                        }}
                      >
                        Email/Chat
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected1("Mobile-SMS");
                        }}
                      >
                        Mobile-SMS
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected1("WhatsApp");
                        }}
                      >
                        WhatsApp
                      </div>
                    </SelectBox>
                  </div>
                  <div style={{ flex: 1.5, margin: "10px 0 10px" }}>
                    <SelectBox selectedValue={selected2} labelText="Send notification">
                      <div
                        className=""
                        onClick={() => {
                          setSelected2("All");
                        }}
                      >
                        All
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected2("Email/Chat");
                        }}
                      >
                        Email/Chat
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected2("Mobile-SMS");
                        }}
                      >
                        Mobile-SMS
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setSelected2("WhatsApp");
                        }}
                      >
                        WhatsApp
                      </div>
                    </SelectBox>
                  </div>
                </>
              </div>
            </div>
            <div className="mob-only" style={{ width: "100%" }}>
              <>
                <div style={{ width: "100%", margin: "10px 0 10px" }}>
                  <SelectBox selectedValue={selected} labelText="Set Status">
                    <div
                      className=""
                      onClick={() => {
                        setSelected("All");
                      }}
                    >
                      All
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("Email/Chat");
                      }}
                    >
                      Email/Chat
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("Mobile-SMS");
                      }}
                    >
                      Mobile-SMS
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("WhatsApp");
                      }}
                    >
                      WhatsApp
                    </div>
                  </SelectBox>
                </div>
                <div style={{ flex: 1, margin: "10px 0px 10px" }}>
                  <SelectBox selectedValue={selected1} labelText="Priority">
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("All");
                      }}
                    >
                      All
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("Email/Chat");
                      }}
                    >
                      Email/Chat
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("Mobile-SMS");
                      }}
                    >
                      Mobile-SMS
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("WhatsApp");
                      }}
                    >
                      WhatsApp
                    </div>
                  </SelectBox>
                </div>
                <div style={{ flex: 1.5, margin: "10px 0 10px" }}>
                  <SelectBox selectedValue={selected2} labelText="Send notification">
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("All");
                      }}
                    >
                      All
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("Email/Chat");
                      }}
                    >
                      Email/Chat
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("Mobile-SMS");
                      }}
                    >
                      Mobile-SMS
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("WhatsApp");
                      }}
                    >
                      WhatsApp
                    </div>
                  </SelectBox>
                </div>
              </>
            </div>
            <div className="desk-only">
              <div className="ConvertTicket_modal-bodyInner-flex-start">
                <div className="ConvertTicket_modal_box flex-1" style={{ marginRight: 5 }}>
                  <Toggle size="md" checkedChildren="On" unCheckedChildren="Off" style={{ marginLeft: 5 }} />
                  <h5>Print Bardcode Label</h5>
                </div>
                <div className="ConvertTicket_modal_box flex-1" style={{ marginLeft: 5 }}>
                  <Toggle size="md" checkedChildren="On" unCheckedChildren="Off" style={{ marginLeft: 5 }} />
                  <h5>Print Job Receipt</h5>
                </div>
              </div>
            </div>
            <div className="mob-only" style={{ width: "100%" }}>
              <div className="ConvertTicket_modal_box flex-1" style={{ marginRight: 0 }}>
                <Toggle size="md" checkedChildren="On" unCheckedChildren="Off" style={{ marginLeft: 5 }} />
                <h5>Print Bardcode Label</h5>
              </div>
              <div className="ConvertTicket_modal_box flex-1" style={{ marginLeft: 0 }}>
                <Toggle size="md" checkedChildren="On" unCheckedChildren="Off" style={{ marginLeft: 5 }} />
                <h5>Print Job Receipt</h5>
              </div>
            </div>
            <div className="desk-only">
              <div className="ConvertTicket_modal-bodyInner-flex-start">
                <div className="bt-secondary">Abort</div>
                <div className="bt-primary">Convert To Job</div>
              </div>
            </div>
            <div className="mob-only" style={{ width: "100%" }}>
              <div className="bt-secondary" onClick={() => close()} >Abort</div>
              <div className="bt-primary" onClick={close} >Convert To Job</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertTicket;
