import {
	STATS_TURNOVER_LOADING,
	SET_TURNOVER,
	STATS_PROGRESS_OVERVIEW_LOADING,
	SET_PROGRESS_OVERVIEW,
	STATS_UNPAID_INVOICE_LOADING,
	SET_UNPAID_INVOICE_STAT,
	STATS_STOCK_VALUE_LOADING,
	SET_STOCK_VALUE,
	STATS_MANUFACTURERS_LOADING,
	SET_MANUFACTURERS
} from "./constants";
import { 
	_fetchTurnOver, 
	_fetchProgressOverview, 
	_fetchUnpaidInvoiceStats, 
	_fetchStockValue,
	_fetchTopManufacturers 
} from "./services";
import { loginRemoveToken } from "../auth/actions";

export function turnoverSetLoading(loading) {
	return {
		type: STATS_TURNOVER_LOADING,
		payload: loading,
	};
}
export function turnoverSetData(params) {
	return {
		type: SET_TURNOVER,
		payload: params,
	};
}
export function progressOverviewSetLoading(loading) {
	return {
		type: STATS_PROGRESS_OVERVIEW_LOADING,
		payload: loading,
	};
}
export function progressOverviewSetData(params) {
	return {
		type: SET_PROGRESS_OVERVIEW,
		payload: params,
	};
}
export function unpaidInvoiceSetLoading(loading) {
	return {
		type: STATS_UNPAID_INVOICE_LOADING,
		payload: loading,
	};
}
export function unpaidInvoiceSetData(params) {
	return {
		type: SET_UNPAID_INVOICE_STAT,
		payload: params,
	};
}
export function stockValueSetLoading(loading) {
	return {
		type: STATS_STOCK_VALUE_LOADING,
		payload: loading,
	};
}
export function stockValueSetData(params) {
	return {
		type: SET_STOCK_VALUE,
		payload: params,
	};
}
export function manufacturerSetLoading(loading) {
	return {
		type: STATS_MANUFACTURERS_LOADING,
		payload: loading,
	};
}
export function manufacturerSetData(params) {
	return {
		type: SET_MANUFACTURERS,
		payload: params,
	};
}
export const fetchTurnOver = (params) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(turnoverSetLoading(true));
		_fetchTurnOver(params, token).then(async (res) => {
			dispatch(turnoverSetData(res));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(turnoverSetLoading(false));
		})
	})
};
export const fetchProgressOverview = (params) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(progressOverviewSetLoading(true));
		_fetchProgressOverview(params, token).then(async (res) => {
			dispatch(progressOverviewSetData(res));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(progressOverviewSetLoading(false));
		})
	})
};
export const fetchUnpaidInvoiceStats = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(unpaidInvoiceSetLoading(true));
		_fetchUnpaidInvoiceStats(token).then(async (res) => {
			dispatch(unpaidInvoiceSetData(res));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(unpaidInvoiceSetLoading(false));
		})
	})
};
export const fetchStockValue = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(stockValueSetLoading(true));
		_fetchStockValue(token).then(async (res) => {
			dispatch(stockValueSetData(res));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(stockValueSetLoading(false));
		})
	})
};
export const fetchTopManufacturers = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(manufacturerSetLoading(true));
		_fetchTopManufacturers(token).then(async (res) => {
			dispatch(manufacturerSetData(res));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(manufacturerSetLoading(false));
		})
	})
};