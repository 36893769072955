import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import numeral from 'numeral';
import 'numeral/locales/de';
import { FiChevronDown, FiChevronUp, FiEdit } from "react-icons/fi";

//helpers
import {isRepairCenter, isOwnRepair} from "../../../helpers/index";
//redux
import { connect } from "react-redux";
const Express: React.FC<{
  job: any;
  company: any;
  profile: any;
}> = ({
  job,
  company,
  profile
}) => {
  const [openExpress, setOpenExpress] = useState(true);
  const prefixForTicket = job.user && job.user.prefixForTicket;
  const jobId = (isRepairCenter(company) && !isOwnRepair(job,profile)) ? prefixForTicket + numeral(job.nr).format('0') : numeral(job.nr).format('0')
  return (
    <>
      <div className="DetailModal-card">
        <div className="DetailModal-card-header">
          <div className="d-flex" >
            <h6>Job Details</h6>
            <div className="DetailModal-card-status" >{job.repairtype}</div>
          </div>
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            {/* <FiEdit className="DetailModal-card-iconbtn cursor-pointer" />   */}
            {openExpress ? (
              <FiChevronUp
                className="cursor-pointer"
                onClick={() => setOpenExpress(!openExpress)}
              />
            ) : (
              <FiChevronDown
                className="cursor-pointer"
                onClick={() => setOpenExpress(!openExpress)}
              />
            )}
          </div>
        </div>
        {openExpress && (
          <div className="DetailModal-card-body">
            <p>
              Ticket No: {jobId} <br />
              Reference: {job.reference || "N/A"} <br />
              Defect type: {Array.isArray(job.damage) ? job.damage.join(", ") : "N/A"}
            </p>
            <h6 className="pb" >
              Problem Description :
            </h6>
            <p>
            {job.description}
            </p>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
  company: {},
  profile: {},
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Express);