import { gql } from "@apollo/client";

export const GET_ALL_ITEMS = gql`
  query GetItems($limit: Int!, $offset: Int!, $where:items_bool_exp, $order:[items_order_by!]) {
    items(limit: $limit, offset: $offset, where: $where, order_by: $order) {
      id
      name
      item_no
      stock
      stock_unit
      manufacturer_no
      physical_location
      conditions
      vat
      description
      purchase_price_excl_vat
      purchase_price_incl_vat
      selling_price_excl_vat
      selling_price_incl_vat
      supplier_prices
      barcodes
      serial_numbers
      images
      stock_manage_on
      stock_alert_on
      minimum_stock
      reminder_type
      manufacturer
      manufacturer_rel {
        id
        name
      }
      category
      category_rel {
        id
        name
      }
      color
      color_rel {
        id
        name
      }
      created_at
    }
    items_aggregate(where: $where) {
      aggregate {
        count(columns: active)
      }
    }
  }
`;

export const GET_ITEMS_BY_SEARCHED = gql`
  query GetItemsBySearched($userId: uuid!, $searchedText: String!) {
    items(
      where: {
        created_by: { _eq: $userId }
        name: { _iregex: $searchedText }
      }
    ) {
      id
      name
      item_no
      stock
      stock_unit
      manufacturer_no
      physical_location
      conditions
      vat
      description
      purchase_price_excl_vat
      purchase_price_incl_vat
      selling_price_excl_vat
      selling_price_incl_vat
      supplier_prices
      barcodes
      serial_numbers
      images
      stock_manage_on
      stock_alert_on
      minimum_stock
      reminder_type
      manufacturer
      manufacturer_rel {
        id
        name
      }
      category
      category_rel {
        id
        name
      }
      color
      color_rel {
        id
        name
      }
      created_at
    }
  }
`;
