import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

//component
import GetStartedModal from "../../components/dashboard/getStartedModal/GetStartedModal";

//redux
import { useDispatch, useSelector } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";
import { profileState, setProfileSettings } from "../../toolkit/profile/profile.slice";
import { fetchProfileApi } from "../../toolkit/profile/profile.api";

//graphql
import { useQuery } from "@apollo/client";
import { GET_PROFILE_SETTINGS } from "../../gql/profileSettings/queries";

const RouteWithLayout: React.FC<{
  layout: any;
  component: any;
  path: any;
  exact: any;
  access: any;
  restricted: any;
}> = (props) => {
  const dispatch = useDispatch();
  const { layout: Layout, component: Component, ...rest } = props;
  const { auth: { token, user: _user } } = useSelector(authState);
  const { user } = useSelector(profileState).profile;
  const [getStatedModal, setGetStartedModal] = useState(false);

  const { data } = useQuery(GET_PROFILE_SETTINGS, {
    variables: {
      createdBy: user.id || _user.id
    }
  });

  useEffect(() => {
    if (token) {
      dispatch(fetchProfileApi(token));
    }
  }, [token])
  useEffect(() => {
    if (user.id && !user.is_walkthrough_done) {
      setGetStartedModal(true)
    } else {
      setGetStartedModal(false)
    }
  }, [user])
  useEffect(() => {
    if (Array.isArray(data?.profile_settings) && data.profile_settings?.length) {
      const { __typename, ...object } = data.profile_settings[0]
      dispatch(setProfileSettings(object))
    }
  }, [data])
  return (
    <>
      {props.access === "public" ? (
        <Route
          {...rest}
          render={(matchProps) =>
            token && props.restricted ? (
              <Redirect to="/dashboard" />
            ) : (
              <Layout>
                <Component {...matchProps} />
              </Layout>
            )
          }
        />
      ) : (
        <Route
          {...rest}
          render={(matchProps) =>
            (token !== null) ? (
              <Layout>
                <Component {...matchProps} />
              </Layout>
            ) : (
              <Redirect to="/" />
            )
          }
        />
      )}
      {/* {getStatedModal === true && 
        // <GetStartedModal close={() => { setGetStartedModal(false) }} />
      } */}
    </>
  );
};
export default RouteWithLayout;