import React, { useState } from "react";

// icons
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";

const Step5: React.FC<{ ChangeStep: Function, ChangeStepBack: Function }> = ({ ChangeStep, ChangeStepBack }) => {
  const [tab, setTab] = useState(1);
  // const [selected, setSelected] = useState("");
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper">
          <div className="stepFormWrapper-left">
            <img src="/assets/icon/iLogo.png" alt="" style={{ width: 120, height: 120, objectFit: "contain" }} />
            <h5 style={{ marginTop: 15 }}>Apple SE 2020</h5>
            <p style={{ textAlign: "center", lineHeight: 1.4 }}>
              <b> IMEI: </b> 125389386491937 <br />
              <b> Storage: </b> 256 GB <br />
              <b> Color: </b> Black <br />
            </p>
          </div>
          <div className="stepFormWrapper-right">
            <div className="stepFormWrapper-right_tab">
              <div
                className={`stepFormWrapper-right_tab-item ${tab === 1 ? "active" : ""}`}
                onClick={() => {
                  setTab(1);
                }}
              >
                Private
              </div>
              <div
                className={`stepFormWrapper-right_tab-item ${tab === 2 ? "active" : ""}`}
                onClick={() => {
                  setTab(2);
                }}
              >
                Organization
              </div>
            </div>
            {tab === 1 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Mr." handleChange={()=> null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Title" handleChange={()=> null} />
                  </div>
                </div>

                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="First Name" handleChange={()=> null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="Last Name" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Telefon" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Email" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Street" handleChange={()=> null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="No." handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Zip" handleChange={()=> null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="City" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Frankreich" handleChange={()=> null} />
                  </div>
                </div>
              </>
            )}
            {tab === 2 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Organization" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Mr." handleChange={()=> null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Title" handleChange={()=> null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="Position" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="First Name" handleChange={()=> null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="Last Name" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Telefon" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Email" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Street" handleChange={()=> null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="No." handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Zip" handleChange={()=> null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="City" handleChange={()=> null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Country" handleChange={()=> null} />
                  </div>
                </div>
              </>
            )}
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default Step5;
