import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ChatBoxBody: React.FC<{children: any}> = ({children}) => {
  return (
    <div className="DetailModal-main-middle-view-chat-right">
      {children}
    </div>
  );
};

export default ChatBoxBody;
