import { createAsyncThunk } from "@reduxjs/toolkit";
import {loginRemoveToken} from "../auth/auth.slice";
import apiURL from "../../config/index";

export const fetchProfileApi: any = createAsyncThunk(
  "profile/fetchProfile",
  async (token, thunkAPI) => {
    try {
      const response = await apiURL.get("user/me", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        if(err.response.data?.status === 403){
          thunkAPI.dispatch(loginRemoveToken())
        }
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const changePasswordApi: any = createAsyncThunk(
  "profile/changePassword",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/change-password", reqParam.formData, {
        headers: {
          Authorization: `Bearer ${reqParam.token}`
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const changeEmailApi: any = createAsyncThunk(
  "profile/changeEmail",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/change-email", reqParam.formData, {
        headers: {
          Authorization: `Bearer ${reqParam.token}`
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const updateEmailApi: any = createAsyncThunk(
  "profile/updateEmail",
  async (reqParam: any, thunkAPI) => {
    try {
      const response = await apiURL.post("user/update-email", reqParam.formData, {
        headers: {
          Authorization: `Bearer ${reqParam.token}`
        }
      });
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);