import React, { useEffect, useState } from "react";
import { IonImg } from "@ionic/react";
import "../../../pages/dashBoard/DashBoard.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Placeholder } from "rsuite";
import moment from "moment";
import { months } from "../../../constants";

//redux
import { connect } from "react-redux";
import { fetchTurnOver } from "../../../stores/stats/actions";

const Turnover: React.FC<{
  fetchTurnOver: Function
  turnover: any
  loading: boolean
}> = (props) => {
  const { Paragraph } = Placeholder;
  const [selectedMonth, setSelectedMonth] = useState<string>("")
  const handleChangeMonth = (action: string) => {
    if (action === "prev") {
      setSelectedMonth(moment(selectedMonth, "MMMM").subtract(1, "month").startOf("month").format('MMMM'))
    } else if (action === "next") {
      setSelectedMonth(moment(selectedMonth, "MMMM").add(1, "month").startOf("month").format('MMMM'))
    }
  }
  useEffect(() => {
    setSelectedMonth(moment().format('MMMM'))
  }, [])
  useEffect(()=>{
    let index = months.findIndex(month => month.label === selectedMonth);
    if(index > -1){
      // props.fetchTurnOver({filter: "month", month: index})
    }
  }, [selectedMonth])
  return (
    <div className="DashbaordPage_body_card">
      <div className="DashbaordPage_row">
        <div className="DashbaordPage_row_col_1">
          <p className="DashbaordPage_Header" style={{ paddingBottom: 7 }}>
            TURNOVER
          </p>
          {props.loading ? (
            <Paragraph rows={2} rowMargin={8} active/>
          ) : (
            <div className="DashbaordPage_row">
              <div 
                className="TURNOVER_text_wrapper" 
                style={{background: props.turnover?.percentage > 0 ? "#dfffe4" : "#ffdfdf"}}
              >
                <h2 
                  className="TURNOVER_text" 
                  style={{color: props.turnover?.percentage > 0 ? "#36e052" : "#ff0000"}}
                >{props.turnover?.percentage || "0"}%</h2>
                <div className="TURNOVER_circle">
                  <IonImg
                    src={`/assets/bg/${props.turnover?.percentage > 0 ? "up" : "down"}.png`}
                    alt=""
                    className="TURNOVER_circle_img"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="divider-vertical-TURNOVER" style={{ marginRight: 10 }} ></div>
        <div className="DashbaordPage_row_col_2">
          {props.loading ? (
            <Paragraph rows={3} rowMargin={8} active/>
          ) : (
            <h2 className="MONTH_text lg">€ {props.turnover?.salesCurrentCicle?.toLocaleString() || "0.00"}</h2>
          )}
        </div>
        <div className="divider-vertical-TURNOVER"></div>
        <div className="DashbaordPage_row_col_1">
          <div className="d-flex-center">
            <FiChevronLeft style={{ color: "#304a50", cursor: "pointer" }} onClick={() => handleChangeMonth("prev")} />
            <IonImg
              src="/assets/bg/cal.png"
              alt=""
              className="TURNOVER_circle_img_cal"
            />
            <FiChevronRight style={{ color: "#304a50", cursor: "pointer" }} onClick={() => handleChangeMonth("next")} />
          </div>
          <p
            className="DashbaordPage_Header"
            style={{ paddingTop: 7, textAlign: "center" }}
          >
            {selectedMonth}
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  turnover: {},
  loading: false
});
const mapDispatchToProps = {
  fetchTurnOver,
};
export default connect(mapStateToProps, mapDispatchToProps)(Turnover);