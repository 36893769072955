import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const RepairWorkFlowBoxes: React.FC<{
  clickFunction: any;
  name: string;
  image: string;
}> = ({ clickFunction, name, image }) => {
  return (
    <>
      {/*  */}
      <div
        className="DetailModal-main-middle-view-options"
        onClick={clickFunction}
      >
        <img src={image} alt="" />
        <h6>{name}</h6>
      </div>
      {/*  */}
      <div className="DetailModal-main-middle-view-options-hr"></div>
      {/*  */}
    </>
  );
};

export default RepairWorkFlowBoxes;
