import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icons

// component
import CompnaySettingsBankStep1 from "./CompnaySettingsBank.step1";

const CompnaySettingsBank = () => {

  return (
    <div className="CompnaySettingsGeneralDetails">
      <div className="flex-1 d-flex-start">
        <h2>Bank Details</h2>
      </div>

      <CompnaySettingsBankStep1 />
    </div>
  );
};

export default CompnaySettingsBank;
