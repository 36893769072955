interface SupportedProps {
  notes: string;
  setNotes: (n: string) => void;
}

const FourthTab = ({ notes, setNotes }: SupportedProps) => {
  return (
    <>
      <div className="CustomerContactModal_inner_note">
        <div
          className="CustomerContactModal_inner_flex_start"
          style={{ marginBottom: 0 }}
        >
          <div className="col">
            <h5 className="CustomerContactModal_tab_body_header">Note</h5>
          </div>
        </div>
        <div
          className="CustomerContactModal_inner_flex_start"
          style={{ marginBottom: 15 }}
        >
          <div className="col">
            <textarea
              placeholder="Write here ..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              style={{resize: "none"}}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthTab;
