import React, { FormEvent } from "react";
import {
  Form,
  FormGroup,
  ButtonToolbar,
  Button,
} from "rsuite";
import OtpInput from "react-otp-input";

//redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

interface PropsType {
  otp: string;
  setOTP: (otp: string) => void;
  onVerifyOTP: ((event: React.SyntheticEvent<Element, Event>) => void);
  errMsg: String;
}

const ForgotPasswordVerifyOTP = ({ otp, setOTP, errMsg, onVerifyOTP }: PropsType) => {
  const { auth: { isLoading } } = useSelector(authState);
  return (
    <Form fluid className="auth_Form">
      <div className="spacedHeight"></div>
      <h1 className="auth_Form_title">Verify your OTP</h1>
      <h3 className="auth_Form_title_sub" style={{ marginBottom: 10 }}>
        {" "}
        <b> You are almost there!</b>
      </h3>
      <h2>
        Enter the OTP below for verification and we will help you to reset your password.
      </h2>
      <OtpInput
        value={otp}
        onChange={setOTP}
        numInputs={4}
        inputStyle="login_otp"
        hasErrored={true}
        shouldAutoFocus={true}
        isInputNum={true}
        isInputSecure={false}
      />
      {errMsg?.length > 0 && (
        <FormGroup>
          <p className="err-msg">{errMsg}</p>
        </FormGroup>
      )}
      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            className="authBtn"
            onClick={onVerifyOTP}
            disabled={isLoading}
          >
            Verify OTP
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
};

export default ForgotPasswordVerifyOTP;
