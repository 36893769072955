import React from "react";

import InputBox from "../../../template/inputBox/InputBox";
import TagInput from "../../../template/tagInput/TagInput";

interface SupportedProps {
  dob: string;
  setDob: (b: string)=> void;
  tags: Array<string>;
  setTags: any;
}

const FifthTab = ({ dob, setDob, setTags, tags }: SupportedProps) => {
  return (
    <>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 0 }}
      >
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">Tag</h5>
        </div>
      </div>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
          <TagInput 
            handleChange={(value)=> {
              if(value.length){
                setTags((tags: any) => [...tags, value])
              }
            }} 
            visualData={tags} 
            setTags={setTags} 
          />
        </div>
      </div>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 0 }}
      >
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">Birthday</h5>
        </div>
      </div>
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
          <InputBox 
            labelText="" 
            valueProp={dob} 
            handleChange={setDob}
            type="date" 
          />
        </div>
      </div>
    </>
  );
};

export default FifthTab;
