import { gql } from "@apollo/client";

export const GET_ALL_MODELS = gql`
  query GetModels($where: models_bool_exp, $limit: Int!, $offset: Int!) {
    models(where: $where, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;
