import React from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ButtonToolbar,
  Button,
  Checkbox,
} from "rsuite";
import { Link } from "react-router-dom";

interface SupportProps {
  email: string;
  handleRegister: () => void;
  name: string;
  setName: (name: string) => void;
  password: string;
  setPassword: (password: string) => void;
  btnLoading: boolean;
}

const Stage3 = ({
  email,
  handleRegister,
  name,
  setName,
  password,
  setPassword,
  btnLoading
}: SupportProps) => {
  return (
    <>
      <Form fluid className="auth_Form" onSubmit={handleRegister}>
        <h1 className="auth_Form_title">Welcome to RepairCMS</h1>
        <h3 className="auth_Form_title_sub">You are signing up as {email}</h3>
        <FormGroup>
          {/* <ControlLabel>Email address</ControlLabel> */}
          <FormControl
            className="authFormInput"
            name="fullname"
            placeholder="Full Name"
            value={name}
            onChange={setName}
            required
          />
        </FormGroup>
        <FormGroup>
          {/* <ControlLabel>Password</ControlLabel> */}
          <FormControl
            className="authFormInput"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={setPassword}
            required
            minLength={8}
          />
          <h2
            style={{
              textAlign: "right",
              color: "rgba(48, 74, 80, 0.6)",
            }}
          >
            Password must be 8 characters or longer
          </h2>
        </FormGroup>
        <FormGroup>
          <div className="width-login">
            <Checkbox>Get featured updates &amp; tips via email</Checkbox>
          </div>
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Button 
              appearance="primary" 
              className="authBtn" 
              type="submit"
              disabled={btnLoading}
            >
              Continue
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Link to="/">
              <h2>
                Wrong account ? <span>Log in</span> instead
              </h2>
            </Link>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </>
  );
};

export default Stage3;
