import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const RepairFlowStatus: React.FC<{
  name: string;
  color: string;
}> = ({ name, color }) => {
  return (
    <>
      <div className="DetailModal-progress-item">
        <div className="DetailModal-progress-item-dot" style={{ backgroundColor: color }} ></div>
        <div className="">
          <h6>{name}</h6>
          <p>31.12.2021 | 04:53</p>
        </div>
      </div>
    </>
  );
};

export default RepairFlowStatus;
