import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ModalBodyAndDropShadow: React.FC<{ openModal: any; children: any }> = ({
  openModal,
  children,
}) => {
  return (
    <>
      <div className={`DetailModal ${openModal ? "" : "dis-none"}`}>
        <div className="DetailModal_body">{children}</div>
      </div>
    </>
  );
};

export default ModalBodyAndDropShadow;
