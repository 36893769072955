import React, { useEffect, useState } from "react";
import { Form, FormGroup, ButtonToolbar, Button } from "rsuite";
import { Link } from "react-router-dom";
//GraphQL
import { GET_ALL_INDUSTRIES } from "../../../gql/industries/queries";
import { useQuery } from "@apollo/client"
interface SupportProps {
  // loginSetToken: () => void;
  setIndustry: (industry: string) => void;
  handleRegister: () => void;
  btnLoading: boolean;
}
interface IndustryType {
  comment: string;
  value: string;
}

const Stage4 = ({ handleRegister, setIndustry, btnLoading }: SupportProps) => {
  const { data, loading } = useQuery(GET_ALL_INDUSTRIES);
  const [industries, setIndustries] = useState<IndustryType[]>([])
  useEffect(() => {
    if (Array.isArray(data?.enum_industries)) {
      setIndustries(data.enum_industries)
    }
  }, [data])
  return (
    <>
      <Form fluid className="auth_Form">
        <h1 className="auth_Form_title">Tell us about yourself,demo</h1>
        <h3 className="auth_Form_title_sub"></h3>
        <FormGroup>
          <select
            name=""
            id=""
            className="auth_Form_checkbox"
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value="" selected disabled>
              What kind of work do you do ?
            </option>
            {industries.map((industry, i) => (
              <option value={industry.value} key={i}>
                {industry.comment}
              </option>
            ))}
          </select>
        </FormGroup>
        <div className="spacedHeight"></div>
        <FormGroup>
          <ButtonToolbar>
            <Button
              appearance="primary"
              className="authBtn"
              onClick={handleRegister}
              disabled={btnLoading}
            >
              Continue
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <FormGroup>
          <ButtonToolbar>
            <Link to="/">
              <h2>
                Wrong account ? <span>Log in</span> instead
              </h2>
            </Link>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </>
  );
};

export default Stage4;
