import React, { useState, useEffect } from "react";
// icons
import { FiX, FiSearch, FiCheck } from "react-icons/fi";

import Loader from "../../../loader/Loader";

//type
import StockItemType from "../../../stockItems/stockItemsModal/StockItems.type";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_ITEMS } from "../../../../gql/stockItems/queries";

//redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../../../toolkit/auth/auth.slice";

//functions
import { BuildStockSearchQuery } from "../../../../pages/stockItems/StockItems.functions"

const AssignedItems: React.FC<{
	assignedItems: StockItemType[];
	onChange: Function;
	onRemove: Function;
}> = ({ assignedItems, onChange, onRemove }) => {
	const { auth: { user } } = useSelector(authState);
	const defaultCondition = {
		created_by: { _eq: user.id },
		active: { _eq: true }
	}
	const [search, setSearch] = useState<string>("");
	const [items, setItems] = useState<StockItemType[]>([])
	const [queryCondition, setQueryCondition] = useState<any>(defaultCondition);
	const [offset, setOffset] = useState<number>(0);
	const [limit, setLimit] = useState<number>(10);

	const { data, refetch, error, loading } = useQuery(GET_ALL_ITEMS, {
		variables: {
			limit: limit,
			offset: offset,
			where: queryCondition
		},
	});
	const handleRemoveFromSearch = (id: string) => {
		let _items: StockItemType[] = [...items]
		_items = _items.filter((item: StockItemType) => item.id !== id)
		setItems(_items)
	}

	useEffect(() => {
		let conditionTemp:any = {}
		if(search.length){
			conditionTemp = BuildStockSearchQuery(search, null)
		}
		if(assignedItems.length){
			conditionTemp.id = {_nin: assignedItems.map((item: StockItemType) => item.id)}
		}
		setQueryCondition({
			...defaultCondition,
			...conditionTemp
		})
	}, [search, assignedItems])
	useEffect(() => {
		if (Array.isArray(data?.items)) {
			setItems(data.items)
		}
	}, [data])
	return (
		<div>
			<div className="ServicesModal_inner_flex_start">
				<div className="col">
					<h5 className="ServicesModal_tab_body_header">
						Assign Items to service
					</h5>
				</div>
				<div className="col">
					<div
						className="ServicesModal_inner_flex_start"
						style={{ justifyContent: "flex-end" }}
					>
						<div
							className="Services_body_top_search"
							style={{ width: 200 }}
						>
							<input
								type="text"
								placeholder="Search & Add Item"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
							<FiSearch />
						</div>
					</div>
				</div>
			</div>
			<div className="">
				{assignedItems.map((item: StockItemType, i: number) => (
					<div className="ConvertTicket_modal_item" key={i}>
						<div className="ConvertTicket_modal_item_left">
							<h5>{item.name}</h5>
							<div className="ConvertTicket_modal-bodyInner-flex-start">
								<h6>Item No. : {item.item_no} </h6>
								<h6> Location : {item.physical_location} </h6>
								<h6 style={{ borderRight: 0 }}>
									{" "}
									Stock Level : {`${item.stock} ${item.stock_unit}`}
								</h6>
							</div>
						</div>
						<div
							className="ConvertTicket_modal_item_left ServicesModal_inner_flex_start"
							style={{
								flex: 1,
								justifyContent: "flex-end",
								alignItems: "center",
								color: "#fff",
							}}
						>
							<h5
								style={{
									fontSize: 14,
									marginRight: 7,
									height: "100%",
								}}
							>
								Price: € {item.selling_price_excl_vat}
							</h5>
							<h6
								style={{
									borderRight: 0,
									flex: 0,
									whiteSpace: "nowrap",
									height: "100%",
								}}
							>
								(Excl. Tax)
							</h6>
						</div>
						<div className="ConvertTicket_modal_item_right">
							<div className="ConvertTicket_modal_item_right_Reserved" onClick={()=>onRemove(item.id)}>
								assigned <FiX style={{ color: "#fff" }} />
							</div>
						</div>
					</div>
				))}
				{loading && (
					<Loader />
				)}
				{items.map((item: StockItemType, i: number) => (
					<div className="ConvertTicket_modal_item" key={i}>
						<div className="ConvertTicket_modal_item_left">
							<h5>{item.name}</h5>
							<div className="ConvertTicket_modal-bodyInner-flex-start">
								<h6>Item No. : {item.item_no} </h6>
								<h6> Location : {item.physical_location} </h6>
								<h6 style={{ borderRight: 0 }}>
									{" "}
									Stock Level : {`${item.stock} ${item.stock_unit}`}
								</h6>
							</div>
						</div>
						{/* <div
							className="ConvertTicket_modal_item_left ServicesModal_inner_flex_start"
							style={{ flex: 1, justifyContent: "flex-end" }}
						></div> */}
						<div
							className="ConvertTicket_modal_item_left ServicesModal_inner_flex_start"
							style={{
								flex: 1,
								justifyContent: "flex-end",
								alignItems: "center",
								color: "#fff",
							}}
						>
							<h5
								style={{
									fontSize: 14,
									marginRight: 7,
									height: "100%",
								}}
							>
								Price: € {item.selling_price_excl_vat}
							</h5>
							<h6
								style={{
									borderRight: 0,
									flex: 0,
									whiteSpace: "nowrap",
									height: "100%",
								}}
							>
								(Excl. Tax)
							</h6>
						</div>
						<div className="ConvertTicket_modal_item_right">
							<div className="botn" onClick={()=> {
								onChange(item)
								handleRemoveFromSearch(item.id)
							}}>
								<FiCheck style={{ color: "#0f0" }} />
							</div>
							<div className="botn" onClick={() => handleRemoveFromSearch(item.id)}>
								<FiX style={{ color: "#f00" }} />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
export default AssignedItems;