import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Checkbox, CheckboxGroup, Tag } from "rsuite";

//graphql
import {GET_ALL_MANUFACTURERS} from "../../../gql/manufacturers/queries"
import {GET_ALL_COLORS} from "../../../gql/colors/queries"
import {GET_ALL_ITEM_CATEGORIES} from "../../../gql/itemCategories/queries"

//redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//components
import CheckPickerPaginate from "../../common/CheckPickerPaginate"
const StockFilters: React.FC<{
  stockSetFilter: Function;
  stockFilters: any
}> = ({ stockSetFilter, stockFilters }) => {
  const { auth: { user } } = useSelector(authState);
  const filterRef = useRef<any>(null)
  const [filters, setFilters] = useState<any[]>([])
  const [filtersTemp, setFiltersTemp] = useState<any[]>([])

  const handleRemoveFilter = (name: string) => {
    setFilters(filters.filter(filter => filter.name !== name))
    setFiltersTemp(filtersTemp.filter(filter => filter !== name))
  }
  const handleSetFilters = () => {
    let filtersArr: any[] = []
    filtersTemp.map((filter) => {
      filtersArr.push({
        name: filter,
        value: null
      })
    })
    setFilters(filtersArr)
    filterRef.current.toggle()
  }
  const handleChangeFilterValue = (key: string, value: any) => {
    stockSetFilter({
    	...stockFilters,
    	[key]:value,
    })
  }
  useEffect(() => {
    let filterObj = {...stockFilters}
    if(!filters.find(filter => filter.name === 'manufacturer')){
    	filterObj = {...filterObj, manufacturer: []}
    }
    if(!filters.find(filter => filter.name === 'color')){
    	filterObj = {...filterObj, color: []}
    }
    if(!filters.find(filter => filter.name === 'category')){
    	filterObj = {...filterObj, category: []}
    }
    if(JSON.stringify(filterObj) !== JSON.stringify(stockFilters)){
    	stockSetFilter(filterObj)
    }
  }, [filters])
  return (
    <>
      <div>
        {filters.map((filter: any, i: number) => (
          <Tag
            closable
            onClose={() => handleRemoveFilter(filter.name)}
            key={i}
          >
            <CheckPickerPaginate
              query={filter.name === "manufacturer" ? GET_ALL_MANUFACTURERS : filter.name === "color" ? GET_ALL_COLORS : GET_ALL_ITEM_CATEGORIES}
              placeholder={`Select ${filter.name}`}
              value={filter.name === "manufacturer" ? stockFilters.manufacturer : filter.name === "color" ? stockFilters.color : stockFilters.category}
              onChange={(value) => handleChangeFilterValue(filter.name, value)}
              arrKey={filter.name === "manufacturer" ? "manufacturers" : filter.name === "color" ? "colors" : "item_categories"}
              objectLabel="name"
              objectValue="id"
              paginate={true}
              filterCondition={{active: {_eq: true}, created_by: {_eq: user.id}}}
						/>
          </Tag>
        ))}
      </div>
      <div className="Services_body_top_btn">
        <Dropdown
          trigger={["click"]}
          className="actionDropPageHeader export sort"
          placement="bottomEnd"
          renderTitle={() => <img src="/assets/bg/filter.png" />}
          ref={filterRef}
        >
          <div className="sort_drop">
            <h5>Filter</h5>
            <CheckboxGroup
              inline
              name="checkboxList"
              onChange={setFiltersTemp}
              className="JobsOverview_body_top_filters"
              value={filtersTemp}
            >
              <Checkbox value="manufacturer">Manufacturer</Checkbox>
              <Checkbox value="color">Color</Checkbox>
              <Checkbox value="category">Category</Checkbox>
            </CheckboxGroup>
            <div className="d-flex">
              <button
                className="bt-secondary"
                onClick={() => filterRef.current.toggle()}
              >
                Close
              </button>
              <button
                className="bt-primary"
                onClick={handleSetFilters}
              >
                Apply
              </button>
            </div>
          </div>
        </Dropdown>
      </div>
    </>
  )
}
export default StockFilters;