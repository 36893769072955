import React, { useState, useEffect } from "react";
import moment from "moment";

// icons
import { FiPlus, FiEdit2, FiTrash2 } from "react-icons/fi";

// components
import SelectBox from "../../template/selectBox/SelectBox";
import SelectBoxSearch from "../../template/selectBoxSearch/SelectBoxSearch";
import AddInvoiceCustomer from "./AddInvoiceCustomer";

//redux
import { useQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { GET_ALL_CUSTOMER } from "../../../gql/customers/queries";
import { authState } from "../../../toolkit/auth/auth.slice";
import {
  customerState,
  setCustomer,
} from "../../../toolkit/customers/customers.slice";

const InvoiceModalDetails: React.FC<{
  setSelectedCustomerDetails: Function;
  selectedCustomerDetails: any;
}> = ({selectedCustomerDetails, setSelectedCustomerDetails}) => {
  const dispatch = useDispatch();
  const { customers } = useSelector(customerState);
  const {
    auth: { user },
  } = useSelector(authState);

  const defaultCondition = {
    created_by: {_eq: user.id}, 
    active: {_eq: true}, 
    user_type: {_eq: "customer"}
  }

  const [step, setStep] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  // const { data, loading, refetch } = useQuery(GET_ALL_CUSTOMER, {
  //   variables: { limit: 10, offset: 0, where: defaultCondition},
  // });

  // useEffect(() => {
  //   if (typeof customers === "undefined" || customers.length > 0 || !data)
  //     return;
  //   dispatch(setCustomer(data));
  // }, [data]);

  return (
      <>
        <div className="InvoiceModal_body_inner_details">
          <div className="InvoiceModal_body_inner_table">
            <div className="InvoiceModal_body_inner_table_col">
              <small>Your Company - Your Street 123 - 12345 Your City </small>

              {step === 1 && (
                <button
                  className="bt-dotted"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  <FiPlus /> Add Customer
                </button>
              )}

              {step === 2 && (
                <>
                  <SelectBoxSearch labelText="Search or add new customer" setSelectedCustomerDetails={setSelectedCustomerDetails} setStep={setStep} />
                </>
              )}
              {step === 3 && (
                <AddInvoiceCustomer setStep={setStep} />
              )}
              {step === 4 && (
                <>
                  <div
                    className="InvoiceModal_body_inner_subBox"
                    style={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    <div className="InvoiceModal_body_inner_table row_align_top">
                      <div className="InvoiceModal_body_inner_table_col ">
                        <b>{selectedCustomerDetails?.addresses[0]?.newName}</b>
                        <br />
                        <b>{selectedCustomerDetails?.addresses[0]?.city}</b>
                        <br />
                        <b>{selectedCustomerDetails?.addresses[0]?.newNo}</b>
                        <br />
                        <b>{`${selectedCustomerDetails?.addresses[0]?.zip} ${selectedCustomerDetails?.addresses[0]?.country}`}</b>
                        <br />
                      </div>
                      <div
                        className="pointer"
                        style={{ width: 20 }}
                        onClick={() => {
                          setStep(5);
                        }}
                      >
                        <FiEdit2 />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 5 && (
                <>
                  <div className="InvoiceModal_body_inner_subBox">
                    <div className="InvoiceModal_body_inner_table row_align_top">
                      <div className="InvoiceModal_body_inner_table_col ">
                        <b>{selectedCustomerDetails?.addresses[0]?.newName}</b>
                        <br />
                        <b>{selectedCustomerDetails?.addresses[0]?.city}</b>
                        <br />
                        <b>{selectedCustomerDetails?.addresses[0]?.newNo}</b>
                        <br />
                        <b>{`${selectedCustomerDetails?.addresses[0]?.zip} ${selectedCustomerDetails?.addresses[0]?.country}`}</b>
                        <br />
                      </div>
                      <div
                        className="pointer"
                        style={{ width: 20 }}
                        onClick={() => {
                          setStep(4);
                        }}
                      >
                        <FiTrash2 />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="InvoiceModal_body_inner_table_col"></div>
          </div>
        </div>

        <div className="InvoiceModal_body_inner_stocks">
          <h5>User : {user?.name}</h5>
          <h6>{moment().format("DD-MM-YY hh:mm")}</h6>
        </div>
      </>
  );
};

export default InvoiceModalDetails;
