import React, { useEffect, useState } from "react";
import { IonImg } from "@ionic/react";
import { NavLink, Link } from "react-router-dom";
import {
  FiPieChart,
  FiBriefcase,
  FiUsers,
  FiLayers,
  FiList,
  FiFileText,
  FiSearch,
  FiPlus,
  FiSliders,
  FiChevronDown,
  FiUser,
  FiSettings,
  FiHelpCircle,
  FiLogOut,
  FiMenu,
  FiCheck,
  FiArrowLeft,
} from "react-icons/fi";
import { RiFileList3Line } from "react-icons/ri";
import { Dropdown } from "rsuite";
import "./SideBar.css";

//import components
import NotificationBox from "../../common/NotificationBox";
//redux
import { connect, useDispatch } from "react-redux";
import { updateProfile } from "../../../stores/profile/actions";
import { loginRemoveToken } from "../../../toolkit/auth/auth.slice";

const SideBar: React.FC<{
  profile: any;
  updateProfile: Function;
}> = ({ profile, ...props }) => {
  const dispatch = useDispatch();
  const [drop, setDrop] = useState("");
  const [dropSub, setDropSub] = useState("");
  const [open, setOpen] = useState(false);
  const [openSubSettings, setOpenSubSettings] = useState(false);
  const [openSubContact, setOpenSubContact] = useState(false);
  const [openSubStock, setOpenSubStock] = useState(false);
  const [openSubMyRepair, setOpenSubMyRepair] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState("");
  const changeBackground = (name: string) => {
    props.updateProfile({ ...profile, background: name });
  };
  return (
    <>
      <div className="desk-only">
        <div className="MainHeader">
          <IonImg src="/assets/logo.png" className="MainHeader_logo" />
          <IonImg
            src="/assets/logo-mini.png"
            className="MainHeader_logo_break1"
          />
          {/* <IonImg src="/assets/logo.png" className="MainHeader_logo_break3" /> */}
          <div className="MainHeader-divider"></div>
          <NavLink
            to="/dashboard"
            className={`navItem ${drop === "dashboard" ? "active" : ""}`}
            onClick={() => {
              setDrop("dashboard");
              setOpenSubContact(false);
              setOpenSubStock(false);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiPieChart />
            <div>Dashboard</div>
            <p>
              <span> Dashboard </span>
            </p>
          </NavLink>
          <NavLink
            to="/jobs"
            className={`navItem ${drop === "jobs" ? "active" : ""}`}
            onClick={() => {
              setDrop("jobs");
              setOpenSubContact(false);
              setOpenSubStock(false);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiBriefcase />
            <div>Jobs</div>
            <p>
              <span> Jobs </span>
            </p>
          </NavLink>
          <NavLink
            to="/subcriptions"
            className={`navItem ${drop === "subcriptions" ? "active" : ""}`}
            onClick={() => {
              setDrop("subcriptions");
              setOpenSubContact(false);
              setOpenSubStock(false);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <RiFileList3Line />
            <div>Subcriptions</div>
            <p>
              <span> Subcriptions </span>
            </p>
          </NavLink>
          <div
            // to="/contacts"
            className={`navItem ${drop === "contacts" ? "active" : ""} ${
              openSubContact === true && drop === "contacts" ? "subActive" : ""
            } `}
            onClick={() => {
              setDrop("contacts");
              setOpenSubContact(!openSubContact);
              setOpenSubStock(false);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiUsers />
            <div>Contacts</div>
            <p>
              <span> Contacts </span>
            </p>
          </div>
          {openSubContact && (
            <div className="sub_menu">
              <NavLink
                to="/contacts/customer"
                className={`navItem ${dropSub === "customer" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("customer");
                }}
              >
                {/* <FaUserTie /> */}
                <div>Customers</div>
                <p>
                  <span> Customers </span>
                </p>
              </NavLink>
              <NavLink
                to="/contacts/supplier"
                className={`navItem ${dropSub === "invoices" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("invoices");
                }}
              >
                {/* <FaTruck /> */}
                <div>Suppliers</div>
                <p>
                  <span> Suppliers </span>
                </p>
              </NavLink>
            </div>
          )}

          <div
            className={`navItem ${drop === "stock" ? "active" : ""}`}
            onClick={() => {
              setDrop("stock");
              setOpenSubContact(false);
              setOpenSubStock(!openSubStock);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiLayers />
            <div>Stock</div>
            <p>
              <span> Stock </span>
            </p>
          </div>
          {openSubStock && (
            <div className="sub_menu">
              <NavLink
                to="/stock/Item"
                className={`navItem ${dropSub === "StockItem" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("StockItem");
                }}
              >
                {/* <FaUserTie /> */}
                <div>Items</div>
                <p>
                  <span> Items </span>
                </p>
              </NavLink>
              <NavLink
                to="/stock/supplier"
                className={`navItem ${
                  dropSub === "stockSuppliers" ? "active" : ""
                }`}
                onClick={() => {
                  setDropSub("stockSuppliers");
                }}
              >
                {/* <FaTruck /> */}
                <div>Suppliers</div>
                <p>
                  <span> Suppliers </span>
                </p>
              </NavLink>
            </div>
          )}

          <NavLink
            to="/services"
            className={`navItem ${drop === "services" ? "active" : ""}`}
            onClick={() => {
              setDrop("services");
              setOpenSubContact(false);
              setOpenSubStock(false);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiList />
            <div>Services</div>
            <p>
              <span> Services </span>
            </p>
          </NavLink>

          <NavLink
            to="/invoices"
            className={`navItem ${drop === "invoices" ? "active" : ""}`}
            onClick={() => {
              setDrop("invoices");
              setOpenSubContact(false);
              setOpenSubStock(false);
              setOpenSubSettings(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiFileText />
            <div>Invoices</div>
            <p>
              <span> Invoices </span>
            </p>
          </NavLink>

          <div
            className={`navItem ${drop === "settings" ? "active" : ""} ${
              openSubSettings === true && drop === "settings" ? "subActive" : ""
            } `}
            onClick={() => {
              setDrop(drop === "settings" ? "" : "settings");
              setOpenSubSettings(!openSubSettings);
              setOpenSubContact(false);
              setOpenSubStock(false);
              setOpenSubMyRepair(false);
            }}
          >
            <FiSettings />
            <div>Settings</div>
            <p>
              <span> Settings </span>
            </p>
          </div>
          {/*  */}
          {openSubSettings && (
            <div className="sub_menu">
              <NavLink
                to="/settings/company"
                className={`navItem ${dropSub === "company" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("company");
                }}
              >
                {/* <FaRegBuilding /> */}
                <div>Company</div>
                <p>
                  <span> Company </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/recipts"
                className={`navItem ${dropSub === "recipts" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("recipts");
                }}
              >
                {/* <FiFile /> */}
                <div>Receipts</div>
                <p>
                  <span> Receipts </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/labels"
                className={`navItem ${dropSub === "labels" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("labels");
                }}
              >
                {/* <FiTag /> */}
                <div>Labels</div>
                <p>
                  <span> Labels </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/text-template"
                className={`navItem ${
                  dropSub === "textTemplates" ? "active" : ""
                }`}
                onClick={() => {
                  setDropSub("textTemplates");
                }}
              >
                {/* <FaTextHeight /> */}
                <div>Text Templates</div>
                <p>
                  <span> Text Templates </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/emailTemplates"
                className={`navItem ${
                  dropSub === "emailTemplate" ? "active" : ""
                }`}
                onClick={() => {
                  setDropSub("emailTemplate");
                }}
              >
                {/* <FiMail /> */}
                <div>Email Templates</div>
                <p>
                  <span> Email Templates </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/status"
                className={`navItem ${dropSub === "status" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("status");
                }}
              >
                {/* <FiMail /> */}
                <div>Status</div>
                <p>
                  <span> Status </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/jobs"
                className={`navItem ${
                  dropSub === "jobsSettings" ? "active" : ""
                }`}
                onClick={() => {
                  setDropSub("jobsSettings");
                }}
              >
                {/* <MdOutlineSchool /> */}
                <div>Jobs</div>
                <p>
                  <span> Jobs </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/chats"
                className={`navItem ${
                  dropSub === "chatSettings" ? "active" : ""
                }`}
                onClick={() => {
                  setDropSub("chatSettings");
                }}
              >
                {/* <FiMessageSquare /> */}
                <div>Chat</div>
                <p>
                  <span> Chat </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/location"
                className={`navItem ${dropSub === "location" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("location");
                }}
              >
                {/* <FiMapPin /> */}
                <div>Locations</div>
                <p>
                  <span> Locations </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/users"
                className={`navItem ${dropSub === "users" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("users");
                }}
              >
                {/* <FiUser /> */}
                <div>Users</div>
                <p>
                  <span> Users </span>
                </p>
              </NavLink>
              <NavLink
                to="/settings/OtherSettings"
                className={`navItem ${dropSub === "othersettings" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("othersetings");
                }}
              >
                {/* <FiUser /> */}
                <div>Other Settings</div>
                <p>
                  <span> Other Settings </span>
                </p>
              </NavLink>
            </div>
          )}
          {/*  */}
          <hr style={{ opacity: 0.3 }} />
          <div
            // to="/settings"
            className={`navItem ${drop === "myRepair" ? "active" : ""} ${
              openSubMyRepair === true && drop === "myRepair" ? "subActive" : ""
            }`}
            onClick={() => {
              setDrop(drop === "myRepair" ? "" : "myRepair");
              setOpenSubMyRepair(!openSubMyRepair);
              setOpenSubSettings(false);
              setOpenSubContact(false);
            }}
          >
            <img src="/assets/lshort.png" />
            <div>My RepairCMS</div>
            <p>
              <span> My RepairCMS </span>
            </p>
          </div>
          {/*  */}
          {openSubMyRepair && (
            <div className="sub_menu">
              <NavLink
                to="/profile"
                className={`navItem ${dropSub === "profile" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("profile");
                }}
              >
                <div>My Profile</div>
                <p>
                  <span> My Profile </span>
                </p>
              </NavLink>
              <NavLink
                to="/subscription"
                className={`navItem ${
                  dropSub === "subscription" ? "active" : ""
                }`}
                onClick={() => {
                  setDropSub("subscription");
                }}
              >
                <div>Subscription</div>
                <p>
                  <span> Subscription </span>
                </p>
              </NavLink>
              <NavLink
                to="/billing"
                className={`navItem ${dropSub === "billing" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("billing");
                }}
              >
                <div>Billing</div>
                <p>
                  <span> Billing </span>
                </p>
              </NavLink>
              <NavLink
                to="/account"
                className={`navItem ${dropSub === "account" ? "active" : ""}`}
                onClick={() => {
                  setDropSub("account");
                }}
              >
                <div>Account</div>
                <p>
                  <span> Account </span>
                </p>
              </NavLink>
            </div>
          )}
          {/*  */}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  profile: {},
});
const mapDispatchToProps = {
  updateProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
