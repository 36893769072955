const validator = require("validator"),
  isEmpty = require("../isEmpty");

const validateCreateService = data => {
  let errors = {};
  data.name = !isEmpty(data.name) ? data.name : "";
  data.category = !isEmpty(data.category) ? data.category : "";
  data.manufacturer = !isEmpty(data.manufacturer) ? data.manufacturer : "";
  data.price_excl_vat = !isEmpty(data.price_excl_vat) ? data.price_excl_vat : "";

  if (validator.isEmpty(data.name)) {
    errors.name = "Product name is required";
  }
  if (validator.isEmpty(data.category)) {
    errors.category = "Category is required";
  }
  if (validator.isEmpty(data.manufacturer)) {
    errors.manufacturer = "Manufacturer is required";
  }
  if (validator.isEmpty(data.price_excl_vat)) {
    errors.price_excl_vat = "Price (excl vat) is required";
  }
  return {
    errors,
    isValid: isEmpty(errors)
  };
};
export default validateCreateService;