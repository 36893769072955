import {
  NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS,
} from "./constants";
import { _fetchNotifications} from "./services";
import {loginRemoveToken} from "../auth/actions";

export function notificationSetLoading(loading) {
  return {
    type: NOTIFICATIONS_LOADING,
    payload: loading,
  };
}
export function notificationSetData(params) {
  return {
    type: SET_NOTIFICATIONS,
    payload: params,
  };
}
export const fetchNotifications = (params) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth;
		dispatch(notificationSetLoading(true));
		_fetchNotifications(params, token).then(async (res) => {
			if(Array.isArray(res.notifications)){
				dispatch(notificationSetData(res.notifications));
			}
			resolve(res)
		}).catch((err) => {
			if(err?.response?.status === 401){
                dispatch(loginRemoveToken());
            }
			reject(err)
		}).finally(() => {
			dispatch(notificationSetLoading(false));
		})
	})
};