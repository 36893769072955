import React, { useState, useEffect, useRef } from "react";
import {
  FlexboxGrid,
  SelectPicker,
  Uploader,
  Message,
  Loader,
  Toggle,
} from "rsuite";
import "./CompnaySettingReceipt.scss";

import { FaTrashAlt } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";

import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/js/plugins.pkgd.min.js";

function previewFile(file: any, callback: any) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

const CompanySettingReceipt: React.FC<{ recepitType: string }> = ({
  recepitType,
}) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);

  const [editor, setEditor] = useState<any>(undefined);
  const [model1, setModel1] = useState<string>("");
  const [model2, setModel2] = useState<string>("");

  const handleModel1Change = (model: any) => {
    setModel1(model1);
  };
  const handleModel2Change = (model: any) => {
    setModel1(model2);
  };
  const checkDlt = () => {
    console.log("yes");
    setFileInfo(null);
  };

  return (
    <div
      className={`receiptsPage ${
        recepitType == "Job ReThermal Receipt(Invoice)ceipt" ||
        recepitType == "Thermal Receipt(Job booking)"
          ? "smallRecepit"
          : "largeRecepit"
      } ${recepitType == "Background Template" ? "tampletReceipt" : null}`}
    >
      {recepitType == "Job ReThermal Receipt(Invoice)ceipt" ||
      recepitType == "Thermal Receipt(Job booking)" ? (
        <FlexboxGrid
          className={`receiptsPage-header ${
            recepitType == "Job ReThermal Receipt(Invoice)ceipt" ||
            recepitType == "Thermal Receipt(Job booking)"
              ? "small-receiptsPage-header"
              : null
          }`}
        >
          <FlexboxGrid.Item className="receiptsPage-header-cols" colspan={24}>
            <div className="small-receiptsPage-header-cols ">
              <div
                className={` ${
                  recepitType == "Job ReThermal Receipt(Invoice)ceipt" ||
                  recepitType == "Thermal Receipt(Job booking)"
                    ? "smallRecepit-logomain"
                    : "largeRecepit-logomain"
                }`}
              >
                {fileInfo !== null ? (
                  <button className="ImgDlt" onClick={checkDlt}>
                    <FaTrashAlt />
                  </button>
                ) : null}
                <Uploader
                  fileListVisible={false}
                  listType="picture"
                  // action="//jsonplaceholder.typicode.com/posts/"
                  onUpload={(file) => {
                    setUploading(true);
                    previewFile(file.blobFile, (value: any) => {
                      setFileInfo(value);
                    });
                  }}
                  onSuccess={(response: Object, file: any) => {
                    setUploading(false);
                    // toaster.push(<Message type="success">Uploaded successfully</Message>);
                    console.log(response);
                  }}
                  onError={() => {
                    setFileInfo(null);
                    setUploading(false);
                    // toaster.push(<Message type="error">Upload failed</Message>);
                  }}
                >
                  <button>
                    {uploading && <Loader backdrop center />}
                    {fileInfo ? (
                      <img src={fileInfo} width="100%" height="100%" />
                    ) : (
                      // <Avatar style={{ fontSize: 80 }} />
                      <div className="logo-layout">
                        <h2>
                          Your <span>LOGO</span>
                        </h2>
                        <p>Company Name</p>
                      </div>
                    )}
                  </button>
                </Uploader>
              </div>

              <div className="compenyDetails-2">
                <p className="compenyDetails-text">Repairshop Center Limited</p>
                <p className="compenyDetails-text">
                  PiusstraBe 123 | 50823 Koln | Germania{" "}
                </p>
                <p className="compenyDetails-text">Tel.: +49 29910 9292002 </p>
                <p className="compenyDetails-text">
                  office@repairshop-center.de
                </p>
                <p className="compenyDetails-text">TAX ID: DE 10400014 12021</p>
              </div>
              <div className="compenyDetails-1 align-left">
                <p className="compenyDetails-text">
                  Muller GmbH-PiusstraBe 133 - 50823 Koln
                </p>
                <p className="compenyDetails-text">Broadway Company Limited</p>
                <p className="compenyDetails-text">Frau Andrea Stock</p>
                <p className="compenyDetails-text">DronstraBe 17</p>
                <p className="compenyDetails-text">50769 Koln</p>
                <p className="compenyDetails-text">Germania</p>
              </div>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : recepitType == "Background Template" ? (
        <FlexboxGrid className="receiptsPage-header">
          <FlexboxGrid.Item className="receiptsPage-header-cols" colspan={12}>
            <div className="compenyDetails-1">
              <p className="compenyDetails-text">
                Muller GmbH-PiusstraBe 133 - 50823 Koln
              </p>
              <p className="compenyDetails-text">Broadway Company Limited</p>
              <p className="compenyDetails-text">Frau Andrea Stock</p>
              <p className="compenyDetails-text">DronstraBe 17</p>
              <p className="compenyDetails-text">50769 Koln</p>
              <p className="compenyDetails-text">Germania</p>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className="receiptsPage-header-cols" colspan={12}>
            <div className="logoMain" style={{ width: "100%" }}>
              <h2 className="uplodCustomText">Upload Custom PDF</h2>
              {fileInfo !== null ? (
                <button className="ImgDlt" onClick={checkDlt}>
                  <FaTrashAlt />
                </button>
              ) : null}
              <Uploader
                fileListVisible={false}
                listType="picture"
                // action="//jsonplaceholder.typicode.com/posts/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value: any) => {
                    setFileInfo(value);
                  });
                }}
                onSuccess={(response: Object, file: any) => {
                  setUploading(false);
                  // toaster.push(<Message type="success">Uploaded successfully</Message>);
                  console.log(response);
                }}
                onError={() => {
                  setFileInfo(null);
                  setUploading(false);
                  // toaster.push(<Message type="error">Upload failed</Message>);
                }}
              >
                <button>
                  {uploading && <Loader backdrop center />}
                  {fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : (
                    <>
                      <div
                        className={`logo-layout ${
                          recepitType == "Background Template"
                            ? "logo-layout-tamplet"
                            : null
                        }`}
                      >
                        <AiOutlineCloudUpload className="AiOutlineCloudUpload" />
                      </div>
                    </>
                  )}
                </button>
              </Uploader>
            </div>
            <div>
              <img src="../assets/barcode.png" width="100%" height="100%" />
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      ) : (
        <FlexboxGrid className="receiptsPage-header">
          <FlexboxGrid.Item className="receiptsPage-header-cols" colspan={12}>
            <div className="compenyDetails-1">
              <p className="compenyDetails-text">
                Muller GmbH-PiusstraBe 133 - 50823 Koln
              </p>
              <p className="compenyDetails-text">Broadway Company Limited</p>
              <p className="compenyDetails-text">Frau Andrea Stock</p>
              <p className="compenyDetails-text">DronstraBe 17</p>
              <p className="compenyDetails-text">50769 Koln</p>
              <p className="compenyDetails-text">Germania</p>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className="receiptsPage-header-cols" colspan={12}>
            <div className="logoMain">
              {fileInfo !== null ? (
                <button className="ImgDlt" onClick={checkDlt}>
                  <FaTrashAlt />
                </button>
              ) : null}
              <Uploader
                fileListVisible={false}
                listType="picture"
                // action="//jsonplaceholder.typicode.com/posts/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value: any) => {
                    setFileInfo(value);
                  });
                }}
                onSuccess={(response: Object, file: any) => {
                  setUploading(false);
                  // toaster.push(<Message type="success">Uploaded successfully</Message>);
                  console.log(response);
                }}
                onError={() => {
                  setFileInfo(null);
                  setUploading(false);
                  // toaster.push(<Message type="error">Upload failed</Message>);
                }}
              >
                <button>
                  {uploading && <Loader backdrop center />}
                  {fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : (
                    // <Avatar style={{ fontSize: 80 }} />
                    <div className="logo-layout">
                      <h2>
                        Your <span>LOGO</span>
                      </h2>
                      <p>Company Name</p>
                    </div>
                  )}
                </button>
              </Uploader>
            </div>
            <div>
              <img src="../assets/barcode.png" width="100%" height="100%" />
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}

      <div
        className={`${
          recepitType == "Job ReThermal Receipt(Invoice)ceipt" ||
          recepitType == "Thermal Receipt(Job booking)"
            ? "smallReceiptBarcode"
            : null
        }`}
      >
        {recepitType == "Job ReThermal Receipt(Invoice)ceipt" ||
        recepitType == "Thermal Receipt(Job booking)" ? (
          <div>
            <img src="../assets/barcode.png" width="100%" height="100%" />
          </div>
        ) : null}
        <h4 className="receiptsPage-body-heading">{recepitType}</h4>
      </div>
      {recepitType !== "Background Template" ? (
        <>
          <div className="receiptsPage-body">
            <Froala
              // ref={ref}
              model={model1}
              onModelChange={handleModel1Change}
              tag="textarea"
              config={{
                documentReady: false,
                fontSizeSelection: true,
                toolbarButtons: [
                  "fontSize",
                  "bold",
                  "italic",
                  "underline",
                  "textColor",
                  "formatOL",
                  "formatUL",
                  "insertTable",
                  "insertLink",
                  "insertHR",
                  "alignLeft",
                  "alignRight",
                  "alignCenter",
                  "outdent",
                  "indent",
                ],
                quickInsertTags: [""],
                // pluginsEnabled: ["quickInsert"],
              }}
            />
          </div>
          <div className="pageBody">
            <h1>BODY</h1>
          </div>

          <div className="receiptsPage-body_body">
            <Froala
              // ref={ref}
              model={model2}
              onModelChange={handleModel2Change}
              tag="textarea"
              config={{
                documentReady: false,
                fontSizeSelection: true,
                toolbarButtons: [
                  "fontSize",
                  "bold",
                  "italic",
                  "underline",
                  "textColor",
                  "formatOL",
                  "formatUL",
                  "insertTable",
                  "insertLink",
                  "insertHR",
                  "alignLeft",
                  "alignRight",
                  "alignCenter",
                  "outdent",
                  "indent",
                ],
                // pluginsEnabled: ["quickInsert"],
                quickInsertTags: [""],
              }}
            />
          </div>

          <div className="qr-code">
            <img src="../assets/qr.png" width={20} height={20} />
            <div>{/* image */}</div>
            <div className="qr-receipt">
              <h6>Enable QR-Code On Receipt</h6>
              <p>
                User can track the services and send messages by scanning the
                qr-code
              </p>
            </div>
            <div>
              <Toggle size="lg" checkedChildren="On" unCheckedChildren="Off" />
            </div>
          </div>
          <div className="pageFooter">
            <h1>
              {recepitType == "Job Receipt"
                ? "FOOTER"
                : recepitType == "Thermal Receipt(Job booking)"
                ? "SIGNATURE"
                : null}
            </h1>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CompanySettingReceipt;
