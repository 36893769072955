import React, { useState } from "react";
import "../detailModal/DetailModal.css";

import { FiChevronDown, FiChevronUp, FiEdit } from "react-icons/fi";
import DeviceSecurityModal from "../deviceSecurityModal/DeviceSecurityModal";
import DeviceSecurityEditModal from "../deviceSecurityEditModal/DeviceSecurityEditModal";

//redux
import { connect } from "react-redux";

const DeviceSecurity: React.FC<{
  job: any;
}> = ({ job }) => {
  const [openSecurity, setOpenSecurity] = useState(true);
  const [openEditSecurity, setOpenEditSecurity] = useState(false);
  const [openDeviceSecurityModal, setOpenDeviceSecurityModal] = useState(false);
  return (
    <>
      <div className="DetailModal-card">
        {openDeviceSecurityModal && <DeviceSecurityModal close={() => setOpenDeviceSecurityModal(false)} security={job.security} />}

        {openEditSecurity && <DeviceSecurityEditModal close={() => setOpenEditSecurity(false)} security={job.security} />}
        <div className="DetailModal-card-header">
          <h6>Device Details</h6>
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <FiEdit
              className="DetailModal-card-iconbtn cursor-pointer"
              onClick={() => {
                setOpenEditSecurity(!openEditSecurity);
              }}
            />
            {openSecurity ? (
              <FiChevronUp className="cursor-pointer" onClick={() => setOpenSecurity(!openSecurity)} />
            ) : (
              <FiChevronDown className="cursor-pointer" onClick={() => setOpenSecurity(!openSecurity)} />
            )}
          </div>
        </div>
        {openSecurity && (
          <div className="DetailModal-card-body">
            <p>
              {job?.device?.manufacturer} {job?.device?.model} {job?.memory} {job?.color}
            </p>
            <p>IMEI: {job.imei || "N/A"}</p>
            {job.security?.type === "none" ? (
              <div style={{ textAlign: "center", borderTop: "1px solid #0001" }}>
                <span style={{ color: "rgba(62, 178, 231, 1)" }}>No device security</span>
              </div>
            ) : (
              <div
                style={{ textAlign: "center", borderTop: "1px solid #0001" }}
                onClick={() => {
                  setOpenDeviceSecurityModal(!openDeviceSecurityModal);
                }}
              >
                <span className="DetailModal-card-Link" style={{ color: "rgba(62, 178, 231, 1)" }}>
                  Show device security
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceSecurity);
