import React, { useState, useRef, useEffect } from "react";
import "../../../pages/dashBoard/DashBoard.css";
import {
  Dropdown,
  Popover,
  DatePicker,
  Form,
  Schema,
  FormControl,
  Loader,
} from "rsuite";
import moment from "moment";
import { v4 as uuid } from "uuid";

//redux
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { CREATE_TASK_MUTATION, UPDATE_TASK_MUTATION } from "../../../gql/dashboard/mutation";
import { authState } from "../../../toolkit/auth/auth.slice";

//components
import { showAlert } from "../../../helpers";

const { StringType } = Schema.Types;
const model = Schema.Model({
  note: StringType().isRequired("This field is required."),
});

const QuickTasksInput: React.FC<{
  task: any;
  refetch: Function;
  close: Function
}> = (props) => {
  const timePickerRef = useRef<any>(null);
  const formRef = useRef<any>(null);
  const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [time, setTime] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [updateQuickTask] = useMutation(UPDATE_TASK_MUTATION);
  const [insertQuickTasks, { data, error }] = useMutation(CREATE_TASK_MUTATION);
  const {
    auth: { user },
  } = useSelector(authState);

  useEffect(() => {
    if (typeof data !== "undefined") {
      showAlert({
        title: "Success!",
        message: "Tasks added Successfully!",
        type: "success",
      });
      props.refetch();
      props.close();
    }
    if (typeof error !== "undefined") {
      showAlert({
        title: "Error!",
        message: "Something went wrong!",
        type: "danger",
      });
    }
  }, [data, error]);

  const handleSetTime = (option: any, time: any) => {
    let timeTemp: any = null;
    if (option === "tomorrow") {
      timeTemp = moment().add(1, "day");
    } else if (option === "custom") {
      timeTemp = moment(time);
    } else {
      timeTemp = moment().add(option, "hour");
    }
    setTime(timeTemp);
    setShowDropdown(false);
    setShowSavePopup(true);
  };
  const handleAbort = () => {
    setNote("");
    setTime(null);
    setShowSavePopup(false);
  };
  const handleSubmit = async () => {
    if (formRef.current.check()) {
      try {
        setLoading(true);
        if (props.task !== null) {
          updateQuickTask({
            variables: {
              object: {
                task: note,
                task_date: time,
                updated_at: new Date()
              },
              id: props.task.id,
            },
          });
        } else {
          insertQuickTasks({
            variables: {
              object: {
                id: uuid(),
                task: note,
                created_at: new Date(),
                created_by: user.id,
                task_date: time,
              },
            },
          });
        }
        handleAbort();
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (props.task !== null) {
      console.log("---bi---", props.task);
      setNote(props.task?.task);
      setTime(moment(props.task?.task_date));
      setShowSavePopup(true);
    }
  }, [props.task]);
  return (
    <div className="DashbaordPage_body_card">
      <Form model={model} onSubmit={handleSubmit} ref={formRef}>
        <FormControl
          name={"note"}
          placeholder="Write here..."
          className="QuickTasks_input"
          value={note}
          onChange={setNote}
          errorPlacement="topStart"
        />
        <div>
          <Dropdown
            trigger={["click"]}
            placement="bottomStart"
            className=""
            ref={timePickerRef}
            renderTitle={() => (
              <span
                className="DetailModal-card-Link"
                style={{ color: "rgba(62, 178, 231, 1)", fontSize: 10 }}
                onClick={() => setShowDropdown(true)}
              >
                {time === null
                  ? "Select Date & Time"
                  : time?.format("DD/MM/YYYY h:mm A")}
              </span>
            )}
            open={showDropdown}
          >
            <div style={{ padding: 15, width: 200 }}>
              <div className="dropDownThemeTitle">Remind me about this</div>
              <div className="drop_time_picker">
                <p onClick={() => handleSetTime(1, null)}>1 hour</p>
                <p onClick={() => handleSetTime(2, null)}>2 hour</p>
                <p onClick={() => handleSetTime(3, null)}>3 hour</p>
                {/* <p className="active">3 hour</p> */}
                <p onClick={() => handleSetTime("tomorrow", null)}>Tomorrow</p>
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  placement={"auto"}
                  placeholder={"Set a time and date"}
                  appearance="subtle"
                  className="quick_task_datetime_picker"
                  onChange={(value) => handleSetTime("custom", value)}
                />
              </div>
            </div>
          </Dropdown>
        </div>
        {showSavePopup && (
          <div style={{ position: "relative" }}>
            <Popover title="" visible>
              <div style={{ width: 260 }}>
                <div className="d-flex">
                  <button
                    className="bt-secondary"
                    onClick={handleAbort}
                    disabled={loading}
                  >
                    Abort
                  </button>
                  <button
                    className="bt-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Loader size="xs" />
                    ) : props.task !== null ? (
                      "Update"
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Popover>
          </div>
        )}
      </Form>
    </div>
  );
};

export default QuickTasksInput;
