import React, { useState, useEffect } from "react";
import "./InputBox.css";

const InputBox: React.FC<{
  labelText: any;
  valueProp?: any;
  disabledInput?: any;
  handleChange: (e: any) => void;
  name?: string;
  required?: boolean;
  type?: string;
  isError?: boolean;
  borderBottom?: boolean;
}> = ({
  labelText,
  valueProp,
  disabledInput,
  handleChange,
  name,
  required,
  type,
  isError = false,
  borderBottom,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    }
  }, [valueProp]);

  return (
    <div
      className={`InputBox ${value !== "" && "active"} ${
        disabledInput ? "disabled" : ""
      } ${isError ? "error-input" : ""}`}
      style={{
        border: borderBottom === true ? 0 : "1px solid rgba(48, 74, 80, 0.39)",
        borderBottom: "1px solid rgba(48, 74, 80, 0.39)",
        borderRadius: borderBottom === true ? 0 : 7,
        height: borderBottom === true ? 40 : 45,
      }}
    >
      <input
        type={type || "text"}
        className="InputBox_input"
        readOnly={disabledInput}
        value={valueProp ? valueProp : value}
        onChange={(evt) => {
          handleChange(evt.target.value);
          setValue(evt.target.value);
        }}
        name={name && name}
        required={required}
        style={{padding: borderBottom === true ? "0px 10px" : "",}}
      />
      <label className="InputBox_label">{labelText}</label>
    </div>
  );
};

export default InputBox;
