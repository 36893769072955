import React, { useState } from "react";
import "./SelectBoxSearch.scss";
import { FiPlus, FiChevronDown } from "react-icons/fi";

import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS_BY_SEARCHED } from "../../../gql/customers/queries";
import { authState } from "../../../toolkit/auth/auth.slice";
import { useSelector } from "react-redux";

// icons

const SelectBoxSearch: React.FC<{
  labelText: any;
  isError?: boolean;
  setSelectedCustomerDetails: Function;
  setStep: Function;
}> = ({
  labelText,
  isError = false,
  setSelectedCustomerDetails,
  setStep,
}) => {
  const [open, setOpen] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const {
    auth: { user },
  } = useSelector(authState);

  const { data } = useQuery(GET_CUSTOMERS_BY_SEARCHED, {
    variables: { searchedText: `%${searchedText}%`, userId: user?.id },
  });

  return (
    <div
      className={`SelectBoxSearch ${open !== "" ? "active" : ""} ${
        isError ? "error-input" : ""
      }`}
      style={{ marginTop: 4 }}
    >
      <div className="SelectBoxSearch_inner" onClick={() => {}}>
        <div className="SelectBoxSearch_inner_Left">
          <input
            type="text"
            placeholder={labelText}
            onChange={(e) => {
              if(e.target.value.length > 2) {
                setOpen(e.target.value);
                setSearchedText(e.target.value);
              }
            }}
          />
        </div>
        <div className="SelectBoxSearch_inner_right">
          <FiChevronDown />
        </div>
      </div>
      {open !== "" && typeof data !== "undefined" && (
        <div className="SelectBoxSearch_drop">
          {data.customers.map((el: any) => (
            <div
              key={el?.id}
              className="SelectBoxSearch_drop_item"
              onClick={() => {setSelectedCustomerDetails(el); setStep(4);}}
            >
              {el?.first_name} {el?.last_name}
            </div>
          ))}
          <div className="SelectBoxSearch_drop_item" onClick={()=> setStep(3)}>
            <FiPlus /> Add Customer
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectBoxSearch;
