import { combineReducers } from "redux";
import authReducer from "./auth/auth.slice";
import customerReducer from "./customers/customers.slice";
import companySettingsReducer from "./companySettings/companySettings.slice";
import dashboardReducer from "./dashboard/dashboard.slice";
import profileReducer from "./profile/profile.slice";
import itemReducer from "./items/items.slice";
import serviceReducer from "./services/services.slice";
import invoiceReducer from "./invoice/invoice.slice";
import jobReducer from "./jobs/jobs.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  customers: customerReducer,
  companySettings: companySettingsReducer,
  dashboard: dashboardReducer,
  profile: profileReducer,
  items: itemReducer,
  services: serviceReducer,
  invoices: invoiceReducer,
  jobs: jobReducer
});
export default rootReducer;
