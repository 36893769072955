import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ChatBoxLeftChat: React.FC<{}> = ({}) => {
  return (
    <>
      <div className="DetailModal-main-middle-view-chat-left">
        <h6>Channels</h6>
        <div className="DetailModal-main-middle-view-chat-left-menu">
          <div className="d-flex-start">
            <img src="/assets/bg/1.1.png" alt="" />
            <h5>All </h5>
          </div>
          <h2>15</h2>
        </div>
        <div className="DetailModal-main-middle-view-chat-left-menu active">
          <div className="d-flex-start">
            <img src="/assets/bg/2.png" alt="" />
            <h5>Email/Chat </h5>
          </div>
          <h2>15</h2>
        </div>
        <div className="DetailModal-main-middle-view-chat-left-menu">
          <div className="d-flex-start">
            <img src="/assets/bg/3.3.png" alt="" />
            <h5>Mobile-SMS </h5>
          </div>
          <h2>15</h2>
        </div>
        <div className="DetailModal-main-middle-view-chat-left-menu">
          <div className="d-flex-start">
            <img src="/assets/bg/4.4.png" alt="" />
            <h5>WhatsApp </h5>
          </div>
          <h2>15</h2>
        </div>
        <div className="DetailModal-main-middle-view-chat-left-menu">
          <div className="d-flex-start">
            <img src="/assets/bg/callMenu.png" alt="" />
            <h5>Call </h5>
          </div>
          <h2>15</h2>
        </div>
      </div>
     
    </>
  );
};

export default ChatBoxLeftChat;
