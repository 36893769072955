import { FiBriefcase } from "react-icons/fi";
import { Dropdown, Toggle } from "rsuite";
import "../../../pages/dashBoard/DashBoard.css";

const PageHeader = () => {
  return (
    <div className="PageHeader">
      <div className="PageHeader-left">
        <FiBriefcase />
        <h2>Job Overview</h2>
      </div>

      <div className="PageHeader-right"></div>
    </div>
  );
};

export default PageHeader;
