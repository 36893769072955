import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icon
import { AiOutlineCloudUpload } from "react-icons/ai";

// component
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";
// import SelectBox from "../../template/selectBox/SelectBox";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  companySettingsState,
  setCompanySettings
} from "../../../toolkit/companySettings/companySettings.slice";

const CompnaySettingsLogoStep1: React.FC<{ 
  twickEdit?: any;
  onFileChange: Function; 
}> = ({
  twickEdit,
  onFileChange
}) => {
  const dispatch = useDispatch();
  const { companySettings } = useSelector(companySettingsState).companySettings;
  const handleChange = (value: string, key: string) => {
    dispatch(setCompanySettings({
      ...companySettings,
      [key]: value
    }))
  }
  const [preview, setPreview] = useState<string | undefined>();
  const handleChangeFile = (file: File) => {
    onFileChange(file)
    setPreview(URL.createObjectURL(file))
  }
  return (
    <>
      <div className="CompnaySettingsGeneralDetails_details">
        <div className="flex-row">
          <div className="flex-row_col">
            <label htmlFor="company_logo">
              <div className="upload_img">
                {preview ? (
                  <img src={preview} />
                ) : (
                  <AiOutlineCloudUpload />
                )}
              </div>
            </label>
            <input
              type="file"
              id="company_logo"
              style={{ display: "none" }}
              onChange={(e: any) => handleChangeFile(e.target.files[0])}
            />
          </div>
          <div className="flex-row_col">
            <img src={companySettings.company_logo ? companySettings.company_logo : "/assets/bg/ilogo.png"} />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <TextAreaBox
              handleChange={(value) => handleChange(value, "opening_hours")}
              labelText="Opening Hours"
              valueProp={companySettings.opening_hours}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnaySettingsLogoStep1;
