import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import "../../../pages/dashBoard/DashBoard.css";
import { Loader, Placeholder } from "rsuite";
//
import { AiFillPlusCircle } from "react-icons/ai";
import ChatBoxLeftChat from "./ChatBoxLeftChat";

//redux
import { connect } from "react-redux";
import { fetchConversations } from "../../../stores/conversations/actions";

const ChatNotification: React.FC<{
  fetchConversations: Function;
  chats: any;
  loading: boolean;
}> = (props) => {
  const { Paragraph } = Placeholder;
  const [chatsData, setChatsData] = useState<any>([]);
  const refetch = () => {
    // props.fetchConversations({ sender: "service", limit: 100000 })
  };
  useEffect(() => {
    // props.fetchConversations({ sender: "service", limit: 100000 })
  }, []);
  useEffect(() => {
    if (Array.isArray(props.chats.docs)) {
      let data = [...props.chats.docs];
      data.reverse();
      let latestChatsWithMessages: any[] = [];
      data.map((chat) => {
        if (chat.messages.length > 0) {
          latestChatsWithMessages.push(chat);
        }
      });
      setChatsData(latestChatsWithMessages);
    }
  }, [props.chats]);
  return (
    <div className="DashbaordPage_body_card Dashboard_chatNotification">
      <div className="DashbaordPage_Header_wrapper">
        <p className="DashbaordPage_Header">PENDING CONVERSATIONS</p>
        <div className="right pointer" onClick={refetch}>
          <FiRefreshCw />
        </div>
      </div>
      <div className="divider-horizontal"></div>
      {/*  */}
      {/*  */}
      <div className="ChatNotification_input_body">
        <>
          <div className="before_data two">
            <img src="/assets/theme/12.png" alt="" />
            <h4>YOU HAVE NO MESSAGES</h4>
            <h6>
              <AiFillPlusCircle /> &nbsp; Book your first job & touch your customer
            </h6>
          </div>
        </>
        <>
          {props.loading ? (
            <Paragraph rows={5} rowMargin={20} rowHeight={20} active />
          ) : (
            chatsData.map((chat: any, i: number) => {
              const lastMessage = chat.messages.slice(-1)[0];
              if (lastMessage.sender !== "service") {
                return (
                  <ChatBoxLeftChat
                    key={i}
                    fullWidth={true}
                    item={lastMessage}
                  />
                );
              }
            })
          )}
        </>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  chats: [],
  loading: false,
});
const mapDispatchToProps = {
  fetchConversations,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatNotification);
