import React, { useEffect, useState } from "react";
import "./tabs.scss";
import moment from "moment";

//components
import Paging from "../../../../components/common/paging/Paging";

//graphql
import { useQuery } from "@apollo/client";
import { GET_INVOICES_QUERY } from "../../../../gql/invoice/queries";

//toolkit
import { useSelector, useDispatch } from "react-redux";
import {
  setInvoices,
  invoicesState,
} from "../../../../toolkit/invoice/invoice.slice";
import Loader from "../../../loader/Loader";

const defaultColumns = [
  {
    key: "date",
    label: "Date",
  },
  {
    key: "invoiceNo",
    label: "Invoice no.",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "jobNo",
    label: "Job no.",
  },
  {
    key: "amount",
    label: "Amount",
    subLabel: "(Excluding Taxs)",
  },
  {
    key: "amountIncludingTaxs",
    label: "Amount",
    subLabel: "(Including Taxs)",
  },
];

const InvoiceTab:React.FC<{
  customerId: string | undefined
}> = ({
  customerId
}) => {
  const dispatch = useDispatch();
  const { invoices } = useSelector(invoicesState);
  const limit = 10;

  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const { data, loading, refetch } = useQuery(GET_INVOICES_QUERY, {
    variables: { limit: limit, offset: offset, where: {customer_id: {_eq: customerId }} },
  });

  const handlePage = (page: number) => {
    setActivePage(page);
    setOffset(limit * page - limit);
  };

  useEffect(() => {
    dispatch(setInvoices(data));
    if (typeof data?.invoices_aggregate?.aggregate?.count !== "undefined") {
      setTotalPages(
        Math.ceil(data?.invoices_aggregate?.aggregate?.count / limit)
      );
    }
  }, [data]);

  return (
    <>
      <div className="table-header">
        {defaultColumns.map((el: any) => (
          <div className="header-title" key={el.key}>
            <div>{el.label}</div>
            {el?.subLabel && (
              <div className="header-sub-title">{el?.subLabel}</div>
            )}
          </div>
        ))}
      </div>
      {Array.isArray(invoices) ? (
        <>
          {invoices.map((el: any, i: number) => (
            <div className="table-body" key={i}>
              <div className="table-body-data">
                {moment(el?.created_at).format('L')}
              </div>
              <div className="table-body-data" style={{ color: "#2589F6" }}>
                {el?.invoice_id}
              </div>
              <div className="table-body-data" style={{ color: `${el?.status === "paid" ? "#4AB754" : "#FF0000"}` }}>
                {el?.status}
              </div>
              <div className="table-body-data" style={{ color: "#2589F6" }}>
                {el?.jobNo}
              </div>
              <div className="table-body-data">
                € {el?.total_amount}
              </div>
              <div className="table-body-data">
                € {el?.total_amount}
              </div>
            </div>
          ))}
          <Paging
            activePage={activePage}
            pages={totalPages}
            onPageClick={handlePage}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvoiceTab;
