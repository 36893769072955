import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icons

// component
import CompnaySettingsTAXStep1 from "./CompnaySettingsTAX.step1";

const CompnaySettingsTAX = () => {
  const [edit, setEdit] = useState(false);

  return (
    <div className="CompnaySettingsGeneralDetails">
      <div className="flex-1 d-flex-start">
        <h2>TAX Details</h2>
      </div>

      <CompnaySettingsTAXStep1 />
    </div>
  );
};

export default CompnaySettingsTAX;
