import { gql } from "@apollo/client";

export const CREATE_PROFILE_SETTINGS_MUTATION = gql`
  mutation InsertCompanyDetailsOne($object: profile_settings_insert_input!) {
    insert_profile_settings_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_PROFILE_SETTINGS_MUTATION = gql`
  mutation UpdateCompanyDetails($object: profile_settings_set_input!, $id: uuid!) {
    update_profile_settings(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        id
      }
    }
  }
`;
