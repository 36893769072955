import React, { useState, useEffect } from "react";
import "../../../pages/services/Services.scss";
import { Modal, Toggle } from "rsuite";
import { v4 as uuid } from "uuid";
import _ from "lodash";

// icons
import { FiX } from "react-icons/fi";

// components
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import InputBoxIcon from "../../template/inputBoxIcon/InputBoxIcon";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";
import AssignedItems from "./tabs/AssignedItems";
import ServiceTags from "./tabs/ServiceTags";
import ServiceImages from "./tabs/ServiceImages";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

//types
import ServiceType, {
  ServiceImageType,
  ServiceItem,
  CategoryRel,
  ManufacturerRel,
  ModelRel,
} from "./Services.type";
import StockItemType from "../../stockItems/stockItemsModal/StockItems.type";

//import validators
import validateCreateService from "../../../validators/services/create";

//redux
import { uploadImageApi } from "../../../toolkit/companySettings/companySettings.api";
import { authState } from "../../../toolkit/auth/auth.slice";
import { useSelector } from "react-redux";
import { serviceState } from "../../../toolkit/services/services.slice";

//graphql
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import {
  CREATE_SERVICE_MUTATION,
  UPDATE_SERVICE_MUTATION,
  CREATE_SERVICE_ITEM_MUTATION,
  UPDATE_SERVICE_ITEM_MUTATION,
} from "../../../gql/services/mutation";
import { GET_ALL_ITEMS_BY_SERVICE } from "../../../gql/services/queries";

//helpers
import { showAlert, formatPriceWithVat } from "../../../helpers";

const ServicesModal: React.FC<{
  Close: any;
  refetch: Function;
  isEdited?: boolean;
  editedId?: string;
}> = ({ Close, refetch, isEdited, editedId }) => {
  const client = useApolloClient();
  const { token } = useSelector(authState).auth;
  const { services } = useSelector(serviceState);
  const [tab, setTab] = useState(1);

  const [selectedDeviceCate, setSelectedDeviceCate] = useState<CategoryRel>({
    id: "",
    name: "",
  });

  const [selectedManufactureCate, setSelectedManufactureCate] =
    useState<ManufacturerRel>({
      id: "",
      name: "",
    });
  const [selectedModel, setSelectedModel] = useState<ModelRel>({
    id: "",
    name: "",
  });

  const [manufactureCategories, setManufactureCategories] = useState<any>([
    {
      id: "80cd8f92-94e7-49e7-9260-e3fcb0492b64",
      name: "iPhone",
    },
  ]);
  const [modelCategories, setModelCategories] = useState<any>([
    {
      id: "80cd8f92-94e7-49e7-9260-e3fcb0492b64",
      name: "iPhone 11",
    },
  ]);

  const [deviceCategories, setDeviceCategories] = useState<any>([
    {
      id: "80cd8f92-94e7-49e7-9260-e3fcb0492b64",
      name: "Smart Phone",
    },
  ]);
  const [state, setState] = useState<ServiceType>({
    id: uuid(),
    name: "",
    service_id: "",
    category: "",
    vat: "0",
    description: "",
    model: "",
    price_excl_vat: "0",
    price_incl_vat: "0",
    show_on_homepage: false,
    items: [],
    images: [],
    manufacturer: "",
  });

  const [assignedItems, setAssignedItems] = useState<StockItemType[]>([]);

  const handelSelectDeviceCate = () => {
    setState({
      ...state,
      category: selectedDeviceCate.id,
    });
  };

  const handelSelecteManufactureCate = () => {
    setState({
      ...state,
      manufacturer: selectedManufactureCate.id,
    });
  };
  const handelSelecteModelCate = () => {
    setState({
      ...state,
      model: selectedModel.id,
    });
  };

  console.log("hello", state);

  const [files, setFiles] = useState<File[]>([]);
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [createServiceMutation, { error, data }] = useMutation(
    CREATE_SERVICE_MUTATION
  );
  const [updateServiceMutation] = useMutation(UPDATE_SERVICE_MUTATION);
  const [createServiceItemMutation] = useMutation(CREATE_SERVICE_ITEM_MUTATION);
  const [updateServiceItemMutation] = useMutation(UPDATE_SERVICE_ITEM_MUTATION);

  const { data: serviceItems, refetch: refetchServiceItems } = useQuery(
    GET_ALL_ITEMS_BY_SERVICE,
    {
      variables: {
        service_id: editedId,
      },
    }
  );

  const resetState = () => {
    setState({
      id: uuid(),
      name: "",
      service_id: "",
      category: "",
      vat: "",
      description: "",
      model: "",
      price_excl_vat: "",
      price_incl_vat: "",
      show_on_homepage: false,
      items: [],
      images: [],
      manufacturer: "",
    });
    setTags([]);
  };

  const formatPayload = async () => {
    let payload: any = { ...state };
    if (payload.manufacturer?.length === 0) {
      payload.manufacturer = null;
    }
    if (payload.category?.length === 0) {
      payload.category = null;
    }
    if (payload.model?.length === 0) {
      payload.model = null;
    }
    payload.vat = parseInt(payload.vat);
    payload.price_excl_vat = parseInt(payload.price_excl_vat);
    payload.price_incl_vat = parseInt(payload.price_incl_vat);
    payload.service_id = undefined;
    payload.tags = tags;
    if (files.length) {
      let formdata = new FormData();
      files.map((file: File) => {
        formdata.append("uploads", file);
      });
      const resp: any = await uploadImageApi(formdata, token);
      if (
        Array.isArray(resp.data?.uploaded_files) &&
        resp.data?.uploaded_files?.length
      ) {
        if (isEdited) {
          let images: any[] = [
            ...state.images.filter((image: ServiceImageType) =>
              image.link.includes(process.env.REACT_APP_API_URL as string)
            ),
          ];
          resp.data.uploaded_files.map((item: any, i: number) => {
            images.push({
              id: uuid(),
              link: item.uploaded_file_url,
              favourite: false,
              name: `Image ${images.length + 1}`,
            });
          });
          payload.images = images;
        } else {
          let images: any[] = [...payload.images];
          resp.data.uploaded_files.map((item: any, i: number) => {
            // images[i].link = item.uploaded_file_url
            images[i] = { ...images[i], link: item.uploaded_file_url };
          });
          payload.images = images;
        }
      }
    }
    return payload;
  };
  const createNewItems = async (items: StockItemType[], service_id: string) => {
    let objects: ServiceItem[] = [];
    items.map((item: StockItemType) => {
      objects.push({
        service_id,
        item_id: item.id,
        active: true,
      });
    });
    await createServiceItemMutation({
      variables: {
        id: editedId,
        objects,
      },
    });
  };
  const handleEditService = async (payload: any, items: StockItemType[]) => {
    await updateServiceMutation({
      variables: {
        id: editedId,
        object: payload,
      },
    });
    let _assignedItems: StockItemType[] = [];
    assignedItems.map((item: any) => {
      _assignedItems.push({ ...item.item });
    });
    //deactive existing items for this service
    let deletedItems = _.differenceBy(_assignedItems, items, "id");
    let _deletedItemIds = deletedItems.map((item: StockItemType) => item.id);
    let deletedMapIds: string[] = [];
    assignedItems.map((item: any) => {
      if (_deletedItemIds.includes(item.item.id)) {
        deletedMapIds.push(item.id);
      }
    });
    if (deletedItems.length) {
      await updateServiceItemMutation({
        variables: {
          object: { active: false },
          where: {
            id: { _in: deletedMapIds },
          },
        },
      });
    }
    //insert new items for this service
    let newItems = _.differenceBy(items, _assignedItems, "id");
    await createNewItems(newItems, editedId as string);
  };
  const handleCreateService = async (payload: any, items: StockItemType[]) => {
    const resp = await createServiceMutation({
      variables: {
        object: payload,
      },
    });
    if (resp?.data?.insert_services_one?.id) {
      await createNewItems(items, resp?.data?.insert_services_one?.id);
    }
  };
  const handleSaveService = async () => {
    const { errors, isValid } = validateCreateService(state);
    if (isValid) {
      try {
        setBtnLoading(true);
        let payload = await formatPayload();
        payload.id = undefined;
        let items = [...payload.items];
        payload.items = undefined;
        if (isEdited) {
          await handleEditService(payload, items);
        } else {
          await handleCreateService(payload, items);
        }
        refetch();
        showAlert({
          title: "Success!",
          message: `Service ${isEdited ? "updated" : "created"} successfully!`,
          type: "success",
        });
        Close();
      } catch (err) {
        showAlert({
          title: "Failed!",
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        setBtnLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

  const handleClose = () => {
    resetState();
    Close();
  };
  const handleSetItem = (item: StockItemType) => {
    let _items: StockItemType[] = [...state.items];
    _items.push(item);
    setState({
      ...state,
      items: _items,
    });
  };
  const handleRemoveItem = (id: string) => {
    let _items: StockItemType[] = [...state.items];
    _items = _items.filter((item: StockItemType) => item.id !== id);
    setState({
      ...state,
      items: _items,
    });
  };
  const handleSetServiceDetails = async (selectedItem: ServiceType) => {
    try {
      let _items: StockItemType[] = [];
      await refetchServiceItems();
      if (Array.isArray(serviceItems?.services_items_map)) {
        serviceItems.services_items_map.map((item: any) => {
          _items.push({ ...item.item });
        });
        if (_items.length) {
          setAssignedItems(serviceItems?.services_items_map);
        }
      }
      setState({
        ...state,
        id: selectedItem.id,
        name: selectedItem.name,
        service_id: selectedItem.service_id,
        category: selectedItem.category,
        vat: String(selectedItem.vat),
        description: selectedItem.description,
        model: selectedItem.model,
        price_excl_vat: String(selectedItem.price_excl_vat),
        price_incl_vat: String(selectedItem.price_incl_vat),
        show_on_homepage: selectedItem.show_on_homepage,
        items: [..._items],
        images: selectedItem.images,
        manufacturer: selectedItem.manufacturer,
      });
      setTags(selectedItem.tags || []);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (editedId && services?.length) {
      const selectedItem = services.find((el: any) => el.id === editedId);
      if (Object.keys(selectedItem).length > 0) {
        handleSetServiceDetails(selectedItem);
      }
    } else {
      resetState();
    }
  }, [editedId, services, serviceItems]);
  return (
    <>
      <div className="desk-only">
        <div className="ServicesModal">
          <div className="ServicesModal_inner">
            <div className="ServicesModal_inner_top">
              <div className="ServicesModal_inner_flex_start">
                <div className="col">
                  <h5 className="">Add Service</h5>
                </div>
                <div className="close" onClick={Close}>
                  <FiX />
                </div>
              </div>
            </div>
            <div
              className="ServicesModal_inner_flex_start"
              style={{ marginBottom: 15 }}
            >
              <div className="col">
                <div className="ServicesModal_inner_flex_start">
                  <div className="col">
                    <InputBox
                      labelText="Service Name"
                      handleChange={(value: string) =>
                        setState({ ...state, name: value })
                      }
                      valueProp={state.name}
                      isError={errors.name?.length > 0}
                    />
                  </div>
                  <div className="" style={{ width: 60 }}>
                    <InputBox
                      labelText="ID"
                      valueProp={isEdited ? state.service_id : "Autogenerate"}
                      handleChange={() => null}
                      disabledInput={true}
                    />
                  </div>
                </div>
                <div
                  className="col"
                  style={{ marginTop: 15, marginRight: -10 }}
                >
                  <TextAreaBox
                    labelText="Service Description"
                    heightValue={166}
                    handleChange={(value: string) =>
                      setState({ ...state, description: value })
                    }
                    valueProp={state.description}
                  />
                </div>
              </div>
              <div className="col">
                <div className="ServicesModal_inner_flex_start">
                  <div className="col" style={{ marginTop: -4 }}>
                    <div className="" style={{ marginBottom: 15 }}>
                      <SearchDropDown
                        labelText="Device Category"
                        data={deviceCategories}
                        selectedOption={setSelectedDeviceCate}
                        onMouseLeave={handelSelectDeviceCate}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <InputBoxIcon
                      labelText="VAT. %"
                      icon="%"
                      handleChange={(value: string) => {
                        let incl_price = formatPriceWithVat(
                          state.price_excl_vat,
                          value,
                          "incl"
                        );
                        setState({
                          ...state,
                          vat: value,
                          price_incl_vat: incl_price,
                        });
                      }}
                      valueProp={state.vat}
                    />
                  </div>
                </div>
                <div className="ServicesModal_inner_flex_start">
                  <div className="col" style={{ marginTop: -4 }}>
                    <div className="" style={{ marginBottom: 15 }}>
                      <SearchDropDown
                        labelText="Manufacture Category"
                        data={manufactureCategories}
                        selectedOption={setSelectedManufactureCate}
                        onMouseLeave={handelSelecteManufactureCate}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <InputBoxIcon
                      labelText="Price (Excl. VAT)"
                      icon="€"
                      handleChange={(value: string) => {
                        let incl_price = formatPriceWithVat(
                          value,
                          state.vat,
                          "incl"
                        );
                        setState({
                          ...state,
                          price_excl_vat: value,
                          price_incl_vat: incl_price,
                        });
                      }}
                      valueProp={state.price_excl_vat}
                      isError={errors.price_excl_vat?.length > 0}
                    />
                  </div>
                </div>
                <div className="ServicesModal_inner_flex_start">
                  <div className="col" style={{ marginTop: -4 }}>
                    <div className="" style={{ marginBottom: 15 }}>
                      <SearchDropDown
                        labelText="iPhone 11"
                        data={modelCategories}
                        selectedOption={setSelectedModel}
                        onMouseLeave={handelSelecteModelCate}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <InputBoxIcon
                      labelText="Price (Incl. VAT)"
                      icon="€"
                      handleChange={(value: string) => {
                        let excl_price = formatPriceWithVat(
                          value,
                          state.vat,
                          "excl"
                        );
                        setState({
                          ...state,
                          price_incl_vat: value,
                          price_excl_vat: excl_price,
                        });
                      }}
                      valueProp={state.price_incl_vat}
                      isError={errors.price_incl_vat?.length > 0}
                    />
                  </div>
                </div>
                <div className="ServicesModal_inner_flex_start">
                  <div className="col">
                    <div className="ServicesModal_inner_blueShow">
                      <img src="assets/bg/wrold.png" alt="" />
                      <div className="f1">
                        <h6>Show This Service On Your Home Page</h6>
                        <p>Customer Ticketbooking via WebWidget</p>
                      </div>
                      <Toggle
                        size="md"
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={state.show_on_homepage}
                        onChange={(checked: boolean) =>
                          setState({ ...state, show_on_homepage: checked })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="ServicesModal_inner_flex_start">
              <div className="col">
                <div className="ServicesModal_tabs">
                  <div
                    className={`ServicesModal_tab ${tab === 1 ? "active" : ""}`}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Assigned Items
                  </div>
                  <div
                    className={`ServicesModal_tab ${tab === 2 ? "active" : ""}`}
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    Images
                  </div>
                  <div
                    className={`ServicesModal_tab ${tab === 3 ? "active" : ""}`}
                    onClick={() => {
                      setTab(3);
                    }}
                  >
                    Tags
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="ServicesModal_tab_body">
              {tab === 1 && (
                <AssignedItems
                  assignedItems={state.items}
                  onChange={handleSetItem}
                  onRemove={handleRemoveItem}
                />
              )}
              {tab === 2 && (
                <ServiceImages
                  images={state.images}
                  files={files}
                  onFileChange={setFiles}
                  onChange={(images: ServiceImageType[]) =>
                    setState({ ...state, images })
                  }
                />
              )}
              {tab === 3 && <ServiceTags tags={tags} setTags={setTags} />}
            </div>
            {/*  */}
            <div
              className="d-flex ServicesModal_btns"
              style={{ justifyContent: "flex-end", marginTop: 15 }}
            >
              <div className="bt-secondary" style={{ flex: 0 }} onClick={Close}>
                Abort
              </div>
              <button
                className={btnLoading ? "bt-secondary" : "bt-primary"}
                style={{ flex: 0 }}
                onClick={handleSaveService}
                disabled={btnLoading}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesModal;
