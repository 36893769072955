import React from "react";
import { FiUploadCloud } from "react-icons/fi";
import ServicesModalImages from "../ServicesModalImages";
import { v4 as uuid } from "uuid";
//types
import { ServiceImageType } from "../Services.type";
const ServiceImages: React.FC<{
	images: ServiceImageType[];
	files: File[];
	onFileChange: Function;
	onChange: Function;
}> = ({ images, files, onFileChange, onChange }) => {
	const handleChangeFile = (event: any) => {
		const _file: File = event.target.files[0];
		onFileChange([...files, _file]);

		let _images: ServiceImageType[] = [...images];
		_images.push({
			id: uuid(),
			link: URL.createObjectURL(_file),
			favourite: false,
			name: `Image ${_images.length + 1}`,
		})
		onChange(_images)
	}
	const handleRemoveFile = (id: string, index: number) => {
		let _images: ServiceImageType[] = [...images];
		_images = _images.filter((image: ServiceImageType) => image.id !== id)
		onChange(_images)

		let _files: File[] = [...files];
		if (_files.length && _files[index]) {
			_files.splice(index, 1);
			onFileChange(_files);
		}
	}
	const handleFavouriteToggle = (id: string) => {
		let _images = JSON.parse(JSON.stringify(images));
		let index = images.findIndex((image: ServiceImageType) => image.id === id)
		_images.map((image: ServiceImageType) => {
			image.favourite = false
		})
		_images[index].favourite = !_images[index].favourite
		onChange(_images)
	}
	return (
		<div>
			<div className="ServicesModal_inner_flex_start">
				<div className="col">
					<h5 className="ServicesModal_tab_body_header">
						Upload Images
					</h5>
				</div>
			</div>
			<div
				className="ServicesModal_inner_flex_start"
				style={{ flexWrap: "wrap" }}
			>
				<label htmlFor="service_image_input">
					<div className="ServicesModal_inner_upload">
						<FiUploadCloud />
					</div>
				</label>
				<input
					type="file"
					style={{ display: "none" }}
					id="service_image_input"
					onChange={handleChangeFile}
					accept="image/jpeg, image/png, image/gif, image/webp, image/jpg"
				/>
				{images.map((image: ServiceImageType, i: number) => (
					<ServicesModalImages
						key={i}
						image={image}
						onRemove={() => handleRemoveFile(image.id, i)}
						onFavouriteToggle={handleFavouriteToggle}
					/>
				))}
			</div>
		</div>
	)
}
export default ServiceImages;