import { gql } from "@apollo/client";

export const CREATE_STOCK_ITEM_MUTATION = gql`
  mutation InsertStockItemOne($object: items_insert_input!) {
    insert_items_one(object: $object) {
     id
    }
  }
`;

export const UPDATE_STOCK_ITEM_MUTATION = gql`
  mutation UpdateStockItem($object: items_set_input!, $id: uuid!) {
    update_items(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        name
      }
    }
  }
`;
