import moment from "moment";
import { Store } from 'react-notifications-component';

//constants
import {languages, time_formats} from "../constants/";

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export const getCalendarFormat = (date) => {
  return moment(date).calendar({
    sameDay: '[Today at] h:mm A',
    nextDay: '[Tomorrow at] h:mm A',
    nextWeek: 'dddd [at] h:mm A',
    lastDay: '[Yesterday at], h:mm A',
    lastWeek: '[Last] dddd [at] h:mm A',
    sameElse: 'DD/MM/YYYY [at] h:mm A'
  })
}
export const showAlert = (params) => {
  Store.addNotification({
    title: params.title,
    message: params.message,
    type: params.type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      onScreen: true
    }
  });
}
export const  parseStatus = (status) =>{
  let parsedStatus = '';
  switch (status) {
      case 'preorder':
          parsedStatus = 'Angenommen';
          break;
      case 'in_repair':
          parsedStatus = 'In Reparatur';
          break;
      case 'kva_send':
          parsedStatus = 'KVA gesendet';
          break;
      case 'kva_confirmed':
          parsedStatus = 'KVA Bestätigt';
          break;
      case 'kva_declined':
          parsedStatus = 'KVA Abgelehnt';
          break;
      case 'on_hold':
          parsedStatus = 'Warten auf Erstzteile';
          break;
      case 'archive':
          parsedStatus = 'Archiv';
          break;
      case 'deleted':
          parsedStatus = 'Gelöscht';
          break;
      case 'finished':
          parsedStatus = 'Abgeschl.';
          break;
      case 'returned':
          parsedStatus = 'Abgeholt';
          break;
      default:
          parsedStatus = status;
          break;
  }

  return parsedStatus;
}
export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};
export const getJobStatusColor = (status) => {
  let styleColor;
  if(status === "finished"){
    styleColor = "rgb(54, 224, 82)";
  }else if(status === 'returned'){
    styleColor = "rgb(0, 132, 69)";
  }else if(status === 'kva_confirmed'){
    styleColor = "rgb(74, 183, 84)";
  }else if(status === 'on_hold'){
    styleColor = "rgb(184, 67, 67)";
  }else if(status === 'kva_send' || status === 'in_repair'){
    styleColor = "rgb(254, 198, 54)";
  }else if(status === 'preorder'){
      styleColor = "rgb(37, 137, 246)";
  }else if(status === 'cancelled' || status === 'kva_declined' || status === 'deleted'){
      styleColor = "rgb(255, 95, 95)";
  }
  return styleColor;
};
export const isAlphabet = (search) => {
  var reA = /^[A-Za-z]+$/;
  if (reA.test(search)) return true;
  else return false;
};
export const parseSearchKey = (search) => {
  var indexOfNumber = 0;
  for (var index = 0; index < search.length; index++) {
    if (!isNaN(search.charAt(index))) {
      indexOfNumber = index;
      break;
    }
  }
  return {
    prefix: search.substring(0, indexOfNumber),
    nr: search.length > indexOfNumber ? search.substring(indexOfNumber, search.length) : undefined,
  };
};
export const isRepairCenter = (company) => {
  if(Array.isArray(company?.extensions)){
    if(company.extensions.indexOf('b2b') >= 0){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
}
export const isOwnRepair = (job, profile) => {
  if(job?.user?._id === profile?._id){
    return true;
  }else{
    return false;
  }
};
export const isValidUrl = (_string) =>{
  let url_string
  try {
      url_string = new URL(_string)
  } catch (_) {
      return false
  }
  return url_string.protocol === "http:" || url_string.protocol === "https:"
}
export const isValidImage = (image) =>{
  return(image.match(/\.(jpeg|jpg|gif|png|webp)$/) != null);
}
export const getExtension = ( url ) =>{
  return url.split(/[#?]/)[0].split('.').pop().trim();
}
export const capitalize =(string)=>{
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const getJobCompletionPercentage = (status) => {
  let percentage = 0;
  if(status === "finished"){
    percentage = 80;
  }else if(status === 'returned'){
    percentage = 100;
  }else if(status === 'kva_confirmed'){
    percentage = 60;
  }else if(status === 'on_hold'){
    percentage = 40;
  }else if(status === 'kva_send' || status === 'in_repair'){
    percentage = 40;
  }else if(status === 'preorder'){
      percentage = 20;
  }else if(status === 'cancelled' || status === 'kva_declined' || status === 'deleted'){
      percentage = 0;
  }
  return percentage;
};
export const formatLanguage = (value) =>{
  const _language = languages.filter((lang)=>lang.value === value)
  if(_language.length){
    return _language[0].text
  }else{
    return value
  }
} 
export const formatTimeFormats = (value) =>{
  const _time_formats = time_formats.filter((tf)=>tf.value === value)
  if(_time_formats.length){
    return _time_formats[0].text
  }else{
    return value
  }
} 

export const title = ["Dr.", "Prof.", "Major", "Hon.", "Sr.", "Jr."];
 
export const shortid = () =>{
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return String(firstPart + secondPart);
}
export const formatPriceWithVat = (price, taxRate, action) =>{
  if(action === "excl"){
    return parseFloat(price / (100 + parseFloat(taxRate)) * 100).toFixed(2)
  }else{
    let _price =  (parseFloat(price) + (parseFloat(price) * (parseFloat(taxRate) / 100))).toFixed(2)
    if(!isNaN(_price)){
      return _price
    }else{
      return "0"
    }
  }
}

export const getFavouriteImage = (item, defaultImg) => {
  if (Array.isArray(item.images) && item.images?.length) {
    const favouritePic = item.images.filter((image) => image.favourite)
    if (favouritePic.length) {
      return favouritePic[0].link
    } else {
      return defaultImg
    }
  } else {
    return defaultImg
  }
}