import React, { useState } from "react";
import moment from "moment";
// icons
import { FiTrash2, FiStar } from "react-icons/fi";
import { FaStar } from "react-icons/fa";

//types
import { SupplierPriceType } from "./StockItems.type";

const StockItemsModalSuppliersItems: React.FC<{
  item: SupplierPriceType;
  onDelete: Function;
  onToggleFavourite: Function;
}> = ({item, onDelete, onToggleFavourite}) => {
  const [select, setSelect] = useState(false);
  return (
    <>
      <div className="StockItemsModal_Supplier_item">
        <div className="col_2" style={{ color: "#2589F6" }}>
          {item.supplier_organization || item.supplier_name}
        </div>
        <div className="col pointer " style={{color: "#2589F6"}} onClick={() => onToggleFavourite(item.id)} >
          {item.favourite === false ? <FiStar /> : <FaStar />}
        </div>
        <div className="col">{moment(item.updated_on).format("DD.MM.YYYY")}</div>
        <div className="col">€ {item.price_excl_vat}</div>
        <div className="col">€ {item.price_incl_vat}</div>
        <div style={{ width: 50 }} onClick={()=>onDelete(item.id)}>
          <FiTrash2 />
        </div>
      </div>
    </>
  );
};

export default StockItemsModalSuppliersItems;
