import { Dropdown } from "rsuite";
import "../../../pages/services/Services.scss";

// icons
import { FiUser, FiLayers, FiChevronDown } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";

const PageHeader = () => {
  return (
    <div className="SettingsPageHeader">
      <div className="PageHeader-left">
        <HiOutlineUserGroup />
        <h2>
          <span style={{ opacity: 0.6 }}> Settings</span> | Users
        </h2>
      </div>
    </div>
  );
};

export default PageHeader;
