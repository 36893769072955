import React, { useState } from "react";

// icon
import { FiMoreHorizontal } from "react-icons/fi";

const SubscriptionsItem: React.FC<{}> = (props) => {
  return (
    <>
      <div className="desk-only">
        <div className="SubscriptionsItem">
          <div className="SubscriptionsItem_left">
            {/* <div className="pos-relative">
              <img src="/assets/bg/contats.png" alt="" />
              <img src="/assets/bg/missedcall.png" alt="" className="small" />
            </div> */}
            <div className="SubscriptionsItem_left_1">
              <p>Start Date </p>
              <h6>10.02.2022 </h6>
            </div>
            <div className="SubscriptionsItem_left_2">
              <p>Expire Date </p>
              <h6>10.02.2022 </h6>
            </div>
            <div className="SubscriptionsItem_left_3">
              <p>Subscription ID</p>
              <h6>000002</h6>
            </div>
            <div className="SubscriptionsItem_left_4">
              <p>Customer Name</p>
              <h6>Herbert Kaputtmacher</h6>
            </div>
            <div className="SubscriptionsItem_left_5">
              <p>Total Excl. VAT</p>
              <h6>€ -120.240,00</h6>
            </div>
            <div className="SubscriptionsItem_left_6">
              <p>Payment Method</p>
              <h6>Bank Transfer</h6>
            </div>
          </div>
          <div className="SubscriptionsItem_right" style={{ marginLeft: 20 }}>
            <div className="">
              <h5>Active</h5>
            </div>
            <FiMoreHorizontal />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionsItem;
