import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Checkbox, CheckboxGroup, Tag, SelectPicker } from "rsuite";

const ContactFilters: React.FC<{
  contactSetFilter: Function;
  contactFilters: any;
}> = ({ contactSetFilter, contactFilters }) => {
  const typeData = [
    { label: "Private", value: "Private" },
    { label: "Public", value: "Public" },
    { label: "Organization", value: "Organization" },
  ];
  const filterRef = useRef<any>(null);
  const [filters, setFilters] = useState<any[]>([]);
  const [filtersTemp, setFiltersTemp] = useState<any[]>([]);

  const handleRemoveFilter = (name: string) => {
    setFilters(filters.filter((filter) => filter.name !== name));
    setFiltersTemp(filtersTemp.filter((filter) => filter !== name));
  };
  const handleSetFilters = () => {
    let filtersArr: any[] = [];
    filtersTemp.map((filter) => {
      filtersArr.push({
        name: filter,
        value: null,
      });
    });
    setFilters(filtersArr);
    filterRef.current.toggle();
  };
  const handleChangeFilterValue = (key: string, value: any) => {
    contactSetFilter({
      ...contactFilters,
      [key]: value,
    });
  };
  useEffect(() => {
    let filterObj = { ...contactFilters };
    if (!filters.find((filter) => filter.name === "type")) {
      filterObj = { ...filterObj, type: [] };
    }
    if (JSON.stringify(filterObj) !== JSON.stringify(contactFilters)) {
      contactSetFilter(filterObj);
    }
  }, [filters]);
  return (
    <>
      <div>
        {filters.map((filter: any, i: number) => (
          <Tag closable onClose={() => handleRemoveFilter(filter.name)} key={i}>
            <SelectPicker
              data={typeData}
              placeholder="Select type"
              searchable={false}
              value={contactFilters.type}
              onChange={(value) => handleChangeFilterValue("type", value)}
              placement="bottomStart"
              cleanable={false}
            />
          </Tag>
        ))}
      </div>
      <div className="Services_body_top_btn">
        <Dropdown
          trigger={["click"]}
          className="actionDropPageHeader export sort"
          placement="bottomEnd"
          renderTitle={() => <img src="/assets/bg/filter.png" />}
          ref={filterRef}
        >
          <div className="sort_drop">
            <h5>Filter</h5>
            <CheckboxGroup
              inline
              name="checkboxList"
              onChange={setFiltersTemp}
              className="JobsOverview_body_top_filters"
              value={filtersTemp}
            >
              <Checkbox value="type">Type</Checkbox>
            </CheckboxGroup>
            <div className="d-flex">
              <button
                className="bt-secondary"
                onClick={() => filterRef.current.toggle()}
              >
                Close
              </button>
              <button className="bt-primary" onClick={handleSetFilters}>
                Apply
              </button>
            </div>
          </div>
        </Dropdown>
      </div>
    </>
  );
};
export default ContactFilters;
