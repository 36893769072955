import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_REMOVE_TOKEN
} from "./constants";
import { loginWithPassword, _resetPassword, _sendOTP, _verifyOTP, _register, _socialLogin } from "./services";
/* Login actions */
export function loginSetLoading(loading) {
  return {
    type: LOGIN_LOADING,
    payload: loading,
  };
}
export function loginSetToken(params) {
  return {
    type: LOGIN_SUCCESS,
    payload: params,
  };
}
export function loginRemoveToken() {
	return {
	  type: LOGIN_REMOVE_TOKEN,
	};
  }
export const checkLogin = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(loginSetLoading(true));
		loginWithPassword(formData).then(async (res) => {
			await dispatch(loginSetToken(res));
			resolve(res)
		}).catch((err) => {
			reject(err)
		}).finally(() => {
			dispatch(loginSetLoading(false));
		})
	})
};
export const resetPassword = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(loginSetLoading(true));
		_resetPassword(formData).then(async (res) => {
			resolve(res)
		}).catch((err) => {
			reject(err)
		}).finally(() => {
			dispatch(loginSetLoading(false));
		})
	})
};
export const sendOTP = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_sendOTP(formData).then(async (res) => {
			resolve(res)
		}).catch((err) => {
			reject(err)
		})
	})
};
export const verifyOTP = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_verifyOTP(formData).then(async (res) => {
			resolve(res)
		}).catch((err) => {
			reject(err)
		})
	})
};
export const register = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_register(formData).then(async (res) => {
			resolve(res)
		}).catch((err) => {
			reject(err)
		})
	})
};
export const socialLogin = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(loginSetLoading(true));
		_socialLogin(formData).then(async (res) => {
			await dispatch(loginSetToken(res));
			resolve(res)
		}).catch((err) => {
			reject(err)
		}).finally(() => {
			dispatch(loginSetLoading(false));
		})
	})
};