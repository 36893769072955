import axios from "axios";
import { API_URL } from "../../config";
export function _fethProfile(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}user/`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _updateProfile(formData, token) {
  return new Promise((resolve, reject) => {
    axios
    .put(`${API_URL}user`, formData,{
      headers:{
        Authorization: `JWT ${token}`
      }
    })
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function _fetchCompany(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}company/`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}