import { gql } from "@apollo/client";

export const GET_ALL_TASKS = gql`
  query getAllTasks($limit: Int!, $userId: uuid!) {
    quick_tasks(limit: $limit, where: {created_by: {_eq: $userId}}, order_by: {task_date: desc}) {
      id
      task
      task_date
      created_at
      checked
    }
  }
`;
