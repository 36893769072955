import { createAsyncThunk } from "@reduxjs/toolkit";

import apiURL from "../../config/index";

export const sendOtpApi: any = createAsyncThunk(
  "auth/sendOtp",
  async (email) => {
    const response = await apiURL.post("user/send-otp", { email });
    return response.data;
  }
);

export const verifyOtpApi: any = createAsyncThunk(
  "auth/verify-otp",
  async (reqParam) => {
    const response = await apiURL.post("user/verify-otp", reqParam);
    return response.data;
  }
);

export const registerApi: any = createAsyncThunk(
  "auth/register",
  async (reqParam) => {
    const response = await apiURL.post("user/register", reqParam);
    return response.data;
  }
);

export const loginApi: any = createAsyncThunk(
  "auth/login",
  async (reqParam, thunkAPI) => {
    try {
      console.log(apiURL.post("user/login", reqParam), reqParam);
      const response = await apiURL.post("user/login", reqParam);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const socialLoginApi: any = createAsyncThunk(
  "auth/social-login",
  async (reqParam) => {
    const response = await apiURL.post("user/social-login", reqParam);
    return response.data;
  }
);

export const forgotPasswordApi: any = createAsyncThunk(
  "auth/forgot-password",
  async (reqParam) => {
    const response = await apiURL.post("user/forgot-password", reqParam);
    return response.data;
  }
);

export const verifyForgotPasswordApi: any = createAsyncThunk(
  "auth/verify-forgot-password",
  async (reqParam) => {
    const response = await apiURL.post("user/verify-forgot-password", reqParam);
    return response.data;
  }
);

export const resetPasswordApi: any = createAsyncThunk(
  "auth/reset-password",
  async (reqParam: any) => {
    const response = await apiURL.post("user/reset-password", reqParam, {
      headers: {
        Authorization: `Bearer ${reqParam.accessToken}`
      }
    });
    return response.data;
  }
);