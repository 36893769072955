import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// component
import InputBox from "../../template/inputBox/InputBox";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  companySettingsState,
  setCompanySettings
} from "../../../toolkit/companySettings/companySettings.slice";

const CompnaySettingsTAXStep1: React.FC<{ twickEdit?: any }> = ({
  twickEdit,
}) => {
  const dispatch = useDispatch();
  const { companySettings } = useSelector(companySettingsState).companySettings;
  const handleChange = (value:string, key:string) =>{
    dispatch(setCompanySettings({
      ...companySettings,
      [key]: value
    }))
  }
  return (
    <>
      <div className="CompnaySettingsGeneralDetails_details">
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              labelText="Company Registration No."
              handleChange={(value) => handleChange(value, "registration_no")} 
              valueProp={companySettings.registration_no} 
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "tax_uid")} 
              valueProp={companySettings.tax_uid} 
              labelText="UID (TAX ID)" 
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "tax_id")} 
              valueProp={companySettings.tax_id}
              labelText="TAX Identfication" 
            />
          </div>
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "default_tax_rate")} 
              valueProp={companySettings.default_tax_rate}
              labelText="Default TAX Rate" 
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "ceo")} 
              valueProp={companySettings.ceo}
              labelText="CEO" 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnaySettingsTAXStep1;
