import React, { useState, useEffect } from "react";

// icons
import {
  FiInfo,
  FiSearch,
  FiX,
  FiCheck,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import Loader from "../../loader/Loader";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_SERVICES } from "../../../gql/services/queries";

//redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";
import {
  serviceState,
  setServices
} from "../../../toolkit/services/services.slice";
import {
  jobState,
  setSelectedServices
} from "../../../toolkit/jobs/jobs.slice";

//types
import ServiceType, { ServiceImageType } from "../../services/servicesModal/Services.type";

//functions
import { BuildServiceSearchQuery } from "../../../pages/services/Services.functions"

//helpers
import { getFavouriteImage } from "../../../helpers";

const defaultImage =  "/assets/theme/2212.png";
const NewStep1: React.FC<{ ChangeStep: Function }> = ({ ChangeStep }) => {
  const dispatch = useDispatch();
  const { services } = useSelector(serviceState);
  const { selectedServices } = useSelector(jobState);
  const { auth: { user } } = useSelector(authState);
  const defaultCondition = {
    created_by: { _eq: user.id },
    active: { _eq: true }
  }
  const [queryCondition, setQueryCondition] = useState<any>(defaultCondition);
  const [orderCondition, setOrderCondition] = useState<any>({ created_at: "desc" });
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  const { data, refetch, error, loading } = useQuery(GET_ALL_SERVICES, {
    variables: {
      limit: limit,
      offset: offset,
      where: queryCondition,
      order: orderCondition
    },
  });

  const handleAddService = (item: ServiceType) =>{
    let _services = [...selectedServices]
    _services.push(item)
    dispatch(setSelectedServices(_services))
  }

  useEffect(() => {
    if (error) {

    } else if (data) {
      dispatch(setServices(data));
      if (typeof data?.services_aggregate?.aggregate?.count !== "undefined") {
        setTotalPages(
          Math.ceil(data?.services_aggregate?.aggregate?.count / limit)
        );
      }
    }
  }, [error, data]);
  useEffect(() => {
    const conditionTemp = BuildServiceSearchQuery(search, null)
    setQueryCondition({
      ...defaultCondition,
      ...conditionTemp
    })
  }, [search])
  return (
    <>
      <div className="desk-only">
        <div className="s1">
          <h2>
            Service Pricing &nbsp; <FiInfo style={{ width: 20 }} />
          </h2>

          <div className="Modalsearch">
            <FiSearch />
            <input
              type="text"
              name=""
              id=""
              placeholder="E.g. iPhonle se"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <FiX />
          </div>
          {loading && (
            <Loader />
          )}
          {services.length > 0 && (
            <div style={{ height: 300, overflowY: "scroll" }}>
              {/* items */}
              {services.map((service: ServiceType, i: number) => (
                <div className="pricingServicesCard" key={i}>
                  <img
                    src={getFavouriteImage(service, defaultImage)}
                    alt=""
                    className="pricingServicesCard-img"
                  />
                  <div className="pricingServicesCard-details">
                    <div className="">
                      <h4>{service.name}</h4>
                      <p>
                        {service.description?.length > 0 ? service.description : "No description available!"}
                      </p>
                    </div>
                    <div className="pricingServicesCard-spaced">
                      <div className="pricingServicesCard-price">
                        € {service.price_incl_vat} <br />
                        <span>incl. {service.vat}% VAT</span>
                      </div>

                      {selectedServices.filter((_selectedService: ServiceType) => _selectedService.id === service.id).length > 0 ? (
                        <div className="pricingServicesCard-btnGreen">
                          Service Selected <FiCheck />
                        </div>
                      ) : (
                        <div 
                          className="pricingServicesCard-btnPrimary"
                          onClick={()=>handleAddService(service)}
                        >
                          Add Service
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {/* items */}
            </div>
          )}
          <button
            className="pricingServicesCard-btnContinue"
            onClick={() => {
              ChangeStep();
            }}
            disabled={selectedServices.length === 0}
          >
            Create Express Job <FiChevronRight />
          </button>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default NewStep1;
