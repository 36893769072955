import { gql } from "@apollo/client";

export const GET_INVOICES_QUERY = gql`
  query getInvoices($where: invoices_bool_exp, $limit: Int!, $offset: Int!) {
    invoices(
      limit: $limit
      offset: $offset
      where: $where
    ) {
      created_by
      created_at
      customer {
        first_name
        last_name
      }
      payment_method
      total_amount
      invoice_id
      status
    }
    invoices_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
