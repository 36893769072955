import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

import { FiMessageSquare, FiSend, FiPaperclip } from "react-icons/fi";

const ChatActivityLog: React.FC<{}> = ({}) => {
  return (
    <>
      <div className="desk-only">
        <div className="DetailModal-main-middle-view-chat_activity">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-row table-header ">
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white">Date</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
                  <h4 className="DetailModal-main-middle-view-body-details-text-white">Activity</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white">Location</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white">User.</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-row" style={{ padding: "7px 0" }}>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">11/11/2021</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
                  <h4 className="DetailModal-main-middle-view-body-details-text">
                    Qutation sent to the <span style={{ color: "#2589F6" }}>demo@gmail.com</span>
                  </h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">India</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">Ranajoy Sharma.</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-row" style={{ padding: "7px 0" }}>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">11/11/2021</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
                  <h4 className="DetailModal-main-middle-view-body-details-text">New Booking</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">India</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">Ranajoy Sharma.</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-row" style={{ padding: "7px 0" }}>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">11/11/2021</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
                  <h4 className="DetailModal-main-middle-view-body-details-text">Ticket Completed</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">India</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">Ranajoy Sharma.</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-row" style={{ padding: "7px 0" }}>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">11/11/2021</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
                  <h4 className="DetailModal-main-middle-view-body-details-text">Add Invoice</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">India</h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text">Ranajoy Sharma.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only w-100 ">

        <div className="DetailModal-main-middle-view-chat_activity_graybg">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">Date</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">11/11/2021</h4>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">Activity</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">
                Qutation sent to the <span style={{ color: "#2589F6" }}>demo@gmail.com</span>
              </h4>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">Location</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">India</h4>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">User</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">Ranajoy Sharma.</h4>
            </div>
          </div>
        </div>

        <div className="DetailModal-main-middle-view-chat_activity_graybg">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">Date</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">11/11/2021</h4>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">Activity</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">
                Qutation sent to the <span style={{ color: "#2589F6" }}>demo@gmail.com</span>
              </h4>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">Location</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">India</h4>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text">User</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col" style={{ flex: 2 }}>
              <h4 className="DetailModal-main-middle-view-body-details-text">Ranajoy Sharma.</h4>
            </div>
          </div>
        </div>

        

      </div>
    </>
  );
};

export default ChatActivityLog;
