import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ModalBodyWrapper: React.FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <div className="DetailModal-main">{children}</div>
    </>
  );
};

export default ModalBodyWrapper;
