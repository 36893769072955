import React, { useEffect, useState, useRef } from "react";
import "../../../pages/dashBoard/DashBoard.css";
import { IonCheckbox } from "@ionic/react";
import { FiMoreVertical, FiEdit, FiTrash } from "react-icons/fi";
import { Popover, Whisper } from "rsuite";
import Swal from "sweetalert2";

//import helpers
import { getCalendarFormat, showAlert } from "../../../helpers";

//redux
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";
import { REMOVE_TASK_MUTATION } from "../../../gql/dashboard/mutation";

const QuickTasksItems: React.FC<{
  task: any;
  setSelectedTask: Function;
  updateChecked: Function;
  refetch: Function;
}> = ({ task, setSelectedTask, updateChecked, refetch }) => {
  const whisperRef = useRef<any>(null);
  const [checked, setChecked] = useState(false);
  const {
    auth: { user },
  } = useSelector(authState);

  const [removeNotesMutation, { error, data }] =
    useMutation(REMOVE_TASK_MUTATION);

  const speaker = (
    <Popover title="" style={{ width: 200 }}>
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          whisperRef.current.close();
          setSelectedTask(task);
        }}
      >
        <FiEdit /> Edit
      </p>
      <hr style={{ margin: "8px 0" }} />
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          whisperRef.current.close();
          handleDelete();
        }}
      >
        <FiTrash /> Delete
      </p>
    </Popover>
  );

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure want to delete this note?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: "warning",
      heightAuto: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await removeNotesMutation({
            variables: {
              id: task.id,
            },
          });
          showAlert({
            title: "Success!",
            message: `Note deleted successfully!`,
            type: "success",
          });
        } catch (err) {
          showAlert({
            title: "Failed!",
            message: `Something went wrong!`,
            type: "danger",
          });
        }
      }
    })
  };

  useEffect(() => {
    setChecked(task.checked);
  }, [task]);

  useEffect(() => {
    if (data) refetch();
  }, [data]);

  return (
    <div className="DashbaordPage_body_card">
      <div className="d-flex-center w-100">
        {/* <IonCheckbox
          checked={checked}
          onIonChange={(e) => {
            updateChecked(e.detail.checked, task.id);
            setChecked(e.detail.checked);
          }}
        /> */}
        <input
          checked={checked}
          type={"checkbox"}
          className={"quicktask-checkbox"}
          onChange={(e) => {
            updateChecked(e.target.checked, task.id);
            setChecked(e.target.checked);
          }}
        />
        <div className="flex-1">
          <div
            className={` ${
              checked ? "QuickTasks_h5_crossed" : "QuickTasks_h5"
            }`}
          >
            {task.task}
          </div>
          <div className="QuickTasks_h6">
            {getCalendarFormat(task.task_date)}, {user?.name}
          </div>
        </div>
        <Whisper
          ref={whisperRef}
          preventOverflow
          trigger="click"
          controlId="control-id-container"
          speaker={speaker}
          placement="auto"
        >
          <FiMoreVertical style={{ color: "#304a50", cursor: "pointer" }} />
        </Whisper>
      </div>
    </div>
  );
};

export default QuickTasksItems;
