import { gql } from "@apollo/client";

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($object: users_set_input!, $id: uuid!) {
    update_users(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        id
      }
    }
  }
`;
