import { FiPieChart, FiPlus, FiFilePlus, FiChevronDown } from "react-icons/fi";
import { Dropdown, Toggle } from "rsuite";
import { FiBriefcase, FiLayers, FiUsers, FiFileText } from "react-icons/fi";
import "../../../pages/dashBoard/DashBoard.css";

const PageHeader = () => {
  return (
    <div className="PageHeader">
      <Dropdown
        trigger={["click"]}
        className="drop_box_profile"
        renderTitle={() => (
          <div className="PageHeader-left">
            <FiPieChart />
            <h2>Dashboard</h2>
            <FiChevronDown className="cursor-pointer" />
          </div>
        )}
      >
        <div className="dropDownRowSpaced">
          <span>Progress Overview</span>
          <Toggle size="sm" />
        </div>
        <div className="dropDownRowSpaced">
          <span>Chat Notification</span>
          <Toggle size="sm" />
        </div>
        <div className="dropDownRowSpaced">
          <span>Stock</span>
          <Toggle size="sm" />
        </div>
        <div className="dropDownRowSpaced">
          <span>Analytics</span>
          <Toggle size="sm" />
        </div>
      </Dropdown>
      {/* <div className="desk-only">
        <div className="PageHeader-right">
          <button className="btn-white btn-left">
          <FiPlus />
          <p>Book Repair</p>
        </button>
        <button className="btn-white btn-right">
          <FiFilePlus />
          <p>Create Invoice</p>
        </button>
          <Dropdown
            trigger={["click"]}
            className="actionDropPageHeader"
            placement="bottomEnd"
            renderTitle={() => (
              <div className="bttn-chevrom">
                <div>Action</div>
                <FiChevronDown />
              </div>
            )}
          >
            <div className="dropDownRowSpaced ">
              <div className="d-flex">
                <FiBriefcase />
                <h6>New Job</h6>
              </div>
            </div>
            <div className="dropDownRowSpaced">
              <div className="d-flex">
                <FiFileText />
                <h6>New Invoice</h6>
              </div>
            </div>
            <div className="dropDownRowSpaced">
              <div className="d-flex">
                <FiUsers />
                <h6>New Contact</h6>
              </div>
            </div>
            <div className="dropDownRowSpaced" style={{ border: 0 }}>
              <div className="d-flex">
                <FiLayers />
                <h6>New Item</h6>
              </div>
            </div>
          </Dropdown>
        </div>
      </div> */}
    </div>
  );
};

export default PageHeader;
