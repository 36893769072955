import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icons

// component
import CompnaySettingsLogoStep1 from "./CompnaySettingsLogo.step1";

const CompnaySettingsLogo: React.FC<{
  onFileChange: Function
}> = ({onFileChange}) =>{
  const [edit, setEdit] = useState(false);
  return (
    <div className="CompnaySettingsGeneralDetails">
      <div className="flex-1 d-flex-start">
        <h2>Company Logo</h2>
      </div>

      <CompnaySettingsLogoStep1 
        onFileChange={onFileChange}
      />
    </div>
  );
};

export default CompnaySettingsLogo;
