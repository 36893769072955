import axios from "axios";
import { API_URL } from "../../config";
export function _fetchTurnOver(params, token) {
  return new Promise((resolve, reject) => {
    let urlParams = new URLSearchParams(params).toString();
    axios
      .get(`${API_URL}sales?${urlParams}`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchProgressOverview(params, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}get-repair-overview`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchUnpaidInvoiceStats(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}unpaid-invoice-stats`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchStockValue(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}get-stock-value`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchTopManufacturers(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}get-top-manufacturers`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}