import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { Button } from "rsuite";
import { FiX, FiTrash2, FiPlus } from "react-icons/fi";
//helper
import { showAlert } from "../../../helpers/index";

//redux
import { connect } from "react-redux";
import { updateActiveJob } from "../../../stores/activeJobs/actions";

const ReturnToClient: React.FC<{
  open: boolean;
  close: any;
  updateActiveJob: Function;
  job: any
}> = ({
  open,
  close,
  updateActiveJob,
  job
}) => {
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const handleSubmit = async () => {
      try {
        setBtnLoading(true)
        await updateActiveJob({
          ...job,
          status: "returned"
        })
        close()
        showAlert({ title: "Success!", message: "Job status updated successfully!", type: "success" })
      } catch (err) {
        showAlert({ title: "Error!", message: "Something Went Wrong", type: "danger" })
      } finally {
        setBtnLoading(false)
      }
    }
    return (
      <div className="ReturnToClient_modal">
        <div className="ReturnToClient_modal-body">
          <FiX className="ReturnToClient_modal-body-close" onClick={close} />
          <img src="/assets/icon/6.png" />
          <h6>5. Return To Client</h6>
          <div className="flex-row mb-2">
            <p>Would you like to return the device to the customer ?</p>
          </div>
          <button 
            className="btn btn-primary"
            onClick={()=>handleSubmit()}
            disabled={btnLoading}
          > 
            Return The Device
          </button>
        </div>
      </div>
    );
  };
const mapStateToProps = (state: any) => ({
  job: {},
});
const mapDispatchToProps = {
  updateActiveJob
};
export default connect(mapStateToProps, mapDispatchToProps)(ReturnToClient);