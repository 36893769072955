import React, { useState } from "react";
import "./SelectBox.css";

// icons
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const SelectBox: React.FC<{ children: any; selectedValue: any }> = ({ children, selectedValue }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={` Jobs SelectBox ${open === true ? "active" : ""}`}>
      <div
        className="SelectBox_inner"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="SelectBox_inner_Left">
          {/* <label className={`SelectBox_inner_label ${selectedValue !== "" && "filled"}`}>{labelText}</label> */}
          {selectedValue !== "" && <p>{selectedValue}</p>}
        </div>
        <div className="SelectBox_inner_right">
          <FiChevronDown />
        </div>
      </div>
      {open === true && (
        <div className="SelectBox_drop">
          {children.map((child: React.ReactElement, i: any) => (
            <div
              className="SelectBox_drop_item"
              key={i}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {child}
            </div>
          ))}
          {/* <div className="SelectBox_drop_item">{children}</div> */}
        </div>
      )}
    </div>
  );
};

export default SelectBox;
