import React, { useState } from "react";
import { Toggle, InputGroup, Input, Dropdown } from "rsuite";
import { v4 as uuid } from "uuid";

// icons
import { FiPlus } from "react-icons/fi";
import { FaBarcode } from "react-icons/fa";

// components

interface Props {
  setProductData: Function;
  setServiceData: Function;
  setOnClickText: Function;
  amount: any;
}

const InvoiceModalTableItemsAdd = ({
  setProductData,
  setServiceData,
  setOnClickText,
  amount,
}: Props) => {
  const setObj = {
    id: uuid(),
    product: "",
    unit: 1,
    price: "",
    vat: "",
    amount: 0,
    productId: "",
  };
  const [discountModal, setDiscountModal] = useState(false);

  return (
    <>
      {/* Items */}
      <div className="InvoiceModal_body_inner_table row_align_top">
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ maxWidth: 50 }}
        >
          <div className="blueBox">
            <FaBarcode />
          </div>
        </div>
        <div className="InvoiceModal_body_inner_table_col_2">
          <Dropdown
            trigger={["click"]}
            className=""
            style={{ width: "100%" }}
            renderTitle={() => (
              <button className="bt-dotted drop">
                <FiPlus style={{ color: "#000", fontSize: 20 }} />
              </button>
            )}
          >
            <div
              className="dropDownRowSpaced"
              onClick={() =>
                setProductData((product: any) => [...product, setObj])
              }
            >
              <span>ADD PRODUCT</span>
            </div>
            <div
              className="dropDownRowSpaced"
              onClick={() =>
                setServiceData((service: any) => [...service, setObj])
              }
            >
              <span>ADD SERVICE</span>
            </div>
            <div
              className="dropDownRowSpaced"
              onClick={() => setOnClickText(true)}
            >
              <span>ADD TEXT</span>
            </div>
          </Dropdown>
        </div>
        <div className="InvoiceModal_body_inner_table_col_3">
          <div className="InvoiceModal_body_inner_subBox">
            <div className="InvoiceModal_body_inner_table">
              <div className="InvoiceModal_body_inner_table_col_2">
                <h6>Subtotal excl. VAT</h6>
              </div>
              <div className="InvoiceModal_body_inner_table_col">
                <h6 className="text-right">0,00 EUR</h6>
              </div>
            </div>
            <div className="InvoiceModal_body_inner_table">
              <div className="InvoiceModal_body_inner_table_col_2">
                <h6
                  className="blueText underline"
                  onClick={() => {
                    setDiscountModal(true);
                  }}
                >
                  Add discount
                </h6>
                {discountModal && (
                  <>
                    <div
                      className="dropDown_wrapper"
                      onClick={() => {
                        setDiscountModal(false);
                      }}
                    ></div>
                    <div
                      className="dropDown_container"
                      style={{ padding: "5px 15px", top: 20, left: 0 }}
                    >
                      <h6 className="text-center">Discount</h6>
                      <InputGroup style={{ margin: "10px 0" }}>
                        <Input />
                        <InputGroup.Addon style={{ border: "1px solid #0001" }}>
                          %
                        </InputGroup.Addon>
                      </InputGroup>
                      <div className="bt-primary">Add</div>
                    </div>
                  </>
                )}
              </div>
              <div className="InvoiceModal_body_inner_table_col">
                <h6 className="text-right">0,00 EUR</h6>
              </div>
            </div>
            <div className="InvoiceModal_body_inner_table">
              <div className="InvoiceModal_body_inner_table_col_2">
                <h6 className="">20% VAT</h6>
              </div>
              <div className="InvoiceModal_body_inner_table_col">
                <h6 className="text-right">0,00 EUR</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Items */}
      {/* Items */}
      <div className="InvoiceModal_body_inner_table">
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ maxWidth: 50 }}
        ></div>
        <div className="InvoiceModal_body_inner_table_col_2"></div>
        <div className="InvoiceModal_body_inner_table_col_3">
          <div className="InvoiceModal_body_inner_subBox">
            <div className="InvoiceModal_body_inner_table">
              <div className="InvoiceModal_body_inner_table_col_2">
                <h5 className="">Total Amount</h5>
              </div>
              <div className="InvoiceModal_body_inner_table_col">
                <h5 className="text-right">
                  {amount}{" "}
                  EUR
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Items */}
    </>
  );
};

export default InvoiceModalTableItemsAdd;
