import { gql } from "@apollo/client";

export const CREATE_COMPANY_SETTINGS_MUTATION = gql`
  mutation InsertCompanyDetailsOne($object: company_details_insert_input!) {
    insert_company_details_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_COMPANY_SETTINGS_MUTATION = gql`
  mutation UpdateCompanyDetails($object: company_details_set_input!, $id: uuid!) {
    update_company_details(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        id
      }
    }
  }
`;
