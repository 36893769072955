import React, { useEffect } from "react";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import "./Profile.scss";

// icons
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";

// component
import {PageHeader, ProfileGeneralDetails, ProfileGeneralLanguage} from "../../components/profile";

//redux
import {useDispatch, useSelector} from "react-redux";
import {authState} from "../../toolkit/auth/auth.slice";
import {setProfileSettings} from "../../toolkit/profile/profile.slice";
import {fetchProfileApi} from "../../toolkit/profile/profile.api";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_PROFILE_SETTINGS } from "../../gql/profileSettings/queries";

const Profile: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const {token, user} = useSelector(authState).auth;

  const { data, loading, refetch } = useQuery(GET_PROFILE_SETTINGS, {
    variables: {
      createdBy: user.id
    }
  });

  useEffect(()=>{
    if(token){
      dispatch(fetchProfileApi(token));
    }
  }, [token])

  useEffect(() => {
    if (Array.isArray(data?.profile_settings) && data.profile_settings?.length) {
      const {__typename, ...object} = data.profile_settings[0]
      dispatch(setProfileSettings(object))
    }
  }, [data])
  return (
    <>
      <PageHeader />
      <FlexboxGrid className="Profile">
        <ProfileGeneralDetails />
        <ProfileGeneralLanguage
          loading={loading} 
          refetch={refetch}
        />
      </FlexboxGrid>
    </>
  );
};

export default Profile;
