import { Switch, Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Custom global */
import "./App.scss";

// import default style
import "rsuite/dist/styles/rsuite-default.css"; // or 'rsuite/dist/styles/rsuite-default.css'

// Layouts
import RouteWithLayout from "./components/routeWithLayout/RouteWithLayout";
import MainView from "./layouts/mainView";
import MinimalView from "./layouts/minimalView";
import MainViewWhite from "./layouts/mainViewWhite";

// pages
import {
  Login,
  Register,
  ForgotPassword,
  Dashbaord,
  JobsOverview,
  Services,
  TextTemplate,
  UsersSettings,
  CustomerContact,
  SupplierContact,
  Subcriptions,
  Profile,
  CompnaySettings,
  StockItems,
  Invoice,
  ReceiptsSettings,
  LabelSettings,
  EmailTemplateSettings,
  StatusSettings,

} from "./pages";


import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

//graphql
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useQuery,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import mainView from "./layouts/mainView";
import OthersSettings from "./pages/othersSettings/OthersSettings";
import Account from "./pages/account/Account";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCI-zptQkrvW1wNPOnpNLYFvDyjNDxxl5k",
  authDomain: "repaircmsv2.firebaseapp.com",
  projectId: "repaircmsv2",
  storageBucket: "repaircmsv2.appspot.com",
  messagingSenderId: "439217977714",
  appId: "1:439217977714:web:f373df95caecd4767b85eb",
  measurementId: "G-Y47R6SG0G2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}v1/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token: any = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${JSON.parse(token)}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <IonApp>
      <ReactNotifications />
      <IonReactRouter>
        <IonRouterOutlet>
          <Switch>
            <RouteWithLayout
              path="/"
              component={Login}
              exact={true}
              layout={MinimalView}
              access="public"
              restricted={true}
            />
            <RouteWithLayout
              path="/register"
              component={Register}
              exact={true}
              layout={MinimalView}
              access="public"
              restricted={true}
            />
            <RouteWithLayout
              path="/forgot-password"
              component={ForgotPassword}
              exact={true}
              layout={MinimalView}
              access="public"
              restricted={true}
            />
            <RouteWithLayout
              path="/dashboard"
              component={Dashbaord}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/dashboard/:id"
              component={Dashbaord}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/jobs"
              component={JobsOverview}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/services"
              component={Services}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/text-template"
              component={TextTemplate}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/users"
              component={UsersSettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />

            <RouteWithLayout
              path="/contacts/:type"
              component={CustomerContact}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            />
            {/* <RouteWithLayout
              path="/contacts/supplier"
              component={SupplierContact}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            /> */}
            <RouteWithLayout
              path="/subcriptions"
              component={Subcriptions}
              exact={true}
              layout={MainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/profile"
              component={Profile}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/company"
              component={CompnaySettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/recipts"
              component={ReceiptsSettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />

            <RouteWithLayout
              path="/settings/labels"
              component={LabelSettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/emailTemplates"
              component={EmailTemplateSettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/status"
              component={StatusSettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />

            <RouteWithLayout
              path="/stock/item"
              component={StockItems}
              exact={true}
              layout={mainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/invoices"
              component={Invoice}
              exact={true}
              layout={mainView}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/settings/OtherSettings"
              component={OthersSettings}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
            <RouteWithLayout
              path="/account"
              component={Account}
              exact={true}
              layout={MainViewWhite}
              access="private"
              restricted={false}
            />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </ApolloProvider>
);

export default App;
