import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Checkbox, CheckboxGroup } from "rsuite";



import "./Account.scss";
import { v4 as uuid } from "uuid";

import { FaTrashAlt } from "react-icons/fa";
import ReactToPrint, {
    PrintContextConsumer,
    useReactToPrint,
} from "react-to-print";
import SearchDropDown from "../../components/template/searchDropDown/SearchDropDown";
import SelectBox from "../../components/template/selectBox/SelectBox";

import Attach from "../../assets/attach.png";
import Send from "../../assets/send.png";
import plug from "../../assets/plug.png";
import emailTemp from "../../assets/emailTemp.png";

// component
import {
    PageHeader,
    CompnaySettingsAddress,
    CompnaySettingsContact,
    CompnaySettingsTAX,
    CompnaySettingsBank,
    CompnaySettingsLogo,
    CompnaySettingsReceipt,
} from "../../components/compnaySettings";

import InputBox from "../../components/template/inputBox/InputBox";

import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { nullFormat } from "numeral";
import TextAreaBox from "../../components/template/textAreaBox/TextAreaBox";

const Account: React.FC<{}> = (props) => {
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [selectedReceipt, selectedSelectedReceipt] = useState("Quotation");
    const [selectedEncryption, setSelectedEncryption] = useState("Encryption");
    const [dob, setDob] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState([
        {
            name: "Attachments",
        },
    ]);

    const [state, setState] = useState<any>({
        id: uuid(),
        password: '',
        description: "",
    });
    const [deleteAccount, setDeleteAccount] = useState<boolean>(false);

    const handleDeleteAccount = () =>{
        setDeleteAccount(!deleteAccount)
    }


    console.log(deleteAccount,'deleteAccount')


    const [model, setModel] = useState<string>("");

    const handleModelChange = (model: any) => {
        setModel(model);
    };

    const [items, setItems] = React.useState([
        {
            id: "1",
            value: "Quotation",
            label: "Quotation",
        },
    ]);
    const [encryptions, setEncryption] = React.useState([
        {
            id: "1",
            name: "Encryption",
        },
    ]);

    const renderMenu = (menu: any) => {
        if (items.length === 0) {
            return (
                <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                    {/* <SpinnerIcon spin />  */}
                    Loading...
                </p>
            );
        }
        return menu;
    };

    const handelSelect = (e: any) => {
        console.log(e, "is the value");
        selectedSelectedReceipt(e);
    };

    const handelSelectFile = (e: any) => {
        setSelectedFile(e.target.files);
        console.log(e.target.files);
    };

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handelSelectEncryptions = (value: string, key: string) => {
        setSelectedEncryption(value);
    };
    return (
        <>
            <PageHeader
                onClick={() => null}
                loading={btnLoading}
                headerText1="Settings"
                headerText2="Account"
                simpleSaveBtn={true}
                saveDropdown={false}
                handelPrint={handlePrint}
            />
            <div className="account_layout">
                <div className="reset_section_container">
                    <div className="reset_hedding">
                        <h6>Reset My Account</h6>
                        <p>Delete all Jobs, Invoices and Stook booking data.
                            Only customers and Items basedata will be not touched.</p>
                        <div className="reset_checkbox">
                            <CheckboxGroup
                                inline
                                name="checkboxList"
                                className="JobsOverview_body_top_filters"
                            >
                                <Checkbox value="type"  onClick={handleDeleteAccount}>I agree, i want to reset my account.</Checkbox>
                            </CheckboxGroup>
                        </div>

                        <div className="reset_input">
                            <InputBox labelText="Password" type= "password"  handleChange={(value: string) =>
                                    setState({ ...state, password: value })
                                } />
                        </div>
                        <div className="resetbtn">
                        {deleteAccount == true  && state.password ? 

<button style={{background: '#258AF6', color: '#fff'}}>Reset Account </button>:  <button>Reset Account </button>

}
                        </div>
                    </div>
                </div>

                <div className="reset_section_container">
                    <div className="reset_hedding">
                        <h6>Delete & Close My Account</h6>
                        <p>Delete all data & close my account. Everthing will be erased
                            and cannot be recovered.</p>
                        <div className="reset_checkbox">
                            <CheckboxGroup
                                inline
                                name="checkboxList"
                                className="JobsOverview_body_top_filters"
                            >
                                <Checkbox value="type" onClick={handleDeleteAccount} >I, agree i want to delete and close my account.</Checkbox>
                            </CheckboxGroup>
                        </div>

                        <div className="reset_input">
                            <InputBox labelText="Password" type= "password" handleChange={(value: string) =>
                                    setState({ ...state, password: value })
                                } />
                        </div>
                        <p>Help us to improve RepairCMS and let us know
                            why do you have decided to delete your account.</p>
                        <div className="reset_textarea">
                            <TextAreaBox
                                labelText="Service Description"
                                heightValue={166}
                                handleChange={(value: string) =>
                                    setState({ ...state, description: value })
                                }
                                valueProp={state.description}
                            />
                        </div>
                        <div className="resetbtn">
                            {deleteAccount == true && state.description && state.password ? 

                             <button style={{background: '#258AF6', color: '#fff'}}>Reset Account </button>:  <button>Reset Account </button>
                            
                            }
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default Account;
