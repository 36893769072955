import React, { useState } from "react";
import { InputGroup, Input, InputNumber } from "rsuite";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
    jobState,
    setDiscount
} from "../../../toolkit/jobs/jobs.slice";

//types
import ServiceType from "../../services/servicesModal/Services.type";

//helpers
import { getFavouriteImage } from "../../../helpers";

const defaultImage = "/assets/theme/2212.png";
const SelectedServicesSection: React.FC<{}> = ({}) => {
    const dispatch = useDispatch();
    const [discountModal, setDiscountModal] = useState(false);
    const { selectedServices, discount } = useSelector(jobState);
    const [discountTemp, setDiscountTemp] = useState<string>("");

    const getTotal = () => {
        let total: number = 0;
        selectedServices.map((item: ServiceType) => {
            total += Number(item.price_incl_vat)
        })
        let discountedValue:number = 0;
        if(discount > 0){
            discountedValue =  (total * discount)/100;
        }
        return total - discountedValue
    }
    const getSubTotal = () => {
        let total: number = 0;
        selectedServices.map((item: ServiceType) => {
            total += Number(item.price_excl_vat)
        })
        return total
    }
    const getTotalDiscountedValue = () => {
        let total: number = 0;
        selectedServices.map((item: ServiceType) => {
            total += Number(item.price_incl_vat)
        })
        if(discount > 0){
            return (total * discount)/100;
        }else{
            return 0
        }
    }
    return (
        <div className="stepFormWrapper-left start">
            <h4>Services</h4>
            {/* items */}
            <div className="ServicesItemsWrapper">
            {selectedServices.map((service: ServiceType, i: number) => (
                <div className="pricingServicesCard" style={{ height: 75, width: '100%' }} key={i}>
                    <img
                        src={getFavouriteImage(service, defaultImage)}
                        alt=""
                        className="pricingServicesCard-img"
                        style={{ height: 55, width: 55 }}
                    />
                    <div
                        className="pricingServicesCard-details"
                        style={{ marginRight: 10 }}
                    >
                        <div className="">
                            <h4>{service.name}</h4>
                            <p>
                                {service.description?.length > 0 ? service.description : "No description available!"}
                            </p>
                        </div>
                    </div>
                    <div className="pricingServicesCard-price">
                        € {service.price_incl_vat} <br />
                        <span>incl. {service.vat}% VAT</span>
                    </div>
                </div>

                
            ))}
              </div>
            {/* items */}

            <div className="InvoiceModal_body_inner_subBox" style={{ marginTop: 15 }}>
                <div className="InvoiceModal_body_inner_table">
                    <div className="InvoiceModal_body_inner_table_col_2">
                        <h6>Subtotal excl. VAT</h6>
                    </div>
                    <div className="InvoiceModal_body_inner_table_col">
                        <h6 className="text-right">{getSubTotal()} EUR</h6>
                    </div>
                </div>
                <div className="InvoiceModal_body_inner_table">
                    <div className="InvoiceModal_body_inner_table_col_2">
                        <h6
                            className="blueText underline cursor-pointer"
                            onClick={() => {
                                setDiscountModal(true);
                            }}
                        >
                            {discount > 0 ? `Edit discount (${discount}%)` : "Add discount"}
                        </h6>
                        {discountModal && (
                            <>
                                <div
                                    className="dropDown_wrapper"
                                    onClick={() => {
                                        setDiscountModal(false);
                                    }}
                                ></div>
                                <div
                                    className="dropDown_container"
                                    style={{ padding: "5px 15px", top: 20, left: 0 }}
                                >
                                    <h6 className="text-center">Discount</h6>
                                    <InputGroup style={{ margin: "10px 0" }}>
                                        <InputNumber 
                                            value={discountTemp}
                                            onChange={(value: number | string) => setDiscountTemp(String(value))}
                                            min={0}
                                            max={100}
                                        />
                                        <InputGroup.Addon
                                            style={{ border: "1px solid #0001" }}
                                        >
                                            %
                                        </InputGroup.Addon>
                                    </InputGroup>
                                    <div 
                                        className="bt-primary"
                                        onClick={()=>{
                                            dispatch(setDiscount(Number(discountTemp)));
                                            setDiscountModal(false);
                                        }}
                                    >Add</div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="InvoiceModal_body_inner_table_col">
                        <h6 className="text-right">{getTotalDiscountedValue()} EUR</h6>
                    </div>
                </div>
                <div className="InvoiceModal_body_inner_table">
                    <div className="InvoiceModal_body_inner_table_col_2">
                        <h6 className="">20% VAT</h6>
                    </div>
                    <div className="InvoiceModal_body_inner_table_col">
                        <h6 className="text-right">0,00 EUR</h6>
                    </div>
                </div>
            </div>
            <div className="InvoiceModal_body_inner_subBox" style={{ marginTop: 15 }} >
                <div className="InvoiceModal_body_inner_table">
                    <div className="InvoiceModal_body_inner_table_col_2">
                        <h5 className="">Total Amount</h5>
                    </div>
                    <div className="InvoiceModal_body_inner_table_col">
                        <h5 className="text-right">
                            {getTotal()} EUR
                        </h5>
                    </div>
                </div>
            </div>
            {/*  */}
        </div>
    );
};

export default SelectedServicesSection;
