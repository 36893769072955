import React, { useEffect, useState } from "react";
import { IonImg } from "@ionic/react";
import {
  FiSearch,
  FiPlus,
  FiSliders,
  FiChevronDown,
  FiUser,
  FiSettings,
  FiHelpCircle,
  FiLogOut,
  FiPhone,
  FiCheck,
} from "react-icons/fi";
import { Dropdown, Toggle } from "rsuite";
import { Link } from "react-router-dom";
import "../../../pages/dashBoard/DashBoard.css";
import moment from "moment";
import { v4 as uuid } from "uuid";
// import { UPLOAD_URL } from "../../../config";

//import components
import NotificationBox from "../../common/NotificationBox";
import JobModal from "../../jobModalWrapper/jobModal/JobModal";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loginRemoveToken, authState } from "../../../toolkit/auth/auth.slice";
import { profileState, setProfileSettings } from "../../../toolkit/profile/profile.slice";

//graphql
import { useMutation } from "@apollo/client";
import { CREATE_PROFILE_SETTINGS_MUTATION, UPDATE_PROFILE_SETTINGS_MUTATION } from "../../../gql/profileSettings/mutation";

const Header: React.FC<{}> = ({ }) => {
  const dispatch = useDispatch();
  const { profileSettings } = useSelector(profileState).profile;
  const [createProfileSettingsMutation] = useMutation(CREATE_PROFILE_SETTINGS_MUTATION);
  const [updateProfileSettingsMutation] = useMutation(UPDATE_PROFILE_SETTINGS_MUTATION);

  const { user: profile } = useSelector(profileState).profile;
  const { user } = useSelector(authState).auth;
  const [jobModal, setJobModal] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTime, setCurrentTime] = useState<any>(
    moment().format("DD MMM, h:mm A")
  );
  const changeBackground = async (name: string) => {
    if (profileSettings.id) { //update
      const { id, created_by, created_at, ...object } = profileSettings
      await updateProfileSettingsMutation({
        variables: {
          object: {
            background: name
          },
          id
        }
      })
    } else { //insert
      await createProfileSettingsMutation({
        variables: {
          object: {
            background: name,
            id: uuid(),
          }
        }
      })
    }
    dispatch(setProfileSettings({
      ...profileSettings,
      background: name
    }))
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTime(moment().format("DD MMM, h:mm A"));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="desk-only w-100">
        <div className="HeaderTop">
          <div className="HeaderTop_flex_start">
            <div className="HeaderTop-timeline desk-only ">
              <div className="HeaderTop-timeline-welcome">
                Welcome {profile.name}
              </div>
              <div className="HeaderTop-timeline-Time">{currentTime}</div>
            </div>
            <div
              className={`HeaderTop-Search desk-only ${search !== "" ? "search_suggested" : ""
                } `}
            >
              <input
                type="search"
                name=""
                id=""
                placeholder="search"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                }}
              />
              <FiSearch style={{ color: "#fffs" }} />
              {search !== "" && (
                <div className="HeaderTop-Search_suggestions">
                  <div className="HeaderTop-Search_suggestions_items">
                    <div className="HeaderTop-Search_suggestions_items_left">
                      <img src="assets/bg/Rectangle.png" alt="" />
                    </div>
                    <div className="HeaderTop-Search_suggestions_items_right">
                      <h6>
                        <span>Jane Doe</span>
                      </h6>
                    </div>
                  </div>
                  <div className="HeaderTop-Search_suggestions_items">
                    <div className="HeaderTop-Search_suggestions_items_left">
                      <FiSettings />
                    </div>
                    <div className="HeaderTop-Search_suggestions_items_right">
                      <h6>
                        <span>Job ID : 54646</span> Jane Doe Samsung S20+
                      </h6>
                    </div>
                  </div>
                  <div className="HeaderTop-Search_suggestions_items">
                    <div className="HeaderTop-Search_suggestions_items_left">
                      <FiSettings />
                    </div>
                    <div className="HeaderTop-Search_suggestions_items_right">
                      <h6>
                        <span>Job ID : 54646</span> Jane Doe Samsung S20+
                      </h6>
                    </div>
                  </div>
                  <div className="HeaderTop-Search_suggestions_items">
                    <div className="HeaderTop-Search_suggestions_items_left">
                      <FiSettings />
                    </div>
                    <div className="HeaderTop-Search_suggestions_items_right">
                      <h6>
                        <span>Job ID : 54646</span> Jane Doe Samsung S20+
                      </h6>
                    </div>
                  </div>
                  <div className="HeaderTop-Search_suggestions_items">
                    <div className="HeaderTop-Search_suggestions_items_left">
                      <FiSettings />
                    </div>
                    <div className="HeaderTop-Search_suggestions_items_right">
                      <h6>
                        <span>Job ID : 54646</span> Jane Doe Samsung S20+
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="HeaderTop-right">
            <button
              className="btn-trans"
              style={{ backgroundColor: "#2589F6" }}
              onClick={() => {
                setJobModal(!jobModal);
              }}
            >
              <FiPlus color={"#fff"} />
            </button>
            <Dropdown
              trigger={["click"]}
              placement="bottomEnd"
              className="dropDownThemeWrapper"
              renderTitle={() => (
                <button className="btn-trans">
                  <FiSliders color={"#fff"} />
                </button>
              )}
            >
              <div className="dropDownTheme">
                <div className="dropDownThemeTitle">Choose Your Theme</div>
                <div className="dropDownTheme-row">
                  {["1", "2", "3", "4", "5", "6", "7", "8"].map((image: any, i: number) => (
                    <div
                      className="pos-relative dropDownTheme-img-wrapper"
                      onClick={() => changeBackground(image)}
                      key={i}
                    >
                      <img
                        src={`/assets/theme/${image}.png`}
                        className={`dropDownTheme-img ${profileSettings.background == image ? "active" : ""
                          }`}
                      />
                      <FiCheck />
                    </div>
                  ))}
                </div>
              </div>
            </Dropdown>
            <NotificationBox />
            <button className="btn_trans_profile" style={{ marginRight: 10 }}>
              <FiPhone color={"#fff"} />
              <Toggle
                size="md"
                checkedChildren="Off"
                unCheckedChildren="On "
                style={{ marginLeft: 5 }}
              />
            </button>
            <Dropdown
              trigger={["click"]}
              className="dropDownProfileWrapper"
              placement="bottomEnd"
              renderTitle={() => (
                <button className="btn_trans_profile">
                  {profile.avatar ? (
                    <img
                      src={profile.avatar}
                      className="btn_trans_profile_img"
                      style={{ borderRadius: "50%", border: "2px solid #2589f6" }}
                    />
                  ) : (
                    <p className="avatar-text" style={{ margin: "auto", marginRight: 5 }}>
                      {user.name?.substring(0, 2)}
                    </p>
                  )}
                  <div style={{ textAlign: "left" }}>
                    <h6 className="desk-only" style={{ color: "#fff" }}>
                      {" "}
                      {profile.name}
                    </h6>
                    <p className="desk-only" style={{ color: "#fff" }}>
                      Administrator
                    </p>
                  </div>
                  <FiChevronDown />
                </button>
              )}
            >
              <div className="dropDownProfile">
                <div className="dropDownProfile-top">
                  {profile.avatar ? (
                    <img
                      src={profile.avatar}
                      className="dropDownProfile-top-img"
                      style={{ borderRadius: "50%", border: "2px solid #2589f6" }}
                    />
                  ) : (
                  <div  className="dropDownProfile-top-avatar-text">
                    <p>
                      {user.name?.substring(0, 2)}
                    </p>
                    </div>
                  )}
                  <div className="dropDownProfile-right">
                    <h4>{profile.name}</h4>
                    <h5>{profile.email}</h5>
                    <h6>Free</h6>
                  </div>
                </div>
                <Link to="/" className="dropDownProfile-Link">
                  <FiUser />
                  <span>Account</span>
                </Link>
                <Link to="/" className="dropDownProfile-Link">
                  <FiSettings />
                  <span>Settings</span>
                </Link>
                <Link to="/" className="dropDownProfile-Link">
                  <FiHelpCircle />
                  <span>Help</span>
                </Link>
                <div
                  onClick={() => dispatch(loginRemoveToken())}
                  className="dropDownProfile-Link"
                  style={{ border: 0, cursor: "pointer" }}
                >
                  <FiLogOut />
                  <span>Sign Out</span>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <JobModal
        openModal={jobModal}
        onClose={() => {
          setJobModal(!jobModal);
        }}
      />
    </>
  );
};
export default Header;
