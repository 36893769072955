import React, { useState, useEffect, useRef } from "react";

import {
    Toggle,
} from "rsuite";
import "./OthersSettings.scss";
import TagInput from "../../components/template/tagInput/TagInput";
import ReactToPrint, {
    PrintContextConsumer,
    useReactToPrint,
} from "react-to-print";
import SelectBox from "../../components/template/selectBox/SelectBox";
import Income from "../../assets/income.png"

import { Dropdown, Checkbox, CheckboxGroup } from "rsuite";

// component

import InputBox from "../../components/template/inputBox/InputBox";

import "tributejs/dist/tribute.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { nullFormat } from "numeral";
import {
    StatusItem,
    StatusModal,
    PageHeader,
} from "../../components/statusSettings";

const OthersSettings: React.FC<{}> = (props) => {
    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    const [selectedMode, setSelectedMode] = useState("Landscape");
    const [prefix, setPrefix] = useState("PRE");
    const [selectedSeparator1, setselectedSeparator1] = useState("-");
    const [selectedSeparator2, setselectedSeparator2] = useState("-");
    const [selectedMonth, setselectedMonth] = useState("Date");
    const [selectedDigits, setselectedDigits] = useState("4 Digits");
    const [salutationtags, setSalutationTags] = useState<string[]>([]);
    const [subtitletags, setSubtitleTags] = useState<string[]>([]);
    const [colorstags, setColorsTags] = useState<string[]>([]);
    const [categories, setCategoriesTags] = useState<string[]>([]);
    const [manufacturer, setManufacturerTags] = useState<string[]>([]);
    
    
    const [tags, setTags] = useState<string[]>([]);



    const [model, setModel] = useState<string>("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleModelChange = (model: any) => {
        setModel(model);
    };

    const [items, setItems] = React.useState([
        {
            id: "1",
            value: "Quotation",
            label: "Quotation",
        },
    ]);
    const [encryptions, setEncryption] = React.useState([
        {
            id: "1",
            name: "Encryption",
        },
    ]);

    const renderMenu = (menu: any) => {
        if (items.length === 0) {
            return (
                <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                    {/* <SpinnerIcon spin />  */}
                    Loading...
                </p>
            );
        }
        return menu;
    };

    const [month, setMonth] = React.useState([
        {
            id: "1",
            name: "month",
        },
        {
            id: "2",
            name: "year",
        },
    ]);
    const [modes, setModes] = React.useState([
        {
            id: "1",
            name: "Landscape",
        },
        {
            id: "2",
            name: "Protrait",
        },
    ]);
    const [Separator, setSeparator] = React.useState([
        {
            id: "1",
            name: "-",
        },
        {
            id: "2",
            name: "/",
        },
    ]);
    const [Digit, setDigit] = React.useState([
        {
            id: "1",
            name: "1 Digit",
        },
        {
            id: "2",
            name: "2 Digits",
        },
        {
            id: "3",
            name: "3 Digits",
        },
        {
            id: "4",
            name: "4 Digits",
        },
    ]);
    const handelSelectMode = (value: string, key: string) => {
        setSelectedMode(value);
    };
    const handelSelectselectedSeparator1 = (value: string, key: string) => {
        setselectedSeparator1(value);

    };
    const handelSelectselectedSeparator2 = (value: string, key: string) => {
        setselectedSeparator2(value);

    };
    const handelSelectselectedMonth = (value: string, key: string) => {
        setselectedMonth(value);

    };
    const handelSelectDigit = (value: string, key: string) => {
        setselectedDigits(value);

    };






    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrefixChange = (value :string, key : string) => {
       setPrefix(value)
    }



    const handelEditClick = () => {
        setIsEdit(!isEdit);
    };
    return (
        <>
            <PageHeader
                onClick={() => null}
                loading={btnLoading}
                headerText1="Settings"
                headerText2="Other Settings"
                simpleSaveBtn={true}
                saveDropdown={false}
                handelPrint={handlePrint}
            />
            <div className="othersetting_layout">


                <div className="otherssettingsContainer" style={{ margin: "5rem" }}>
                    <div className="othersettingContant">
                        <div className="contant_headding">
                            <p>Document Numbering</p>
                        </div>
                        <div className="contant_body">
                            <div className="body_preview">
                                <p>Preview</p>
                                <div className="select_type">
                                    <div>
                                        <p className="data">Prefix</p>
                                        <p className="value">{prefix.toUpperCase()}{selectedSeparator1}</p>
                                    </div>
                                    <div>
                                        <p className="data">Data</p>
                                        <p className="value">0422{selectedSeparator2}</p>

                                    </div>
                                    <div>
                                        <p className="data">Ongoing No.</p>
                                        <p className="value">0001</p>

                                    </div>

                                </div>

                            </div>
                            <div className="input_sections">
                                <div className="input-container">
                                <InputBox labelText="Prefix" handleChange={(value) => handlePrefixChange(value, "prefix")} />
                                </div>
                                <div className="input-container">
                                    <SelectBox selectedValue={selectedSeparator1} labelText="Separator">
                                        {Separator.map((item, i: number) => (
                                            <div
                                                className="selectBox-option"
                                                onClick={() => {
                                                    handelSelectselectedSeparator1(item.name, "country");
                                                }}
                                                key={i}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </SelectBox>

                                </div>
                                <div className="input-container">
                                    <SelectBox  selectedValue={selectedMonth} labelText="Date">
                                        {month.map((item, i: number) => (
                                            <div
                                                className="selectBox-option"
                                                onClick={() => {
                                                    handelSelectselectedMonth(item.name, "country");
                                                }}
                                                key={i}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </SelectBox>

                                </div>
                                <div className="input-container">
                                    <SelectBox selectedValue={selectedSeparator2} labelText="Separator">
                                        {Separator.map((item, i: number) => (
                                            <div
                                                className="selectBox-option"
                                                onClick={() => {
                                                    handelSelectselectedSeparator2(item.name, "country");
                                                }}
                                                key={i}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </SelectBox>

                                </div>
                                <div className="input-container">
                                    <SelectBox selectedValue={selectedDigits} labelText="Digits">
                                        {Digit.map((item, i: number) => (
                                            <div
                                                className="selectBox-option"
                                                onClick={() => {
                                                    handelSelectDigit(item.name, "country");
                                                }}
                                                key={i}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </SelectBox>

                                </div>
                            </div>
                            <div className="checkbox_section">
                                <p>Use format for:</p>

                                <div className="checkbox">
                                    <CheckboxGroup
                                        inline
                                        name="checkboxList"
                                        className="JobsOverview_body_top_filters"
                                    >
                                        <Checkbox value="type">Invoice</Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        inline
                                        name="checkboxList"
                                        className="JobsOverview_body_top_filters"
                                    >
                                        <Checkbox value="type">Job ID</Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        inline
                                        name="checkboxList"
                                        className="JobsOverview_body_top_filters"
                                    >
                                        <Checkbox value="type">Ouotation</Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        inline
                                        name="checkboxList"
                                        className="JobsOverview_body_top_filters"
                                    >
                                        <Checkbox value="type">Delivery Note</Checkbox>
                                    </CheckboxGroup>
                                </div>


                            </div>
                            <div className="doc_section">
                                <p>Document Start Numbering</p>
                                <div className="doc_input">
                                    <div className="inputContainer">
                                        <InputBox labelText="Invoice Start No." handleChange={() => null} />
                                    </div>
                                    <div className="inputContainer">
                                        <InputBox labelText="Job ID Start No." handleChange={() => null} />
                                    </div>
                                    <div className="inputContainer">
                                        <InputBox labelText="Quotation Start No." handleChange={() => null} />
                                    </div>
                                    <div className="inputContainer">
                                        <InputBox  labelText="Delivery Note Start No.  " handleChange={() => null} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="InvoiceSettings_container" style={{ margin: "5rem" }}>
                    <div className="InvoiceSettings_contant">
                        <p className="Invoice_para">Invoice Settings</p>
                        <div className="Formalise_contant">
                            <div className="toggle" style={{ marginTop: 35 }}>
                                <div className="CustomerContactModal_inner_blueShow">
                                    <img src={Income} alt="" />
                                    <div className="f1">
                                        <h6>Formalise Invoice</h6>
                                        <p>Cannot change details of created invoices</p>
                                    </div>
                                    <Toggle
                                        size="md"
                                        checkedChildren="ON"
                                        unCheckedChildren="OFF"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Managed_container" style={{ margin: "5rem" }}>
                    <div className="Managed_contant">
                        <h6>Managed Drop Down Fields. </h6>
                        <p>You can add, rename and delete dropdown itmes. Deleting will not have any affect <br />
                            for existing datas or documents.  </p>

                        <div className="Managed_tags_contant">
                            <div className="tag_box">
                                <h6>Salutation</h6>
                                <TagInput
                                    handleChange={(value) => {
                                        if (value.length) {
                                            setSalutationTags((salutationtags) => [...salutationtags, value])
                                        }
                                    }}
                                    visualData={salutationtags}
                                    setTags={setSalutationTags}
                                />
                            </div>
                            <div className="tag_box">
                                <h6>Subtitle</h6>
                                <TagInput
                                    handleChange={(value) => {
                                        if (value.length) {
                                            setSubtitleTags((subtitletags) => [...subtitletags, value])
                                        }
                                    }}
                                    visualData={subtitletags}
                                    setTags={setSubtitleTags}
                                />
                            </div>
                            <div className="tag_box">
                                <h6>Colors</h6>
                                <TagInput
                                    handleChange={(value) => {
                                        if (value.length) {
                                            setColorsTags((colorstags) => [...colorstags, value])
                                        }
                                    }}
                                    visualData={colorstags}
                                    setTags={setColorsTags}
                                />
                            </div>
                            <div className="tag_box">
                                <h6>Item Categories </h6>
                                <TagInput
                                    handleChange={(value) => {
                                        if (value.length) {
                                            setCategoriesTags((categories) => [...categories, value])
                                        }
                                    }}
                                    visualData={categories}
                                    setTags={setCategoriesTags}
                                />
                            </div>
                            <div className="tag_box">
                                <h6>Manufacturer </h6>
                                <TagInput
                                    handleChange={(value) => {
                                        if (value.length) {
                                            setManufacturerTags((manufacturer) => [...manufacturer, value])
                                        }
                                    }}
                                    visualData={manufacturer}
                                    setTags={setManufacturerTags}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default OthersSettings;
