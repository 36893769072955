import { gql } from "@apollo/client";

export const GET_ALL_MANUFACTURERS = gql`
  query GetManucaturers($where: manufacturers_bool_exp, $limit: Int!, $offset: Int!) {
    manufacturers(where: $where, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;
