import React, { useState } from "react";
import "../../../pages/services/Services.scss";
import { Placeholder } from "rsuite";

// icons
import { FiEdit2 } from "react-icons/fi";

// component
import ProfileGeneralLanguageStep1 from "./ProfileGeneralLanguage.step1";
import ProfileGeneralLanguageStep2 from "./ProfileGeneralLanguage.step2";

const ProfileGeneralLanguage: React.FC<{ loading: boolean, refetch: Function }> = ({ loading, refetch }) => {
  const [edit, setEdit] = useState(false);
  const { Paragraph } = Placeholder
  return (
    <div className="ProfileGeneralDetails">
      <div className="flex-1 d-flex-start">
        <h2>Language & Timezone</h2>
        {edit === false && (
          <div
            className="ProfileGeneralDetails_edit"
            onClick={() => {
              setEdit(!edit);
            }}
          >
            <FiEdit2 />
          </div>
        )}
      </div>
      {edit === false ? loading ?
        <Paragraph
          rows={15}
          rowMargin={8}
          active
          rowHeight={14}
          style={{ marginTop: 10 }}
        /> : <ProfileGeneralLanguageStep2 /> : (
        <ProfileGeneralLanguageStep1
          twickEdit={() => {
            setEdit(false);
          }}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default ProfileGeneralLanguage;
