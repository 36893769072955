import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { FiChevronDown, FiChevronUp, FiMaximize, FiPlus } from "react-icons/fi";

// 
import ChangeStatusModal from '../changeStatusModal/ChangeStatusModal';
import RepairFlowStatus from '../component/repairFlowStatus/RepairFlowStatus';

const RepairFlow: React.FC<{}> = ({ }) => {
  const [openProgress, setOpenProgress] = useState(true);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);

  return (
    <>
      <div className="DetailModal-card">
        {openChangeStatusModal && (
          <ChangeStatusModal close={() => setOpenChangeStatusModal(false)} />
        )}
        <div className="DetailModal-card-header">
          <h6>Repair Progress</h6>
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <FiMaximize className="DetailModal-card-iconbtn cursor-pointer" />
            <div onClick={() => { setOpenChangeStatusModal(!openChangeStatusModal) }}>
              <FiPlus className="DetailModal-card-iconbtn cursor-pointer" />
            </div>
            {openProgress ? (
              <FiChevronUp
                className="cursor-pointer"
                onClick={() => setOpenProgress(!openProgress)}
              />
            ) : (
              <FiChevronDown
                className="cursor-pointer"
                onClick={() => setOpenProgress(!openProgress)}
              />
            )}
          </div>
        </div>
        {openProgress && (
          <div className="DetailModal-card-body">
            <div className="DetailModal-progress">
              <RepairFlowStatus name='Booked in' color='#2CBFD6' />
              <RepairFlowStatus name='Repair in Progress' color='#FEC636' />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RepairFlow;
