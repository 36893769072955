import { gql } from "@apollo/client";

export const CREATE_TASK_MUTATION = gql`
  mutation InsertTask($object: quick_tasks_insert_input!) {
    insert_quick_tasks_one(object: $object) {
      id
      task
      created_at
      checked
    }
  }
`;

export const UPDATE_TASK_MUTATION = gql`
  mutation UpdateTask($id: uuid!, $object: quick_tasks_set_input!) {
    update_quick_tasks(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        checked
        created_at
        task
        id
        task_date
        updated_at
      }
    }
  }
`;

export const REMOVE_TASK_MUTATION = gql`
  mutation RemoveTask($id: uuid!) {
    delete_quick_tasks_by_pk(id: $id) {
      id
    }
  }
`;
