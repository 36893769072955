import { IonImg } from "@ionic/react";
import "../../../pages/dashBoard/DashBoard.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Placeholder } from "rsuite";

//redux
import { connect } from "react-redux";
import { fetchUnpaidInvoiceStats } from "../../../stores/stats/actions";
import { useEffect } from "react";

const Unpaid: React.FC<{
	fetchUnpaidInvoiceStats: Function
	loading: boolean
	unpaidInvoice: any
}> = (props) => {
  const { Paragraph } = Placeholder;
  useEffect(()=>{
    // props.fetchUnpaidInvoiceStats()
  }, [])
  return (
    <div className="DashbaordPage_body_card">
      <div className="DashbaordPage_row">
        <div className="DashbaordPage_row_col_2">
          <div className="DashbaordPage_Header_wrapper">
            <p className="DashbaordPage_Header" 
              // style={{ paddingBottom: 7 }}
            >
              UNPAID INVOICES
            </p>
          </div>
          {props.loading ? (
            <Paragraph rows={2} rowMargin={8} active/>
          ) : (
          <div className="DashbaordPage_row">
            <h2
              className="MONTH_text"
              style={{ color: "#FF0000", textAlign: "left" }}
            >
              {props.unpaidInvoice?.count || 0}
            </h2>
            <div className="TURNOVER_text_wrapper_wrong">
              <h2 className="TURNOVER_text_wrong">€ {props.unpaidInvoice?.totalUnpaid?.toLocaleString() || "0.00"}</h2>
              <div className="TURNOVER_circle">
                <IonImg
                  src="/assets/bg/down.png"
                  alt=""
                  className="TURNOVER_circle_img"
                />
              </div>
            </div>
          </div>
          )}
        </div>
        <div className="divider-vertical-TURNOVER"></div>
        <div className="DashbaordPage_row_col_1">
          <div className="d-flex-center">
            <IonImg
              src="/assets/icon/3.png"
              alt=""
              className="TURNOVER_circle_img_cal"
            />
          </div>
          <div className="d-flex-center w-100">
            <span
              className="DetailModal-card-Link unpaid_link "
              style={{ paddingTop: 5, textAlign: "center" }}
            >
              Invoice List
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  loading: false,
  unpaidInvoice: {},
});
const mapDispatchToProps = {
	fetchUnpaidInvoiceStats,
};
export default connect(mapStateToProps, mapDispatchToProps)(Unpaid);