import React, { useState } from "react";
import "../../../pages/customerContact/CustomerContact.scss";
import { Dropdown } from "rsuite";

// icon
import { FiMoreHorizontal } from "react-icons/fi";

interface CustomerTypes {
  id: string;
  first_name: string;
  last_name: string;
  active: boolean;
  emails: [string];
  addresses: [string];
  created_at: string;
  phones: [string];
  type: string;
  organization: string;
  setModal: (m: boolean) => void;
  setEditedId: (i: string) => void;
  setIsEdited: (i: boolean) => void;
  removeCustomer: Function;
}

const CustomerContactItems = ({
  id,
  first_name,
  last_name,
  emails,
  addresses,
  active,
  created_at,
  phones,
  setModal,
  setEditedId,
  setIsEdited,
  removeCustomer,
  type,
  organization
}: CustomerTypes) => {
  const [showList, setShowList] = useState<boolean>(false);

  const onEdit = () => {
    setShowList(false);
    setModal(true);
    setEditedId(id);
    setIsEdited(true);
  }

  return (
    <>
      <div className="desk-only">
        <div className="CustomerContactItems">
          <div className="CustomerContactItems_left" onClick={onEdit}>
            <div className="pos-relative">
              <img src="/assets/bg/contats.png" alt="" />
              {/* <img src="/assets/bg/missedcall.png" alt="" className="small" /> */}
            </div>
            <div className="CustomerContactItems_left_1">
              <p>Name</p>
              <h6>
                {type === "Organization" ? organization || `${first_name} ${last_name}` : `${first_name} ${last_name}`}
              </h6>
            </div>
            <div className="CustomerContactItems_left_2">

                <p>PLZ, City</p>
              {addresses.map((el: any, idx: number) => (
                <h6 key={`${el?.zip}-${idx}`}>
                  {el?.zip} {el?.city}
                </h6>
              ))}
              {addresses.filter((item: any) => item.zip?.length > 0 || item.city?.length > 0).length == 0 && (
                <h6 className="text-muted"> N/A </h6>
              )}
            </div>
            <div className="CustomerContactItems_left_3">
              <p>E-Mail</p>
              {emails.map((el: any, idx: number) => (
                <h6 key={`${el?.email}-${idx}`} style={{ color: "#2589F6" }}>
                  {el?.email}
                </h6>
              ))}
              {emails.filter((item: any) => item.email?.length > 0).length == 0 && (
                <h6 className="text-muted">N/A</h6>
              )}
            </div>
            <div className="CustomerContactItems_left_4">
              <p>Telephone</p>
              {phones.map((el: any, idx: number) => (
                <h6 key={`${el?.phone}-${idx}`}>{el?.phone}</h6>
              ))}
              {phones.filter((item: any) => item.phone?.length > 0).length == 0 && (
                <h6 className="text-muted">N/A</h6>
              )}
            </div>
          </div>
          <div
            className="CustomerContactItems_right"
            style={{ marginLeft: 20 }}
          >
            <div className="" onClick={onEdit}>
              <h5>{type}</h5>
            </div>

            {/* <div className="Customer-actions">
              <FiMoreHorizontal onClick={() => setShowList(!showList)} />
              {showList && (
                <div className="Customer-actions-container">
                  <div className="Customer-action" onClick={onEdit}>Edit</div>
                  <div className="Customer-action" onClick={()=> removeCustomer(id)}>Remove</div>
                </div>
              )}
            </div> */}
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/icon/dotsHorizontal.png" />}
            >
              <div className="dropDownProfile">
                <span className="dropDownProfile-Link" onClick={onEdit}>Edit</span>
                <span className="dropDownProfile-Link" onClick={() => removeCustomer(id)}>Remove</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerContactItems;
