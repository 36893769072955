import React, { useState, useEffect, useRef } from "react";
import {
  FlexboxGrid,
  SelectPicker,
  Uploader,
  Message,
  Loader,
  Toggle,
} from "rsuite";
import "./ReceiptsSettings.scss";
import { v4 as uuid } from "uuid";

import { FaTrashAlt } from "react-icons/fa";
import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint,
} from "react-to-print";

// component
import {
  PageHeader,
  CompnaySettingsAddress,
  CompnaySettingsContact,
  CompnaySettingsTAX,
  CompnaySettingsBank,
  CompnaySettingsLogo,
  CompnaySettingsReceipt,
} from "../../components/compnaySettings";
// import Loader from "../../components/loader/Loader";

//redux
// import { useSelector, useDispatch } from "react-redux";

//graphql
// import { useQuery, useMutation } from "@apollo/client";
// import { GET_COMPANY_SETTING } from "../../gql/companySettings/queries";
// import { authState } from "../../toolkit/auth/auth.slice";

// import { fetchCompanySettingsApi } from "../../toolkit/companySettings/companySettings.api";

import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { nullFormat } from "numeral";

function previewFile(file: any, callback: any) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

const ReceiptsSettings: React.FC<{}> = (props) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [selectedReceipt, selectedSelectedReceipt] = useState("Job Receipt");

  // const { user, token } = useSelector(authState).auth;

  // const ref = useRef({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const tribute = new Tribute({
    values: [
      { key: "Phil Heartman", value: "pheartman" },
      { key: "Gordon Ramsey", value: "gramsey" },
      { key: "Phil Heartman", value: "pheartman" },
      { key: "Gordon Ramsey", value: "gramsey" },
      { key: "Phil Heartman", value: "pheartman" },
      { key: "Gordon Ramsey", value: "gramsey" },
    ],
  });
  const [editor, setEditor] = useState<any>(undefined);
  const [model, setModel] = useState<string>("");

  const handleModelChange = (model: any) => {
    setModel(model);
  };
  const checkDlt = () => {
    console.log("yes");
    setFileInfo(null);
  };

  // const { data, loading, refetch } = useQuery(GET_COMPANY_SETTING, {
  //   variables: {
  //     createdBy: user.id,
  //   },
  // });

  // useEffect(() => {
  //   if (Array.isArray(data?.company_details) && data.company_details?.length) {
  //     const { __typename, ...object } = data.company_details[0];

  //     console.log(object, "object");
  //   }
  // }, [data]);

  // const getData = async () => {
  //   const resp: any = await fetchCompanySettingsApi();
  //   console.log(resp, "resp");
  // };
  // getData();

  const [items, setItems] = React.useState([
    {
      id: "1",
      value: "Job Receipt",
      label: "Job Receipt",
    },
    {
      id: "2",
      value: "Service Receipt",
      label: "Service Receipt",
    },
    {
      id: "3",
      value: "Invoice Receipt",
      label: "Invoice Receipt",
    },
    {
      id: "4",
      value: "Quotation",
      label: "Quotation",
    },
    {
      id: "5",
      value: "Delivery Note",
      label: "Delivery Note",
    },
    {
      id: "6",
      value: "Thermal Receipt(Job booking)",
      label: "Thermal Receipt(Job booking)",
    },
    {
      id: "7",
      value: "Thermal Receipt(Invoice)",
      label: "Thermal Receipt(Invoice)",
    },
    {
      id: "8",
      value: "Background Template",
      label: "Background Template",
    },
  ]);

  const renderMenu = (menu: any) => {
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          {/* <SpinnerIcon spin />  */}
          Loading...
        </p>
      );
    }
    return menu;
  };

  const handelSelect = (e: any) => {
    console.log(e, "is the value");
    selectedSelectedReceipt(e);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <PageHeader
        onClick={() => null}
        loading={btnLoading}
        headerText1="Settings"
        headerText2="Receipts"
        simpleSaveBtn={false}
        saveDropdown={true}
        handelPrint={handlePrint}
      />
      <div className="receiptsContainer">
        <div className="dropdown_receipts">
          <SelectPicker
            placeholder="Job Receipt"
            defaultValue={"|"}
            data={items}
            renderMenu={renderMenu}
            searchable={false}
            onSelect={handelSelect}
          />
        </div>

        <div ref={componentRef}></div>
        <CompnaySettingsReceipt recepitType={selectedReceipt} />
      </div>
    </>
  );
};

export default ReceiptsSettings;
