import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
  errorMsg: "",
};

const invoiceSlice: any = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices: (state, { payload }) => {
      return {
        ...state,
        invoices: payload?.invoices,
      };
    },
  },
});

export const invoicesState = (state: any) => state.invoices;
export const { setInvoices } = invoiceSlice.actions;

export default invoiceSlice.reducer;