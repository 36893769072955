import { createSlice } from "@reduxjs/toolkit";
//types
import ServiceType from "../../components/services/servicesModal/Services.type";

interface StateType {
  selectedServices: ServiceType[];
  errorMsg: string;
  discount: number;
}

const initialState: StateType = {
  selectedServices: [],
  errorMsg: "",
  discount: 0
};

const jobSlice: any = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setSelectedServices: (state, { payload }) => {
      return {
        ...state,
        selectedServices: payload,
      };
    },
    setDiscount: (state, { payload }) => {
      return {
        ...state,
        discount: payload,
      };
    },
  },
});

export const jobState = (state: any) => state.jobs;
export const { setSelectedServices, setDiscount } = jobSlice.actions;
export default jobSlice.reducer;