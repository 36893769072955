import React, { useRef, useState } from "react";
import {
  Dropdown,
  Tag,
  Checkbox,
  CheckboxGroup,
  DateRangePicker,
  SelectPicker,
} from "rsuite";
import { FiMoreVertical, FiSearch, FiUpload } from "react-icons/fi";
import moment from "moment";

//icons
import { FiPlus } from "react-icons/fi";

//redux
import { connect } from "react-redux";
import { activeJobSetFilter } from "../../../stores/activeJobs/actions";
import { useEffect } from "react";

const SubscriptionFilterComponent: React.FC<{
  searchText: string;
  setSearchText: Function;
  handleExportCSV: Function;
  activeJobSetFilter: Function;
  activeJobFilters: any;
}> = (props) => {
  const statuses = [
    { label: "Angenommen", value: "preorder" },
    { label: "In Reparatur", value: "in_repair" },
    { label: "KVA gesendet", value: "kva_send" },
    { label: "KVA Bestätigt", value: "kva_confirmed" },
    { label: "KVA Abgelehnt", value: "kva_declined" },
    { label: "Warten auf Erstzteile", value: "on_hold" },
    { label: "Archiv", value: "archive" },
    { label: "Gelöscht", value: "deleted" },
    { label: "Abgeschl.", value: "finished" },
    { label: "Abgeholt", value: "returned" },
  ];
  const dateRangePickerRef = useRef<any>(null);
  const filterRef = useRef<any>(null);
  const [filters, setFilters] = useState<any[]>([]);
  const [filtersTemp, setFiltersTemp] = useState<any[]>([]);

  const handleSetDateRanges = (dateRanges: any[]) => {
    props.activeJobSetFilter({
      ...props.activeJobFilters,
      startDate: moment(dateRanges[0]).format("DD/MM/YYYY"),
      endDate: moment(dateRanges[1]).format("DD/MM/YYYY"),
    });
  };
  const handleRemoveFilter = (name: string) => {
    setFilters(filters.filter((filter) => filter.name !== name));
    setFiltersTemp(filtersTemp.filter((filter) => filter !== name));
  };
  const handleSetFilters = () => {
    let filtersArr: any[] = [];
    filtersTemp.map((filter) => {
      filtersArr.push({
        name: filter,
        value: null,
      });
    });
    setFilters(filtersArr);
    filterRef.current.toggle();
  };
  const handleChangeFilterValue = (key: string, value: any) => {
    props.activeJobSetFilter({
      ...props.activeJobFilters,
      [key]: value,
    });
  };
  useEffect(() => {
    let filterObj = { ...props.activeJobFilters };
    if (!filters.find((filter) => filter.name === "Status")) {
      filterObj = { ...filterObj, status: "" };
    }
    if (JSON.stringify(filterObj) !== JSON.stringify(props.activeJobFilters)) {
      props.activeJobSetFilter(filterObj);
    }
  }, [filters]);
  return (
    <div className="JobsOverview_body_top">
      <div className="table-head-icon1">
        <div className="JobsOverview_body_top_btn">
          <Dropdown
            trigger={["click"]}
            className="actionDropPageHeader export sort"
            placement="bottomEnd"
            renderTitle={() => <img src="/assets/bg/sort.png" />}
          >
            <div className="sort_drop">
              <h5>Sort</h5>
              <Checkbox>1 - 10 (ascending)</Checkbox>
              <Checkbox>10 - 1 (descending)</Checkbox>
              <Checkbox>A - Z (ascending)</Checkbox>
              <Checkbox>Z - A (descending)</Checkbox>
              <div className="d-flex">
                <button className="bt-secondary">Abort</button>
                <button className="bt-primary">Apply</button>
              </div>
            </div>
          </Dropdown>
        </div>

        <div>
          {filters.map((filter: any, i: number) => (
            <Tag
              closable
              onClose={() => handleRemoveFilter(filter.name)}
              key={i}
            >
              {/* {filter.name} */}
              {filter.name === "Status" && (
                <SelectPicker
                  placeholder="Select Status"
                  data={statuses}
                  onSelect={(value) => handleChangeFilterValue("status", value)}
                />
              )}
            </Tag>
          ))}
        </div>
        <div className="JobsOverview_body_top_btn">
          <Dropdown
            trigger={["click"]}
            className="actionDropPageHeader export sort"
            placement="bottomEnd"
            renderTitle={() => <img src="/assets/bg/filter.png" />}
            ref={filterRef}
          >
            <div className="sort_drop">
              <h5>Filter</h5>
              <CheckboxGroup
                inline
                name="checkboxList"
                onChange={setFiltersTemp}
                className="JobsOverview_body_top_filters"
                value={filtersTemp}
              >
                <Checkbox value="Job Type">Job Type</Checkbox>
                <Checkbox value="Status">Status</Checkbox>
                {/* <CheckTree data={sort} defaultExpandAll /> */}
                <Checkbox value="Box Location">Location</Checkbox>
                <Checkbox value="Account">Account</Checkbox>
                <Checkbox value="Channels">Channels</Checkbox>
              </CheckboxGroup>
              <div className="d-flex">
                <button
                  className="bt-secondary"
                  onClick={() => filterRef.current.toggle()}
                >
                  Close
                </button>
                <button className="bt-primary" onClick={handleSetFilters}>
                  Apply
                </button>
              </div>
            </div>
          </Dropdown>
        </div>
        <div className="JobsOverview_body_top_btn">
          <img
            src="/assets/bg/cal.png"
            onClick={() => dateRangePickerRef.current.open()}
          />
          <DateRangePicker
            placement="auto"
            ref={dateRangePickerRef}
            style={{ width: 0, height: 0, visibility: "hidden" }}
            onChange={handleSetDateRanges}
          />
        </div>
      </div>
      <div className="table-head-icon1">
        <div className="JobsOverview_body_top_search">
          <div className="searchIcon">
            <FiSearch />
          </div>
          <input
            type="text"
            className="joboverview-searchInput"
            placeholder="Search..."
            value={props.searchText}
            onChange={(e) => props.setSearchText(e.target.value)}
          />
        </div>
        <div className="JobsOverview_body_top_btn blue_btn">
          <FiPlus />
        </div>
        <div>
          <Dropdown
            trigger={["click"]}
            className="actionDropPageHeader export"
            placement="bottomEnd"
            renderTitle={() => (
              <FiMoreVertical
                style={{ color: "#304a50", fontSize: 20, cursor: "pointer" }}
              />
            )}
          >
            <div
              className="dropDownRowSpaced"
              style={{ border: 0 }}
              onClick={() => props.handleExportCSV()}
            >
              <div className="dropDownRowSpaced-option">
                <FiUpload />
                <h6>Export to Excel</h6>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  activeJobFilters: {},
});
const mapDispatchToProps = {
  activeJobSetFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionFilterComponent);
