import React, { useState } from "react";
import { Toggle, InputGroup, Input, Dropdown } from "rsuite";

// icons
import {
  FiChevronRight,
  FiChevronLeft,
  FiPrinter,
  FiSearch,
} from "react-icons/fi";
import { FaRunning, FaPrint ,FaEnvelopeOpenText} from "react-icons/fa";
import {IoReceipt} from 'react-icons/io5';
import {BsFillCheckCircleFill} from 'react-icons/bs'

// component
import SelectedServicesSection from "../selectedServicesSection/SelectedServicesSection";

const NewStep7: React.FC<{      
  ChangeStep: Function;
  ChangeStepBack: Function;
}> = ({ ChangeStep, ChangeStepBack }) => {

  const [selectedPrint, setSelectedPrint] = useState<string>("");

  // const handlePrint =() =>{
  //   setSelect(true)
  // }
  
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper start">
          <SelectedServicesSection />
          <div className="stepFormWrapper-right">
            <h4 style={{ textAlign: "center", marginBottom: 10 }}>
              Print Options
            </h4>
            <>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <div
                  id="1"
                    className={`s9-item ${selectedPrint == "1" ? "activePrint" : ""}`}
                    onClick={(e : any) => {
                      ChangeStep();
                      setSelectedPrint(e.target.id)
                    }}
                  >
                    <div className="s9-item_icon">
                    <IoReceipt/>
                    </div>
                    <p>A4 Receipt</p>
                  <div className='check-active'>
                    <BsFillCheckCircleFill/>
                  </div>
                  </div>
                </div>
                <div className="col">
                  <div
                  id="2"
                  className={`s9-item ${selectedPrint == "2" ? "activePrint" : ""}`}
                    onClick={(e : any) => {
                      ChangeStep();
                      setSelectedPrint(e.target.id)
                    }}
                  >
                    <div className="s9-item_icon">
                      <FaEnvelopeOpenText />
                    </div>
                    <p>Thermal Receipt</p>
                    <div className="check-active">
                    <BsFillCheckCircleFill/>
                  </div>
                  </div>
                </div>
              </div>

              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <div className="stepFormWrapper-right-search">
                    <div className="flex-1">
                      Print device label on the next step
                    </div>
                    <Toggle style={{ marginLeft: 10 }} />
                  </div>
                </div>
              </div>
            </>
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className={selectedPrint ? "bt-gray-btn": "btn-gray-active" }
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                 Create Job & Print
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default NewStep7;
