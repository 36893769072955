//types
import CustomerTypes from "./CustomerContact.types"
export const BuildCustomerSearchQuery = (value: string, queryCondition: any) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ first_name: { _ilike: `%${value}%` } },
				{ last_name: { _ilike: `%${value}%` } },
				{ customer_number: { _ilike: `%${value}%` } },
				{ type: { _ilike: `%${value}%` } },
				{ emails: { _contains: [{"email": value}] } },
				{ phones: { _contains: [{"phone": value}] } },
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildContactExportColumns = () =>{
	return [{
		label:"Customer No.",
		value: "customer_number"
	},{
		label:"First Name",
		value: "first_name"
	},{
		label:"Last Name",
		value: "last_name"
	},{
		label:"Emails",
		value: "emails"
	},{
		label:"Phones",
		value: "phones"
	},{
		label:"Type",
		value: "type"
	},{
		label:"Organization",
		value: "organization"
	}]
}
export const BuildContactExportData = (items: CustomerTypes[]) =>{
	let data:any[] = []
	if(Array.isArray(items)){
		data = items.map((item: CustomerTypes) => {
			let emails = item.emails.map((email:any)=> email.email)
			let phones = item.phones.map((phone:any)=> phone.phone)
			return {
				customer_number: item.customer_number,
				first_name: item.first_name,
				last_name: item.last_name,
				emails: emails.length > 0 ? emails.join(", ") : "N/A",
				phones: phones.length > 0 ? phones.join(", ") : "N/A",
				type: item.type,
				organization: item.organization
			}
		})
	}
	return data;
}
export const BuildContactSortQuery = (value: string) => {
	switch(value){
		case "numeric_asc":
			return {customer_number: "asc"}
		case "numeric_desc":
			return {customer_number: "desc"}
		case "alpha_asc":
			return {first_name: "asc"}
		case "alpha_desc":
			return {first_name: "desc"}
	}
}
export const BuildContactFilterQuery = (filterValue: any, queryCondition: any) => {
	if (Object.keys(filterValue).length) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		if (filterValue?.hasOwnProperty("type") && filterValue?.type?.length) {
			conditionTemp = {
				...conditionTemp,
				type: {_eq: filterValue.type}
			}
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}