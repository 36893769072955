import React, { useState } from "react";
import {v4 as uuid} from "uuid";
// icons
import { FiUploadCloud } from "react-icons/fi";

// component
import StockItemsModalImages from "./StockItemsModal.images";

//types
import { StockImageType } from "./StockItems.type";

const StockItemsModalImagesWrapper: React.FC<{
  images: StockImageType[];
  files: File[];
  onFileChange: Function;
  onChange: Function;
}> = ({ images, files, onFileChange, onChange }) => {
  const handleChangeFile = (event: any) =>{
    const _file: File = event.target.files[0];
    onFileChange([...files, _file]);

    let _images: StockImageType[] = [...images];
    _images.push({
      id: uuid(),
      link: URL.createObjectURL(_file),
      favourite: false,
      name: `Image ${_images.length + 1}`,
    })
    onChange(_images)
  }
  const handleRemoveFile = (id: string, index: number) =>{
    let _images: StockImageType[] = [...images];
    _images = _images.filter((image: StockImageType) => image.id !== id)
    onChange(_images)

    let _files: File[] = [...files];
    if(_files.length && _files[index]){
      _files.splice(index, 1);
      onFileChange(_files);
    }
  }
  const handleFavouriteToggle = (id: string) => {
    let _images = JSON.parse(JSON.stringify(images));
    let index = images.findIndex((image: StockImageType) => image.id === id)
    _images.map((image: StockImageType) => {
      image.favourite = false
    })
    _images[index].favourite = !_images[index].favourite
    onChange(_images)
  }
  return (
    <>
      <div className="StockItemsModal_inner_flex_start">
        <div className="col">
          <h5 className="StockItemsModal_tab_body_header">Upload Images</h5>
        </div>
      </div>
      <div
        className="StockItemsModal_inner_flex_start"
        style={{ flexWrap: "wrap" }}
      >
        <label htmlFor="stock_image_input">
          <div className="StockItemsModal_inner_upload">
            <FiUploadCloud />
          </div>
        </label>
        <input
          type="file"
          style={{ display: "none" }}
          id="stock_image_input"
          onChange={handleChangeFile}
          accept="image/jpeg, image/png, image/gif, image/webp, image/jpg"
        />
        {images.map((image: StockImageType, i: number) => (
          <StockItemsModalImages
            key={i}
            image={image} 
            onRemove={()=>handleRemoveFile(image.id, i)}
            onFavouriteToggle={handleFavouriteToggle}
          />
        ))}
      </div>
    </>
  );
};

export default StockItemsModalImagesWrapper;
