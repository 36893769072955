import React, { useState } from "react";
import { Toggle, InputGroup, Input, Dropdown } from "rsuite";
// icons
import {
  FiChevronRight,
  FiChevronLeft,
  FiCheck,
  FiX,
  FiSearch,
} from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";
import SelectedServicesSection from "../selectedServicesSection/SelectedServicesSection";

const NewStep4: React.FC<{
  ChangeStep: Function;
  ChangeStepBack: Function;
}> = ({ ChangeStep, ChangeStepBack }) => {

  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper start">
          <SelectedServicesSection />
          <div className="stepFormWrapper-right">
            <>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <InputBox labelText="Defect" handleChange={() => null} />
                </div>
              </div>
              <div className="JobModal_spaced">
                <h4>Suggested Items:</h4>
                <div className="JobModal_search JobsOverview_body_top_search">
                  <input type="text" placeholder="Search & Add Items" />
                  <FiSearch />
                </div>
              </div>
              <div className="">
                <div className="JobModal_item">
                  <div className="JobModal_item_left">
                    <h5>Apple iPhone SE 2020 LCD Black</h5>
                    <div className="JobModal-bodyInner-flex-start">
                      <h6>Item No. : 12353 </h6>
                      <h6> Location : 5B </h6>
                      <h6 style={{ borderRight: 0 }}> Stock Level : 15Pcs </h6>
                    </div>
                  </div>
                  <div className="JobModal_item_right">
                    <div className="JobModal_item_right_Reserved">
                      Reserved <FiX style={{ color: "#fff" }} />
                    </div>
                  </div>
                </div>
                <div className="JobModal_item">
                  <div className="JobModal_item_left">
                    <h5>Apple iPhone SE 2020 LCD Black</h5>
                    <div className="JobModal-bodyInner-flex-start">
                      <h6>Item No. : 12353 </h6>
                      <h6> Location : 5B </h6>
                      <h6 style={{ borderRight: 0 }}> Stock Level : 15Pcs </h6>
                    </div>
                  </div>
                  <div className="JobModal_item_right">
                    <div className="botn">
                      <FiCheck style={{ color: "#0f0" }} />
                    </div>
                    <div className="botn">
                      <FiX style={{ color: "#f00" }} />
                    </div>
                  </div>
                </div>
                <div className="JobModal_row">
                  <div className="col">
                    <div style={{ color: "#2589f6", textAlign: "right" }}>
                      1 Item(s) has been reserved for this job
                    </div>
                  </div>
                </div>
              </div>
              <div className="JobModal_row">
                <div className="col"></div>
              </div>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <TextAreaBox
                    labelText="Problem Description"
                    handleChange={(value) => { }}
                    valueProp={""}
                  />
                </div>
              </div>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <TextAreaBox
                    labelText="Internal Note"
                    handleChange={(value) => { }}
                    valueProp={""}
                  />
                </div>
              </div>
            </>
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default NewStep4;
