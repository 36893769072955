import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ChatBoxLeftChatCallList: React.FC<{}> = ({}) => {
  return (
    <div
      className="DetailModal-main-middle-view-chat-right-body-left callList "
      style={{ backgroundColor: "#C4C4C440" }}
    >
      <div className="DetailModal-main-middle-view-chat-right-body-left-dp">
        <div className="DetailModal-main-middle-view-chat-right-body-left-dp-circle">
          <img src="/assets/bg/called.png" alt="" />P
        </div>
      </div>
      {/* <div className=""> */}
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <h6>
          Jane Doe <span className="muted_text">Mobile</span>{" "}
          <span className="blue_text">+91 7002555843</span>{" "}
        </h6>

        <div>
          <h5>Yesterday, 12:39</h5>
          <p>
            08min. 12sec
          </p>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ChatBoxLeftChatCallList;
