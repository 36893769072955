import React, { useState } from "react";
import "../../../pages/services/Services.scss";
import { Placeholder } from "rsuite";

//helpers
import { showAlert } from "../../../helpers";

// component
import ChangePasswordModal from "../changePasswordModal/ChangePasswordModal";
import ChangeEmailModal from "../changeEmailModal/ChangeEmailModal";

//redux
import { useSelector } from "react-redux";
import { profileState } from "../../../toolkit/profile/profile.slice";
import { authState } from "../../../toolkit/auth/auth.slice";
import { uploadImageApi } from "../../../toolkit/companySettings/companySettings.api";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { UPDATE_PROFILE_MUTATION } from "../../../gql/profile/mutation";

const ProfileGeneralDetailsStep2 = () => {
  const { Paragraph } = Placeholder;
  const { user, isLoading } = useSelector(profileState).profile;
  const { token } = useSelector(authState).auth;
  const [updateProfileMutation] = useMutation(UPDATE_PROFILE_MUTATION);
  const [chnagePassword, setChangePassword] = useState<boolean>(false);
  const [chnageEmail, setChangeEmail] = useState<boolean>(false);
  const [preview, setPreview] = useState<string | undefined>();

  const handleChangeFile = async (file: File) => {
    setPreview(URL.createObjectURL(file));
    let avatar = user.avatar;
    let formdata = new FormData();
    formdata.append("uploads", file);
    const resp: any = await uploadImageApi(formdata, token);
    if (Array.isArray(resp.data?.uploaded_files) && resp.data?.uploaded_files?.length) {
      avatar = resp.data.uploaded_files[0]?.uploaded_file_url;
    }
    const { id, role, created_at, ...object } = user;
    await updateProfileMutation({
      variables: {
        object: {
          ...object,
          avatar
        },
        id: user.id
      }
    })
    showAlert({
      title: "Success!",
      message: `Profile picture saved successfully!`,
      type: "success",
    });
  }
  return (
    <>
      {isLoading ? (
        <Paragraph
          rows={15}
          rowMargin={8}
          active
          rowHeight={14}
          style={{marginTop: 10}}
        />
      ) : (
        <>
          <div className="ProfileGeneralDetails_details center">
            <label htmlFor="avatar">
              <img src={user.avatar ? user.avatar : preview ? preview : "/assets/bg/ppimg.png"} alt="" />
            </label>
            <input
              type="file"
              id="avatar"
              style={{ display: "none" }}
              onChange={(e: any) => handleChangeFile(e.target.files[0])}
            />
            <h5>{user.name}</h5>
            <h5>{user.email}</h5>
            <p>{user.position}</p>
          </div>
          <div className="flex-row">
            <div
              className="flex-row_col"
              onClick={() => {
                setChangeEmail(true);
              }}
            >
              <div className="bt-primary">Change Email</div>
            </div>
            <div
              className="flex-row_col"
              onClick={() => {
                setChangePassword(true);
              }}
            >
              <div className="bt-primary">Change Password</div>
            </div>
          </div>

          {chnagePassword == true && (
            <ChangePasswordModal
              close={() => {
                setChangePassword(false);
              }}
            />
          )}
          {chnageEmail == true && (
            <ChangeEmailModal
              close={() => {
                setChangeEmail(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfileGeneralDetailsStep2;
