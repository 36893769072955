import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { Dropdown, ButtonGroup, Button, InputGroup, Input } from "rsuite";
import {
  FiTrash2,
  FiPlus,
  FiMenu,
  FiX,
  FiInfo,
  FiEdit,
  FiSearch,
} from "react-icons/fi";

//
import NewTemplate from "../newTemplate/NewTemplate";

// compoent
import ChatBoxWrapper from "../component/chatBox/ChatBoxWrapper";

//
import Header from "../component/services/Header";
import Body from "../component/services/Body";

const ServiceReceipt: React.FC<{ goback: any }> = ({ goback }) => {
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [chat, setChat] = useState(false);
  const [textTemplateModal, setTextTemplateModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [infoModal1, setInfoModal1] = useState(false);
  const [taxType, setTaxType] = useState("netto");

  return (
    <div className="DetailModal-main-middle">
      {/* main view */}
      <div className="DetailModal-main-middle-view">
        {/* main view header */}
        <Header
          back={goback}
          name={"Service Receipt"}
          chatToggle={() => {
            setChat(!chat);
          }}
        />
        {openNewTemplate && (
          <NewTemplate close={() => setOpenNewTemplate(false)} />
        )}
        {/* main view header */}
        {/* main view body */}
        <Body>
          <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col-row-end mbCenter">
                <img
                  src="/assets/bg/ilogo.png"
                  alt=""
                  className="DetailModal-main-middle-view-body-header-img"
                />
              </div>
            </div>
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col">
                <div className="DetailModal-main-middle-view-body-details">
                  <h6 className="DetailModal-main-middle-view-body-details-small-text">
                    Muller GmbH - PiusstraBe 123 - 50823 Koln
                  </h6>
                  <div className="d-flex-start">
                    <h4 className="DetailModal-main-middle-view-body-details-text">
                      Broadway Company Limited
                    </h4>
                    <div className="DetailModal-main-middle-view-body-details-text-btn">
                      <FiEdit />
                    </div>
                  </div>
                  <h4 className="DetailModal-main-middle-view-body-details-text">
                    Frau Andrea Stock
                  </h4>
                  <h4 className="DetailModal-main-middle-view-body-details-text">
                    DornstraBe 17
                  </h4>
                  <h4 className="DetailModal-main-middle-view-body-details-text">
                    50769 Koln
                  </h4>
                </div>
              </div>
            </div>
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col">
                <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                  User : Shankhajeet Taran
                </h4>
                <h6 className="DetailModal-main-middle-view-body-details-small-text text-right">
                  18.06.2021 23:50
                </h6>
              </div>
            </div>
            <div
              className="DetailModal-main-middle-view-body-row"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="DetailModal-main-middle-view-body-row-col-row-end">
                <ButtonGroup>
                  <Button
                    color="violet"
                    appearance={taxType === "brutto" ? "default" : "ghost"}
                    onClick={() => setTaxType("brutto")}
                  >
                    Brutto
                  </Button>
                  <Button
                    color="violet"
                    appearance={taxType === "netto" ? "default" : "ghost"}
                    onClick={() => setTaxType("netto")}
                  >
                    Netto
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          {/*  */}
          {/* <div className="divider-horizontal"></div> */}
          {/*  */}

          <div className="desk-only">
            <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
              <div className="DetailModal-main-middle-view-body-row">
                <div className="DetailModal-main-middle-view-body-row-col">
                  <div className="DetailModal-main-middle-view-body-row table-header ">
                    <div className="DetailModal-main-middle-view-body-row-col-2">
                      <h4 className="DetailModal-main-middle-view-body-details-text-white">
                        Description
                      </h4>
                    </div>
                    <div className="DetailModal-main-middle-view-body-row-col">
                      <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                        Quantity
                      </h4>
                    </div>
                    <div className="DetailModal-main-middle-view-body-row-col">
                      <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                        Price (Netto)
                      </h4>
                    </div>
                    <div className="DetailModal-main-middle-view-body-row-col">
                      <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                        MwSt.
                      </h4>
                    </div>
                    <div className="DetailModal-main-middle-view-body-row-col">
                      <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                        Amount
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
              <div className="DetailModal-main-middle-view-body-row">
                <button className="btn btn-light btn-onHoverInput starting">
                  <FiMenu />
                </button>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col-2">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder=""
                      value="iPhone X LCD"
                    />
                    <div
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        position: "absolute",
                        right: 15,
                        top: 17,
                      }}
                    >
                      <FiInfo
                        style={{ color: "#304A50" }}
                        onClick={() => {
                          setInfoModal(!infoModal);
                        }}
                      />
                    </div>
                    {infoModal && (
                      <>
                        <div
                          className="dropDown_wrapper"
                          style={{
                            backgroundColor: "#0000",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={() => {
                            setInfoModal(false);
                          }}
                        ></div>
                        <div
                          className="dropDown_container"
                          style={{ padding: "12px 15px", top: 40, width: 150 }}
                        >
                          <div
                            className="d-flex justify-content-between"
                            style={{ justifyContent: "spaced-between" }}
                          >
                            <p>Stock Qty </p>
                            <p
                              style={{
                                margin: "0px 5px",
                                padding: 5,
                                backgroundColor: "#697C80",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                            >
                              12
                            </p>
                          </div>
                          <hr style={{ margin: "15px 0" }} />
                          <div
                            className="d-flex justify-content-between"
                            style={{ justifyContent: "spaced-between" }}
                          >
                            <p>Stock Location </p>
                            <p
                              style={{
                                margin: "0px 5px",
                                padding: 5,
                                backgroundColor: "#697C80",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                            >
                              B22
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="number"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="20"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row">
                      <input
                        type="text"
                        className="form-control DetailModal-main-middle-view-body-input"
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
                <button className="btn-light btn-onHoverInput ending">
                  <FiTrash2 />
                </button>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
              <div className="DetailModal-main-middle-view-body-row">
                <button className="btn btn-light btn-onHoverInput starting">
                  <FiMenu />
                </button>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col-2">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder=""
                      value=""
                    />
                    <div
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        position: "absolute",
                        right: 15,
                        top: 17,
                      }}
                    >
                      <FiInfo style={{ color: "#304A5000" }} />
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="number"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="20"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row">
                      <input
                        type="text"
                        className="form-control DetailModal-main-middle-view-body-input"
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
                <button className="btn-light btn-onHoverInput ending">
                  <FiTrash2 />
                </button>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
              <div className="DetailModal-main-middle-view-body-row">
                <button className="btn btn-light btn-onHoverInput starting">
                  <FiMenu />
                </button>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input-area"
                      placeholder=""
                    />
                    <span
                      onClick={() => {
                        setTextTemplateModal(true);
                      }}
                      style={{
                        position: "absolute",
                        top: "10%",
                        right: "2%",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src="/assets/bg/textTemplates.png"
                        alt=""
                        style={{
                          width: 25,
                          height: 25,
                          objectFit: "contain",
                        }}
                      />
                    </span>
                    {textTemplateModal && (
                      <>
                        <div
                          className="dropDown_wrapper"
                          onClick={() => {
                            setTextTemplateModal(false);
                          }}
                        ></div>
                        <div
                          className="w-280 dropDown_container"
                          style={{ padding: "5px 15px" }}
                        >
                          <div className="d-flex" style={{ marginTop: 10 }}>
                            <div className="search_wrapper">
                              <input
                                type="text"
                                placeholder="Search"
                                className="search_wrapper_input"
                                onClick={() => {
                                  setTextTemplateModal(true);
                                }}
                                style={{ margin: "0 10px" }}
                              />
                              <div className="bt-search">
                                <FiSearch />
                              </div>
                            </div>
                            <div
                              className="bt-plus"
                              onClick={() => {
                                setTextTemplateModal(false);
                                setOpenNewTemplate(!openNewTemplate);
                              }}
                            >
                              <FiPlus />
                            </div>
                          </div>
                          <hr />
                          <div
                            className="dropDownProfile-Link w-100"
                            style={{ border: 0 }}
                          >
                            <span>Lorem Ipsum is simply dummy</span>
                          </div>
                          <div
                            className="dropDownProfile-Link w-100"
                            style={{ border: 0 }}
                          >
                            <span>Lorem Ipsum is simply</span>
                          </div>
                          <div
                            className="dropDownProfile-Link w-100"
                            style={{ border: 0 }}
                          >
                            <span>Lorem Ipsum is simply dummy text </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <button className="btn-light btn-onHoverInput ending">
                  <FiTrash2 />
                </button>
              </div>
            </div>
            {/*  */}
          </div>

          <div className="mob-only">
            <div style={{ marginBottom: 20 }}>
              <div className="DetailModal-main-middle-view-body-serviceReciptContainer      mobile">
                <button className="btn btn-light btn-onHoverInput starting">
                  <FiMenu />
                </button>
                <button className="btn-light btn-onHoverInput ending">
                  <FiTrash2 />
                </button>

                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row table-header ">
                      <div className="DetailModal-main-middle-view-body-row-col-2">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white">
                          Description
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col-2">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder=""
                      value="iPhone X LCD"
                    />
                    <div
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        position: "absolute",
                        right: 3,
                        top: 5,
                      }}
                    >
                      <FiInfo
                        style={{ color: "#304A50" }}
                        onClick={() => {
                          setInfoModal(!infoModal);
                        }}
                      />
                    </div>
                    {infoModal && (
                      <>
                        <div
                          className="dropDown_wrapper"
                          style={{
                            backgroundColor: "#0000",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={() => {
                            setInfoModal(false);
                          }}
                        ></div>
                        <div
                          className="dropDown_container"
                          style={{ padding: "12px 15px", top: 40, width: 150 }}
                        >
                          <div
                            className="d-flex justify-content-between"
                            style={{ justifyContent: "spaced-between" }}
                          >
                            <p>Stock Qty </p>
                            <p
                              style={{
                                margin: "0px 5px",
                                padding: 5,
                                backgroundColor: "#697C80",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                            >
                              12
                            </p>
                          </div>
                          <hr style={{ margin: "15px 0" }} />
                          <div
                            className="d-flex justify-content-between"
                            style={{ justifyContent: "spaced-between" }}
                          >
                            <p>Stock Location </p>
                            <p
                              style={{
                                margin: "0px 5px",
                                padding: 5,
                                backgroundColor: "#697C80",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                            >
                              B22
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row table-header ">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white">
                          Quantity
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                          Price (Netto)
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                          MwSt.
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                          Amount
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="number"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="20"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row">
                      <input
                        type="text"
                        className="form-control DetailModal-main-middle-view-body-input"
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div style={{ marginBottom: 20 }}>
              <div className="DetailModal-main-middle-view-body-serviceReciptContainer      mobile">
                <button className="btn btn-light btn-onHoverInput starting">
                  <FiMenu />
                </button>
                <button className="btn-light btn-onHoverInput ending">
                  <FiTrash2 />
                </button>

                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row table-header ">
                      <div className="DetailModal-main-middle-view-body-row-col-2">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white">
                          Description
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col-2">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder=""
                      value="iPhone X LCD"
                    />
                    <div
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        position: "absolute",
                        right: 3,
                        top: 5,
                      }}
                    >
                      <FiInfo
                        style={{ color: "#304A50" }}
                        onClick={() => {
                          setInfoModal1(!infoModal1);
                        }}
                      />
                    </div>
                    {infoModal1 && (
                      <>
                        <div
                          className="dropDown_wrapper"
                          style={{
                            backgroundColor: "#0000",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={() => {
                            setInfoModal1(false);
                          }}
                        ></div>
                        <div
                          className="dropDown_container"
                          style={{ padding: "12px 15px", top: 40, width: 150 }}
                        >
                          <div
                            className="d-flex justify-content-between"
                            style={{ justifyContent: "spaced-between" }}
                          >
                            <p>Stock Qty </p>
                            <p
                              style={{
                                margin: "0px 5px",
                                padding: 5,
                                backgroundColor: "#697C80",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                            >
                              12
                            </p>
                          </div>
                          <hr style={{ margin: "15px 0" }} />
                          <div
                            className="d-flex justify-content-between"
                            style={{ justifyContent: "spaced-between" }}
                          >
                            <p>Stock Location </p>
                            <p
                              style={{
                                margin: "0px 5px",
                                padding: 5,
                                backgroundColor: "#697C80",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                            >
                              B22
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row table-header ">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white">
                          Quantity
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                          Price (Netto)
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                          MwSt.
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                          Amount
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="number"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="0"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <input
                      type="text"
                      className="form-control DetailModal-main-middle-view-body-input"
                      placeholder="20"
                    />
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row">
                      <input
                        type="text"
                        className="form-control DetailModal-main-middle-view-body-input"
                        placeholder="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div style={{ marginBottom: 20 }}>
              <div className="DetailModal-main-middle-view-body-serviceReciptContainer      mobile">
                <button className="btn btn-light btn-onHoverInput starting">
                  <FiMenu />
                </button>
                <button className="btn-light btn-onHoverInput ending">
                  <FiTrash2 />
                </button>

                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="DetailModal-main-middle-view-body-row-col">
                      <input
                        type="text"
                        className="form-control DetailModal-main-middle-view-body-input-area"
                        placeholder=""
                      />
                      <span
                        onClick={() => {
                          setTextTemplateModal(true);
                        }}
                        style={{
                          position: "absolute",
                          top: "10%",
                          right: "2%",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="/assets/bg/textTemplates.png"
                          alt=""
                          style={{
                            width: 25,
                            height: 25,
                            objectFit: "contain",
                          }}
                        />
                      </span>
                      {textTemplateModal && (
                        <>
                          <div
                            className="dropDown_wrapper"
                            onClick={() => {
                              setTextTemplateModal(false);
                            }}
                          ></div>
                          <div
                            className="w-280 dropDown_container"
                            style={{ padding: "5px 15px" }}
                          >
                            <div className="d-flex" style={{ marginTop: 10 }}>
                              <div className="search_wrapper">
                                <input
                                  type="text"
                                  placeholder="Search"
                                  className="search_wrapper_input"
                                  onClick={() => {
                                    setTextTemplateModal(true);
                                  }}
                                  style={{ margin: "0 10px" }}
                                />
                                <div className="bt-search">
                                  <FiSearch />
                                </div>
                              </div>
                              <div
                                className="bt-plus"
                                onClick={() => {
                                  setTextTemplateModal(false);
                                  setOpenNewTemplate(!openNewTemplate);
                                }}
                              >
                                <FiPlus />
                              </div>
                            </div>
                            <hr />
                            <div
                              className="dropDownProfile-Link w-100"
                              style={{ border: 0 }}
                            >
                              <span>Lorem Ipsum is simply dummy</span>
                            </div>
                            <div
                              className="dropDownProfile-Link w-100"
                              style={{ border: 0 }}
                            >
                              <span>Lorem Ipsum is simply</span>
                            </div>
                            <div
                              className="dropDownProfile-Link w-100"
                              style={{ border: 0 }}
                            >
                              <span>Lorem Ipsum is simply dummy text </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
          <div className="desk-only">
            <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
              <div className="DetailModal-main-middle-view-body-row mob-row-column ">
                <div className="DetailModal-main-middle-view-body-row-col-2">
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader w-100"
                    placement="bottomEnd"
                    renderTitle={() => (
                      <button className="DetailModal-main-middle-view-body-btn-plus">
                        <FiPlus />
                      </button>
                    )}
                  >
                    <div className="w-280">
                      <div className="dropDownProfile-Link w-100">
                        <span>Service</span>
                      </div>
                      <div className="dropDownProfile-Link w-100">
                        <span>Items</span>
                      </div>
                      <div
                        className="dropDownProfile-Link w-100"
                        style={{ border: 0 }}
                      >
                        <span>Text</span>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <div className="DetailModal-main-middle-view-body-subtotal">
                    <div className="DetailModal-main-middle-view-body-row">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text">
                          Subtotal
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                          0.00 EUR
                        </h4>
                      </div>
                    </div>
                    <div
                      className="DetailModal-main-middle-view-body-row"
                      style={{ marginTop: 15 }}
                    >
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text">
                          <span
                            className="DetailModal-card-Link"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setDiscountModal(true);
                            }}
                          >
                            Add discount
                          </span>
                        </h4>
                        {discountModal && (
                          <>
                            <div
                              className="dropDown_wrapper"
                              onClick={() => {
                                setDiscountModal(false);
                              }}
                            ></div>
                            <div
                              className="dropDown_container"
                              style={{ padding: "5px 15px", top: 20, left: 0 }}
                            >
                              <h6 className="text-center">Discount</h6>
                              <InputGroup style={{ margin: "10px 0" }}>
                                <Input />
                                <InputGroup.Addon
                                  style={{ border: "1px solid #0001" }}
                                >
                                  %
                                </InputGroup.Addon>
                              </InputGroup>
                              <div className="bt-primary">Add</div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                          0.00 EUR
                        </h4>
                      </div>
                    </div>
                    <div className="DetailModal-main-middle-view-body-row">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text">
                          incl. MwSt. 20.00%
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                          0.00 EUR
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="DetailModal-main-middle-view-body-subtotal  "
                    style={{
                      marginTop: 15,
                      border: "1px  solid rgba(39, 51, 51, 0.25)",
                    }}
                  >
                    <div className="DetailModal-main-middle-view-body-row">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text due-amount">
                          Total Amount
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right due-amount">
                          120,00 EUR
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-only">
            <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
              <div
                className="DetailModal-main-middle-view-body-row mob-row-column "
                style={{
                  background: "#fff",
                  boxShadow: "0px 0px 5px 1px #0003",
                  width: "100vw",
                  marginLeft: -17,
                  padding: 17,
                }}
              >
                <div className="DetailModal-main-middle-view-body-row-col-2">
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader w-100"
                    placement="bottomEnd"
                    renderTitle={() => (
                      <button className="DetailModal-main-middle-view-body-btn-plus">
                        <FiPlus />
                      </button>
                    )}
                  >
                    <div className="w-280">
                      <div className="dropDownProfile-Link w-100">
                        <span>Service</span>
                      </div>
                      <div className="dropDownProfile-Link w-100">
                        <span>Items</span>
                      </div>
                      <div
                        className="dropDownProfile-Link w-100"
                        style={{ border: 0 }}
                      >
                        <span>Text</span>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <div className="DetailModal-main-middle-view-body-subtotal">
                    <div className="DetailModal-main-middle-view-body-row">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text">
                          Subtotal
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                          0.00 EUR
                        </h4>
                      </div>
                    </div>
                    <div
                      className="DetailModal-main-middle-view-body-row"
                      style={{ marginTop: 15 }}
                    >
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text">
                          <span
                            className="DetailModal-card-Link"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setDiscountModal(true);
                            }}
                          >
                            Add discount
                          </span>
                        </h4>
                        {discountModal && (
                          <>
                            <div
                              className="dropDown_wrapper"
                              onClick={() => {
                                setDiscountModal(false);
                              }}
                            ></div>
                            <div
                              className="dropDown_container"
                              style={{ padding: "5px 15px", top: 20, left: 0 }}
                            >
                              <h6 className="text-center">Discount</h6>
                              <InputGroup style={{ margin: "10px 0" }}>
                                <Input />
                                <InputGroup.Addon
                                  style={{ border: "1px solid #0001" }}
                                >
                                  %
                                </InputGroup.Addon>
                              </InputGroup>
                              <div className="bt-primary">Add</div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                          0.00 EUR
                        </h4>
                      </div>
                    </div>
                    <div className="DetailModal-main-middle-view-body-row">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text">
                          incl. MwSt. 20.00%
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                          0.00 EUR
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="DetailModal-main-middle-view-body-subtotal  "
                    style={{
                      marginTop: 15,
                      border: "1px  solid rgba(39, 51, 51, 0.25)",
                    }}
                  >
                    <div className="DetailModal-main-middle-view-body-row">
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text due-amount">
                          Total Amount
                        </h4>
                      </div>
                      <div className="DetailModal-main-middle-view-body-row-col">
                        <h4 className="DetailModal-main-middle-view-body-details-text text-right due-amount">
                          120,00 EUR
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {chat && (
            <div className="DetailModal-main-middle-view-body-chat-modal">
              <div className="d-flex-center ">
                <div className="DetailModal-main-middle-view-body-chat-modal-sending">
                  Sending Quotation
                </div>
              </div>
              <div
                className="DetailModal-main-middle-view-body-chat-modal-close"
                onClick={() => {
                  setChat(!chat);
                }}
              >
                <FiX />
              </div>
              {/* chat */}
              <ChatBoxWrapper />
              {/* chat */}
            </div>
          )}
        </Body>
        {/* main view body */}
      </div>
    </div>
  );
};

export default ServiceReceipt;
