import React, { useEffect, useState } from "react";
import "../../../pages/services/Services.scss";
import { v4 as uuid } from "uuid";

// component
import SelectBox from "../../template/selectBox/SelectBox";

//constants
import { curencies, timezones, date_formats, time_formats, languages } from "../../../constants/";

//helpers
import { showAlert } from "../../../helpers";

//redux
import { useSelector } from "react-redux";
import { profileState } from "../../../toolkit/profile/profile.slice";

//graphql
import { useMutation } from "@apollo/client";
import { CREATE_PROFILE_SETTINGS_MUTATION, UPDATE_PROFILE_SETTINGS_MUTATION } from "../../../gql/profileSettings/mutation";

const ProfileGeneralLanguageStep1: React.FC<{ twickEdit: any, refetch: Function }> = ({ twickEdit, refetch }) => {
  const { profileSettings } = useSelector(profileState).profile;
  const [createProfileSettingsMutation] = useMutation(CREATE_PROFILE_SETTINGS_MUTATION);
  const [updateProfileSettingsMutation] = useMutation(UPDATE_PROFILE_SETTINGS_MUTATION);

  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");
  const [selected3, setSelected3] = useState("");
  const [selected4, setSelected4] = useState("");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const handleSaveProfileSettings = async () => {
    try {
      setBtnLoading(true);
      if (profileSettings.id) { //update
        const { id, created_by, created_at, ...object } = profileSettings
        await updateProfileSettingsMutation({
          variables: {
            object: {
              language: selected,
              timezone: selected1,
              date_format: selected2,
              time_format: selected3,
              currency: selected4
            },
            id
          }
        })
      } else { //insert
        await createProfileSettingsMutation({
          variables: {
            object: {
              language: selected,
              timezone: selected1,
              date_format: selected2,
              time_format: selected3,
              currency: selected4,
              id: uuid(),
              // created_by: user.id
            }
          }
        })
      }
      refetch();
      twickEdit();
      showAlert({
        title: "Success!",
        message: `Settings ${profileSettings.id ? "updated" : "created"} successfully.`,
        type: "success",
      });
    } catch (err) {
      showAlert({
        title: "Error!",
        message: "Something went wrong",
        type: "danger",
      });
    } finally {
      setBtnLoading(false);
    }
  }
  useEffect(()=>{
    setSelected(profileSettings.language)
    setSelected1(profileSettings.timezone)
    setSelected2(profileSettings.date_format)
    setSelected3(profileSettings.time_format)
    setSelected4(profileSettings.currency)
  }, [profileSettings])
  return (
    <div className="ProfileGeneralDetails_details">
      <div className="flex-row">
        <div className="flex-row_col">
          <SelectBox selectedValue={selected} labelText="Language">
            {languages.map((language, i) => (
              <div
                key={i}
                className=""
                onClick={() => {
                  setSelected(language.value);
                }}
              >
                {language.text}
              </div>
            ))}
          </SelectBox>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-row_col">
          <SelectBox
            selectedValue={selected1}
            labelText="Time Zone"
          >
            {timezones.map((timezone, i) => (
              <div
                className=""
                onClick={() => {
                  setSelected1(timezone.abbr);
                }}
              >
                {timezone.value}
              </div>
            ))}
          </SelectBox>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-row_col">
          <SelectBox selectedValue={selected2} labelText="Date Format (/.)">
            {date_formats.map((dateFormat, i) => (
              <div
                className=""
                onClick={() => {
                  setSelected2(dateFormat.value);
                }}
                key={i}
              >
                {dateFormat.value}
              </div>
            ))}
          </SelectBox>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-row_col">
          <SelectBox selectedValue={selected3} labelText="Time Format">
            {time_formats.map((timeFormat, i) => (
              <div
                className=""
                onClick={() => {
                  setSelected3(timeFormat.value);
                }}
                key={i}
              >
                {timeFormat.text}
              </div>
            ))}
          </SelectBox>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-row_col">
          <SelectBox selectedValue={selected4} labelText="Currency">
            {curencies.map((currency, i) => (
              <div
                className=""
                onClick={() => {
                  setSelected4(currency.symbol);
                }}
                key={i}
              >
                {`${currency.cc} (${currency.symbol})`}
              </div>
            ))}
          </SelectBox>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-row_col" onClick={() => { twickEdit() }}>
          <div className="bt-secondary" >Abort</div>
        </div>
        <div className="flex-row_col">
          <button
            className="bt-primary"
            onClick={handleSaveProfileSettings}
            disabled={btnLoading}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileGeneralLanguageStep1;
