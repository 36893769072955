import React, { useEffect, useState } from "react";
import "../../../pages/services/Services.scss";

//helpers
import { showAlert } from "../../../helpers";

// component
import InputBox from "../../template/inputBox/InputBox";

//redux
import { useSelector, useDispatch } from "react-redux";
import { profileState } from "../../../toolkit/profile/profile.slice";
import { authState } from "../../../toolkit/auth/auth.slice";
import { uploadImageApi } from "../../../toolkit/companySettings/companySettings.api";
import { fetchProfileApi } from "../../../toolkit/profile/profile.api";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { UPDATE_PROFILE_MUTATION } from "../../../gql/profile/mutation";

const ProfileGeneralDetailsStep1: React.FC<{ twickEdit: any }> = ({ twickEdit }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(profileState).profile;
  const { token } = useSelector(authState).auth;
  const [updateProfileMutation, { loading }] = useMutation(UPDATE_PROFILE_MUTATION);
  const [preview, setPreview] = useState<string | undefined>();
  const [file, setFile] = useState<any>(null);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    shortName: "",
    position: ""
  })

  const handleChangeFile = (file: File) => {
    setFile(file);
    setPreview(URL.createObjectURL(file));
  }
  const handleUpdateProfile = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    try {
      let avatar = user.avatar;
      if(file){
        let formdata = new FormData();
        formdata.append("uploads", file);
        const resp: any = await uploadImageApi(formdata, token);
        if (Array.isArray(resp.data?.uploaded_files) && resp.data?.uploaded_files?.length) {
          avatar = resp.data.uploaded_files[0]?.uploaded_file_url;
        }
      }
      const { id, role, created_at, ...object } = user;
      await updateProfileMutation({
        variables: {
          object: {
            ...object,
            avatar,
            name: `${state.firstName} ${state.lastName}`,
            short_name: state.shortName,
            position: state.position
          },
          id: user.id
        }
      })
      dispatch(fetchProfileApi(token));
      showAlert({
        title: "Success!",
        message: `Profile updated successfully!`,
        type: "success",
      });
      twickEdit();
    } catch (err) {
      showAlert({
        title: "Failed!",
        message: `We are unable to update your profile!`,
        type: "danger",
      });
    }
  }
  useEffect(() => {
    if (user?.id) {
      var firstName = user.name.split(' ').slice(0, -1).join(' ');
      var lastName = user.name.split(' ').slice(-1).join(' ');
      setState({
        firstName,
        lastName,
        shortName: user.short_name,
        position: user.position
      })
    }
  }, [user])
  return (
    <>
      <form onSubmit={handleUpdateProfile}>
        <div className="ProfileGeneralDetails_details center">
          <label htmlFor="avatar">
              <img src={preview ? preview : "/assets/bg/ppimg.png"} alt="" />
            </label>
            <input
              type="file"
              id="avatar"
              style={{ display: "none" }}
              onChange={(e: any) => handleChangeFile(e.target.files[0])}
            />

          <div className="flex-row">
            <div className="flex-row_col">
              <InputBox
                labelText="First Name"
                handleChange={(firstName) => setState({ ...state, firstName })}
                valueProp={state.firstName}
                required={true}
              />
            </div>
            <div className="flex-row_col">
              <InputBox
                handleChange={(lastName) => setState({ ...state, lastName })}
                valueProp={state.lastName}
                labelText="Last Name"
                required={true}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="flex-row_col">
              <InputBox
                handleChange={(shortName) => setState({ ...state, shortName })}
                valueProp={state.shortName}
                labelText="Short Name"
              />
            </div>
            <div className="flex-row_col">
              <InputBox
                handleChange={(position) => setState({ ...state, position })}
                valueProp={state.position}
                labelText="Position"
              />
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col" onClick={() => { twickEdit() }} >
            <button className="bt-secondary" type="button">Abort</button>
          </div>
          <div className="flex-row_col">
            <button
              className="bt-primary"
              type="submit"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProfileGeneralDetailsStep1;
