import { gql } from "@apollo/client";

export const CREATE_INVOICE = gql`
  mutation InsertInvoice($objects: [invoices_insert_input!]!) {
    insert_invoices(objects: $objects) {
      returning {
        created_at
        created_by
        customer_id
        discount_type
        discount_value
        id
        invoice_id
        payment_condition
        payment_information
        payment_method
        total_amount
        updated_at
      }
    }
  }
`;

export const CREATE_INVOICE_PRODUCT = gql`
  mutation InsertInvoiceProduct($objects: [invoices_items_map_insert_input!]!) {
    insert_invoices_items_map(objects: $objects) {
      returning {
        id
        created_by
        invoice_id
        unit
        updated_at
        created_at
        item_id
      }
    }
  }
`;

export const CREATE_INVOICE_SERVICES = gql`
  mutation InsertInvoiceServices($object: [invoices_services_map_insert_input!]!) {
    insert_invoices_services_map(objects: $object) {
      returning {
        created_at
        created_by
        id
        invoice_id
        service_id
        unit
        updated_at
      }
    }
  }
`;
