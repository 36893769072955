import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { FiArrowLeftCircle, FiTrash2, FiPlus } from "react-icons/fi";

import RepairFlow from "../repairFlow/RepairFlow";
import FileUpload from "../fileUpload/FileUpload";
import JobType from "../jobType/JobType";
import Reference from "../reference/Reference";
import Express from "../express/Express";
import DeviceSecurity from "../deviceSecurity/DeviceSecurity";
import Notes from "../notes/Notes";

const Information: React.FC<{ goback: any }> = ({ goback }) => {
  return (
    <div className="DetailModal-main-middle">
      {/* main view */}
      <div className="DetailModal-main-middle-view">
        {/* main view header */}
        <div className="DetailModal-main-middle-view-header">
          <div
            className="DetailModal-main-middle-view-header-back"
            onClick={goback}
          >
            <FiArrowLeftCircle />
          </div>
          <div className="DetailModal-main-middle-view-header-title-spaced">
            <div className="DetailModal-main-middle-view-header-row">
              <div className="DetailModal-main-middle-view-header-title-text">
                Information
              </div>
            </div>
            <div className="desk-only">
              <div className="DetailModal-main-middle-view-header-row">
                <button className="btn-blue">Create An Information</button>
              </div>
            </div>
          </div>
        </div>
        {/* main view header */}
        {/* main view body */}
        <div className="DetailModal-main-middle-view-body" style={{backgroundColor: '#fff0', padding: 0}} >
          <RepairFlow />
          <JobType />
          <Reference />
          <Express />
          <DeviceSecurity />
        </div>
        {/* main view body */}
      </div>
    </div>
  );
};

export default Information;
