import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMER = gql`
  query GetCustomers($limit: Int!, $offset: Int!, $where:customers_bool_exp, $order:[customers_order_by!]) {
    customers(limit: $limit, offset: $offset, where: $where, order_by: $order) {
      first_name
      last_name
      active
      addresses
      bic
      created_at
      customer_number
      discount
      discount_type
      dob
      emails
      iban
      id
      notes
      organization
      payment_condition
      phones
      position
      reverse_charge_invoice_active
      salutation
      tags
      title
      type
      updated_at
      websites
      vat_id
    }
    customers_aggregate(where: $where) {
      aggregate {
        count(columns: active)
      }
    }
  }
`;

export const GET_CUSTOMERS_BY_SEARCHED = gql`
  query getAllCustomers($searchedText: String!, $userId: uuid!) {
    customers(
      where: {
        _or: [
          { first_name: { _ilike: $searchedText } }
          { last_name: { _ilike: $searchedText } }
        ]
        created_by: { _eq: $userId }
      }
    ) {
      id
      active
      addresses
      bic
      created_at
      created_by
      customer_number
      first_name
      last_name
    }
  }
`;
