import React, { useState } from "react";

// icons
import { FiTrash2 } from "react-icons/fi";
import { IoStarOutline, IoStar } from "react-icons/io5";

//types
import { StockImageType } from "./StockItems.type";

const StockItemsModalImages: React.FC<{
  image: StockImageType;
  onRemove: Function;
  onFavouriteToggle: Function;
}> = ({image, onRemove, onFavouriteToggle}) => {
  return (
    <>
      <div className="StockItemsModal_inner_uploaded_img">
        <div className="StockItemsModal_inner_flex_spaced">
          <div
            className="pointer_blue"
            onClick={() => onFavouriteToggle(image.id)}
          >
            {image.favourite === false ? <IoStarOutline /> : <IoStar />}
          </div>
          <div className="pointer" onClick={()=>onRemove()}>
            <FiTrash2 />
          </div>
        </div>
        <img src={image.link} alt="" />
        <p>{image.name}</p>
      </div>
    </>
  );
};

export default StockItemsModalImages;
