import { gql } from "@apollo/client";

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation InsertCustomerOne($object: customers_insert_input!) {
    insert_customers_one(object: $object) {
      active
      first_name
      id
      last_name
      type
      emails
    }
  }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation UpdateCustomer($object: customers_set_input!, $id: uuid!) {
    update_customers(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        bic
        addresses
        first_name
        last_name
      }
    }
  }
`;
