import axios from "axios";
import { API_URL, APP_URL } from "../../config";
export function _fetchActiveJobs(params, token) {
  return new Promise((resolve, reject) => {
    let finalParams = {}
    for (const [key, value] of Object.entries(params)) {
      if(value){
        finalParams[key] = value
      }
    }
    let urlParams = new URLSearchParams(finalParams).toString();
    axios
      .get(`${API_URL}repairs?${urlParams}`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createActiveJob(formData, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}quick-tasks`, formData, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteActiveJob(id, token) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}quick-tasks?id=${id}`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _updateActiveJob(formData, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}repairs/${formData._id}`, formData, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _exportCSV(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}export/repairs`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchActiveJobDetails(id, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}repairs/${id}`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteActiveJobFile(filename, jobId, token) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}repairs/${jobId}/files/${filename}`, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _uploadActiveJobFile(formData, jobId, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`https://app.repaircms.com/api/repairs/${jobId}/files`, formData, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _printDocument(formData, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${APP_URL}api/papers`, formData, {
        headers:{
          Authorization: `JWT ${token}`
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}