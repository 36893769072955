import React, { useState } from "react";
import { FlexboxGrid, Placeholder } from "rsuite";
import "./Subcriptions.css";

// component
import Paging from "../../components/common/paging/Paging";

import { PageHeader, SubscriptionsTabs, SubscriptionFilterComponent, SelectBox, SubscriptionsItem } from "../../components/subscriptions";

//redux
import { connect } from "react-redux";
import { fetchActiveJobs, fetchActiveJobDetails, activeJobSetFilter, activeJobSetPages, exportCSV, activeJobSetDetails } from "../../stores/activeJobs/actions";
import { useEffect } from "react";

//helpers
import { isAlphabet, parseSearchKey } from "../../helpers";

const Subcriptions: React.FC<{
  fetchActiveJobs: Function;
  fetchActiveJobDetails: Function;
  activeJobSetDetails: Function;
  activeJobSetFilter: Function;
  activeJobSetPages: Function;
  exportCSV: Function;
  activeJobs: any[];
  activeJobFilters: any;
  pages: any;
  activeJobsLoading: boolean;
}> = (props) => {
  const { Paragraph } = Placeholder;
  const [tabs, setTabs] = useState<string>("active_jobs");
  const [tab, setTab] = useState(1);
  const [tabTicket, setTabTicket] = useState(1);
  const [selected, setSelected] = useState("ESTIMATED JOBS");
  const [searchText, setSearchText] = useState<string>("");
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectedActiveJob, setSelectedActiveJob] = useState<any>({});
  const sort = [
    {
      label: "Status",
      value: 3,
      children: [
        {
          label: "All Jobs",
          value: 36,
        },
        {
          label: "Repair In Progress",
          value: 37,
        },
        {
          label: "Recected Quotes",
          value: 38,
        },
      ],
    },
  ];
  const handlePageChange = (page: number) => {
    props.activeJobSetPages({ ...props.pages, page });
    props.activeJobSetFilter({ ...props.activeJobFilters, page });
  };
  const handleExportCSV = async () => {
    const resp = await props.exportCSV();
    const link = document.createElement("a");
    link.href = `data:text/csv,${encodeURI(resp)}`;
    link.download = `Reparaturen.csv`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  useEffect(() => {
    // props.fetchActiveJobs();
  }, [props.activeJobFilters]);
  useEffect(() => {
    let params: any = {};
    if (searchText.length > 0) {
      if (isAlphabet(searchText)) {
        params.contactName = searchText;
      } else {
        if (searchText.length === 15) {
          params.imei = searchText;
        } else {
          if (isNaN(parseInt(searchText))) {
            var res = parseSearchKey(searchText);
            params.prefix = res.prefix;
            params.nr = res.nr;
          } else {
            params.nr = searchText;
          }
        }
      }
      props.activeJobSetFilter({
        ...props.activeJobFilters,
        page: 1,
        ...params,
      });
    } else {
      const { page, limit, status } = props.activeJobFilters;
      props.activeJobSetFilter({
        page,
        limit,
        status,
      });
    }
  }, [searchText]);
  useEffect(() => {
    if (openDetailModal) {
      props.fetchActiveJobDetails(selectedActiveJob?._id);
    } else {
      props.activeJobSetDetails({});
      setSelectedActiveJob(null);
    }
  }, [openDetailModal]);

  console.log(tab);

  return (
    <FlexboxGrid className="Subcriptions">
      <PageHeader />
      <div className="desk-only w-100">
        <SubscriptionsTabs setTabTicket={setTabTicket} tabTicket={tabTicket} activeTab={tabs} tab={tab} setTab={setTab} />
        <div className="Subcriptions_body">
          <SubscriptionFilterComponent searchText={searchText} setSearchText={setSearchText} handleExportCSV={handleExportCSV} />
          <div className="Subcriptions_body_bottom">
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
            <SubscriptionsItem />
          </div>
          
          <Paging activePage={1} pages={10} onPageClick={handlePageChange} />
        </div>
      </div>
      {/* Mobile Version Start*/}
      <div className="mob-only" style={{ width: "94vw", marginTop: 10 }}>
        <div className="d-flex-start" style={{ width: "calc(100vw - 25px)" }}>
          <div className="flex-1" style={{ marginLeft: "5%" }}>
            <SelectBox selectedValue={selected}>
              <div
                className=""
                onClick={() => {
                  setSelected("ESTIMATED JOBS");
                }}
              >
                ESTIMATED JOBS
              </div>
              <div
                className=""
                onClick={() => {
                  setSelected("ACTIVE JOBS");
                }}
              >
                ACTIVE JOBS
              </div>
            </SelectBox>
          </div>
        </div>
        <div className="Subcriptions_jobTabs_mobile_scroll"></div>
        {/*  */}
        <div className="Subcriptions_body">
          {/*  */}
          {/* <div className="Subcriptions_body_top">
            <div className="Subcriptions_body_top_btn">
              <Dropdown trigger={["click"]} className="actionDropPageHeader export sort" placement="bottomStart" renderTitle={() => <img src="/assets/bg/filter.png" />}>
                <div className="sort_drop">
                  <h5>Filter</h5>

                  <Checkbox>Job Type</Checkbox>
                  <CheckTree data={sort} defaultExpandAll />
                  <Checkbox>Location</Checkbox>
                  <Checkbox>Account</Checkbox>
                  <Checkbox>Channels</Checkbox>
                  <div className="d-flex">
                    <button className="bt-secondary">Abort</button>
                    <button className="bt-primary">Apply</button>
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className="Subcriptions_body_top_btn">
              <FiPlus />
            </div>
            <div className="Subcriptions_body_top_btn">
              <Dropdown trigger={["click"]} className="actionDropPageHeader export calender" placement="bottomStart" renderTitle={() => <img src="/assets/bg/cal.png" />}>
                <Calendar compact bordered />
              </Dropdown>
            </div>
            
            <div className="Subcriptions_body_top_btn">
              <Dropdown trigger={["click"]} className="actionDropPageHeader export sort" placement="bottomStart" renderTitle={() => <img src="/assets/bg/sort.png" />}>
                <div className="sort_drop">
                  <h5>Sort</h5>

                  <Checkbox>1 - 10 (ascending)</Checkbox>
                  <Checkbox>10 - 1 (descending)</Checkbox>
                  <Checkbox>A - Z (ascending)</Checkbox>
                  <Checkbox>Z - A (descending)</Checkbox>
                  <div className="d-flex">
                    <button className="bt-secondary">Abort</button>
                    <button className="bt-primary">Apply</button>
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className="Subcriptions_body_top_search">
              <input type="text" placeholder="Job No, Name..." />
              <FiSearch />
            </div>
            <div>
              <Dropdown
                trigger={["click"]}
                className="actionDropPageHeader export"
                placement="bottomEnd"
                renderTitle={() => <FiMoreVertical style={{ color: "#304a50", fontSize: 20, cursor: "pointer" }} />}
              >
                <div className="dropDownRowSpaced" style={{ border: 0 }}>
                  <div className="d-flex">
                    <FiUpload />
                    <h6>Export to Excel</h6>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div> */}
          {/*  */}
          <div className="Subcriptions_body_bottom"></div>
          {/*  */}
        </div>
      </div>

      {/* Mobile Version End*/}
      {/* <ActiveJobDetailModal 
        openModal={openDetailModal} 
        onClose={() => setOpenDetailModal(false)} 
      /> */}
    </FlexboxGrid>
  );
};
const mapStateToProps = (state: any) => ({
  activeJobs: [],
  activeJobFilters: {},
  pages: {},
  activeJobsLoading: false,
});
const mapDispatchToProps = {
  fetchActiveJobs,
  fetchActiveJobDetails,
  activeJobSetDetails,
  activeJobSetFilter,
  activeJobSetPages,
  exportCSV,
};
export default connect(mapStateToProps, mapDispatchToProps)(Subcriptions);
