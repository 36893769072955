import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import './minimalView.css';

const MinimalView: React.FC<{ children: any }> = ({ children }) => {
  return (
    <IonPage>
      <IonContent className="minimalView-Content" fullscreen >{children}</IonContent>
    </IonPage>
  );
};

export default MinimalView;
