import React, { Component } from "react";

import "./Paging.scss";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import PropTypes from "prop-types";

class Paging extends Component {
  render() {
    const { pages, activePage, onPageClick } = this.props;

    const pagesToRender = [];

    if (activePage - 2 > 0) pagesToRender.push(activePage - 2);
    if (activePage - 1 > 0) pagesToRender.push(activePage - 1);

    pagesToRender.push(activePage);

    if (pages >= activePage + 1) pagesToRender.push(activePage + 1);
    if (pages >= activePage + 2) pagesToRender.push(activePage + 2);

    const pageList = [];

    if (activePage - 1 > 0)
      pageList.push(
        <div className={"button"} onClick={() => onPageClick(activePage - 1)}>
          <span>Prev</span>
        </div>
      );

    pagesToRender.map((page) => {
      if (activePage !== page) {
        pageList.push(
          <div
            className={"listItem"}
            onClick={() => onPageClick(page)}
            key={page}
          >
            <span>{page}</span>
          </div>
        );
      } else {
        pageList.push(
          <div className="listItem listItemActive" key={page}>
            <span>{page}</span>
          </div>
        );
      }
    });

    if (pages >= activePage + 1)
      pageList.push(
        <div className={"button"} onClick={() => onPageClick(activePage + 1)}>
          <span>Nt</span>
        </div>
      );

    return (
      <div className={"wrapper"}>
        <div className="table_paganation space-between">
          <div className="d-flex">
            <p>View</p>
            <select name="" id="" className="paganation_select">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
            <p>entries per page</p>
          </div>
          <div className="d-flex">
            <p className="p_tag">Showing 1 to 30 of 1.000 entries</p>
            <FaAngleDoubleLeft className="paging-btn" />
            <FaAngleLeft className="paging-btn" />
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <FaAngleRight className="paging-btn" />
            <FaAngleDoubleRight className="paging-btn" />
          </div>
        </div>
      </div>
    );
  }
}

Paging.propTypes = {
  onPageClick: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default Paging;
