import React, { useState } from "react";
import "../../../pages/textTemplate/TextTemplate.scss";
import TextTempletModel from "../textTempletModel/textTempletModel";
import { Dropdown } from "rsuite";

import Print from "../../../assets/Print.png";
import Edit from "../../../assets/Edit.png";
import Email from "../../../assets/Email.png";
import Mark from "../../../assets/Mark.png";
import Preview from "../../../assets/Preview.png";
import Cancel from "../../../assets/Cancel.png";



// icon
import { FiMoreHorizontal } from "react-icons/fi";

const TextTemplateItems: React.FC<{}> = (props) => {
  const [modal, setModal] = useState(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<string>("");

  const handelModel = () => {
    setIsEdited(!isEdited);
    setModal(!modal);

    console.log(!isEdited, "aaa");
  };
  return (
    <>
      <div className="desk-only">
        <div className="TextTemplateItems">
          <div className="TextTemplateItems_left">
            <img src="/assets/bg/textTemplateList.png" alt="" />
            <div className="TextTemplateItems_left_1">
              <p>Name</p>
              <h6>This is the text template name</h6>
            </div>
            <div className="TextTemplateItems_left_2">
              <p>Category</p>
              <h6>Receipts</h6>
            </div>
            <div className="TextTemplateItems_left_4">
              <p>
                Description and text wich can be used when choosing this
                template Lorem Ipsum is simply dummy text of the printin
                .........
              </p>
            </div>
          </div>
          <div className="TextTemplateItems_right" style={{ marginLeft: 20 }}>
            {/* <FiMoreHorizontal onClick={handelModel} /> */}
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/icon/dotsHorizontal.png" />}
            >
              <div className="dropDownProfile">
              <span className="dropDownProfile-Link"> <img src={Print} alt="" /> Preview</span>

                <span className="dropDownProfile-Link" onClick={handelModel}> <img src={Edit} alt="" />
                  Edit
                </span>
                <span className="dropDownProfile-Link"> <img src={Edit} alt="" />Print</span>
                <span className="dropDownProfile-Link"> <img src={Email} alt="" />Send with Email</span>
                <span className="dropDownProfile-Link"> <img src={Mark} alt="" />Mark as paid</span>
                <span className="dropDownProfile-Link"> <img src={Cancel} alt="" />Cancel Invoice</span>

              </div>
            </Dropdown>
          </div>
          {modal && (
            <TextTempletModel
              isEdited={isEdited}
              editedId={editedId}
              Close={() => {
                setModal(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TextTemplateItems;
