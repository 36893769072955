import "../../../pages/dashBoard/DashBoard.css";

const ReturnDevices = () => {
  return (
    <div className="JobsOverview_returnDevices">
      <img
        src="/assets/bg/face1.png"
        className="JobsOverview_returnDevices_img"
      />
      <p className="JobsOverview_returnDevices_text">RETURNED DEVICES TODAY</p>
      <p className="JobsOverview_returnDevices_number">
        <div className="JobsOverview_returnDevices_number_text"> 14</div>
      </p>
    </div>
  );
};

export default ReturnDevices;
