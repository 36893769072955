import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

import { FiMessageSquare, FiSend, FiPaperclip } from "react-icons/fi";

const ChatBoxSend: React.FC<{}> = ({}) => {
  return (
    <div className="DetailModal-main-middle-view-chat-right-send">
      <div className="DetailModal-main-middle-view-chat-right-send-input-wrapper">
        <input type="text" placeholder="Write Message" />
        <div style={{ padding: 10 }}>
          <img
            src="/assets/bg/textTemplates.png"
            className="DetailModal-main-middle-view-chat-right-send-input-icon"
          />
        </div>
        <div style={{ padding: 10 }}>
          <img
            src="/assets/bg/pin.png"
            className="DetailModal-main-middle-view-chat-right-send-input-icon"
          />
        </div>
      </div>
      <div className="DetailModal-main-middle-view-chat-right-send-btn">
        <img
          src="/assets/bg/send.png"
          className="DetailModal-main-middle-view-chat-right-send-input-icon"
        />
      </div>
    </div>
  );
};

export default ChatBoxSend;
