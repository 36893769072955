import React, { useState, useEffect } from "react";

// icons
import { FiInfo, FiTrash2 } from "react-icons/fi";
import { FaBars } from "react-icons/fa";

// components

const InvoiceModalTableItems: React.FC<{
  position: number;
  suggestionItems: [any];
  id: string;
  setProductData: Function;
  setSearchedText: Function;
}> = ({ position, suggestionItems, id, setProductData, setSearchedText }) => {
  const [infoModal, setInfoModal] = useState(false);
  const [openSuggestions, setOpenSuggestion] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [unit, setUnit] = useState<number>(1);
  
  useEffect(() => {
    if (Object.keys(selectedItem).length > 0) {
      setProductData((products: any) => [
        ...products.map((el: any) => {
          if (el.id === id) {
            return {
              ...el,
              unit,
              price: selectedItem?.selling_price_excl_vat,
              amount: unit * selectedItem?.selling_price_excl_vat,
              productId: selectedItem?.id
            };
          } else {
            return el;
          }
        }),
      ]);
    }
  }, [unit, selectedItem]);

  return (
    <>
      <div className="InvoiceModal_body_inner_table">
        <div className="start">
          <FaBars />
        </div>
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ maxWidth: 50 }}
        >
          <h4
            className="InvoiceModal_body_inner_table_text "
            style={{
              width: 40,
              height: 40,
              borderRadius: 7,
              backgroundColor: "#ededed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {position + 1}.
          </h4>
        </div>
        <div className="InvoiceModal_body_inner_table_col_2">
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={selectedItem?.name}
            onChange={(e) => {
              setOpenSuggestion(e.target.value);
              setSearchedText(e.target.value);
            }}
          />
          <div
            style={{
              padding: 5,
              cursor: "pointer",
              position: "absolute",
              right: 15,
              top: 8,
            }}
          >
            <FiInfo
              style={{ color: "#304A50" }}
              onClick={() => {
                setInfoModal(!infoModal);
              }}
            />
          </div>
          {openSuggestions !== "" && (
            <div className="SelectBoxSearch_drop">
              {suggestionItems.map((el: any) => (
                <div
                  className="SelectBoxSearch_drop_item"
                  key={el?.id}
                  onClick={() => {
                    setSelectedItem(el);
                    setOpenSuggestion("");
                  }}
                >
                  <div>{el?.name}</div>
                  <div>Item No.: {el?.item_no}</div>
                </div>
              ))}
            </div>
          )}
          {infoModal && (
            <>
              <div
                className="dropDown_wrapper"
                style={{
                  backgroundColor: "#0000",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => {
                  setInfoModal(false);
                }}
              ></div>
              <div
                className="dropDown_container"
                style={{ padding: "12px 15px", top: 40, width: 150 }}
              >
                <div
                  className="d-flex justify-content-between"
                  style={{ justifyContent: "spaced-between" }}
                >
                  <p>Stock Qty </p>
                  <p
                    style={{
                      margin: "0px 5px",
                      padding: 5,
                      backgroundColor: "#697C80",
                      borderRadius: 5,
                      color: "#fff",
                    }}
                  >
                    {selectedItem?.stock}
                  </p>
                </div>
                <hr style={{ margin: "15px 0" }} />
                <div
                  className="d-flex justify-content-between"
                  style={{ justifyContent: "spaced-between" }}
                >
                  <p>Stock Location </p>
                  <p
                    style={{
                      margin: "0px 5px",
                      padding: 5,
                      backgroundColor: "#697C80",
                      borderRadius: 5,
                      color: "#fff",
                    }}
                  >
                    {selectedItem?.physical_location}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <input
            type="number"
            className="form-control"
            placeholder="0"
            value={unit}
            onChange={(e) => {
              if (parseInt(e.target.value) > 0) {
                setUnit(parseInt(e.target.value));
              }
            }}
          />
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <input
            type="text"
            className="form-control"
            placeholder="0"
            value={selectedItem?.selling_price_excl_vat}
          />
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <input type="text" className="form-control" placeholder="20" />
        </div>
        <div className="InvoiceModal_body_inner_table_col">
          <input
            type="text"
            className="form-control"
            placeholder="20"
            value={
              !isNaN(selectedItem?.selling_price_excl_vat * unit)
                ? selectedItem?.selling_price_excl_vat * unit
                : 0
            }
          />
        </div>
        <div className="end">
          <FiTrash2 />
        </div>
      </div>
    </>
  );
};

export default InvoiceModalTableItems;
