//types
import StockItemType from "../../components/stockItems/stockItemsModal/StockItems.type";
export const BuildStockSearchQuery = (value: string, queryCondition: any) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ name: { _ilike: `%${value}%` } },
				{ item_no: { _ilike: `%${value}%` } },
				{ physical_location: { _ilike: `%${value}%` } },
				{ conditions: { _ilike: `%${value}%` } },
				{ manufacturer_rel: { name: { _ilike: `%${value}%` } } },
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildStockExportColumns = () =>{
	return [{
		label:"Item No.",
		value: "item_no"
	},{
		label:"Item Name",
		value: "name"
	},{
		label:"Description",
		value: "description"
	},{
		label:"Color",
		value: "color"
	},{
		label:"Manufacturer",
		value: "manufacturer"
	},{
		label:"Manufacturer No",
		value: "manufacturer_no"
	},{
		label:"Category",
		value: "category"
	},{
		label:"Physical Location",
		value: "physical_location"
	},{
		label:"Stock",
		value: "stock"
	},{
		label:"Condition",
		value: "conditions"
	},{
		label:"Vat (%)",
		value: "vat"
	},{
		label:"Purchase Price (Excl. VAT)",
		value: "purchase_price_excl_vat"
	},{
		label:"Purchase Price (Incl. VAT)",
		value: "purchase_price_incl_vat"
	},{
		label:"Selling Price (Excl. VAT)",
		value: "selling_price_excl_vat"
	},{
		label:"Selling Price (Incl. VAT)",
		value: "selling_price_incl_vat"
	}]
}
export const BuildStockExportData = (items: StockItemType[]) =>{
	let data:any[] = []
	if(Array.isArray(items)){
		data = items.map((item: StockItemType) => {
			return {
				item_no: item.item_no,
				name: item.name,
				description: item.description,
				color: item.color_rel ? item.color_rel.name : "N/A",
				manufacturer: item.manufacturer_rel ? item.manufacturer_rel.name : "N/A",
				manufacturer_no: item.manufacturer_no,
				category: item.category_rel ? item.category_rel.name : "N/A",
				physical_location: item.physical_location,
				stock: item.stock,
				conditions: item.conditions,
				vat: item.vat,
				purchase_price_excl_vat: item.purchase_price_excl_vat,
				purchase_price_incl_vat: item.purchase_price_incl_vat,
				selling_price_excl_vat: item.selling_price_excl_vat,
				selling_price_incl_vat: item.selling_price_incl_vat,
			}
		})
	}
	return data;
}
export const BuildStockFilterQuery = (filterValue: any, queryCondition: any) => {
	if (Object.keys(filterValue).length) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		if (filterValue?.hasOwnProperty("manufacturer") && filterValue?.manufacturer?.length) {
			conditionTemp = {
				...conditionTemp,
				manufacturer: {_in: filterValue.manufacturer}
			}
		}
		if (filterValue?.hasOwnProperty("color") && filterValue?.color?.length) {
			conditionTemp = {
				...conditionTemp,
				color: {_in: filterValue.color}
			}
		}
		if (filterValue?.hasOwnProperty("category") && filterValue?.category?.length) {
			conditionTemp = {
				...conditionTemp,
				category: {_in: filterValue.category}
			}
		}
		if (filterValue?.hasOwnProperty("color") && filterValue?.color?.length) {
			conditionTemp = {
				...conditionTemp,
				color: {_in: filterValue.color}
			}
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildStockSortQuery = (value: string) => {
	switch(value){
		case "numeric_asc":
			return {item_no: "asc"}
		case "numeric_desc":
			return {item_no: "desc"}
		case "alpha_asc":
			return {name: "asc"}
		case "alpha_desc":
			return {name: "desc"}
	}
}