import React, { useState } from "react";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";
import "./Invoice.scss";

// component
import {
  PageHeader,
  InvoiceItems,
  InvoiceModal,
} from "../../components/invoice";

const Invoice: React.FC<{}> = () => {
  const [modal, setModal] = useState(false);
  return (
    <FlexboxGrid className="Invoice">
      <PageHeader />
      <div className="Invoice_body">
        <div className="Invoice_body_top">
          <div className="space-between">
            <div className="d-flex">
              <div className="Invoice_body_top_btn">
                <Dropdown
                  trigger={["click"]}
                  className="actionDropPageHeader export sort"
                  placement="bottomEnd"
                  renderTitle={() => <img src="/assets/bg/filter.png" />}
                >
                  <div className="sort_drop">
                    <h5>Filter</h5>
                    <Checkbox>Job Type</Checkbox>
                    <Checkbox>Location</Checkbox>
                    <Checkbox>Account</Checkbox>
                    <Checkbox>Channels</Checkbox>
                    <div className="d-flex">
                      <button className="bt-secondary">Abort</button>
                      <button className="bt-primary">Apply</button>
                    </div>
                  </div>
                </Dropdown>
              </div>

              <div className="Invoice_body_top_btn">
                <Dropdown
                  trigger={["click"]}
                  className="actionDropPageHeader export sort"
                  placement="bottomEnd"
                  renderTitle={() => <img src="/assets/bg/sort.png" />}
                >
                  <div className="sort_drop">
                    <h5>Sort</h5>
                    <Checkbox>1 - 10 (ascending)</Checkbox>
                    <Checkbox>10 - 1 (descending)</Checkbox>
                    <Checkbox>A - Z (ascending)</Checkbox>
                    <Checkbox>Z - A (descending)</Checkbox>
                    <div className="d-flex">
                      <button className="bt-secondary">Abort</button>
                      <button className="bt-primary">Apply</button>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>

            <div className="d-flex">
              <div className="JobsOverview_body_top_search">
                <div className="searchIcon">
                  <FiSearch />
                </div>
                <input
                  type="text"
                  className="joboverview-searchInput"
                  placeholder="Search..."
                />
              </div>

              <div
                className="Invoice_body_top_btn blue_btn"
                onClick={() => {
                  setModal(true);
                }}
              >
                <FiPlus />
              </div>
              <div>
                <Dropdown
                  trigger={["click"]}
                  className="actionDropPageHeader export"
                  placement="bottomEnd"
                  renderTitle={() => (
                    <FiMoreVertical
                      style={{
                        color: "#304a50",
                        fontSize: 20,
                        cursor: "pointer",
                      }}
                    />
                  )}
                >
                  <div className="dropDownRowSpaced" style={{ border: 0 }}>
                    <div className="d-flex dropDownRowSpaced-option">
                      <FiUpload />
                      <h6>Export to Excel</h6>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="Invoice_body_bottom">
          <InvoiceItems />
        </div>
      </div>

      {modal && (
        <InvoiceModal
          Close={() => {
            setModal(false);
          }}
        />
      )}
    </FlexboxGrid>
  );
};

export default Invoice;
