import React, { useState } from "react";
import "./SelectBox.css";

// icons
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const SelectBox: React.FC<{
  children: any;
  selectedValue: any;
  labelText: any;
  isError?: boolean;
  borderBottom?: boolean;
  willSearch?: boolean;
}> = ({
  children,
  selectedValue,
  labelText,
  isError = false,
  willSearch,
  borderBottom,
}) => {
    const [open, setOpen] = useState(false);

    return (
      <div
        className={`SelectBox ${open === true ? "active" : ""} ${isError ? "error-input" : ""
          }`}
        style={{
          marginTop: 1,
          border: borderBottom === true ? 0 : "1px solid rgba(48, 74, 80, 0.39)",
          borderBottom: "1px solid rgba(48, 74, 80, 0.39)",
          borderRadius: borderBottom === true ? 0 : 7,
          height: borderBottom === true ? 40 : 45,
        }}
      >
        <div
          className="SelectBox_inner"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="SelectBox_inner_Left">
            <label
              className={`SelectBox_inner_label ${selectedValue !== "" && "filled"
                }`}
            >
              {labelText}
            </label>
            {selectedValue !== "" && <p>{selectedValue}</p>}
          </div>
          <div className="SelectBox_inner_right">
            <FiChevronDown />
          </div>
        </div>
        {open === true && (
          <div className="SelectBox_drop">
            {willSearch === true && (
              <input  type="text" name="search" id="search" placeholder="search" />
            )}

            {Array.isArray(children)
              ? children.map((child: React.ReactElement, i: any) => (
                <div
                  className="SelectBox_drop_item"
                  key={i}
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {child}
                </div>
              ))
              :
              <div
                className="SelectBox_drop_item"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {children}
              </div>
            }
            {/* <div className="SelectBox_drop_item">{children}</div> */}
          </div>
        )}
      </div>
    );
  };

export default SelectBox;
