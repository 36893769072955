import React, { useState } from "react";

// icons
import { FiSettings } from "react-icons/fi";

// components
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import InputBoxIcon from "../../template/inputBoxIcon/InputBoxIcon";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";
import InputBoxBotton from "../../template/inputBoxBotton/InputBoxBotton";
import SelectBoxAdd from "../../template/selectBoxAdd/SelectBoxAdd";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

//types
import StockItemType from "./StockItems.type";

//utils
import {formatPriceWithVat} from "../../../helpers/index";

const StockItemsModalTop: React.FC<{
  state: StockItemType;
  setState: Function;
  errors: any;
  isEdited: boolean
}> = ({ state, setState, errors, isEdited }) => {

  const [selectedManufacturer, setselectedManufacturer] = useState("");
  const [manufacturerMode, setManufacturerMode] = useState([
    {
      id: 1,
      name: "Apple"
    },
    {
      id: 2,
      name: "OnePlus"
    }
  ])
  const [categoryMode, setCategoryMode] = useState([
    {
      id: 1,
      name: "Connector"
    },
    {
      id: 2,
      name: "Connector1"
    }
  ])
  const [colorMode, setColorMode] = useState([
    {
      id: 1,
      name: "Black"
    },
    {
      id: 2,
      name: "Red"
    }
  ])
  const [conditionsMode, setConditionsModeMode] = useState([
    {
      id: 1,
      name: "New"
    },
    {
      id: 2,
      name: "Old"
    },
    {
      id: 2,
      name: "Refurbished"
    },
    {
      id: 2,
      name: "Damaged"
    }
    
  ])


  
  return (
    <>
      <div
        className="StockItemsModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
          <div className="StockItemsModal_inner_flex_start">
            <div className="col" style={{ marginBottom: 15, marginRight: -10 }}>
              <InputBox
                labelText="Product Name"
                handleChange={(value) => setState({ ...state, name: value })}
                valueProp={state.name}
                isError={errors.name?.length > 0}
              />
            </div>
          </div>

          <div
            className="col"
            style={{ marginBottom: 15, marginLeft: -10, marginRight: -20, marginTop: 3 }}
          >
            <div className="StockItemsModal_inner_flex_start">
              <div className="col">
                {/* <SelectBox
                  selectedValue={"Apple"}
                  labelText="Manufacturer"
                  isError={errors.manufacturer?.length > 0}
                >
                  <div
                    className=""
                    onClick={() => setState({
                      ...state, 
                      manufacturer: "dee07210-b99a-4031-bcb7-2d5aafa6ffcc"
                    })}
                  >
                    <div className="d-flex-start">Apple</div>
                  </div>
                </SelectBox> */}
                <SearchDropDown
                        labelText="Manufacturer"
                        data={manufacturerMode}
                        selectedOption={setselectedManufacturer}
                        onMouseLeave={() => null}
                      />
              </div>
              <div className="col">
                {/* <SelectBox
                  selectedValue={"Connector"}
                  labelText="Category"
                >
                  <div
                    className=""
                    onClick={() => setState({
                      ...state, 
                      category: "a392ca0d-73b0-44f2-ae74-e797026ebf24"
                    })}
                  >
                    <div className="d-flex-start"> Connector</div>
                  </div>
                </SelectBox> */}

                 <SearchDropDown
                        labelText="Category"
                        data={categoryMode}
                        selectedOption={setselectedManufacturer}
                        onMouseLeave={() => null}
                      />
                    
              </div>
            </div>
          </div>

          <div
            className="col"
            style={{ marginBottom: 15, marginLeft: -10, marginRight: -20 }}
          >
            <div className="StockItemsModal_inner_flex_start">
              <div className="col">
                {/* <SelectBox
                  selectedValue={"Black"}
                  labelText="Color"
                >
                  <div
                    className=""
                    onClick={() => setState({
                      ...state, 
                      color: "da303bd7-16a5-4752-a2fd-9fe6d6347977"
                    })}
                  >
                    <div className="d-flex-start"> Black</div>
                  </div>
                </SelectBox> */}
                 <SearchDropDown
                        labelText="Color"
                        data={colorMode}
                        selectedOption={setselectedManufacturer}
                        onMouseLeave={() => null}
                      />

                {/* <SelectBoxAdd labelText="Colors" /> */}
              </div>
              <div className="col">
                <div>
                  {/* <SelectBox 
                    selectedValue={state.conditions} 
                    labelText="Conditions"
                    isError={errors.conditions?.length > 0}
                  >
                    {["New", "Old", "Refurbished", "Damaged"].map((cond: string, i: number) => (
                      <div
                        className=""
                        onClick={() => setState({ ...state, conditions: cond })}
                        key={i}
                      >
                        <div className="d-flex-start">{cond}</div>
                      </div>
                    ))}
                  </SelectBox> */}
                   <SearchDropDown
                        labelText="Conditions"
                        data={conditionsMode}
                        selectedOption={setselectedManufacturer}
                        onMouseLeave={() => null}
                      />
                </div>
              </div>
            </div>
          </div>

          <div className="col" style={{ marginRight: -10, marginTop: 17 }}>
            <TextAreaBox
              labelText={"Item Description"}
              handleChange={(value) => setState({ ...state, description: value })}
              valueProp={state.description}
            />
          </div>
        </div>

        <div className="col">
          <div className="StockItemsModal_inner_flex_start">
            <div className="col" style={{ marginBottom: 18 }}>
              <InputBoxBotton
                labelText="Item No."
                valueProp={isEdited ? state.item_no : "Autogenerate"}
                icon={<FiSettings />}
                btnClicks={() => console.log("Hi")}
                disabledInput={true}
              />
            </div>

            <div className="" style={{ width: 85 }}>
              <InputBox
                labelText="Stock Value"
                handleChange={(value) => setState({ ...state, stock: value })}
                valueProp={state.stock}
                isError={errors.stock?.length > 0}
                type="number"
              />
            </div>

            <div className="" style={{ width: 85, marginLeft: 10 }}>
              <InputBox
                labelText="Stock Unit"
                handleChange={(value) => setState({ ...state, stock_unit: value })}
                valueProp={state.stock_unit}
                isError={errors.stock_unit?.length > 0}
              />
            </div>
          </div>

          <div
            className="col"
            style={{ marginBottom: 19, marginLeft: -10, marginRight: -20 }}
          >
            <div className="StockItemsModal_inner_flex_start">
              <div className="col">
                <InputBox
                  labelText="Manufacturer No."
                  handleChange={(value) => setState({ ...state, manufacturer_no: value })}
                  valueProp={state.manufacturer_no}
                />
              </div>
              <div className="col">
                <InputBox
                  labelText="Pysical Location"
                  handleChange={(value) => setState({ ...state, physical_location: value })}
                  valueProp={state.physical_location}
                />
              </div>
            </div>
          </div>

          <div
            className="col"
            style={{ marginBottom: 15, marginLeft: -10, marginRight: -20 }}
          >
            <div className="StockItemsModal_inner_flex_start">
              <div className="col"></div>
              <div className="col">
                <InputBoxIcon
                  labelText="VAT. %"
                  handleChange={(value: string) => {
                    let purchase_incl_price = formatPriceWithVat(state.purchase_price_excl_vat, value, "incl")
                    let selling_incl_price = formatPriceWithVat(state.selling_price_excl_vat, value, "incl")
                    setState({ ...state, vat: value, purchase_price_incl_vat: purchase_incl_price, selling_price_incl_vat: selling_incl_price})
                  }}
                  valueProp={state.vat}
                  icon="%"
                  type="number"
                />
              </div>
            </div>
          </div>

          <div
            className="col"
            style={{ marginBottom: 15, marginLeft: -10, marginRight: -20 }}
          >
            <div className="StockItemsModal_inner_flex_start">
              <div className="col">
                <InputBoxIcon
                  labelText="Purchase Price (Excl. VAT)"
                  handleChange={(value: string) => {
                    let incl_price = formatPriceWithVat(value, state.vat, "incl")
                    setState({...state, purchase_price_excl_vat: value, purchase_price_incl_vat: incl_price})
                  }}
                  valueProp={state.purchase_price_excl_vat}
                  icon="€"
                  isError={errors.purchase_price_excl_vat?.length > 0}
                  type="number"
                />
              </div>
              <div className="col">
                <InputBoxIcon
                  labelText="Purchase Price (Incl. VAT)"
                  handleChange={(value: string) => {
                    let excl_price = formatPriceWithVat(value, state.vat, "excl")
                    setState({...state, purchase_price_incl_vat: value, purchase_price_excl_vat: excl_price})
                  }}
                  valueProp={state.purchase_price_incl_vat}
                  icon="€"
                  isError={errors.purchase_price_incl_vat?.length > 0}
                  type="number"
                  // disabledInput={true}
                />
              </div>
            </div>
          </div>

          <div
            className="col"
            style={{ marginBottom: 15, marginLeft: -10, marginRight: -20 }}
          >
            <div className="StockItemsModal_inner_flex_start">
              <div className="col">
                <InputBoxIcon
                  labelText="Selling Price (Excl. VAT)"
                  handleChange={(value: string) => {
                    let incl_price = formatPriceWithVat(value, state.vat, "incl")
                    setState({...state, selling_price_excl_vat: value, selling_price_incl_vat: incl_price})
                  }}
                  valueProp={state.selling_price_excl_vat}
                  icon="€"
                  isError={errors.selling_price_excl_vat?.length > 0}
                  type="number"
                />
              </div>
              <div className="col">
                <InputBoxIcon
                  labelText="Selling Price (Incl. VAT)"
                  handleChange={(value: string) => {
                    let excl_price = formatPriceWithVat(value, state.vat, "excl")
                    setState({...state, selling_price_incl_vat: value, selling_price_excl_vat: excl_price})
                  }}
                  valueProp={state.selling_price_incl_vat}
                  icon="€"
                  isError={errors.selling_price_incl_vat?.length > 0}
                  type="number"
                  // disabledInput={true}
                />
              </div>
            </div>
          </div>

          {/* <div className="StockItemsModal_inner_flex_start">
            <div className="col">
              <div className="StockItemsModal_inner_blueShow">
                <img src="assets/bg/wrold.png" alt="" />
                <div className="f1">
                  <h6>Show This Service On Your Home Page</h6>
                  <p>Customer Ticketbooking via WebWidget</p>
                </div>
                <Toggle
                  size="md"
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default StockItemsModalTop;
