import React, { useState } from "react";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";

import SelectBox from "../../../template/selectBox/SelectBox";
import InputBox from "../../../template/inputBox/InputBox";
import PlusButton from "../../../template/plusButton/PlusButton";
import SearchDropDown from "../../../template/searchDropDown/SearchDropDown";

interface SupportedProps {
  emails: any;
  setEmails: (a: any) => void;
  phones: any;
  setPhones: (a: any) => void;
  websites: any;
  setWebsites: (a: any) => void;
  handlePhoneNumbers: Function;
  handleAddEmails: Function;
  handleAddWebsites: Function;
}

const SecondTab = ({
  emails,
  setEmails,
  phones,
  setPhones,
  websites,
  setWebsites,
  handlePhoneNumbers,
  handleAddEmails,
  handleAddWebsites,
}: SupportedProps) => {
  const addPhone = (name: string, value: string, id: string) => {
    const newPhones = phones.map((phone: any) => {
      if (phone.id === id) {
        return {
          ...phone,
          [name]: value,
        };
      } else {
        return phone;
      }
    });
    setPhones(newPhones);
  };

  const addEmails = (name: string, value: string, id: string) => {
    const newEmail = emails.map((email: any) => {
      if (email.id === id) {
        return {
          ...email,
          [name]: value,
        };
      } else {
        return email;
      }
    });
    setEmails(newEmail);
  };

  const addWebsites = (name: string, value: string, id: string) => {
    const newWebsites = websites.map((website: any) => {
      if (website.id === id) {
        return {
          ...website,
          [name]: value,
        };
      } else {
        return website;
      }
    });
    setWebsites(newWebsites);
  };
  const [selectedType, setSelectedType ] = useState<any>("")

  const [phoneType, setPhoneType ] = useState<any> ([

    {
      id: 1,
      name: "Private"
    },
    {
      id: 2,
      name: "Public"
    }
  ])

  return (
    <>
      <div className="CustomerContactModal_inner_flex_start">
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">Telephone</h5>
        </div>
      </div>
      {phones.map((phone: any, idx: number) => (
        <div key={phone.id}>
          <div
            className="CustomerContactModal_inner_flex_start"
            style={{ marginBottom: 15, width: "70%" }}
          >
            <div className="col">
              <div
                className="CustomerContactModal_inner_flex_start"
                style={{ marginLeft: -10, marginBottom: 10 }}
              >
                <div className="col">
                  <InputBox
                    labelText="Telephone"
                    valueProp={phone.phone}
                    handleChange={(e) => addPhone("phone", e, phone.id)}
                  />
                </div>
                <div style={{ width: 95, height:46, marginTop:1 }}>
                  {/* <SelectBox selectedValue={phone.type} labelText="Type">
                    <div
                      className=""
                      onClick={() => {
                        addPhone("type", "Private", phone.id);
                      }}
                    >
                      <div className="d-flex-start">Private</div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        addPhone("type", "Public", phone.id);
                      }}
                    >
                      <div className="d-flex-start">Public</div>
                    </div>
                  </SelectBox> */}
                  <SearchDropDown
                        labelText="Type"
                        data={phoneType}
                        selectedOption={setSelectedType}
                        onMouseLeave={() => null}
                      />
                </div>
                {idx > 0 ? (
                  <div style={{ width: 50 }}>
                    <div
                      className="CustomerContactModal_trash"
                      onClick={() => handlePhoneNumbers(true, phone.id)}
                    >
                      <FiTrash2 />
                    </div>
                  </div>
                ) : (
                  <div style={{ width: 50 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
       
            <div className="CustomerContactModal_inner_flex_start">
              <PlusButton
                labelText={"New Telephone"}
                btnClicks={() => handlePhoneNumbers(false)}
                notFill={false}
              />
              {/* <PlusButton labelText={"New Address"} btnClicks={() => null} /> */}
            </div>
      
        </div>
      </div>

      {/* E-mail Address */}
      <div className="CustomerContactModal_inner_flex_start">
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">
            E-mail Address
          </h5>
        </div>
      </div>
      {emails.map((email: any, idx: number) => (
        <div key={email.id}>
          <div
            className="CustomerContactModal_inner_flex_start"
            style={{ marginBottom: 15, width: "70%" }}
          >
            <div className="col">
              <div
                className="CustomerContactModal_inner_flex_start"
                style={{ marginLeft: -10, marginBottom: 10 }}
              >
                <div className="col">
                  <InputBox
                    labelText="E-mail Address"
                    valueProp={email.email}
                    handleChange={(e) => addEmails("email", e, email.id)}
                  />
                </div>
                <div style={{ width: 95, height:46, marginTop:1  }}>
                  {/* <SelectBox selectedValue={email.type} labelText="Type">
                    <div
                      className=""
                      onClick={() => {
                        addEmails("type", "Private", email.id);
                      }}
                    >
                      <div className="d-flex-start">Private</div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        addEmails("type", "Public", email.id);
                      }}
                    >
                      <div className="d-flex-start">Public</div>
                    </div>
                  </SelectBox> */}
                   <SearchDropDown
                        labelText="Type"
                        data={phoneType}
                        selectedOption={setSelectedType}
                        onMouseLeave={() => null}
                      />
                </div>
                {idx > 0 ? (
                  <div style={{ width: 50, }}>
                    <div
                      className="CustomerContactModal_trash"
                      onClick={() => handleAddEmails(true, email.id)}
                    >
                      <FiTrash2 />
                    </div>
                  </div>
                ) : (
                  <div style={{ width: 50 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
            <div className="CustomerContactModal_inner_flex_start">
              <PlusButton
                labelText={"New E-mail"}
                btnClicks={() => handleAddEmails(false)}
                notFill={false}
              />
              {/* <PlusButton labelText={"New Address"} btnClicks={() => null} /> */}
            </div>
        </div>
      </div>

      {/* Website */}
      <div className="CustomerContactModal_inner_flex_start">
        <div className="col">
          <h5 className="CustomerContactModal_tab_body_header">Website</h5>
        </div>
      </div>
      {websites.map((website: any, idx: number) => (
        <div key={website.id}>
          <div
            className="CustomerContactModal_inner_flex_start"
            style={{ marginBottom: 15, width: "70%" }}
          >
            <div className="col">
              {/*  */}
              <div
                className="CustomerContactModal_inner_flex_start"
                style={{ marginLeft: -10, marginBottom: 10 }}
              >
                <div className="col">
                  <InputBox
                    labelText="Websites"
                    valueProp={website.website}
                    handleChange={(e) => addWebsites("website", e, website.id)}
                  />
                </div>
                <div style={{ width: 95, height:46, marginTop:1 }}>
                  {/*  */}
                  {/* <SelectBox selectedValue={website.type} labelText="Type">
                    <div
                      className=""
                      onClick={() => {
                        addWebsites("type", "Private", website.id);
                      }}
                    >
                      <div className="d-flex-start">Private</div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        addWebsites("type", "Public", website.id);
                      }}
                    >
                      <div className="d-flex-start">Public</div>
                    </div>
                  </SelectBox> */}
                       <SearchDropDown
                        labelText="Type"
                        data={phoneType}
                        selectedOption={setSelectedType}
                        onMouseLeave={() => null}
                      />
                </div>
                {idx > 0 ? (
                  <div style={{ width: 50 }}>
                    <div
                      className="CustomerContactModal_trash"
                      onClick={() => handleAddWebsites(true, website.id)}
                    >
                      <FiTrash2 />
                    </div>
                  </div>
                ) : (
                  <div style={{ width: 50 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
            <PlusButton
              labelText={"New Website"}
              btnClicks={() => handleAddWebsites(false)}
            />
          </div>
      </div>
    </>
  );
};

export default SecondTab;
