import React, { useState } from "react";
import "../detailModal/DetailModal.css";

// icons
import { FiCheck, FiTrash2, FiX } from "react-icons/fi";

// components
import InputBox from "../../template/inputBox/InputBox";
import { useEffect } from "react";

const DeviceSecurityEditModal: React.FC<{
  close: any;
  inputValueProps?: any;
  disabled?: any;
  security: any;
}> = ({ close, inputValueProps, disabled, security }) => {
  const [activeDots, setActiveDots] = useState<number[]>([]);
  const renderPattern = () => {
    let jsx: any = [];
    let count: number = 0;
    let countStep: number = 0;
    for (let row = 0; row < 3; row++) {
      //for 3 rows
      jsx.push(
        <div className="d-flex">
          {[0, 1, 2].map((column: number) => {
            let dotNumber: number = count++;
            let isActive: boolean = activeDots.includes(dotNumber);
            if (isActive) {
              ++countStep;
            }
            return <div className={`security_modal_pattern ${isActive ? "active" : ""}`}>{isActive ? countStep : ""}</div>;
          })}
        </div>
      );
    }
    return jsx;
  };
  useEffect(() => {
    if (Array.isArray(security?.value) && security.type === "pattern") {
      setActiveDots(security.value);
    }
  }, [security]);
  return (
    <>
      <div className="backdrop">
        <div className="DetailModal-card-bg card_modal security_modal">
          <div className="DetailModal-card-header card_modal">
            <h6>Device Details</h6>
            <div onClick={() => close()} style={{ cursor: "pointer" }}>
              <FiX style={{ fontSize: 25 }} />
            </div>
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <InputBox labelText="Categories" valueProp={'Smart Phone'} handleChange={()=> null} />
            <div style={{height: 15}} />
            <InputBox labelText="Manufacturer" valueProp={'Iphone'} handleChange={()=> null} />
            <div style={{height: 15}} />
            <InputBox labelText="Model" valueProp={'Iphone X'} handleChange={()=> null} />
            <div style={{height: 15}} />
            <InputBox labelText="IMEI" valueProp={'125698566365'} handleChange={()=> null} />
            <div style={{height: 15}} />
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <button className="btn btn-secondary" onClick={() => close()}>
              Abort
            </button>
            <button className="btn btn-primary" onClick={() => {}}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceSecurityEditModal;
