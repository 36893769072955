
import { RiFileList3Line } from "react-icons/ri";
import "../../../pages/dashBoard/DashBoard.css";

const PageHeader = () => {
  return (
    <div className="PageHeader">
      <div className="PageHeader-left">
        <RiFileList3Line />
        <h2>Subscriptions</h2>
      </div>

      <div className="PageHeader-right"></div>
    </div>
  );
};

export default PageHeader;
