import React, { useState, useEffect } from "react";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import moment from "moment";

// icon
import { FiMail } from "react-icons/fi";

//graphql
import { useQuery } from "@apollo/client";
import { GET_INVOICES_QUERY } from "../../../gql/invoice/queries";

//toolkit
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";
import {
  setInvoices,
  invoicesState,
} from "../../../toolkit/invoice/invoice.slice";

//components
import Loader from "../../../components/loader/Loader";
import Paging from "../../../components/common/paging/Paging";

const InvoiceItems: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const { invoices } = useSelector(invoicesState);
  const limit = 10;

  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const {
    auth: { user },
  } = useSelector(authState);
  const { data, loading, refetch } = useQuery(GET_INVOICES_QUERY, {
    variables: {
      limit: limit,
      offset: offset,
      where: { created_by: { _eq: user.id } },
    },
  });

  useEffect(() => {
    dispatch(setInvoices(data));
    if (typeof data?.invoices_aggregate?.aggregate?.count !== "undefined") {
      setTotalPages(
        Math.ceil(data?.invoices_aggregate?.aggregate?.count / limit)
      );
    }
  }, [data]);

  const handlePage = (page: number) => {
    setActivePage(page);
    setOffset(limit * page - limit);
  };

  return (
    <>
      {loading || typeof invoices === "undefined" ? (
        <Loader />
      ) : invoices.length > 0 ? (
        <>
          {invoices.map((el: any) => (
            <div className="desk-only" key={el?.invoice_id}>
              <div className="InvoiceItems">
                <div className="InvoiceItems_left">
                  <div className="InvoiceItems_left_2">
                    <p>Date</p>
                    <h6>{moment(el?.created_at).format("L")}</h6>
                  </div>
                  <div className="InvoiceItems_left_3">
                    <p>Invoice No.</p>
                    <h6>{el?.invoice_id}</h6>
                  </div>
                  <div className="InvoiceItems_left_1">
                    <p>Customer Name | Reference</p>
                    <h6>
                      {`${el?.customer?.first_name} ${el?.customer?.last_name}`}{" "}
                      <span> Job No. 000012</span>
                    </h6>
                  </div>
                  <div className="InvoiceItems_left_3">
                    <p>Total Incl. VAT</p>
                    <h6>€ {el?.total_amount}</h6>
                  </div>
                  <div className="InvoiceItems_left_3">
                    <p>Total Excl. VAT</p>
                    <h6>€ -120.240,00</h6>
                  </div>
                  <div className="InvoiceItems_left_3">
                    <p>Payment Method</p>
                    <h6>{el?.payment_method}</h6>
                  </div>
                </div>
                <div className="InvoiceItems_right">
                  <div className="">
                    <h6>Canceled</h6>
                  </div>
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader export sort"
                    placement="bottomEnd"
                    renderTitle={() => (
                      <img src="/assets/icon/dotsHorizontal.png" />
                    )}
                  >
                    <div className="dropDownProfile">
                      <span className="dropDownProfile-Link">
                        <FiMail style={{ marginRight: 10 }} /> Send with Email
                      </span>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
          <div className="table-footer">
            <Paging
              activePage={activePage}
              pages={totalPages}
              onPageClick={handlePage}
            />
          </div>
        </>
      ) : (
        <>
          <p className="text-center mt-25" style={{ fontSize: "1rem" }}>
            No Invoices found!
          </p>
        </>
      )}
    </>
  );
};

export default InvoiceItems;
