import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  errorMsg: "",
};

const customerSlice: any = createSlice({
  name: "customes",
  initialState,
  reducers: {
    setCustomer: (state, { payload }) => {
      return {
        ...state,
        customers: payload?.customers,
      };
    },
  },
});

export const customerState = (state: any) => state.customers;
export const { setCustomer } = customerSlice.actions;

export default customerSlice.reducer;
