import {
	ACTIVE_JOBS_LOADING,
	SET_ACTIVE_JOBS,
	REMOVE_ACTIVE_JOBS,
	UPDATE_ACTIVE_JOBS,
	ACTIVE_JOBS_SET_FILTER,
	ACTIVE_JOBS_SET_PAGES,
	SET_ACTIVE_JOB_DETAILS
} from "./constants";
import {
	_fetchActiveJobs,
	_fetchActiveJobDetails,
	_createActiveJob,
	_deleteActiveJob,
	_updateActiveJob,
	_exportCSV,
	_deleteActiveJobFile,
	_uploadActiveJobFile,
	_printDocument
} from "./services";
import { loginRemoveToken } from "../auth/actions";

export function activeJobSetLoading(key, loading) {
	return {
		type: ACTIVE_JOBS_LOADING,
		payload: {key, loading},
	};
}
export function activeJobSetData(params) {
	return {
		type: SET_ACTIVE_JOBS,
		payload: params,
	};
}
export function activeJobSetDetails(params) {
	return {
		type: SET_ACTIVE_JOB_DETAILS,
		payload: params,
	};
}
export function activeJobRemoveData(params) {
	return {
		type: REMOVE_ACTIVE_JOBS,
		payload: params,
	};
}
export function activeJobUpdateData(params) {
	return {
		type: UPDATE_ACTIVE_JOBS,
		payload: params,
	};
}
export function activeJobSetFilter(params) {
	return {
		type: ACTIVE_JOBS_SET_FILTER,
		payload: params,
	};
}
export function activeJobSetPages(params) {
	return {
		type: ACTIVE_JOBS_SET_PAGES,
		payload: params,
	};
}
export const fetchActiveJobs = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		const { filters } = getState().activeJobs;
		dispatch(activeJobSetLoading("loading", true));
		_fetchActiveJobs(filters, token).then(async (res) => {
			if (Array.isArray(res.docs)) {
				dispatch(activeJobSetData(res.docs));
				dispatch(activeJobSetPages({page: res.page, pages: res.pages, total: res.total}));
			}
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(activeJobSetLoading("loading",false));
		})
	})
};
export const fetchActiveJobDetails = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(activeJobSetLoading("loadingJobDetails",true));
		_fetchActiveJobDetails(id, token).then(async (res) => {
			dispatch(activeJobSetDetails(res));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(activeJobSetLoading("loadingJobDetails",false));
		})
	})
};
export const createActiveJob = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		_createActiveJob(formData, token).then(async (res) => {
			dispatch(fetchActiveJobs());
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		})
	})
};
export const deleteActiveJob = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		dispatch(activeJobSetLoading("loading",true));
		_deleteActiveJob(id, token).then(async (res) => {
			dispatch(activeJobRemoveData(id));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		}).finally(() => {
			dispatch(activeJobSetLoading("loading",false));
		})
	})
};
export const updateActiveJob = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		_updateActiveJob(formData, token).then(async (res) => {
			dispatch(fetchActiveJobDetails(formData._id));
			dispatch(fetchActiveJobs());
			resolve(res);
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		})
	})
};
export const exportCSV = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		_exportCSV(token).then(async (res) => {
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		})
	})
};
export const deleteActiveJobFile = (filename) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		const { job } = getState().activeJobs;
		_deleteActiveJobFile(filename, job._id, token).then(async (res) => {
			dispatch(fetchActiveJobDetails(job._id));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		})
	})
};
export const uploadActiveJobFile = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		const { job } = getState().activeJobs;
		_uploadActiveJobFile(formData, job._id, token).then(async (res) => {
			dispatch(fetchActiveJobDetails(job._id));
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		})
	})
};
export const printDocument = (formData) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const { token } = getState().auth;
		_printDocument(formData, token).then(async (res) => {
			resolve(res)
		}).catch((err) => {
			if (err?.response?.status === 401) {
				dispatch(loginRemoveToken());
			}
			reject(err)
		})
	})
};