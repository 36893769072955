import React, { useState, useEffect } from "react";

// icons
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";

import SelectBox from "../../../template/selectBox/SelectBox";
import InputBox from "../../../template/inputBox/InputBox";
import SearchDropDown from "../../../template/searchDropDown/SearchDropDown";


interface SupportedProps {
  addresses: any;
  setAddresses: (a: any) => void;
  addNewAddress: Function;
}

const FirstTab = ({
  addresses,
  setAddresses,
  addNewAddress,
}: SupportedProps) => {
  const [addressType, setAddressType] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<any>("");

  const [countrys, setCountry] = useState<any>([
    {
      id: 1,
      name: "Turkey"
    },
    {
      id: 2,
      name: "Pakistan"
    }
      
  ])

  const addAddresses = (name: string, value: string, id: string) => {
    const newAddress = addresses.map((address: any) => {
      if (address.id === id) {
        return {
          ...address,
          [name]: value,
        };
      } else {
        return address;
      }
    });
    setAddresses(newAddress);
  };

  useEffect(() => {
    if (addresses[0]?.country) {
      setAddressType(addresses[0].country);
    }
  }, [addresses]);

  return (
    <>
      {addresses.map((el: any, i: number) => (
        <div key={el.id}>
          <div
            className="CustomerContactModal_inner_flex_start"
            style={{ marginBottom: 15 }}
          >
            <div className="col">
              <h5 className="CustomerContactModal_tab_body_header">
                Billing Adress
              </h5>
            </div>
            {i > 0 && (
              <div
                className="col"
                style={{ justifyContent: "flex-end", display: "flex" }}
                onClick={() => addNewAddress(true, el.id)}
              >
                <h5 className="CustomerContactModal_tab_body_header">
                  <FiTrash />
                </h5>
              </div>
            )}
          </div>
          <div
            className="CustomerContactModal_inner_flex_start"
            style={{ marginBottom: 15 }}
          >
            <div className="col">
              <div
                className="CustomerContactModal_inner_flex_start"
                // style={{ marginLeft: -10 }}
              >
                <div className="col" style={{ paddingLeft: 0 }}>
                  <InputBox
                    labelText="Street"
                    valueProp={el.newName}
                    handleChange={(e) => addAddresses("newName", e, el.id)}
                  />
                </div>
                <div style={{ width: 90 }}>
                  <InputBox
                    labelText="No."
                    valueProp={el.newNo}
                    handleChange={(e) => {
                      if (!isNaN(e)) {
                        addAddresses("newNo", e, el.id);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <InputBox
                labelText="Zip"
                valueProp={el.zip}
                handleChange={(e) => {
                  if (!isNaN(e)) {
                    addAddresses("zip", e, el.id);
                  }
                }}
              />
            </div>
          </div>
          <div
            className="CustomerContactModal_inner_flex_start"
            style={{ marginBottom: 15 }}
          >
            <div className="col">
              <InputBox
                labelText="City"
                valueProp={el.city}
                handleChange={(e) => addAddresses("city", e, el.id)}
              />
            </div>
            <div className="col">
              {/*  */}
              {/* <SelectBox selectedValue={el.country} labelText="Country">
                <div
                  className=""
                  onClick={() => {
                    addAddresses("country", "Germany", el.id);
                  }}
                >
                  <div className="d-flex-start"> Germany</div>
                </div>
                <div
                  className=""
                  onClick={() => {
                    addAddresses("country", "India", el.id);
                  }}
                >
                  <div className="d-flex-start"> India</div>
                </div>
              </SelectBox> */}
              <SearchDropDown
                        labelText="Country"
                        data={countrys}
                        selectedOption={setSelectedCountry}
                        onMouseLeave={() => null}
                      />
              {/*  */}
            </div>
          </div>
        </div>
      ))}
      <div
        className="CustomerContactModal_inner_flex_start"
        style={{ marginBottom: 15 }}
      >
        <div className="col">
            <p
              className="cursor-pointer"
              style={{ color: "#2589f6" }}
              onClick={() => addNewAddress(false, "")}
            >
              <AiFillPlusCircle
                style={{
                  marginRight: 10,
                  marginBottom: -5,
                  width: 25,
                  height: 25,
                }}
              />{" "}
              New Address
            </p>
        </div>
      </div>
    </>
  );
};

export default FirstTab;
