import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import {
  FiArrowLeftCircle,
  FiPlus,
  FiMinimize2,
  FiEye,
  FiTrash2,
} from "react-icons/fi";

const NotesView: React.FC<{ goback: any }> = ({ goback }) => {
  return (
    <div className="DetailModal-main-middle">
      {/* main view */}
      <div className="DetailModal-main-middle-view">
        {/* main view header */}
        <div className="DetailModal-main-middle-view-header">
          <div
            className="DetailModal-main-middle-view-header-back"
            onClick={goback}
          >
            <FiArrowLeftCircle />
          </div>
          <div className="DetailModal-main-middle-view-header-title-spaced">
            <div className="DetailModal-main-middle-view-header-title-text">
              Notes
            </div>
            <div className="DetailModal-main-middle-view-header-row">
              <button>
                <FiPlus />
              </button>
              <div className="desk-only">
                <button>
                  <FiMinimize2 />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* main view header */}
        {/* main view body */}
        <div className="DetailModal-main-middle-view-body">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
            (item, i) => (
              <div className="DetailModal-card-body-note-item" key={i}>
                <div className="DetailModal-card-body-note-item-top">
                  <div className="DetailModal-card-body-note-item-top-text">
                    Internal Note
                  </div>
                  <div className="DetailModal-card-body-note-item-top-right">
                    21:24 | 06/18/2021
                  </div>
                </div>
                <div className="DetailModal-card-body-note-item-bottom-flex">
                  <h6>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                  </h6>
                  <button className="DetailModal-card-body-note-item-bottom-delete">
                    <FiTrash2 />
                  </button>
                </div>
              </div>
            )
          )}
        </div>
        {/* main view body */}
      </div>
    </div>
  );
};

export default NotesView;
