import React, { useState, useEffect } from "react";
import { IonImg, IonContent } from "@ionic/react";
import {
  Form,
  FlexboxGrid,
  FormGroup,
  FormControl,
  ButtonToolbar,
  Button,
  Checkbox,
  Loader,
} from "rsuite";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./Login.css";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loginApi, socialLoginApi } from "../../toolkit/auth/auth.api";
import { authState } from "../../toolkit/auth/auth.slice";

// component
import SelectBox from "../../components/template/selectBox/SelectBox";

//helpers
import { showAlert, parseJwt } from "../../helpers";
//config
import {
  GOOGLE_OAUTH,
  FACEBOOK_APP_ID,
  APPLE_APP_ID,
  APPLE_REDIRECT_URI,
} from "../../config";

const Login: React.FC<{
  checkLogin: Function;
  socialLogin: Function;
}> = (props) => {
  const dispatch = useDispatch();
  const {
    auth: { token, isLoading, errorMsg },
  } = useSelector(authState);
  // const token = localStorage.getItem("token");
  const history = useHistory();

  const [loginData, setLoginData] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [selected, setSelected] = useState("");
  const [appleLoading, setAppleLoading] = useState<boolean>(false);
  const [googleLoading, setGoogleLoading] = useState<boolean>(false);
  const [facebookLoading, setFacebookLoading] = useState<boolean>(false);

  const submitHandler = async () => {
    try {
      setBtnLoading(true);
      const resp = await dispatch(
        loginApi({ email: loginData.email, password: loginData.password })
      );
      if (resp.type === "auth/login/rejected") {
        setErrMsg("Invalid Email/Password");
      }
    } catch (err: any) {
      if (typeof err?.response?.data === "object") {
        setErrMsg("Invalid Email/Password");
      }
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (errorMsg) {
      setErrMsg(errorMsg);
    }
  }, [errorMsg]);

  const socialLogin = (
    socialType: string,
    socialId: string,
    avatar: string,
    email: string,
    name: string
  ) => {
    dispatch(
      socialLoginApi({
        email,
        name,
        avatar,
        socialId,
        socialType,
      })
    );
  };

  const onSuccessGoogle = async (response: any) => {
    const { email, googleId, imageUrl, name } = response.profileObj;
    try {
      socialLogin("google", googleId, imageUrl, email, name);
    } catch (err: any) {
      showAlert({
        title: "Error!",
        message: "Unable to login using Google",
        type: "danger",
      });
    } finally {
      setGoogleLoading(false);
    }
  };

  const onFailureGoogle = () => {
    showAlert({
      title: "Error!",
      message: "Unable to login using Google",
      type: "danger",
    });
    setGoogleLoading(false);
  };

  const responseFacebook = async (response: any) => {
    const { email, userID, picture, name } = response;
    console.log(response);
    try {
      const imageUrl = picture?.data?.url;
      socialLogin("facebook", userID, imageUrl, email, name);
    } catch (err: any) {
      showAlert({
        title: "Error!",
        message: "Unable to login using Facebook",
        type: "danger",
      });
    } finally {
      setFacebookLoading(false);
    }
  };

  const responseApple = async (response: any) => {
    if (response?.authorization) {
      setAppleLoading(true);
      const { id_token, user, code } = response.authorization;
      const { email } = parseJwt(id_token);
      console.log(user);
      let name = "RepairCMS User";
      if (user) {
        name = `${user?.name?.firstName} ${user?.name?.lastName}`;
      }
      try {
        const imageUrl =
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyWLjkYKGswBE2f9mynFkd8oPT1W4Gx8RpDQ&usqp=CAU";

        socialLogin("apple", code, imageUrl, email, name);
      } catch (err: any) {
        showAlert({
          title: "Error!",
          message: "Unable to login using Apple",
          type: "danger",
        });
      } finally {
        setAppleLoading(false);
      }
    } else {
      setAppleLoading(false);
    }
  };

  return (
    <IonContent className="loginPage">
      <FlexboxGrid className="auth_Flex">
        <div className="auth_Flex_card">
          <FlexboxGrid.Item className="auth_Flex_Right ">
            <IonImg src="/assets/logo.png" className="auth_logo" />
            <h2 className="desk-only">
              "We will either find a way, or make one”
            </h2>
            <h3 className="desk-only">Hanibal</h3>
            <IonImg
              src="/assets/bg/auth.png"
              className="auth_logo_lg desk-only"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className="auth_Flex_Left">
            <Form fluid className="auth_Form" onSubmit={submitHandler}>
              {/* <IonImg
                src="/assets/logo-white.png"
                className="auth_logo mob-only"
              /> */}
              <div className="">
                {/* <select className="card_modal_input login">
                  <option value="1">English</option>
                  <option value="1">Germany</option>
                </select> */}
                <div className="" style={{ width: 170, marginBottom: 15 }}>
                  <SelectBox selectedValue={selected} labelText="Language">
                    <div
                      className=""
                      onClick={() => {
                        setSelected("English");
                      }}
                    >
                      English
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("Germany");
                      }}
                    >
                      Germany
                    </div>
                  </SelectBox>
                </div>
              </div>
              <h1 className="auth_Form_title">Log In to your account</h1>
              <h3 className="auth_Form_title_sub">Sign in to your account</h3>
              <FormGroup>
                {/* <ControlLabel>Email address</ControlLabel> */}
                <FormControl
                  className="authFormInput"
                  name="email"
                  placeholder="Email"
                  onChange={(value) =>
                    setLoginData({ ...loginData, email: value })
                  }
                  required
                  style={{ borderColor: errMsg.length > 0 ? "#f00" : "#0005" }}
                />
              </FormGroup>
              <FormGroup>
                {/* <ControlLabel>Password</ControlLabel> */}
                <FormControl
                  className="authFormInput"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={(value) =>
                    setLoginData({ ...loginData, password: value })
                  }
                  required
                  style={{ borderColor: errMsg.length > 0 ? "#f00" : "#0005" }}
                />
              </FormGroup>
              {errMsg?.length > 0 && (
                <FormGroup>
                  <p className="err-msg">{errMsg}</p>
                </FormGroup>
              )}
              <FormGroup>
                <ButtonToolbar>
                  <Link to="/forgot-password">
                    <h2 style={{ textAlign: "right" }}>Forgot password?</h2>
                  </Link>
                </ButtonToolbar>
                <div className="width-login">
                  <Checkbox>Remember me</Checkbox>
                </div>
              </FormGroup>
              <FormGroup>
                <ButtonToolbar>
                  <Button
                    appearance="primary"
                    className="authBtn"
                    type="submit"
                    disabled={isLoading}
                  >
                    Log in
                  </Button>
                </ButtonToolbar>
              </FormGroup>
              <FormGroup>
                <ButtonToolbar>
                  <Link to="/register">
                    <h2>
                      Don't have an account?<span>Sign Up</span>
                    </h2>
                  </Link>
                  <h2>Or</h2>
                  <h2>Sign In with</h2>
                  <div className="d-flex">
                    <AppleLogin
                      clientId={APPLE_APP_ID}
                      redirectURI={APPLE_REDIRECT_URI}
                      scope="name email"
                      usePopup={true}
                      callback={responseApple}
                      render={(renderProps: any) => (
                        <>
                          {appleLoading ? (
                            <div className="auth_social social_loader">
                              <Loader size="sm" content="" />
                            </div>
                          ) : (
                            <IonImg
                              src="/assets/bg/apple.png"
                              className="auth_social"
                              onClick={() => {
                                setAppleLoading(true);
                                renderProps.onClick();
                              }}
                            />
                          )}
                        </>
                      )}
                    />
                    <GoogleLogin
                      clientId={GOOGLE_OAUTH}
                      buttonText="Login"
                      onSuccess={onSuccessGoogle}
                      onFailure={onFailureGoogle}
                      render={(renderProps) => (
                        <>
                          {googleLoading ? (
                            <div className="auth_social social_loader">
                              <Loader size="sm" content="" />
                            </div>
                          ) : (
                            <IonImg
                              onClick={() => {
                                setGoogleLoading(true);
                                renderProps.onClick();
                              }}
                              src="/assets/bg/google.png"
                              className="auth_social"
                            />
                          )}
                        </>
                      )}
                    />
                    <FacebookLogin
                      appId={FACEBOOK_APP_ID}
                      autoLoad={false}
                      callback={responseFacebook}
                      fields="name,email,picture"
                      render={(renderProps: any) => (
                        <>
                          {facebookLoading ? (
                            <div className="auth_social social_loader">
                              <Loader size="sm" content="" />
                            </div>
                          ) : (
                            <IonImg
                              src="/assets/bg/facebook.jpeg"
                              className="auth_social"
                              onClick={() => {
                                setFacebookLoading(true);
                                renderProps.onClick();
                              }}
                            />
                          )}
                        </>
                      )}
                    />
                  </div>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </FlexboxGrid.Item>
        </div>
      </FlexboxGrid>
    </IonContent>
  );
};

export default Login;
