import React, { useState, useEffect } from "react";
import "./InputBoxIcon.css";

const InputBoxIcon: React.FC<{ labelText: any; valueProp?: any; disabledInput?: any; icon?: any, handleChange?: Function, type?: string, isError?: boolean }> = ({ labelText, valueProp="", disabledInput, icon, handleChange, type="text", isError=false }) => {
  const [value, setValue] = useState(""); 
  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    }
  }, [valueProp]);

  return (
    <div className={`InputBoxIcon ${value !== "" && "active"} ${disabledInput ? "disabled" : ""} ${isError ? "error-input" : ""}`}>
      <input
        type={type}
        className="InputBoxIcon_input"
        readOnly={disabledInput}
        value={valueProp}
        onChange={(evt) => {
          handleChange ? handleChange(evt.target.value) : setValue(evt.target.value);
        }}
      />
      <div className="InputBoxIcon_input_right">{icon}</div>
      <label className="InputBoxIcon_label">{labelText}</label>
    </div>
  );
};

export default InputBoxIcon;
