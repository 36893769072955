import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonImg, IonContent } from "@ionic/react";
import {
  FlexboxGrid
} from "rsuite";
import "./Register.css";

// component
import SelectBox from "../../components/template/selectBox/SelectBox";
import Stage1 from "./stages/Stage1";
import Stage2 from "./stages/Stage2";
import Stage3 from "./stages/Stage3";
import Stage4 from "./stages/Stage4";

//redux
import { useDispatch, useSelector } from "react-redux";
import { authState, resetMessage } from "../../toolkit/auth/auth.slice";
import {
  sendOtpApi,
  verifyOtpApi,
  registerApi,
} from "../../toolkit/auth/auth.api";

//helpers
import { showAlert } from "../../helpers";

const Register = () => {
  const dispatch = useDispatch();
  const {
    auth: { message, errorMsg, token, isLoading },
  } = useSelector(authState);
  const history = useHistory();

  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState("");
  const [email, setEmail] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [otp, setOTP] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [industry, setIndustry] = useState<string>("MOBILE_REPAIR");

  const onVerifyOTP = async () => {
    if (otp.length !== 4) {
      setErrMessage("Please enter the OTP");
    } else {
      const reqParam = {
        email,
        otp,
      };
      setBtnLoading(true);
      dispatch(verifyOtpApi(reqParam));
    }
  };

  const onFinal = () => {
    if (!name || !password) {
      setErrMessage("Multiple fields missing!");
    } else {
      handleRegister()
      // setStep(4);
    }
  };

  const handleRegister = async () => {
    try {
      setBtnLoading(true);
      dispatch(registerApi({ email, name, password, industry }));
    } catch (err: any) {
      let msg = "Something Went Wrong";
      setErrMessage(msg);
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (message) {
      setErrMessage("");
      if (step === 1) {
        showAlert({
          title: "Success!",
          message: "We have send OTP in your email for verification.",
          type: "success",
        });
        setStep(2);
      } else if (step === 2) {
        setStep(3);
      }
    }
    if (errorMsg) {
      setErrMessage(errorMsg);
      setBtnLoading(false);
    }else{
      setErrMessage("");
    }
  }, [message, errorMsg]);

  useEffect(()=> {
    if(token) {
      history.push("/dashboard");
    }
  }, [token, history]);
  useEffect(()=>{
    dispatch(resetMessage());
  }, [step])
  return (
    <IonContent className="RegisterPage">
      <FlexboxGrid className="auth_Flex">
        <div className="auth_Flex_card">
          <FlexboxGrid.Item className="auth_Flex_Right ">
            <IonImg src="/assets/logo.png" className="auth_logo" />
            <h2 className="desk-only">
              "We will either find a way, or make one”
            </h2>
            <h3 className="desk-only">Hanibal</h3>
            <IonImg
              src="/assets/bg/auth.png"
              className="auth_logo_lg desk-only"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item className="auth_Flex_Left">
            <div className="" style={{ width: 170, marginBottom: 15 }}>
              <SelectBox selectedValue={selected} labelText="Language">
                <div
                  className=""
                  onClick={() => {
                    setSelected("English");
                  }}
                >
                  English
                </div>
                <div
                  className=""
                  onClick={() => {
                    setSelected("Germany");
                  }}
                >
                  Germany
                </div>
              </SelectBox>
            </div>
            {step === 1 && (
              <Stage1
                email={email}
                setEmail={setEmail}
                onVerifyEmail={() => dispatch(sendOtpApi(email))}
                errMessage={errMessage}
                btnLoading={isLoading}
              />
            )}
            {step === 2 && (
              <Stage2
                btnLoading={isLoading}
                errMessage={errMessage}
                onVerifyOTP={onVerifyOTP}
                otp={otp}
                setOTP={setOTP}
              />
            )}
            {step === 3 && (
              <Stage3
                email={email}
                handleRegister={onFinal}
                name={name}
                password={password}
                setName={setName}
                setPassword={setPassword}
                btnLoading={isLoading}
              />
            )}
            {step === 4 && (
              <Stage4
                handleRegister={handleRegister}
                setIndustry={setIndustry}
                btnLoading={isLoading}
              />
            )}
          </FlexboxGrid.Item>
        </div>
      </FlexboxGrid>
    </IonContent>
  );
};

export default Register;
