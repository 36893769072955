import React, { useEffect, useState } from "react";
import "../../../pages/services/Services.scss";

// icons
import { FiX } from "react-icons/fi";

// component
import InputBox from "../../template/inputBox/InputBox";

//helpers
import { showAlert } from "../../../helpers";

//redux
import {useDispatch, useSelector} from "react-redux";
import {changePasswordApi} from "../../../toolkit/profile/profile.api";
import {authState} from "../../../toolkit/auth/auth.slice";
import {profileState} from "../../../toolkit/profile/profile.slice";

const ChangePasswordModal: React.FC<{ close: any }> = ({ close }) => {
  const dispatch = useDispatch();
  const {token} = useSelector(authState).auth
  const {errorMsg} = useSelector(profileState).profile
  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
    errorMsg: ""
  })
  const reset = () =>{
    setState({
      old_password: "",
      new_password: "",
      confirm_password: "",
      errorMsg: ""
    })
  }
  const handleClose = () =>{
    reset();
    close();
  }
  const handleChangePassword = async(e: React.SyntheticEvent<Element, Event>) =>{
    e.preventDefault();
    try{
      const resp = await dispatch(changePasswordApi({formData: state, token}));
      if(resp.type === "profile/changePassword/fulfilled"){
        showAlert({
          title: "Success!",
          message: `Password changed successfully!`,
          type: "success",
        });
        handleClose();
      }
    }catch(err){
      showAlert({
        title: "Failed!",
        message: `Something went wrong!`,
        type: "danger",
      });
    }finally{

    }
  }
  useEffect(()=>{
    if(errorMsg?.length){
      setState({...state, errorMsg})
    }else{
      setState({...state, errorMsg: ""})
    }
  }, [errorMsg])
  return (
    <div className="ChangePasswordModal">
      <form onSubmit={handleChangePassword}>
      <div className="ChangePasswordModal_inner">
        <div className="ChangePasswordModal_inner_top">
          <h2>Change Password</h2>
          <div
            className="ChangePasswordModal_inner_top_cancel"
            onClick={() => {
              handleClose();
            }}
          >
            <FiX />
          </div>
        </div>
        <div className="flex-row mt-1">
          <div className="flex-row_col">
            <InputBox
              handleChange={(value) => setState({ ...state, old_password: value })}
              labelText="Old Password"
              required={true}
              type="password"
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox
              handleChange={(value) => setState({ ...state, new_password: value })}
              labelText="New Password"
              required={true}
              type="password"
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => setState({ ...state, confirm_password: value })} labelText="Confirm Password" 
              required={true}
              type="password"
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <p className={state.errorMsg.length > 0 ? "text-danger" : ""}>
              {state.errorMsg.length > 0 ? state.errorMsg : 
              "Your password must contain at least 8 characters and a number or a capital letter!"}
            </p>
          </div>
        </div>
        <div className="flex-row">
          <div
            className="flex-row_col"
            onClick={() => {
              handleClose();
            }}
          >
            <div className="bt-secondary">Abort</div>
          </div>
          <div className="flex-row_col">
            <button 
              className="bt-primary"
              type="submit"
            >Save</button>
          </div>
        </div>
      </div>
      </form>
    </div>
  );
};

export default ChangePasswordModal;
