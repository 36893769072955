import React, { useEffect } from "react";
import "../../../pages/dashBoard/DashBoard.css";

//redux
import { connect } from "react-redux";
import { fetchProgressOverview } from "../../../stores/stats/actions";
import { activeJobSetFilter } from "../../../stores/activeJobs/actions";

const SubscriptionsTabs: React.FC<{
  activeTab: string;
  setTabTicket: Function;
  tabTicket: number;
  tab: number;
  setTab: Function;
  fetchProgressOverview: Function;
  progressOverview: any;
  activeJobSetFilter: Function;
  activeJobFilters: any;
}> = (props) => {
  useEffect(() => {
    // props.fetchProgressOverview();
  }, []);
  return (
    <div className="Subcriptions_SubscriptionsTabs">
      <div className=""></div>

      {props.activeTab === "active_jobs" && (
        <>
          <div className="Subcriptions_SubscriptionsTabs_inner">
            <div
              className={`${props.tab === 1 ? "Subcriptions_SubscriptionsTabs_inner_item_active" : "Subcriptions_SubscriptionsTabs_inner_item"}`}
              onClick={() => {
                props.setTab(1);
                props.activeJobSetFilter({ ...props.activeJobFilters, status: "" });
              }}
            >
              <div className="Subcriptions_SubscriptionsTabs_inner_item_circle" style={{ backgroundColor: "#2589F6" }}>
                <div className="Subcriptions_SubscriptionsTabs_inner_item_circle_text">{props.progressOverview?.totalCount}</div>
              </div>
              <div className="Subcriptions_SubscriptionsTabs_inner_item_text">All Subscriptions</div>
            </div>
            <div className="Subcriptions_SubscriptionsTabs_inner_border"></div>
            <div
              className={`${props.tab === 2 ? "Subcriptions_SubscriptionsTabs_inner_item_active" : "Subcriptions_SubscriptionsTabs_inner_item"}`}
              onClick={() => {
                props.setTab(2);
                props.activeJobSetFilter({ ...props.activeJobFilters, status: "in_repair" });
              }}
            >
              <div className="Subcriptions_SubscriptionsTabs_inner_item_circle" style={{ backgroundColor: "#FEC636" }}>
                <div className="Subcriptions_SubscriptionsTabs_inner_item_circle_text">{props.progressOverview?.inProgressCount}</div>
              </div>
              <div className="Subcriptions_SubscriptionsTabs_inner_item_text">Expire Shortly</div>
            </div>
            <div className="Subcriptions_SubscriptionsTabs_inner_border"></div>
            <div
              className={`${props.tab === 3 ? "Subcriptions_SubscriptionsTabs_inner_item_active" : "Subcriptions_SubscriptionsTabs_inner_item"}`}
              onClick={() => {
                props.setTab(3);
                props.activeJobSetFilter({ ...props.activeJobFilters, status: "kva_declined" });
              }}
            >
              <div className="Subcriptions_SubscriptionsTabs_inner_item_circle" style={{ backgroundColor: "#FF5F5F" }}>
                <div className="Subcriptions_SubscriptionsTabs_inner_item_circle_text">{props.progressOverview?.rejectedCount}</div>
              </div>
              <div className="Subcriptions_SubscriptionsTabs_inner_item_text">Expired</div>
            </div>
            <div className="Subcriptions_SubscriptionsTabs_inner_border"></div>
            <div
              className={`${props.tab === 4 ? "Subcriptions_SubscriptionsTabs_inner_item_active" : "Subcriptions_SubscriptionsTabs_inner_item"}`}
              onClick={() => {
                props.setTab(4);
                props.activeJobSetFilter({ ...props.activeJobFilters, status: "kva_confirmed" });
              }}
            >
              <div className="Subcriptions_SubscriptionsTabs_inner_item_circle" style={{ backgroundColor: "#36E052" }}>
                <div className="Subcriptions_SubscriptionsTabs_inner_item_circle_text">{props.progressOverview?.confirmedCount}</div>
              </div>
              <div className="Subcriptions_SubscriptionsTabs_inner_item_text">Recently Started</div>
            </div>
            <div className="Subcriptions_SubscriptionsTabs_inner_border"></div>
            <div
              className={`${props.tab === 5 ? "Subcriptions_SubscriptionsTabs_inner_item_active" : "Subcriptions_SubscriptionsTabs_inner_item"}`}
              onClick={() => {
                props.setTab(5);
                props.activeJobSetFilter({ ...props.activeJobFilters, status: "on_hold" });
              }}
            >
              <div className="Subcriptions_SubscriptionsTabs_inner_item_circle" style={{ backgroundColor: "#B84343" }}>
                <div className="Subcriptions_SubscriptionsTabs_inner_item_circle_text">{props.progressOverview?.onHoldCount}</div>
              </div>
              <div className="Subcriptions_SubscriptionsTabs_inner_item_text">Payment Recected</div>
            </div>
            <div className="Subcriptions_SubscriptionsTabs_inner_border"></div>
            <div
              className={`${props.tab === 6 ? "Subcriptions_SubscriptionsTabs_inner_item_active" : "Subcriptions_SubscriptionsTabs_inner_item"}`}
              onClick={() => {
                props.setTab(6);
                props.activeJobSetFilter({ ...props.activeJobFilters, status: "finished" });
              }}
            >
              <div className="Subcriptions_SubscriptionsTabs_inner_item_circle" style={{ backgroundColor: "#008444" }}>
                <div className="Subcriptions_SubscriptionsTabs_inner_item_circle_text">{props.progressOverview?.completedCount}</div>
              </div>
              <div className="Subcriptions_SubscriptionsTabs_inner_item_text">Trash</div>
            </div>
          </div>
        </>
      )}
      {/* <ReturnDevices /> */}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  progressOverview: {},
  loading: false,
  activeJobFilters: {},
});
const mapDispatchToProps = {
  fetchProgressOverview,
  activeJobSetFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsTabs);
