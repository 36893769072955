import React, { useState } from "react";
import "../../../pages/services/Services.scss";
import "./compnaySettingAddress.scss";

// component
import InputBox from "../../template/inputBox/InputBox";
import SelectBox from "../../template/selectBox/SelectBox";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  companySettingsState,
  setCompanySettings
} from "../../../toolkit/companySettings/companySettings.slice";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";


//constants 
import {countries} from "../../../constants/index";

const CompnaySettingsAddressStep1: React.FC<{ twickEdit?: any }> = ({}) => {
  const dispatch = useDispatch();
  const { companySettings } = useSelector(companySettingsState).companySettings;
  const handleChange = (value:string, key:string) =>{
    dispatch(setCompanySettings({
      ...companySettings,
      [key]: value

    }
    ))
  }
  
  const [selectedCountry, setSelecteContry] = useState()
  console.log(selectedCountry, "asd");
  return (
    <>
      <div className="CompnaySettingsGeneralDetails_details">
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "organization")} 
              labelText="Organization" 
              valueProp={companySettings.organization}
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "street")}  
              labelText="Street" 
              valueProp={companySettings.street}
            />
          </div>
          <div className="flex-row_col" style={{ maxWidth: 100 }}>
            <InputBox 
              handleChange={(value) => handleChange(value, "street_no")}  
              labelText="No." 
              valueProp={companySettings.street_no}
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "city")}
              labelText="City" 
              valueProp={companySettings.city}
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            <InputBox 
              handleChange={(value) => handleChange(value, "zip")}
              valueProp={companySettings.zip}
              labelText="Zip" 
            />
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-row_col">
            {/* <SelectBox selectedValue={companySettings.country} labelText="Country">
              {countries.map((country, i: number)=>(
                <div
                className=""
                onClick={() => {
                  handleChange(country.name, "country")
                }}
                key={i}
              >
                {country.name}
              </div>
              ))}
            </SelectBox> */}
            <SearchDropDown
                        labelText="Country"
                        data={countries}
                        selectedOption={setSelecteContry}
                        onMouseLeave={() => null}
                        
                      />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnaySettingsAddressStep1;
