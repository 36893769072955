import React, { useState, useEffect } from "react";
import "./SearchDropDown.scss";
import { v4 as uuid } from "uuid";

const SearchDropDown: React.FC<{
  labelText: any;
  valueProp?: any;
  disabledInput?: any;
  name?: string;
  required?: boolean;
  type?: string;
  isError?: boolean;
  data?: any;
  selectedOption: any;
  onMouseLeave: () => void;
}> = ({
  labelText,
  valueProp,
  disabledInput,
  selectedOption,
  isError = false,
  data,
  onMouseLeave,
}) => {
  const [searchValues, setSearchValues] = useState<
    Array<{
      id: any;
      name: string;
    }>
  >([]);

  const [searchText, setSearchText] = useState("");
  const [active, setActive] = useState(false);
  const [activeAdd, setActiveAdd] = useState(false);
  const [options, setOptions] = useState(data);
  const [selectedValue, setSelectedValue] = useState<
    Array<{
      id: any;
      name: string;
    }>
  >([]);

  const handelChange = (e: any) => {
    setSelectedValue([
      {
        id: 1,
        name: e.target.value,
      },
    ]);

    setSearchText(e.target.value);
    const word = e.target.value;
    const results = options.filter((item: any) => {
      return item.name.toLowerCase().includes(word.toLowerCase());
    });
    if (results.length == 0) {
      setActiveAdd(true);
    } else {
      setSearchValues(results);
    }

    if (searchText == "") {
      setActiveAdd(false);
    }
  };

  const handelSelect = (item: any) => {
    setSelectedValue([item]);
    selectedOption(item);
  };

  const handelAdd = (e: any) => {
    const newValue = {
      id: uuid(),
      name: e.target.id,
    };
    setOptions([...options, newValue]);
    setSelectedValue([newValue]);
    setSearchText("");
    e.preventDefault();

    selectedOption(newValue);
  };

  const newValue = selectedValue.map((option, index) => {
    return option.name;
  });

  return (
    <div
      className={`InputBox ${searchText ? " active" : ""} ${
        selectedValue.length !== 0 ? "active" : ""
      } ${disabledInput ? "disabled" : ""} ${
        isError ? "error-input" : ""
      } searchDropDown`}
    >
      <input
        className="input"
        type="text"
        onChange={handelChange}
        value={newValue}
      />

      <div className="drop">
        {activeAdd == true ? (
          <>
            <p className="AddOption" id={searchText} onMouseDown={handelAdd}>
              {searchText}{" "}
              {searchText == "" ? null : (
                <span
                  className="addBtn"
                  id={searchText}
                  onMouseDown={handelAdd}
                >
                  add
                </span>
              )}
            </p>{" "}
          </>
        ) : null}

        <>
          {searchText == "" ? (
            <>
              {options.map((item: any) => {
                return (
                  <p
                    className="option"
                    onMouseDown={() => handelSelect(item)}
                    onMouseLeave={onMouseLeave}
                    id={item.name}
                  >
                    {item.name}
                  </p>
                );
              })}
            </>
          ) : (
            <>
              {searchValues.map((item) => {
                return (
                  <p
                    className="option"
                    onMouseDown={() => handelSelect(item)}
                    id={item.name}
                  >
                    {item.name}
                  </p>
                );
              })}
            </>
          )}
        </>
      </div>

      <label className="InputBox_label">{labelText}</label>
    </div>
  );
};

export default SearchDropDown;
