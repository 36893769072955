import React, { useState } from "react";
import "../detailModal/DetailModal.css";

import { FiEdit, FiTrash2, FiX } from "react-icons/fi";

const DeleteModal: React.FC<{
  close: Function
}> = ({close}) => {
  return (
    <>
      <div className="backdrop">
        <div className="DetailModal-card-bg card_modal security_modal">
          <div className="DetailModal-card-header card_modal">
            <h6></h6>
            <div onClick={()=>close()}>
              <FiX style={{fontSize: 25}} />
            </div>
          </div>
          <div style={{marginTop: 20, marginBottom: 20}} >
            <div className="DetailModal-card-header card_modal">
              <h6 style={{textAlign: "center", width: '100%'}} >Are sure want to delete it ?</h6>
            </div>
          </div>

          <div className='d-flex' style={{justifyContent: "center"}} >
            <button className="btn btn-secondary" onClick={()=>close()}>Abort</button>
            <button className="btn btn-primary" onClick={()=>close()}>Yes, delete</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
