import React, { useState } from "react";
import { useSelector } from "react-redux";

import InvoiceModalTableItems from "./InvoiceModal.table.item";

import { useQuery } from "@apollo/client";
import { GET_ITEMS_BY_SEARCHED } from "../../../gql/stockItems/queries";

import { authState } from "../../../toolkit/auth/auth.slice";


interface Props {
  productData: any;
  setProductData: Function;
}

const InvoiceProduct = ({ productData, setProductData }: Props) => {
  const [searchedPro, setSearchedPro] = useState<string | number>();
  const {
    auth: { user },
  } = useSelector(authState);

  const { data } = useQuery(GET_ITEMS_BY_SEARCHED, {
    variables: {
      searchedText: searchedPro,
      userId: user.id,
    },
  });
  
  return (
    <>
      {productData.map((el: any, idx: number) => (
        <InvoiceModalTableItems
          key={`product-${idx}`}
          position={idx}
          suggestionItems={typeof data !== "undefined" ? data?.items : []}
          id={el.id}
          setProductData={setProductData}
          setSearchedText={setSearchedPro}
        />
      ))}
    </>
  );
};

export default InvoiceProduct;
