import React, { FormEvent } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ButtonToolbar,
  Button,
} from "rsuite";
import { Link } from "react-router-dom";

interface PropsType {
  email: string;
  setEmail: (email: string) => void;
  onVerifyEmail:
    | ((checkStatus: boolean, event: FormEvent<HTMLFormElement>) => void)
    | undefined;
  errMessage: string;
  btnLoading: boolean;
}

const Stage1 = ({
  email,
  setEmail,
  onVerifyEmail,
  errMessage,
  btnLoading,
}: PropsType) => {
  return (
    <Form fluid className="auth_Form" onSubmit={onVerifyEmail}>
      <h1 className="auth_Form_title">Sign Up</h1>
      <h3 className="auth_Form_title_sub">
        By signing up, I agree to the RepairCMS <span>Privacy policy</span>{" "}
        &amp; <span>Terms of servics</span>
      </h3>
      <FormGroup>
        <FormControl
          className="authFormInput"
          name="email"
          placeholder="Enter your email"
          value={email}
          onChange={setEmail}
          type="email"
          required
          style={{
            borderColor: errMessage.length > 0 ? "#f00" : "#0005",
          }}
        />
      </FormGroup>
      {errMessage?.length > 0 && (
        <FormGroup>
          <p className="err-msg">{errMessage}</p>
        </FormGroup>
      )}
      <div className="spacedHeight"></div>
      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            className="authBtn"
            type="submit"
            disabled={btnLoading}
          >
            Sign Up
          </Button>
        </ButtonToolbar>
      </FormGroup>
      <FormGroup>
        <ButtonToolbar>
          <Link to="/">
            <h2>
              Wrong account ? <span>Log in</span> instead
            </h2>
          </Link>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
};

export default Stage1;
