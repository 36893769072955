//types
import ServiceType from "../../components/services/servicesModal/Services.type";
export const BuildServiceSearchQuery = (value: string, queryCondition: any) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ name: { _ilike: `%${value}%` } },
				{ service_id: { _ilike: `%${value}%` } },
				{ model_rel: { name: {_ilike: `%${value}%` } } },
				{ manufacturer_rel: { name: { _ilike: `%${value}%` } } },
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildServiceExportColumns = () =>{
	return [{
		label:"Service ID",
		value: "service_id"
	},{
		label:"Service Name",
		value: "name"
	},{
		label:"Description",
		value: "description"
	},{
		label:"Manufacturer",
		value: "manufacturer"
	},{
		label:"Category",
		value: "category"
	},{
		label:"Model",
		value: "model"
	},{
		label:"Vat (%)",
		value: "vat"
	},{
		label:"Purchase Price (Excl. VAT)",
		value: "price_excl_vat"
	},{
		label:"Purchase Price (Incl. VAT)",
		value: "price_incl_vat"
	},{
		label:"Tags",
		value: "tags"
	}]
}
export const BuildServiceExportData = (items: ServiceType[]) =>{
	let data:any[] = []
	if(Array.isArray(items)){
		data = items.map((item: ServiceType) => {
			return {
				service_id: item.service_id,
				name: item.name,
				description: item.description,
				manufacturer: item.manufacturer_rel ? item.manufacturer_rel.name : "N/A",
				category: item.category_rel ? item.category_rel.name : "N/A",
				model: item.model_rel ? item.model_rel.name : "N/A",
				vat: item.vat,
				price_incl_vat: item.price_incl_vat,
				price_excl_vat: item.price_excl_vat,
				tags: item.tags?.join(","),
			}
		})
	}
	return data;
}
export const BuildServiceSortQuery = (value: string) => {
	switch(value){
		case "numeric_asc":
			return {price_incl_vat: "asc"}
		case "numeric_desc":
			return {price_incl_vat: "desc"}
		case "alpha_asc":
			return {name: "asc"}
		case "alpha_desc":
			return {name: "desc"}
	}
}
export const BuildServiceFilterQuery = (filterValue: any, queryCondition: any) => {
	if (Object.keys(filterValue).length) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		if (filterValue?.hasOwnProperty("manufacturer") && filterValue?.manufacturer?.length) {
			conditionTemp = {
				...conditionTemp,
				manufacturer: {_in: filterValue.manufacturer}
			}
		}
		if (filterValue?.hasOwnProperty("model") && filterValue?.model?.length) {
			conditionTemp = {
				...conditionTemp,
				model: {_in: filterValue.model}
			}
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}