import React, { useState } from "react";

// icons
import { FiPlus, FiArrowLeftCircle, FiInfo, FiTrash2 } from "react-icons/fi";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import {
  CREATE_INVOICE,
  CREATE_INVOICE_PRODUCT,
  CREATE_INVOICE_SERVICES,
} from "../../../gql/invoice/mutation";

//redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

// components
import InvoiceModalTable from "./InvoiceModal.table";
import InvoiceModalLogo from "./InvoiceModal.logo";
import InvoiceModalDetails from "./InvoiceModal.details";
import { showAlert } from "../../../helpers";


const InvoiceModal: React.FC<{ Close: any }> = ({ Close }) => {
  const {
    auth: { user },
  } = useSelector(authState);

  const [createInvoice, { data: invoiceData, error: invoiceError }] =
    useMutation(CREATE_INVOICE);
  const [
    createInvoiceProduct,
    { data: invoiceProductData, error: invoiceProductError },
  ] = useMutation(CREATE_INVOICE_PRODUCT);
  const [
    createInvoiceService,
    { data: invoiceServiceData, error: invoiceServiceError },
  ] = useMutation(CREATE_INVOICE_SERVICES);

  const [productData, setProductData] = useState<any>([]);
  const [serviceData, setServiceData] = useState<any>([]);
  const [textData, setTextData] = useState<string>("");
  const [onClickText, setOnClickText] = useState<boolean>(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState<any>(
    {}
  );

  const totalAmount = () => {
    let productPrice = 0;
    let servicePrice = 0;

    if (productData.length > 0) {
      if (
        typeof productData.reduce((a: any, b: any) => a?.amount + b?.amount) ===
        "number"
      ) {
        productPrice = productData.reduce(
          (a: any, b: any) => a?.amount + b?.amount
        );
      } else {
        productPrice = productData.reduce(
          (a: any, b: any) => a?.amount + b?.amount
        ).amount;
      }
    }

    if (serviceData.length > 0) {
      if (
        typeof serviceData.reduce((a: any, b: any) => a?.amount + b?.amount) ===
        "number"
      ) {
        servicePrice = serviceData.reduce(
          (a: any, b: any) => a?.amount + b?.amount
        );
      } else {
        servicePrice = serviceData.reduce(
          (a: any, b: any) => a?.amount + b?.amount
        ).amount;
      }
    }

    return productPrice + servicePrice;
  };

  const onSaveInvoice = async () => {
    // console.log("-----", selectedCustomerDetails, totalAmount()); return;
    const {
      data: {
        insert_invoices: { returning },
      },
    } = await createInvoice({
      variables: {
        objects: {
          customer_id: selectedCustomerDetails?.id,
          // discount_type: ""
          // discount_value
          invoice_id: Date.now().toString(),
          // payment_condition
          // payment_information
          payment_method: "Card",
          total_amount: totalAmount(),
        },
      },
    });
    
    if (productData.length > 0) {
      let productArr: any[] = [];
      productData.forEach((el: any) => {
        productArr.push({
          invoice_id: returning[0].id,
          unit: el?.unit,
          item_id: el?.productId,
        });
      });
      createInvoiceProduct({
        variables: {
          objects: productArr,
        },
      });
    }

    if (serviceData.length > 0) {
      let serviceArr: any[] = [];
      serviceData.forEach((el: any) => {
        serviceArr = [
          ...serviceArr,
          {
            invoice_id: returning[0].id,
            unit: el?.unit,
            service_id: el?.productId,
          },
        ];
      });
      createInvoiceService({
        variables: {
          objects: serviceArr,
        },
      });
      showAlert({
        title: "Success!",
        message: "Invoice created successfully",
        type: "success",
      });
    }
  };

  return (
    <>
      <div className="desk-only">
        <div className="InvoiceModal">
          <div className="InvoiceModal_header">
            <div className="InvoiceModal_header_back">
              <FiArrowLeftCircle />
            </div>
            <div className="InvoiceModal_header_Title">New Invoice</div>
            <button className="" onClick={onSaveInvoice}>
              Create Invoice
            </button>
          </div>
          <div className="InvoiceModal_body">
            <div className="InvoiceModal_body_inner">
              <InvoiceModalLogo />

              <InvoiceModalDetails
                selectedCustomerDetails={selectedCustomerDetails}
                setSelectedCustomerDetails={setSelectedCustomerDetails}
              />

              <InvoiceModalTable
                productData={productData}
                setProductData={setProductData}
                serviceData={serviceData}
                setServiceData={setServiceData}
                textData={textData}
                setTextData={setTextData}
                onClickText={onClickText}
                setOnClickText={setOnClickText}
                amount={totalAmount()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceModal;
