import React, { FormEvent } from "react";
import {
  Form,
  FormGroup,
  ButtonToolbar,
  Button,
} from "rsuite";
import OtpInput from "react-otp-input";

interface PropsType {
  otp: string;
  setOTP: (otp: string) => void;
  onVerifyOTP: ((event: React.SyntheticEvent<Element, Event>) => void);
  errMessage: string;
  btnLoading: boolean;
}

const Stage2 = ({ otp, setOTP, errMessage, onVerifyOTP, btnLoading }: PropsType) => {
  return (
    <Form fluid className="auth_Form">
      <div className="spacedHeight"></div>
      <h1 className="auth_Form_title">Verify your Email</h1>
      <h3 className="auth_Form_title_sub" style={{ marginBottom: 10 }}>
        {" "}
        <b> You are almost there!</b>
      </h3>
      <h2>
        Thank you for signing up for RepairCMS, Enter the OTP below to verify
        your email and we will help you get started.
      </h2>
      <OtpInput
        value={otp}
        onChange={setOTP}
        numInputs={4}
        inputStyle="login_otp"
        hasErrored={true}
        shouldAutoFocus={true}
        isInputNum={true}
        isInputSecure={false}
      />
      {errMessage?.length > 0 && (
        <FormGroup>
          <p className="err-msg">{errMessage}</p>
        </FormGroup>
      )}
      <FormGroup>
        <ButtonToolbar>
          <Button
            appearance="primary"
            className="authBtn"
            onClick={onVerifyOTP}
            disabled={btnLoading}
          >
            Verify Your Email
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
};

export default Stage2;
