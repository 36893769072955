import React, { useEffect, useState } from "react";
import { IonImg } from "@ionic/react";
import {
	FiBell,
	FiUser,
	FiLayers,
} from "react-icons/fi";
import { Dropdown } from "rsuite";
import { Link } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { fetchNotifications } from "../../stores/notifications/actions";
const NotificationBox: React.FC<{
	fetchNotifications: Function
	notifications: any
}> = (props) => {
	useEffect(() => {
		// props.fetchNotifications()
	}, [])
	return (
		<Dropdown
			trigger={["click"]}
			placement="bottomEnd"
			className="dropDownThemeWrapper Notification_drop"
			renderTitle={() => (
				<button className="btn-trans">
					<div className="btn-pin">{props.notifications?.length}</div>
					<FiBell color={"#fff"} />
				</button>
			)}
		>
			<div className="dropDownTheme">
				<div className="dropDownThemeTitle">
					<b> Notifications</b>
				</div>
				{props.notifications.map((notifiation:any, i:number)=>(
					<Link
					to="/"
					className="dropDownProfile-Link"
					style={{ width: "100%" }}
					key={i}
				>
					<FiUser />
					<p>
						{/* The <span>Job ID: 54646</span> the Quote has been confirmed{" "} */}
						{notifiation.message}
					</p>
				</Link>
				))}
				
				{/* <Link
        to="/"
        className="dropDownProfile-Link"
        style={{ width: "100%" }}
        >
        <IonImg
            src="/assets/bg/Rectangle.png"
            alt=""
            className="btn_trans_profile_img"
        />
        <p>
            <span>Johne Doe</span> has messaged you
        </p>
        </Link> */}
			</div>
		</Dropdown>
	);
};
const mapStateToProps = (state: any) => ({
	notifications: []
});
const mapDispatchToProps = {
	fetchNotifications
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationBox);