import React, { useState } from "react";
import "../../detailModal/DetailModal.css";
import { Toggle } from "rsuite";
import { FiArrowLeftCircle, FiHome } from "react-icons/fi";

const Header: React.FC<{ back: any; name: string; chatToggle: any }> = ({
  back,
  name,
  chatToggle,
}) => {
  return (
    <div className="DetailModal-main-middle-view-header">
      <div className="DetailModal-main-middle-view-header-back" onClick={back}>
        <FiArrowLeftCircle />
      </div>
      <div className="DetailModal-main-middle-view-header-title-spaced">
        <div
          className="DetailModal-main-middle-view-header-row"
          style={{ width: "80%" }}
        >
          <div className="DetailModal-main-middle-view-header-title-text none">
            {name}
          </div>
          <div className="d-flex-start">
            <h6>Booking Channel:</h6>
            <div className="blue-boc">
              <FiHome />
            </div>
          </div>
        </div>
        <div className="desk-only">
          <Toggle size="lg" checkedChildren="" unCheckedChildren="Edit" />
        </div>
      </div>
    </div>
  );
};

export default Header;
