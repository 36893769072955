const validator = require("validator"),
  isEmpty = require("../isEmpty");

const validateCreateCustomer = data => {
  let errors = {};
  data.type = !isEmpty(data.type) ? data.type : "";
  data.firstName = !isEmpty(data.firstName) ? data.firstName : "";
  data.lastName = !isEmpty(data.lastName) ? data.lastName : "";

  if (validator.isEmpty(data.type)) {
    errors.type = "Type is required";
  }
  if (!validator.isLength(data.firstName, { min: 2, max: 30 })) {
    errors.firstName = "First Name must be min 2 and max 30 characters";
  }
  if (validator.isEmpty(data.firstName)) {
    errors.firstName = "First Name is required";
  }
  if (!validator.isLength(data.lastName, { min: 2, max: 30 })) {
    errors.lastName = "Last Name must be min 2 and max 30 characters";
  }
  if (validator.isEmpty(data.lastName)) {
    errors.lastName = "Last Name is required";
  }
  return {
    errors,
    isValid: isEmpty(errors)
  };
};
export default validateCreateCustomer;