import { gql } from "@apollo/client";

export const GET_COMPANY_SETTING = gql`
  query GetCompanyDetails($createdBy: uuid!) {
    company_details(where: {created_by: {_eq: $createdBy}}) {
        bank_bic
        bank_iban
        bank_name
        ceo
        city
        company_logo
        country
        created_by
        default_tax_rate
        email
        fax
        id
        opening_hours
        organization
        phone
        registration_no
        street
        street_no
        tax_id
        tax_uid
        website
        zip
      }
  }
`;
