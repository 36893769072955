import React, { useRef, useState } from "react";
import { Dropdown, RadioGroup, Radio } from "rsuite";
const SortPicker: React.FC<{
  setSort: Function;
}> = ({ setSort }) => {
  const filterRef = useRef<any>(null);
  const [value, setValue] = useState<string>("");
  return (
    <Dropdown
      trigger={["click"]}
      className="actionDropPageHeader export sort"
      placement="bottomEnd"
      renderTitle={() => (
        <img src="/assets/bg/sort.png" width="25px" height="18px" />
      )}
      ref={filterRef}
    >
      <div className="sort_drop">
        <h5>Sort</h5>
        <RadioGroup name="radioList" onChange={setValue}>
          <Radio value="numeric_asc">1 - 10 (ascending)</Radio>
          <Radio value="numeric_desc">10 - 1 (descending)</Radio>
          <Radio value="alpha_asc">A - Z (ascending)</Radio>
          <Radio value="alpha_desc">Z - A (descending)</Radio>
        </RadioGroup>
        <div className="d-flex">
          <button
            className="bt-secondary"
            onClick={() => filterRef.current.toggle()}
          >
            Abort
          </button>
          <button
            className="bt-primary"
            onClick={() => {
              setSort(value);
              filterRef.current.toggle();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Dropdown>
  );
};
export default SortPicker;
