import { Dropdown } from "rsuite";
import "../../../pages/services/Services.scss";

// icons
import { FiLayers, FiChevronDown } from "react-icons/fi";

const PageHeader = () => {
  return (
    <div className="PageHeader">
      <div className="PageHeader-left">
        <FiLayers />
        <h2>Items</h2>
      </div>
    </div>
  );
};

export default PageHeader;
