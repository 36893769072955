import React, { useState } from "react";
import "../detailModal/DetailModal.css";

import {
  FiMaximize,
  FiChevronDown,
  FiPlus,
  FiTrash2,
  FiEye,
  FiChevronUp,
} from "react-icons/fi";
import moment from "moment";

import NotesModal from '../notesModal/NotesModal';
//redux
import { connect } from "react-redux";
const Notes: React.FC<{
  setNotes: any,
  job: any
}> = ({ setNotes, job }) => {
  const [openNote, setOpenNote] = useState(true);
  const [openNotesModal, setOpenNotesModal] = useState(false);

  return (
    <>
      <div className="DetailModal-card-bg">
        {openNotesModal && (
          <NotesModal close={() => setOpenNotesModal(false)} />
        )}
        <div className="DetailModal-card-header">
          <h6>Notes</h6>
          <div
            className="DetailModal-card-body-row"
            style={{ justifyContent: "flex-end" }}
          >
            {/* <FiMaximize className="DetailModal-card-iconbtn cursor-pointer" /> */}
            <div onClick={() => setOpenNotesModal(true)}>
              <FiPlus className="DetailModal-card-iconbtn cursor-pointer" />
            </div>
            {openNote ? (
              <FiChevronUp
                className="DetailModal-card-iconbtn cursor-pointer"
                onClick={() => setOpenNote(!openNote)}
              />
            ) : (
              <FiChevronDown
                className="DetailModal-card-iconbtn cursor-pointer"
                onClick={() => setOpenNote(!openNote)}
              />
            )}
          </div>
        </div>
        {openNote && (
          <div className="DetailModal-card-body">
            {job.notes?.map((note: any, i: number) => (
              <div className="DetailModal-card-body-note-item">
                <div className="DetailModal-card-body-note-item-bottom">
                  <h6>{note.value}</h6>
                  <button className="DetailModal-card-body-note-item-bottom-eye" onClick={() => setOpenNotesModal(true)}>
                  <FiEye />
                </button>
                {/*<button className="DetailModal-card-body-note-item-bottom-delete">
                  <FiTrash2 />
                </button> */}
                </div>
                <div className="DetailModal-card-body-note-item-top">
                  <div className="DetailModal-card-body-note-item-top-text">
                    {moment(note.date).format("hh:mm")}
                  </div>
                  <div className="DetailModal-card-body-note-item-top-right">
                    {moment(note.date).format("DD/MM/YYYY")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
  loading: false,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Notes);