import React, { useState, useRef } from "react";
import { Dropdown, FlexboxGrid, Checkbox } from "rsuite";
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";
import "./TextTemplate.scss";

// component
import { TextTemplateItems } from "../../components/textTemplate";
import { PageHeader } from "../../components/compnaySettings";
import NewTemplate from "../../components/detailModalWrapper/newTemplate/NewTemplate";
import Paging from "../../components/common/paging/Paging";
import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint,
} from "react-to-print";

const TextTemplate: React.FC<{}> = (props) => {
  const [modal, setModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  

  const handlePageChange = () => {};
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
   
      <PageHeader
        onClick={() => null}
        loading={btnLoading}
        headerText1="Settings"
        headerText2="Text Templates"
        simpleSaveBtn={true}
        saveDropdown={false}
        handelPrint={handlePrint}
      />
    <FlexboxGrid className="TextTemplate">
      {/* <PageHeader /> */}
      <div className="TextTemplate_body">
        <div>
          <div className="TextTemplate_body_top">
            <div className="space-between">
              <div className="d-flex">
                <div className="TextTemplate_body_top_btn">
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader export sort"
                    placement="bottomEnd"
                    renderTitle={() => <img src="/assets/bg/sort.png" />}
                  >
                    <div className="sort_drop">
                      <h5>Sort</h5>
                      <Checkbox>1 - 10 (ascending)</Checkbox>
                      <Checkbox>10 - 1 (descending)</Checkbox>
                      <Checkbox>A - Z (ascending)</Checkbox>
                      <Checkbox>Z - A (descending)</Checkbox>
                      <div className="d-flex">
                        <button className="bt-secondary">Abort</button>
                        <button className="bt-primary">Apply</button>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                <div className="TextTemplate_body_top_btn">
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader export sort"
                    placement="bottomEnd"
                    renderTitle={() => <img src="/assets/bg/filter.png" />}
                  >
                    <div className="sort_drop">
                      <h5>Filter</h5>
                      <Checkbox>Job Type</Checkbox>
                      <Checkbox>Location</Checkbox>
                      <Checkbox>Account</Checkbox>
                      <Checkbox>Channels</Checkbox>
                      <div className="d-flex">
                        <button className="bt-secondary">Abort</button>
                        <button className="bt-primary">Apply</button>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>

              <div className="d-flex">
                <div className="JobsOverview_body_top_search">
                  <div className="searchIcon">
                    <FiSearch />
                  </div>
                  <input
                    type="text"
                    className="joboverview-searchInput"
                    placeholder="Search..."
                  />
                </div>
                <div
                  className="TextTemplate_body_top_btn blue_btn"
                  onClick={() => {
                    setModal(true);
                    console.log("ffffffff");
                  }}
                >
                  <FiPlus />
                </div>
                <div>
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader export"
                    placement="bottomEnd"
                    renderTitle={() => (
                      <FiMoreVertical
                        style={{
                          color: "#304a50",
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  >
                    <div className="dropDownRowSpaced" style={{ border: 0 }}>
                      <div className="d-flex">
                        <FiUpload />
                        <h6>Export to Excel</h6>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="TextTemplate_body_bottom">
            <TextTemplateItems />
          </div>
        </div>
        <div className="table-footer">
          <Paging activePage={1} pages={0} onPageClick={handlePageChange} />
        </div>
      </div>
      {modal && (
        <NewTemplate
          close={() => {
            setModal(false);
          }}
        />
      )}
    </FlexboxGrid>
    </>
  );
};

export default TextTemplate;
