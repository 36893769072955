const validator = require("validator"),
  isEmpty = require("../isEmpty");

const validateCreateStockItem = data => {
  let errors = {};
  data.name = !isEmpty(data.name) ? data.name : "";
  data.stock = !isEmpty(data.stock) ? data.stock : "";
  data.stock_unit = !isEmpty(data.stock_unit) ? data.stock_unit : "";
  data.manufacturer = !isEmpty(data.manufacturer) ? data.manufacturer : "";
  data.purchase_price_excl_vat = !isEmpty(data.purchase_price_excl_vat) ? data.purchase_price_excl_vat : "";
  data.purchase_price_incl_vat = !isEmpty(data.purchase_price_incl_vat) ? data.purchase_price_incl_vat : "";
  data.selling_price_excl_vat = !isEmpty(data.selling_price_excl_vat) ? data.selling_price_excl_vat : "";
  data.selling_price_incl_vat = !isEmpty(data.selling_price_incl_vat) ? data.selling_price_incl_vat : "";
  data.conditions = !isEmpty(data.conditions) ? data.conditions : "";

  if (validator.isEmpty(data.name)) {
    errors.name = "Product name is required";
  }
  if (validator.isEmpty(data.stock)) {
    errors.stock = "Stock is required";
  }
  if (validator.isEmpty(data.stock_unit)) {
    errors.stock_unit = "Stock unit is required";
  }
  if (validator.isEmpty(data.manufacturer)) {
    errors.manufacturer = "Manufacturer is required";
  }
  if (validator.isEmpty(data.purchase_price_excl_vat)) {
    errors.purchase_price_excl_vat = "Purchase price (excl vat) is required";
  }
  if (validator.isEmpty(data.purchase_price_incl_vat)) {
    errors.purchase_price_incl_vat = "Purchase price (incl vat) is required";
  }
  if (validator.isEmpty(data.selling_price_excl_vat)) {
    errors.selling_price_excl_vat = "Selling price (excl vat) is required";
  }
  if (validator.isEmpty(data.selling_price_incl_vat)) {
    errors.selling_price_incl_vat = "Selling price (incl vat) is required";
  }
  if (validator.isEmpty(data.conditions)) {
    errors.conditions = "Condition is required";
  }
  return {
    errors,
    isValid: isEmpty(errors)
  };
};
export default validateCreateStockItem;