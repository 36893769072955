import React, { useState, useEffect, useRef } from "react";
import {
  FlexboxGrid,
  SelectPicker,
  Uploader,
  Message,
  Loader,
  Toggle,
} from "rsuite";
import "./LabelSettings.scss";
import { v4 as uuid } from "uuid";
import SearchDropDown from "../../components/template/searchDropDown/SearchDropDown";
import SelectBox from "../../components/template/selectBox/SelectBox";
import { FaTrashAlt } from "react-icons/fa";
import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint,
} from "react-to-print";
import Barcode from "../../assets/bg/bar.png";
import QRcode from "../../assets/bg/qr.png";
// component
import {
  PageHeader,
  CompnaySettingsAddress,
  CompnaySettingsContact,
  CompnaySettingsTAX,
  CompnaySettingsBank,
  CompnaySettingsLogo,
  CompnaySettingsReceipt,
} from "../../components/compnaySettings";
import InputBox from "../../components/template/inputBox/InputBox";

function previewFile(file: any, callback: any) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

const LabelSettings: React.FC<{}> = (props) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selectedReceipt, selectedSelectedReceipt] = useState("Device Label");
  const [selectedMode, setSelectedMode] = useState("Landscape");
  const [selected, setSelected] = useState("");
  const [selected2, setSelected2] = useState("");

  const [items, setItems] = React.useState([
    {
      id: "2",
      value: "Device Label",
      label: "Device Label",
    },
    {
      id: "1",
      value: "Product Label",
      label: "Product Label",
    },
  ]);
  const [modes, setModes] = React.useState([
    {
      id: "1",
      name: "Landscape",
    },
    {
      id: "2",
      name: "Protrait",
    },
  ]);
  const [modes2, setModes2] = React.useState([
    {
      id: "1",
      name: "Landscape",
    },
    {
      id: "2",
      name: "Protrait",
    },
  ]);

  const renderMenu = (menu: any) => {
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          {/* <SpinnerIcon spin />  */}
          Loading...
        </p>
      );
    }
    return menu;
  };

  const handelSelect = (e: any) => {
    console.log(e, "is the value");
    selectedSelectedReceipt(e);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const deviceLabelData = [
    { id: uuid(), name: "Organzation" },
    { id: uuid(), name: "Job No." },
    { id: uuid(), name: "Barcode (EAN 128) " },
    { id: uuid(), name: "Customer Name" },
    { id: uuid(), name: "QR-Code (Tracking)" },
    { id: uuid(), name: "Model, Manufacturer" },
    { id: uuid(), name: "Date" },
    { id: uuid(), name: "Defect" },
    { id: uuid(), name: "Job Type" },
    { id: uuid(), name: "Pysical Location" },
  ];
  const productLabelData = [
    { id: uuid(), name: "Item No" },
    { id: uuid(), name: "Product Name" },
    { id: uuid(), name: "Barcode 1 (EAN 128) " },
    { id: uuid(), name: "Category" },
    { id: uuid(), name: "Manufacturer No." },
    { id: uuid(), name: "Manufacturer" },
    { id: uuid(), name: "Color" },
    { id: uuid(), name: "Conditions" },
    { id: uuid(), name: "Sales Price" },
    { id: uuid(), name: "Pysical Location" },
  ];

  const handelSelectMode = (value: string, key: string) => {
    setSelectedMode(value);
  };

  return (
    <>
      <PageHeader
        onClick={() => null}
        loading={btnLoading}
        headerText1="Settings"
        headerText2="Labels"
        simpleSaveBtn={false}
        saveDropdown={true}
        handelPrint={handlePrint}
      />
      {selectedReceipt == "Device Label" ? (
        <div className="full-width">
          <div className="dropdown_receipts">
            <SelectPicker
              placeholder="Device Label"
              defaultValue={"|"}
              data={items}
              renderMenu={renderMenu}
              searchable={false}
              onSelect={handelSelect}
            />
          </div>

          <div ref={componentRef}></div>
          <div className="Label-container">
            <div className="bar_section">
              <div className="qrcode_container">
                <img src={Barcode} alt="" />
                <p>JOB-No: 000142134</p>
                <div className="model_para">
                  <p>25.02.2022 | KVA | First Name Lastname | Organization</p>
                  <p>Manufactuer Modell | Defect | Pysical Location </p>
                </div>
              </div>
              <div className="barcode_container">
                <img src={QRcode} alt="" />
              </div>
            </div>
            <div className="d-flex space-between">
              <div className="input-container">
                <InputBox labelText="Length (mm)" handleChange={() => null} />
              </div>
              <div className="input-container">
                <InputBox labelText="Width (mm)" handleChange={() => null} />
              </div>
            </div>
            <div className="flex-end">
              <div className="input-container">
                {/* <SelectBox selectedValue={selectedMode} labelText="Mode">
                  {modes.map((item, i: number) => (
                    <div
                      className="selectBox-option"
                      onClick={() => {
                        handelSelectMode(item.name, "country");
                      }}
                      key={i}
                    >
                      {item.name}
                    </div>
                  ))}
                </SelectBox> */}
                <SearchDropDown
                        labelText="Mode"
                        data={modes}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                      />
              </div>
            </div>

            <div className="switches-container">
              {deviceLabelData.map((item) => {
                return (
                  <div className="switch">
                    <div className="f1">
                      <p>{item.name}</p>
                      {/* <p>Customer Ticketbooking via WebWidget</p> */}
                    </div>
                    <Toggle
                      size="md"
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="full-width">
          <div className="dropdown_receipts">
            <SelectPicker
              placeholder="Device Label"
              defaultValue={"|"}
              data={items}
              renderMenu={renderMenu}
              searchable={false}
              onSelect={handelSelect}
            />
          </div>

          <div ref={componentRef}></div>
          <div className="Label-container">
            <div className="bar_section">
              <div className="qrcode_container">
                <img src={Barcode} alt="" />
                <p>JOB-No: 000142134</p>
                <div className="model_para">
                  <p>25.02.2022 | KVA | First Name Lastname | Organization</p>
                  <p>Manufactuer Modell | Defect | Pysical Location </p>
                </div>
              </div>
              <div className="barcode_container">
                <h2 className="price">€125,00</h2>
              </div>
            </div>
            <div className="d-flex space-between">
              <div className="input-container">
                <InputBox labelText="Length (mm)" handleChange={() => null} />
              </div>
              <div className="input-container">
                <InputBox labelText="Width (mm)" handleChange={() => null} />
              </div>
            </div>
            <div className="flex-end">
              <div className="input-container">
                {/* <SelectBox selectedValue={selectedMode} labelText="Mode">
                  {modes.map((item, i: number) => (
                    <div
                      className="selectBox-option"
                      onClick={() => {
                        handelSelectMode(item.name, "country");
                      }}
                      key={i}
                    >
                      {item.name}
                    </div>
                  ))}
                </SelectBox> */}
                <SearchDropDown
                        labelText="Mode"
                        data={modes2}
                        selectedOption={setSelected2}
                        onMouseLeave={() => null}
                      />
              </div>
            </div>

            <div className="switches-container">
              {productLabelData.map((item) => {
                return (
                  <div className="switch">
                    <div className="f1">
                      <p>{item.name}</p>
                      {/* <p>Customer Ticketbooking via WebWidget</p> */}
                    </div>
                    <Toggle
                      size="md"
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LabelSettings;
