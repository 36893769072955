import React, { useState } from "react";
import { Toggle } from "rsuite";

// icons
import { FiSearch } from "react-icons/fi";

// component

const StockItemsModalSerial: React.FC<{}> = (props) => {

  return (
    <>
      <br />
      <div className="StockItemsModal_inner_flex_start">
        <div className="col">
          <div className="StockItemsModal_inner_blueShow">
            <img src="assets/bg/wrold.png" alt="" />
            <div className="f1">
              <h6>Show This Service On Your Home Page</h6>
              <p>Customer Ticketbooking via WebWidget</p>
            </div>
            <Toggle size="md" checkedChildren="ON" unCheckedChildren="OFF" />
            <div className="StockItemsModal_vertical_hr"></div>
            <div className="col" style={{justifyContent: "flex-end", display: "flex"}} >
              <div className="StockItems_body_top_search" style={{ width: 200 }}>
                <input type="text" placeholder="Search & Add Item" />
                <FiSearch />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="StockItemsModal_SerialNumber" >
        <div className="col" >
          <p>Serial No.</p>
          <h6>10493700001739413949</h6>
        </div>
        <div className="StockItemsModal_vertical_hr"></div>
        <div className="col" >
          <p>Booked In</p>
          <h6>10.02.2022</h6>
        </div>
        <div className="col" >
          <p>Delivery Note No.</p>
          <h6 style={{color: '#2589F6'}} >LS-10498610</h6>
        </div>
      </div>
    </>
  );
};

export default StockItemsModalSerial;
