import React, { useState } from "react";
import "../../detailModal/DetailModal.css";
import {
  FiArrowLeftCircle,
  FiChevronDown,
  FiEye,
  FiPrinter,
  FiMail,
} from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Dropdown } from "rsuite";

const Body: React.FC<{children: any}> = ({ children}) => {
  return (
    <div className="DetailModal-main-middle-view-body">
      {children}
    </div>
  );
};

export default Body;
