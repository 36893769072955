import React, { useState, useRef } from "react";
import "../detailModal/DetailModal.css";

import { FiEdit, FiTrash2 } from "react-icons/fi";
import moment from "moment";
import Swal from "sweetalert2";

import DeleteModal from '../deleteModal/DeleteModal';

//helper
import { showAlert } from "../../../helpers/index";

//redux
import { connect } from "react-redux";
import {updateActiveJob} from "../../../stores/activeJobs/actions";
import { useEffect } from "react";

const NotesModal: React.FC<{
  close: Function;
  job: any;
  updateActiveJob: Function;
  profile: any;
}> = ({
  close,
  job,
  updateActiveJob,
  profile
}) => {
  const textareaRef = useRef<any>(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [note, setNote] = useState<string>("");
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number>(-1);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<any[]>([]);


  const handleUpdateNotes = async() =>{
    try{
      setBtnLoading(true)
      let notesTemp:any[] = [...notes]
      if(selectedNoteIndex >= 0){
        // notesTemp[selectedNoteIndex].value = note
      }else if(note?.length > 0){
        notesTemp.push({
          user: profile.fullname,
          value: note
        })
      }
      if((selectedNoteIndex >= 0) || (note?.length > 0)){
        await updateActiveJob({
          ...job,
          notes: notesTemp
        })
        setNote("")
        showAlert({ title: "Success!", message: "Notes Updated Successfully!", type: "success" })
      }
    }catch(err){
      showAlert({ title: "Error!", message: "Something Went Wrong", type: "danger" })
    }finally{
      setBtnLoading(false)
    }
  }
  const handleDeleteNote = (index: number) => {
    Swal.fire({
      title: 'Are you sure want to delete this note?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: "warning",
      heightAuto: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        let notesTemp:any[] = [...notes]
        notesTemp.splice(index, 1);
        await updateActiveJob({
          ...job,
          notes: notesTemp
        })
        setNote("")
        showAlert({ title: "Success!", message: "Note Deleted Successfully!", type: "success" })
      }
    }).catch((err) => {
      showAlert({ title: "Error!", message: "Something Went Wrong", type: "danger" })
    })
  }
  const handleChangeNote = (e:any, i:number) =>{
    let notesTemp:any[] = [...notes]
    notesTemp[i].value = e.target.value
    setNotes([...notesTemp])
    setSelectedNoteIndex(i)
  }
  useEffect(()=>{
    if(note?.length === 0){
      setSelectedNoteIndex(-1)
    }
  }, [note])
  useEffect(()=>{
    textareaRef.current.focus()
  }, [])
  useEffect(()=>{
    if(Array.isArray(job?.notes)){
      setNotes([...job.notes])
    }
  }, [job])
  return (
    <>
      <div className="backdrop">
      {openDeleteModal && (
        <DeleteModal close={()=>setOpenDeleteModal(false)}/>
      )}
        <div className="DetailModal-card-bg lg card_modal">
          <div className="DetailModal-card-header card_modal">
            <h6>Notes</h6>
          </div>
          <div className="DetailModal-card-body">
            {notes?.map((note:any, i:number) => (
              <div className="DetailModal-card-body-note-item" key={i}>
                <div className="DetailModal-card-body-note-item-bottom">
                  {/* <h6>
                    {note.value}
                  </h6> */}
                  <input 
                    className="note-textinput"
                    type="text" 
                    value={note.value}
                    onChange={(e)=>handleChangeNote(e, i)}
                  />
                </div>
                <div
                  className="DetailModal-card-body-note-item-top"
                  style={{ marginTop: 10 }}
                >
                  <div className="DetailModal-card-body-note-item-top-text">
                  {moment(note.date).format("hh:mm")}
                  </div>
                  <div className="d-flex">
                    <div className="DetailModal-card-body-note-item-top-text">
                    {moment(note.date).format("DD/MM/YYYY")}
                    </div>
                    {/* <div onClick={() => {
                      setNote(note.value)
                      setSelectedNoteIndex(i)
                      textareaRef.current.focus()
                    }} >
                      <FiEdit 
                        style={{ marginLeft: 10, cursor: "pointer" }} 
                      />
                    </div> */}
                    <div onClick={() => handleDeleteNote(i)} >
                      <FiTrash2 style={{ marginLeft: 10, cursor: "pointer" }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="DetailModal-card-body-note-item">
              <div className="DetailModal-card-body-note-item-bottom">
                <textarea 
                  className="form-control DetailModal-card-body-note-item-bottom_textArea" placeholder="Write new notes"
                  value={note}
                  onChange={(e)=>setNote(e.target.value)}
                  ref={textareaRef}
                ></textarea>
              </div>
            </div>
          </div>
          <div className='d-flex' style={{justifyContent: "flex-end"}} >
            <button className="btn btn-secondary" onClick={()=>close()}>Close</button>
            <button 
              className="btn btn-primary" 
              onClick={()=>handleUpdateNotes()}
              disabled={btnLoading}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
  loading: false,
  profile: {},
});
const mapDispatchToProps = {
  updateActiveJob
};
export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);