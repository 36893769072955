import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icons
import { FiEdit2 } from "react-icons/fi";

// component
import CompnaySettingsAddressStep1 from "./CompnaySettingsAddress.step1";

const CompnaySettingsAddress = () => {
  const [edit, setEdit] = useState(false);

  return (
    <div className="CompnaySettingsGeneralDetails">
      <div className="flex-1 d-flex-start">
        <h2>Company Adress</h2>
      </div>

      <CompnaySettingsAddressStep1 />
    </div>
  );
};

export default CompnaySettingsAddress;
