import React, { useState } from "react";
// icons
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import SelectedServicesSection from "../selectedServicesSection/SelectedServicesSection";

const NewStep3: React.FC<{
  ChangeStep: Function;
  ChangeStepBack: Function;
}> = ({ ChangeStep, ChangeStepBack }) => {
  const [tab, setTab] = useState(1);
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper start">
        <SelectedServicesSection />
          <div className="stepFormWrapper-right">
            <div className="stepFormWrapper-right_tab">
              <div
                className={`stepFormWrapper-right_tab-item ${
                  tab === 1 ? "active" : ""
                }`}
                onClick={() => {
                  setTab(1);
                }}
              >
                Private
              </div>
              <div
                className={`stepFormWrapper-right_tab-item ${
                  tab === 2 ? "active" : ""
                }`}
                onClick={() => {
                  setTab(2);
                }}
              >
                Organization
              </div>
            </div>
            {tab === 1 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Mr." handleChange={() => null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Title" handleChange={() => null} />
                  </div>
                </div>

                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox
                      labelText="First Name"
                      handleChange={() => null}
                    />
                  </div>
                  <div className="col">
                    <InputBox labelText="Last Name" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Telefon" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Email" handleChange={() => null} />
                  </div>
                </div>
                {/* <div
                  className="JobModal_row"
                  style={{ marginBottom: 15 }}
                  onClick={() => {
                    setAddAddress(!addAddress);
                  }}
                >
                  <div className="col" style={{ color: "#2589f6" }}>
                    <FaPlusCircle style={{ marginRight: 10 }} />
                    Add Address
                  </div>
                </div> */}
                {/* {addAddress && ( */}
                  <>
                    <div className="JobModal_row" style={{ marginBottom: 15 }}>
                      <div className="col">
                        <InputBox
                          labelText="Street"
                          handleChange={() => null}
                        />
                      </div>
                      <div style={{ width: 100, padding: "1px 10px" }}>
                        <InputBox labelText="No." handleChange={() => null} />
                      </div>
                    </div>
                    <div className="JobModal_row" style={{ marginBottom: 15 }}>
                      <div className="col">
                        <InputBox labelText="Zip" handleChange={() => null} />
                      </div>
                      <div className="col">
                        <InputBox labelText="City" handleChange={() => null} />
                      </div>
                    </div>
                    <div className="JobModal_row" style={{ marginBottom: 15 }}>
                      <div className="col">
                        <InputBox
                          labelText="Frankreich"
                          handleChange={() => null}
                        />
                      </div>
                    </div>
                  </>
                {/* )} */}
              </>
            )}
            {tab === 2 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox
                      labelText="Organization"
                      handleChange={() => null}
                    />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Mr." handleChange={() => null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="Title" handleChange={() => null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="Position" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox
                      labelText="First Name"
                      handleChange={() => null}
                    />
                  </div>
                  <div className="col">
                    <InputBox labelText="Last Name" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Telefon" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Email" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Street" handleChange={() => null} />
                  </div>
                  <div style={{ width: 100, padding: "1px 10px" }}>
                    <InputBox labelText="No." handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Zip" handleChange={() => null} />
                  </div>
                  <div className="col">
                    <InputBox labelText="City" handleChange={() => null} />
                  </div>
                </div>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <InputBox labelText="Country" handleChange={() => null} />
                  </div>
                </div>
              </>
            )}
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewStep3;
