import React, { useState } from "react";
import { Dropdown } from "rsuite";

// icon
import { FiMoreHorizontal } from "react-icons/fi";

//types
import StockItemType, {StockImageType} from "../stockItemsModal/StockItems.type";

const StockItemsItems: React.FC<{
  id: string;
  item: StockItemType;
  setModal: (m: boolean)=> void;
  setEditedId: (i: string)=> void;
  setIsEdited: (i: boolean)=> void;
  removeCustomer: Function;
}> = ({id, item, setModal, setEditedId, setIsEdited, removeCustomer}) => {
  const onEdit = () => {
    setModal(true);
    setEditedId(id);
    setIsEdited(true);
  }
  const getFavouriteImage = () =>{
    if(Array.isArray(item.images) && item.images?.length){
      const favouritePic:StockImageType[] = item.images.filter((image: StockImageType) => image.favourite)
      if(favouritePic.length){
        return favouritePic[0].link
      }else{
        return "/assets/software.png"
      }
    }else{
      return "/assets/software.png"
    }
  }
  return (
    <>
      <div className="desk-only">
        <div className="StockItemsItems">
          <div className="StockItemsItems_left" onClick={onEdit}>
            <img src={getFavouriteImage()} alt="" />
            <div className="StockItemsItems_left_1">
              <p>Item Name</p>
              <h6>{item.name}</h6>
            </div>
            <div className="StockItemsItems_left_2">
              <p>Color</p>
              <h6>{item.color_rel?.name || "N/A"}</h6>
            </div>
            <div className="StockItemsItems_left_3">
              <p>Item No.</p>
              <h6>{item.item_no}</h6>
            </div>
            <div className="StockItemsItems_left_3">
              <p>Manufacturer</p>
              <h6>{item.manufacturer_rel?.name || "N/A"}</h6>
            </div>
            <div className="StockItemsItems_left_3">
              <p>Category</p>
              <h6>{item.category_rel?.name || "N/A"}</h6>
            </div>
            <div className="StockItemsItems_left_3">
              <p>Pysical Location</p>
              <h6>{item.physical_location || "N/A"}</h6>
            </div>
            <div className="StockItemsItems_left_3">
              <p>Stock Amount</p>
              <h6>{item.stock} {item.stock_unit}</h6>
            </div>
          </div>
          <div className="StockItemsItems_right">
            <div className="" onClick={onEdit}>
              <h5>
                <p> € {item.selling_price_incl_vat}</p>
                <span>Incl. {item.vat}% VAT</span>
              </h5>

              <h6 style={{textTransform: "uppercase"}}>{item.conditions}</h6>
            </div>
            {/* <FiMoreHorizontal /> */}
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/icon/dotsHorizontal.png" />}
            >
              <div className="dropDownProfile">
                <span className="dropDownProfile-Link" onClick={onEdit}>Edit</span>
                <span className="dropDownProfile-Link" onClick={()=> removeCustomer(id)}>Remove</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockItemsItems;
