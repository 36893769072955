import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { Button, Checkbox } from "rsuite";
import { FiX, FiTrash2, FiPlus } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";

//helper
import { showAlert } from "../../../helpers/index";

//redux
import { connect } from "react-redux";
import { updateActiveJob } from "../../../stores/activeJobs/actions";

const SetComplete: React.FC<{ 
  open: boolean; 
  close: any;
  updateActiveJob: Function;
  job: any;
}> = ({ 
  open, 
  close,
  updateActiveJob,
  job 
}) => {
  const [selected, setSelected] = useState("");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    try {
      setBtnLoading(true)
      await updateActiveJob({
        ...job,
        status: "finished"
      })
      close()
      showAlert({ title: "Success!", message: "Job status updated successfully!", type: "success" })
    } catch (err) {
      showAlert({ title: "Error!", message: "Something Went Wrong", type: "danger" })
    } finally {
      setBtnLoading(false)
    }
  }
  return (
    <div className="setComplete_modal">
      <div className="setComplete_modal-body">
        <FiX className="setComplete_modal-body-close" onClick={close} />
        <h5>Set Complete</h5>
        <img src="/assets/icon/5.png" />
        <h6>Customer Notification</h6>
        <div className="flex-row mb-2">
          <div className="card_modal_input_wrapper" style={{ width: 220 }}>
            <SelectBox selectedValue={selected} labelText="Channel">
              <div
                className=""
                onClick={() => {
                  setSelected("All");
                }}
              >
                <Checkbox>
                  <div className="d-flex-start">
                    <img src="/assets/bg/1.png" alt="" /> All
                  </div>
                </Checkbox>
              </div>
              <div
                className=""
                onClick={() => {
                  setSelected("Email/Chat");
                }}
              >
                <Checkbox>
                  <div className="d-flex-start">
                    <img src="/assets/bg/2.png" alt="" /> Email/Chat
                  </div>
                </Checkbox>
              </div>
              <div
                className=""
                onClick={() => {
                  setSelected("Mobile-SMS");
                }}
              >
                <Checkbox>
                  <div className="d-flex-start">
                    <img src="/assets/bg/3.png" alt="" /> Mobile-SMS
                  </div>
                </Checkbox>
              </div>
              <div
                className=""
                onClick={() => {
                  setSelected("WhatsApp");
                }}
              >
                <Checkbox>
                  <div className="d-flex-start">
                    <img src="/assets/bg/4.png" alt="" /> WhatsApp
                  </div>
                </Checkbox>
              </div>
            </SelectBox>
          </div>
        </div>
        <button 
          className="btn btn-primary"
          onClick={()=>handleSubmit()}
          disabled={btnLoading}
        >
          Set Complete
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  job: {},
});
const mapDispatchToProps = {
  updateActiveJob
};
export default connect(mapStateToProps, mapDispatchToProps)(SetComplete);