import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";

// icons
import { FiChevronRight, FiChevronLeft, FiUploadCloud, FiX, FiSearch } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";

const Step8: React.FC<{ ChangeStep: Function; ChangeStepBack: Function }> = ({ ChangeStep, ChangeStepBack }) => {
  const [tab, setTab] = useState(1);
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper">
          <div className="stepFormWrapper-left">
            <img src="/assets/icon/iLogo.png" alt="" style={{ width: 120, height: 120, objectFit: "contain" }} />
            <h5 style={{ marginTop: 15 }}>Apple SE 2020</h5>
            <p style={{ textAlign: "center", lineHeight: 1.4 }}>
              <b> IMEI: </b> 125389386491937 <br />
              <b> Storage: </b> 256 GB <br />
              <b> Color: </b> Black <br />
            </p>
          </div>
          <div className="stepFormWrapper-right">
            <h4 style={{ textAlign: "center", marginBottom: 10 }}>Signature</h4>
            <div className="stepFormWrapper-right_tab">
              <div
                className={`stepFormWrapper-right_tab-item ${tab === 1 ? "active" : ""}`}
                onClick={() => {
                  setTab(1);
                }}
              >
                Draw
              </div>
              <div
                className={`stepFormWrapper-right_tab-item ${tab === 2 ? "active" : ""}`}
                onClick={() => {
                  setTab(2);
                }}
              >
                upload
              </div>
            </div>
            {tab === 1 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <div className="JobModal_upload">
                      <SignatureCanvas penColor="green" canvasProps={{ width: 500, height: 200, className: "sigCanvas" }} />
                    </div>
                  </div>
                </div>
              </>
            )}
            {tab === 2 && (
              <>
                <div className="JobModal_row" style={{ marginBottom: 15 }}>
                  <div className="col">
                    <div className="JobModal_upload">
                      <FiUploadCloud />
                      <p style={{ textAlign: "center" }}>Drag here..</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default Step8;
