import React, { useState } from "react";

// icons
import { FaSearch } from "react-icons/fa";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";

const Step3: React.FC<{ ChangeStep: Function }> = ({ ChangeStep }) => {
  const [add, setAdd] = useState(false);
  const [selected, setSelected] = useState("");
  return (
    <>
      <div className="desk-only">
        {add === false ? (
          <>
            <div className="stepFormWrapper">
              <div className="stepFormWrapper-left">
                <h2>Select Model</h2>
              </div>
              <div className="stepFormWrapper-right">
                <div className="stepFormWrapper-right-search_lg">
                  <input type="text" placeholder="Manufacturer, Model.." />
                  <div className="stepFormWrapper-right-search_lg_btn">
                    <FaSearch />
                  </div>
                </div>
                <div className="stepFormWrapper-right_item_wrapper">
                  <div
                    className="stepFormWrapper-right_item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <img src="/assets/icon/iLogo.png" alt="" />
                    <h4>Apple iPhone SE 2020</h4>
                  </div>
                  <div
                    className="stepFormWrapper-right_item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <img src="/assets/icon/iLogo.png" alt="" />
                    <h4>Apple iPhone SE 2020</h4>
                  </div>
                  <div
                    className="stepFormWrapper-right_item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <img src="/assets/icon/iLogo.png" alt="" />
                    <h4>Apple iPhone SE 2020</h4>
                  </div>
                  <div
                    className="stepFormWrapper-right_item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <img src="/assets/icon/iLogo.png" alt="" />
                    <h4>Apple iPhone SE 2020</h4>
                  </div>
                  <div
                    className="stepFormWrapper-right_item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <img src="/assets/icon/iLogo.png" alt="" />
                    <h4>Apple iPhone SE 2020</h4>
                  </div>
                  <div
                    className="stepFormWrapper-right_item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <img src="/assets/icon/iLogo.png" alt="" />
                    <h4>Apple iPhone SE 2020</h4>
                  </div>
                </div>
                <div className="JobModal-btn">cannot find item?</div>
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    setAdd(!add);
                  }}
                >
                  Add +
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="stepFormWrapper">
              <div className="stepFormWrapper-left">
                <h2>Select Model</h2>
              </div>
              <div className="stepFormWrapper-right">
                <div className="" style={{marginBottom: 15,}} >
                  <InputBox labelText="Manufacturer" handleChange={()=> null} />
                </div>
                <div className="" style={{marginBottom: 15,}} >
                  <InputBox labelText="Model" handleChange={()=> null} />
                </div>
                <div className="" style={{marginBottom: 15,}} >
                  <SelectBox selectedValue={selected} labelText="Category">
                    <div
                      className=""
                      onClick={() => {
                        setSelected("All");
                      }}
                    >
                      <div className="d-flex-start">
                        <img src="/assets/bg/1.png" alt="" /> All
                      </div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("Email/Chat");
                      }}
                    >
                      <div className="d-flex-start">
                        <img src="/assets/bg/2.png" alt="" /> Email/Chat
                      </div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("Mobile-SMS");
                      }}
                    >
                      <div className="d-flex-start">
                        <img src="/assets/bg/3.png" alt="" /> Mobile-SMS
                      </div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("WhatsApp");
                      }}
                    >
                      <div className="d-flex-start">
                        <img src="/assets/bg/4.png" alt="" /> WhatsApp
                      </div>
                    </div>
                  </SelectBox>
                </div>
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    setAdd(!add);
                  }}
                >
                  Add +
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default Step3;
