import React, { useState, useEffect } from "react";
import "./TextAreaBox.css";

const TextAreaBox: React.FC<{ 
  labelText: any; 
  heightValue?: any;
  handleChange: (e: any)=> void; 
  valueProp?: any;
}> = ({ labelText, heightValue, handleChange, valueProp }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    }
  }, [valueProp]);

  return (
    <div className={`TextAreaBox ${value !== '' && 'active' }`} style={{height: heightValue ? heightValue : 100}} >
      <textarea
        className="TextAreaBox_input"
        value={value}
        onChange={(evt) => {
          handleChange(evt.target.value)
          setValue(evt.target.value);
        }}
      ></textarea>
      <label className="TextAreaBox_label">{labelText}</label>
    </div>
  );
};

export default TextAreaBox;
