import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
  fetchProfileApi,
  changePasswordApi,
  changeEmailApi,
  updateEmailApi
} from "./profile.api";

interface UserType {
  role: string;
  is_walkthrough_done: boolean;
  name: string;
  email: string;
  industry: string;
  id: string;
  is_email_verified: boolean;
  active: boolean;
  avatar: string | undefined | null;
  google_id: string | undefined | null;
  facebook_id: null;
  apple_id: string | undefined | null;
  updated_at: string;
  created_at: string;
  notification_token: string | undefined | null;
  stripe_id: string | undefined | null;
  position: string | null;
  short_name: string | null;
}

interface ProfileSettingsType {
  language: string;
  timezone: string | null;
  date_format: string | null;
  time_format: string | null;
  currency: string | null;
  id: string;
  background: string;
}
interface StateType {
  isLoading: boolean;
  errorMsg: string;
  user: UserType;
  profileSettings: ProfileSettingsType;
}

interface PayloadType {
  message: string;
  status: number;
}

const initialState: StateType = {
  isLoading: false,
  errorMsg: "",
  user: {
    role: "",
    is_walkthrough_done: false,
    name: "",
    email: "",
    industry: "",
    id: "",
    is_email_verified: false,
    active: false,
    avatar: null,
    google_id: null,
    facebook_id: null,
    apple_id: null,
    updated_at: "",
    created_at: "",
    notification_token: null,
    stripe_id: null,
    position: "",
    short_name: ""
  },
  profileSettings: {
    language: "",
    timezone: "",
    date_format: "",
    time_format: "",
    currency: "",
    id: "",
    background: "6"
  }
};

export const profileSlice: any = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileSettings: (state, { payload }) => {
      return {
        ...state,
        profileSettings: payload
      };
    },
  },
  extraReducers: {
    /** --------------Send OTP Reducer--------------- */
    [fetchProfileApi.pending]: (state) => {
      return { ...state, isLoading: true, errorMsg: "" };
    },
    [fetchProfileApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
        user: payload
      };
    },
    [fetchProfileApi.rejected]: (state, { payload }) => {
      return { ...state, isLoading: false, errorMsg: "Something went wrong!"};
    },
    /** --------------Change Password Reducer--------------- */
    [changePasswordApi.pending]: (state) => {
      return { ...state, errorMsg: "" };
    },
    [changePasswordApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        errorMsg: "",
      };
    },
    [changePasswordApi.rejected]: (state, { payload }) => {
      let errorMsg = "Something went wrong!";
      if(payload?.error?.message){
        errorMsg = payload.error.message
      }
      return { ...state, errorMsg};
    },
     /** --------------Change Email Reducer--------------- */
     [changeEmailApi.pending]: (state) => {
      return { ...state, errorMsg: "" };
    },
    [changeEmailApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        errorMsg: "",
      };
    },
    [changeEmailApi.rejected]: (state, { payload }) => {
      let errorMsg = "Something went wrong!";
      if(payload?.error?.message){
        errorMsg = payload.error.message
      }
      return { ...state, errorMsg};
    },
    /** --------------Update Email Reducer--------------- */
    [updateEmailApi.pending]: (state) => {
      return { ...state, errorMsg: "" };
    },
    [updateEmailApi.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        errorMsg: "",
      };
    },
    [updateEmailApi.rejected]: (state, { payload }) => {
      let errorMsg = "Something went wrong!";
      if(payload?.error?.message){
        errorMsg = payload.error.message
      }
      return { ...state, errorMsg};
    },
  },
});

export const profileState = (state: any) => state;
export const { setProfileSettings } = profileSlice.actions
export default profileSlice.reducer;
