import { gql } from "@apollo/client";

export const GET_ALL_COLORS = gql`
  query GetColors($where: colors_bool_exp, $limit: Int!, $offset: Int!) {
    colors(where: $where, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;
