import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";

import InputBox from "../../template/inputBox/InputBox";
import SelectBox from "../../template/selectBox/SelectBox";

import { title } from "../../../helpers";

import { CREATE_CUSTOMER_MUTATION } from "../../../gql/customers/mutation";
import { authState } from "../../../toolkit/auth/auth.slice";

interface SupportProps {
  setStep: Function;
}

const AddInvoiceCustomer = ({ setStep }: SupportProps) => {
  const [createCustomerMutation, { data, loading, error }] = useMutation(
    CREATE_CUSTOMER_MUTATION
  );
  const {
    auth: { user },
  } = useSelector(authState);

  const [salutation, setSalutation] = useState("");
  const [customerTitle, setCustomerTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [street, setStreet] = useState([]);
  const [no, setNo] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [type, setType] = useState("Private");

  const handleSave = async () => {
    await createCustomerMutation({
      variables: {
        object: {
          first_name: firstName,
          last_name: lastName,
          id: uuid(),
          type,
          title: customerTitle,
          created_at: new Date(),
          customer_number: telephone,
          addresses: [
            {
              newName: street,
              newNo: no,
              zip: zip,
              city: city,
              country: country,
              id: uuid(),
            },
          ],
          phones: [
            {
              phone: telephone,
              type: "Private",
              id: uuid(),
            },
          ],
          emails: [
            {
              email: email,
              type: "Private",
              id: uuid(),
            },
          ],
          created_by: user.id,
        },
      },
    });
    // refetch();
  };

  return (
    <>
      <div className="InvoiceModal_body_inner_table"  >
        <div
          className="InvoiceModal_body_inner_table_col"
        >
          <div className="d-flex-start">
            <div className="d-flex-start">
              <input
                type="radio"
                name="type"
                id=""
                style={{ marginRight: 10 }}
                value={type}
                onChange={() => setType("Private")}
              />
              Private
            </div>
            <div className="d-flex-start">
              <input
                type="radio"
                name="type"
                id=""
                style={{ marginRight: 10 }}
                value={type}
                onChange={() => setType("Organization")}
              />
              Organization
            </div>
          </div>
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <SelectBox
            selectedValue={salutation}
            labelText="Salutation"
            borderBottom={true}
          >
            <div
              className=""
              onClick={() => {
                setSalutation("Mr.");
                setStep(3);
              }}
            >
              <div className="d-flex-start"> Mr. </div>
            </div>
            <div
              className=""
              onClick={() => {
                setSalutation("Mrs.");
                setStep(3);
              }}
            >
              <div className="d-flex-start"> Mrs.</div>
            </div>
          </SelectBox>
        </div>
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <SelectBox
            selectedValue={customerTitle}
            labelText="Title"
            borderBottom={true}
          >
            {title.map((el: string, idx: number) => (
              <div
                className=""
                onClick={() => {
                  setCustomerTitle(el);
                  setStep(3);
                }}
                key={`${el}-${idx}`}
              >
                <div className="d-flex-start"> {el} </div>
              </div>
            ))}
          </SelectBox>
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="First Name"
            valueProp={firstName}
            handleChange={setFirstName}
            borderBottom={true}
          />
        </div>
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="Last Name"
            valueProp={lastName}
            handleChange={setLastName}
            borderBottom={true}
          />
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="Organization"
            valueProp={organization}
            handleChange={setOrganization}
            borderBottom={true}
          />
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div className="InvoiceModal_body_inner_table_col_2">
          <InputBox
            labelText="Address / Street"
            valueProp={street}
            handleChange={setStreet}
            borderBottom={true}
          />
        </div>
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="No."
            valueProp={no}
            handleChange={setNo}
            borderBottom={true}
          />
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="Zip code"
            valueProp={zip}
            handleChange={setZip}
            borderBottom={true}
          />
        </div>
        <div
          className="InvoiceModal_body_inner_table_col_2"
          style={{ marginTop: -7 }}
        >
          <InputBox
            borderBottom={true}
            labelText="City."
            valueProp={city}
            handleChange={setCity}
          />
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <SelectBox
            selectedValue={country}
            labelText="Country"
            borderBottom={true}
          >
            <div
              className=""
              onClick={() => {
                setCountry("Germany");
                setStep(3);
              }}
            >
              <div className="d-flex-start"> Germany </div>
            </div>
            <div
              className=""
              onClick={() => {
                setCountry("India");
                setStep(3);
              }}
            >
              <div className="d-flex-start"> India</div>
            </div>
          </SelectBox>
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="Email"
            valueProp={email}
            handleChange={setEmail}
            borderBottom={true}
          />
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <InputBox
            labelText="Telephone"
            valueProp={telephone}
            handleChange={setTelephone}
            borderBottom={true}
          />
        </div>
      </div>
      <div className="InvoiceModal_body_inner_table" style={{ marginTop: -3 }} >
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
        >
          <div className="bt-secondary">Abort</div>
        </div>
        <div
          className="InvoiceModal_body_inner_table_col"
          style={{ marginTop: -7 }}
          onClick={() => {
            setStep(4);
          }}
        >
          <div className="bt-primary" onClick={handleSave}>
            Save
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInvoiceCustomer;
