import React, { useState } from "react";
import { Toggle } from "rsuite";

// icons

// components

const InvoiceModalLogo: React.FC<{}> = ({}) => {
  return (
    <>
      <div className="InvoiceModal_body_inner_logo">
        <img src="/assets/bg/ilogo.png" alt="" />
      </div>
    </>
  );
};

export default InvoiceModalLogo;
