import React, { useState } from "react";
import {
  Dropdown,
  Toggle,
  FlexboxGrid,
  Calendar,
  Checkbox,
  CheckTree,
} from "rsuite";
import {
  FiMoreVertical,
  FiSearch,
  FiFilter,
  FiBriefcase,
  FiUpload,
} from "react-icons/fi";
import "./JobsOverview.css";

// component
import PageHeader from "../../components/jobs/pageHeader/PageHeader";
import TopTab from "../../components/jobs/topTab/TopTab";
import JobTabs from "../../components/jobs/jobTabs/JobTabs";
import AllTicketItems from "../../components/jobs/allTicketItems/AllTicketItems";
import ReturnDevices from "../../components/jobs/returnDevices/ReturnDevices";
import EstimatedTicketsItems from "../../components/jobs/estimatedTicketsItems/EstimatedTicketsItems";

const JobsOverview: React.FC = () => {
  const [tabs, setTabs] = useState(2);
  const [tab, setTab] = useState(1);
  const [tabTicket, setTabTicket] = useState(1);
  const sort = [
    {
      label: "Status",
      value: 3,
      children: [
        {
          label: "All Jobs",
          value: 36,
        },
        {
          label: "Repair In Progress",
          value: 37,
        },
        {
          label: "Recected Quotes",
          value: 38,
        },
      ],
    },
  ];

  return (
    <FlexboxGrid className="JobsOverview">
      {/* pageheader */}
      <PageHeader />
      {/* <TopTab /> */}
      <div className="JobsOverview_top_tabs">
        <div
          className={`${tabs === 1
            ? "JobsOverview_top_tabs_item_active"
            : "JobsOverview_top_tabs_item"
            }`}
          onClick={() => {
            setTabs(1);
          }}
        >
          ESTIMATED JOBS
        </div>
        <div
          className={`${tabs === 2
            ? "JobsOverview_top_tabs_item_active"
            : "JobsOverview_top_tabs_item"
            }`}
          onClick={() => {
            setTabs(2);
          }}
        >
          ACTIVE JOBS
        </div>
      </div>
      {/*  */}
      {/* <JobTabs /> */}
      <div className="JobsOverview_jobTabs">
        <div className=""></div>
        {tabs === 1 && (
          <div
            className="JobsOverview_jobTabs_inner"
            style={{
              width: 300,
              position: "absolute",
              left: "50%",
            }}
          >
            <div
              className={`${tabTicket === 1
                ? "JobsOverview_jobTabs_inner_item_active"
                : "JobsOverview_jobTabs_inner_item"
                }`}
              onClick={() => {
                setTabTicket(1);
              }}
            >
              <img src="/assets/bg/wrold.png" alt="" />
              <div className="JobsOverview_jobTabs_inner_item_text">
                2 Tickets
              </div>
            </div>
            <div className="JobsOverview_jobTabs_inner_border"></div>
            <div
              className={`${tabTicket === 2
                ? "JobsOverview_jobTabs_inner_item_active"
                : "JobsOverview_jobTabs_inner_item"
                }`}
              onClick={() => {
                setTabTicket(2);
              }}
            >
              <img src="/assets/bg/hands.png" />
              <div className="JobsOverview_jobTabs_inner_item_text">
                5 Tickets
              </div>
            </div>
          </div>
        )}
        {tabs === 2 && (
          <>
            <div className="JobsOverview_jobTabs_inner">
              <div
                className={`${tab === 1
                  ? "JobsOverview_jobTabs_inner_item_active"
                  : "JobsOverview_jobTabs_inner_item"
                  }`}
                onClick={() => {
                  setTab(1);
                }}
              >
                <div
                  className="JobsOverview_jobTabs_inner_item_circle"
                  style={{ backgroundColor: "#2589F6" }}
                >
                  <div className="JobsOverview_jobTabs_inner_item_circle_text">
                    365
                  </div>
                </div>
                <div className="JobsOverview_jobTabs_inner_item_text">
                  All Jobs
                </div>
              </div>
              <div className="JobsOverview_jobTabs_inner_border"></div>
              <div
                className={`${tab === 2
                  ? "JobsOverview_jobTabs_inner_item_active"
                  : "JobsOverview_jobTabs_inner_item"
                  }`}
                onClick={() => {
                  setTab(2);
                }}
              >
                <div
                  className="JobsOverview_jobTabs_inner_item_circle"
                  style={{ backgroundColor: "#FEC636" }}
                >
                  <div className="JobsOverview_jobTabs_inner_item_circle_text">
                    7
                  </div>
                </div>
                <div className="JobsOverview_jobTabs_inner_item_text">
                  Repair In Progress
                </div>
              </div>
              <div className="JobsOverview_jobTabs_inner_border"></div>
              <div
                className={`${tab === 3
                  ? "JobsOverview_jobTabs_inner_item_active"
                  : "JobsOverview_jobTabs_inner_item"
                  }`}
                onClick={() => {
                  setTab(3);
                }}
              >
                <div
                  className="JobsOverview_jobTabs_inner_item_circle"
                  style={{ backgroundColor: "#FF5F5F" }}
                >
                  <div className="JobsOverview_jobTabs_inner_item_circle_text">
                    30
                  </div>
                </div>
                <div className="JobsOverview_jobTabs_inner_item_text">
                  Recected Quotes
                </div>
              </div>
              <div className="JobsOverview_jobTabs_inner_border"></div>
              <div
                className={`${tab === 4
                  ? "JobsOverview_jobTabs_inner_item_active"
                  : "JobsOverview_jobTabs_inner_item"
                  }`}
                onClick={() => {
                  setTab(4);
                }}
              >
                <div
                  className="JobsOverview_jobTabs_inner_item_circle"
                  style={{ backgroundColor: "#36E052" }}
                >
                  <div className="JobsOverview_jobTabs_inner_item_circle_text">
                    2
                  </div>
                </div>
                <div className="JobsOverview_jobTabs_inner_item_text">
                  Accepted Quotes
                </div>
              </div>
              <div className="JobsOverview_jobTabs_inner_border"></div>
              <div
                className={`${tab === 5
                  ? "JobsOverview_jobTabs_inner_item_active"
                  : "JobsOverview_jobTabs_inner_item"
                  }`}
                onClick={() => {
                  setTab(5);
                }}
              >
                <div
                  className="JobsOverview_jobTabs_inner_item_circle"
                  style={{ backgroundColor: "#B84343" }}
                >
                  <div className="JobsOverview_jobTabs_inner_item_circle_text">
                    35
                  </div>
                </div>
                <div className="JobsOverview_jobTabs_inner_item_text">
                  Parts Not Availible
                </div>
              </div>
              <div className="JobsOverview_jobTabs_inner_border"></div>
              <div
                className={`${tab === 6
                  ? "JobsOverview_jobTabs_inner_item_active"
                  : "JobsOverview_jobTabs_inner_item"
                  }`}
                onClick={() => {
                  setTab(6);
                }}
              >
                <div
                  className="JobsOverview_jobTabs_inner_item_circle"
                  style={{ backgroundColor: "#008444" }}
                >
                  <div className="JobsOverview_jobTabs_inner_item_circle_text">
                    5
                  </div>
                </div>
                <div className="JobsOverview_jobTabs_inner_item_text">
                  Ready To Return
                </div>
              </div>
            </div>
          </>
        )}
        <ReturnDevices />
      </div>
      {/*  */}
      <div className="JobsOverview_body">
        <div className="JobsOverview_body_top">
          <div className="JobsOverview_body_top_btn">
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/bg/filter.png" />}
            >
              <div className="sort_drop">
                <h5>Filter</h5>

                <Checkbox>Job Type</Checkbox>
                <CheckTree data={sort} defaultExpandAll />
                <Checkbox>Location</Checkbox>
                <Checkbox>Account</Checkbox>
                <Checkbox>Channels</Checkbox>
                <div className="d-flex">
                  <button className="bt-secondary">Abort</button>
                  <button className="bt-primary">Apply</button>
                </div>
              </div>
            </Dropdown>
          </div>
          <div className="JobsOverview_body_top_btn">
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export calender"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/bg/cal.png" />}
            >
              <Calendar compact bordered />
            </Dropdown>
          </div>
          <div className="JobsOverview_body_top_btn">
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export sort"
              placement="bottomEnd"
              renderTitle={() => <img src="/assets/bg/sort.png" />}
            >
              <div className="sort_drop">
                <h5>Sort</h5>

                <Checkbox>1 - 10 (ascending)</Checkbox>
                <Checkbox>10 - 1 (descending)</Checkbox>
                <Checkbox>A - Z (ascending)</Checkbox>
                <Checkbox>Z - A (descending)</Checkbox>
                <div className="d-flex">
                  <button className="bt-secondary">Abort</button>
                  <button className="bt-primary">Apply</button>
                </div>
              </div>
            </Dropdown>
          </div>
          <div className="JobsOverview_body_top_search">
            <input type="text" placeholder="Job No, Name..." />
            <FiSearch />
          </div>
          <div>
            <Dropdown
              trigger={["click"]}
              className="actionDropPageHeader export"
              placement="bottomEnd"
              renderTitle={() => (
                <FiMoreVertical
                  style={{ color: "#304a50", fontSize: 20, cursor: "pointer" }}
                />
              )}
            >
              <div className="dropDownRowSpaced" style={{ border: 0 }} >
                <div className="d-flex">
                  <FiUpload />
                  <h6>Export to Excel</h6>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="JobsOverview_body_bottom">
          {tabs === 1 && (
            <div className="JobsOverview_body_bottom_flex">
              {tabTicket === 1 && (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                    (item, i) => (
                      <EstimatedTicketsItems key={i} />
                    )
                  )}
                </>
              )}
              {tabTicket === 2 && (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                    (item, i) => (
                      <EstimatedTicketsItems key={i} />
                    )
                  )}
                </>
              )}
            </div>
          )}

          {tabs === 2 && (
            <>
              {tab === 1 && (
                <>
                  {[1, 2, 3, 4, 5].map((item, i) => (
                    <AllTicketItems key={i} />
                  ))}
                </>
              )}
              {tab === 2 && (
                <>
                  {[1, 2, 3].map((item, i) => (
                    <AllTicketItems key={i} />
                  ))}
                </>
              )}
              {tab === 3 && (
                <>
                  {[1, 2, 3, 4, 5, 6, 7].map((item, i) => (
                    <AllTicketItems key={i} />
                  ))}
                </>
              )}
              {tab === 4 && (
                <>
                  {[1, 2].map((item, i) => (
                    <AllTicketItems key={i} />
                  ))}
                </>
              )}
              {tab === 5 && (
                <>
                  {[1, 2, 3, 4].map((item, i) => (
                    <AllTicketItems key={i} />
                  ))}
                </>
              )}
              {tab === 6 && (
                <>
                  {[1].map((item, i) => (
                    <AllTicketItems key={i} />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </FlexboxGrid>
  );
};

export default JobsOverview;
