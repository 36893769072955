import {
  CONVERSATIONS_LOADING,
  SET_CONVERSATIONS,
} from "./constants";
import { _fetchConversations} from "./services";
import {loginRemoveToken} from "../auth/actions";

export function conversationSetLoading(loading) {
  return {
    type: CONVERSATIONS_LOADING,
    payload: loading,
  };
}
export function conversationSetData(params) {
  return {
    type: SET_CONVERSATIONS,
    payload: params,
  };
}
export const fetchConversations = (params) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const {token} = getState().auth;
		dispatch(conversationSetLoading(true));
		_fetchConversations(params, token).then(async (res) => {
			dispatch(conversationSetData(res));
			resolve(res)
		}).catch((err) => {
			if(err?.response?.status === 401){
                dispatch(loginRemoveToken());
            }
			reject(err)
		}).finally(() => {
			dispatch(conversationSetLoading(false));
		})
	})
};