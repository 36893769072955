import React, { useState } from "react";
import { FiPlay } from "react-icons/fi";
import "../../detailModal/DetailModal.css";

const ChatBoxLeftChatVoiceMail: React.FC<{}> = ({}) => {
  return (
    <div
      className="DetailModal-main-middle-view-chat-right-body-left callList"
      style={{ backgroundColor: "#C4C4C440" }}
    >
      <div className="DetailModal-main-middle-view-chat-right-body-left-dp">
        <div className="DetailModal-main-middle-view-chat-right-body-left-dp-circle">
          <img src="/assets/bg/6.png" alt="" />P
        </div>
      </div>
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }}>
          <div className="progress">
            <div className="line-muted"></div>
            <div className="line">
              <div className="cirlce"></div>
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p>08min. 12sec</p>
            <p style={{textAlign: 'right', paddingRight: 10}} >Yesterdar, 12:39</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="play">
            <FiPlay style={{marginLeft: 4}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxLeftChatVoiceMail;
