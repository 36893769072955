import React, { useState } from "react";
import "../../../pages/services/Services.scss";

// icons
import { FiTrash2 } from "react-icons/fi";
import { IoStarOutline, IoStar } from "react-icons/io5";

//types
import { ServiceImageType } from "./Services.type";

const ServicesModalImages: React.FC<{
  image: ServiceImageType;
  onRemove: Function;
  onFavouriteToggle: Function;
}> = ({image, onRemove, onFavouriteToggle}) => {
  return (
    <>
      <div className="ServicesModal_inner_uploaded_img">
        <div className="ServicesModal_inner_flex_spaced">
          <div
            className="pointer_blue"
            onClick={() => onFavouriteToggle(image.id)}
          >
            {image.favourite === false ? <IoStarOutline /> : <IoStar />}
          </div>
          <div className="pointer" onClick={()=>onRemove()}>
            <FiTrash2 />
          </div>
        </div>
        <img src={image.link} alt="" />
        <p>{image.name}</p>
      </div>
    </>
  );
};

export default ServicesModalImages;
