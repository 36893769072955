import React, { useState } from "react";
import "../../../pages/usersSettings/UsersSettings.scss";

// icon
import { FiMoreHorizontal } from "react-icons/fi";

const UsersSettingsItems: React.FC<{}> = (props) => {
  return (
    <>
      <div className="desk-only">
        <div className="UsersSettingsItems">
          <div className="UsersSettingsItems_left">
            <img src="/assets/bg/contats.png" alt="" />
            <div className="UsersSettingsItems_left_1">
              <p>Name</p>
              <h6>Jane Dorianovic</h6>
            </div>
            <div className="UsersSettingsItems_left_2">
              <p>Role</p>
              <h6>Administrator</h6>
            </div>
            <div className="UsersSettingsItems_left_3">
              <p>Location</p>
              <h6>Texas Shop A</h6>
            </div>
            <div className="UsersSettingsItems_left_4">
              <p>E-Mail</p>
              <h6 style={{ color: "#2589F6" }}>office@bignamcompanyname.con</h6>
            </div>
          </div>
          <div className="UsersSettingsItems_right" style={{ marginLeft: 20 }}>
            <div className="">
              <h5>Active</h5>
            </div>
            <FiMoreHorizontal />
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersSettingsItems;
