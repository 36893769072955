import React, { useState, useEffect } from "react";
import { Dropdown, FlexboxGrid, Checkbox, RadioGroup, Radio } from "rsuite";
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";
import "./Services.scss";
import Swal from "sweetalert2";
import Loader from "../../components/loader/Loader";
import Paging from "../../components/common/paging/Paging";
import ExcelExport from "../../components/common/excelExport/ExcelExport";
import SortPicker from "../../components/common/SortPicker";
// component
import {
  PageHeader,
  ServicesItems,
  ServicesModal,
  ServiceFilters,
} from "../../components/services/";

//import helpers
import { showAlert } from "../../helpers";

//graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_SERVICES } from "../../gql/services/queries";
import { UPDATE_SERVICE_MUTATION } from "../../gql/services/mutation";
//redux
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../toolkit/auth/auth.slice";
import {
  serviceState,
  setServices,
} from "../../toolkit/services/services.slice";

//types
import ServiceType from "../../components/services/servicesModal/Services.type";

//functions
import {
  BuildServiceSearchQuery,
  BuildServiceExportData,
  BuildServiceExportColumns,
  BuildServiceSortQuery,
  BuildServiceFilterQuery,
} from "./Services.functions";

const Services: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { services } = useSelector(serviceState);
  const {
    auth: { user },
  } = useSelector(authState);
  const defaultCondition = {
    created_by: { _eq: user.id },
    active: { _eq: true },
  };
  const [modal, setModal] = useState(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [queryCondition, setQueryCondition] = useState<any>(defaultCondition);
  const [orderCondition, setOrderCondition] = useState<any>({
    created_at: "desc",
  });
  const [sort, setSort] = useState("");
  const [serviceFilters, serviceSetFilter] = useState<any>({});

  const { data, refetch, error, loading } = useQuery(GET_ALL_SERVICES, {
    variables: {
      limit: limit,
      offset: offset,
      where: queryCondition,
      order: orderCondition,
    },
  });
  const [updateServiceMutation] = useMutation(UPDATE_SERVICE_MUTATION);
  const handlePage = (page: number) => {
    setActivePage(page);
    setOffset(limit * page - limit);
  };
  const removeService = (id: string) => {
    Swal.fire({
      title: "Are you sure want to remove this service?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
      heightAuto: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateServiceMutation({
          variables: {
            object: {
              active: false,
            },
            id,
          },
        });
        refetch();
        showAlert({
          title: "Success!",
          message: `Service removed successfully!`,
          type: "success",
        });
      }
    });
  };
  useEffect(() => {
    if (error) {
    } else {
      dispatch(setServices(data));
      if (typeof data?.services_aggregate?.aggregate?.count !== "undefined") {
        setTotalPages(
          Math.ceil(data?.services_aggregate?.aggregate?.count / limit)
        );
      }
    }
  }, [error, data]);
  useEffect(() => {
    const conditionTemp = BuildServiceSearchQuery(search, null);
    setQueryCondition({
      ...defaultCondition,
      ...conditionTemp,
    });
  }, [search]);
  useEffect(() => {
    if (sort.length > 0) {
      const _sortQuery = BuildServiceSortQuery(sort);
      setOrderCondition(_sortQuery);
    } else {
      setOrderCondition({ created_at: "desc" });
    }
  }, [sort]);
  useEffect(() => {
    if (Object.keys(serviceFilters).length) {
      const conditionTemp = BuildServiceFilterQuery(serviceFilters, null);
      setQueryCondition({
        ...defaultCondition,
        ...conditionTemp,
      });
    } else {
      setOrderCondition({ created_at: "desc" });
    }
  }, [serviceFilters]);
  return (
    <FlexboxGrid className="Services">
      <PageHeader />
      <div className="Services_body">
        <div>
          <div className="Services_body_top">
            <div className="space-between">
              <div className="d-flex">
                <ServiceFilters
                  serviceFilters={serviceFilters}
                  serviceSetFilter={serviceSetFilter}
                />
                <div className="Services_body_top_btn">
                  <SortPicker setSort={setSort} />
                </div>
              </div>
              <div className="d-flex">
                <div className="JobsOverview_body_top_search">
                  <div className="searchIcon">
                    <FiSearch />
                  </div>
                  <input
                    type="text"
                    className="joboverview-searchInput"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div
                  className="Services_body_top_btn blue_btn"
                  onClick={() => {
                    setModal(true);
                    console.log("ffffffff");
                  }}
                >
                  <FiPlus />
                </div>
                <div>
                  <Dropdown
                    trigger={["click"]}
                    className="actionDropPageHeader export"
                    placement="bottomEnd"
                    renderTitle={() => (
                      <FiMoreVertical
                        style={{
                          color: "#304a50",
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  >
                    <div
                      className="dropDownRowSpaced"
                      style={{ border: 0, padding: 5 }}
                    >
                      <div className="d-flex dropDownRowSpaced-option">
                        <FiUpload />
                        <ExcelExport
                          columns={BuildServiceExportColumns()}
                          data={BuildServiceExportData(services)}
                          filename={`repaircms_services_${Date.now()}`}
                          fileExtension="xlsx"
                        >
                          <h6>Export to Excel</h6>
                        </ExcelExport>
                      </div>
                    </div>
                    <div
                      className="dropDownRowSpaced"
                      style={{ border: 0, padding: 5 }}
                    >
                      <div className="d-flex dropDownRowSpaced-option">
                        <FiUpload />
                        <ExcelExport
                          columns={BuildServiceExportColumns()}
                          data={BuildServiceExportData(services)}
                          filename={`repaircms_services_${Date.now()}`}
                          fileExtension="csv"
                        >
                          <h6>Export to CSV</h6>
                        </ExcelExport>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="Services_body_bottom">
            {loading || typeof services === "undefined" ? (
              <Loader />
            ) : (
              <>
                {services.map((item: ServiceType, i: number) => (
                  <ServicesItems
                    setModal={setModal}
                    key={i}
                    id={item.id}
                    item={item}
                    setEditedId={setEditedId}
                    setIsEdited={setIsEdited}
                    removeService={removeService}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <div className="table-footer">
          <Paging
            activePage={activePage}
            pages={totalPages}
            onPageClick={handlePage}
          />
        </div>
      </div>

      {modal && (
        <ServicesModal
          Close={() => {
            setModal(false);
            setEditedId("");
            setIsEdited(false);
          }}
          refetch={refetch}
          isEdited={isEdited}
          editedId={editedId}
        />
      )}
    </FlexboxGrid>
  );
};

export default Services;
