import React, { useState, useEffect, useRef } from "react";
import {
  FlexboxGrid,
  SelectPicker,
  Uploader,
  Message,
  Loader,
  Toggle,
} from "rsuite";
import "./StatusSettings.scss";
import { v4 as uuid } from "uuid";
import { InvoiceModal } from "../../components/invoice";
import { FaTrashAlt } from "react-icons/fa";
import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint,
} from "react-to-print";

import Paging from "../../components/common/paging/Paging";

import { Dropdown, Checkbox } from "rsuite";
import { FiMoreVertical, FiSearch, FiUpload, FiPlus } from "react-icons/fi";

// component

import InputBox from "../../components/template/inputBox/InputBox";

import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { nullFormat } from "numeral";
import {
  StatusItem,
  StatusModal,
  PageHeader,
} from "../../components/statusSettings";

const StatusSettings: React.FC<{}> = (props) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selectedReceipt, selectedSelectedReceipt] = useState("Quotation");
  const [selectedEncryption, setSelectedEncryption] = useState("Encryption");
  const [selectedFile, setSelectedFile] = useState([
    {
      name: "Attachments",
    },
  ]);

  const [model, setModel] = useState<string>("");
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleModelChange = (model: any) => {
    setModel(model);
  };

  const [items, setItems] = React.useState([
    {
      id: "1",
      value: "Quotation",
      label: "Quotation",
    },
  ]);
  const [encryptions, setEncryption] = React.useState([
    {
      id: "1",
      name: "Encryption",
    },
  ]);

  const renderMenu = (menu: any) => {
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          {/* <SpinnerIcon spin />  */}
          Loading...
        </p>
      );
    }
    return menu;
  };

  const handelSelect = (e: any) => {
    console.log(e, "is the value");
    selectedSelectedReceipt(e);
  };

  const handelSelectFile = (e: any) => {
    setSelectedFile(e.target.files);
    console.log(e.target.files);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handelSelectEncryptions = (value: string, key: string) => {
    setSelectedEncryption(value);
  };

  const handelEditClick = () => {
    setIsEdit(!isEdit);
  };
  return (
    <>
      <PageHeader
        onClick={() => null}
        loading={btnLoading}
        headerText1="Settings"
        headerText2="Status"
        simpleSaveBtn={true}
        saveDropdown={false}
        handelPrint={handlePrint}
      />
      <div className="emailTemplateContainer" style={{ margin: "5rem" }}>
        <FlexboxGrid className="Invoice">
          <div className="Invoice_body">
            <div className="Invoice_body_top">
              <div className="space-between">
                <div className="d-flex">
                  <div className="Invoice_body_top_btn">
                    <Dropdown
                      trigger={["click"]}
                      className="actionDropPageHeader export sort"
                      placement="bottomEnd"
                      renderTitle={() => <img src="/assets/bg/filter.png" />}
                    >
                      <div className="sort_drop">
                        <h5>Filter</h5>
                        <Checkbox>Job Type</Checkbox>
                        <Checkbox>Location</Checkbox>
                        <Checkbox>Account</Checkbox>
                        <Checkbox>Channels</Checkbox>
                        <div className="d-flex">
                          <button className="bt-secondary">Abort</button>
                          <button className="bt-primary">Apply</button>
                        </div>
                      </div>
                    </Dropdown>
                  </div>

                  <div className="Invoice_body_top_btn">
                    <Dropdown
                      trigger={["click"]}
                      className="actionDropPageHeader export sort"
                      placement="bottomEnd"
                      renderTitle={() => <img src="/assets/bg/sort.png" />}
                    >
                      <div className="sort_drop">
                        <h5>Sort</h5>
                        <Checkbox>1 - 10 (ascending)</Checkbox>
                        <Checkbox>10 - 1 (descending)</Checkbox>
                        <Checkbox>A - Z (ascending)</Checkbox>
                        <Checkbox>Z - A (descending)</Checkbox>
                        <div className="d-flex">
                          <button className="bt-secondary">Abort</button>
                          <button className="bt-primary">Apply</button>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="JobsOverview_body_top_search">
                    <div className="searchIcon">
                      <FiSearch />
                    </div>
                    <input
                      type="text"
                      className="joboverview-searchInput"
                      placeholder="Search..."
                    />
                  </div>

                  <div
                    className="Invoice_body_top_btn blue_btn"
                    onClick={() => {
                      setModal(true);
                      setIsEdit(false);
                    }}
                  >
                    <FiPlus />
                  </div>
                  <div>
                    <Dropdown
                      trigger={["click"]}
                      className="actionDropPageHeader export"
                      placement="bottomEnd"
                      renderTitle={() => (
                        <FiMoreVertical
                          style={{
                            color: "#304a50",
                            fontSize: 20,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    >
                      <div className="dropDownRowSpaced" style={{ border: 0 }}>
                        <div className="d-flex dropDownRowSpaced-option">
                          <FiUpload />
                          <h6>Export to Excel</h6>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div className="Invoice_body_bottom">
              <StatusItem
                setModal={setModal}
                key={123}
                id="123"
                // item={item}
                setEditedId={() => null}
                setIsEdited={() => null}
                handelEditClick={handelEditClick}
              />
              <div className="table-footer">
                <Paging activePage={1} pages={2} onPageClick={() => null} />
              </div>
            </div>
          </div>

          {modal && (
            <StatusModal
              Close={() => {
                setModal(false);
              }}
              isEdited={modal}
              isEdit={isEdit}
            />
          )}
        </FlexboxGrid>
      </div>
    </>
  );
};

export default StatusSettings;
