import React, { useState } from "react";
import "../detailModal/DetailModal.css";

// icon
import { FiEdit, FiTrash2, FiX } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

const NewTemplate: React.FC<{
  close: Function;
}> = ({ close }) => {
  const [selected, setSelected] = useState("");

  const [channel, setChannel] = useState <any>([

    {
      id: 1,
      name: "Receipts"
    },
    {
      id: 2,
      name: "Communication"
    }
  ])

  return (
    <>
      <div className="backdrop">
        <div className="DetailModal-card-bg card_modal security_modal">
          <div className="DetailModal-card-header card_modal">
            <h6>New Text-Template</h6>
            <div onClick={() => close()}>
              <FiX style={{ fontSize: 25 }} />
            </div>
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="" style={{ marginBottom: 20 }}>
              <InputBox labelText="Template Name" handleChange={()=> null} />
            </div>
            <div className="card_modal_input_wrapper" style={{ marginBottom: 20 }}>
              {/* <SelectBox selectedValue={selected} labelText="Channel">
                <div
                  className=""
                  onClick={() => {
                    setSelected("Receipts");
                  }}
                >
                  Receipts
                </div>
                <div
                  className=""
                  onClick={() => {
                    setSelected("Communication");
                  }}
                >
                  Communication
                </div>
              </SelectBox> */}
              <SearchDropDown
                        labelText="Channel"
                        data={channel}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                      />
            </div>
            <TextAreaBox labelText="Hello {Name}, This  you "  handleChange={(value) => {}} 
              valueProp={""}/>
          </div>
          <div className="d-flex" style={{marginBottom: 15, justifyContent: 'flex-end'}} >
            <p>
              Allowed parameters: {`{Name}`} {`{Device}`} <span style={{color: '#2589f6'}} > more...</span>
            </p>
          </div>

          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <button className="btn btn-secondary" onClick={() => close()}>
              Abort
            </button>
            <button className="btn btn-primary" onClick={() => close()}>
              Save & Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTemplate;
