import React, { useState } from "react";
import { Toggle } from "rsuite";

// icons
import { FiChevronRight, FiChevronLeft, FiPrinter, FiSearch } from "react-icons/fi";
import { FaRunning, FaPrint,  } from "react-icons/fa";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";

const Step9: React.FC<{ ChangeStep: Function; ChangeStepBack: Function }> = ({ ChangeStep, ChangeStepBack }) => {
  // const [selected, setSelected] = useState("");
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper">
          <div className="stepFormWrapper-left">
            <img src="/assets/icon/iLogo.png" alt="" style={{ width: 120, height: 120, objectFit: "contain" }} />
            <h5 style={{ marginTop: 15 }}>Apple SE 2020</h5>
            <p style={{ textAlign: "center", lineHeight: 1.4 }}>
              <b> IMEI: </b> 125389386491937 <br />
              <b> Storage: </b> 256 GB <br />
              <b> Color: </b> Black <br />
            </p>
          </div>
          <div className="stepFormWrapper-right">
            <h4 style={{ textAlign: "center", marginBottom: 10 }}>Print Options</h4>
            <>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <div
                    className="s9-item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <div className="s9-item_icon">
                      <FiPrinter />
                    </div>
                    <p>A4 Receipt</p>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="s9-item"
                    onClick={() => {
                      ChangeStep();
                    }}
                  >
                    <div className="s9-item_icon">
                      <FaPrint />
                    </div>
                    <p>Receipt (80mm)</p>
                  </div>
                </div>
              </div>

              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <div className="stepFormWrapper-right-search">
                    <div className="flex-1">Print device label on the next step</div>
                    <Toggle style={{ marginLeft: 10 }} />
                  </div>
                </div>
              </div>
            </>
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default Step9;
