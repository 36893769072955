import React, { useState } from "react";
import { Container, Panel, Form, FlexboxGrid, FormGroup, ControlLabel, FormControl, ButtonToolbar, Button } from "rsuite";
import { Link } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

const ForgotPasswordSendOTP: React.FC<{
	handleSubmit: Function;
	errMsg: String
}> = ({handleSubmit, errMsg}) => {
	const { auth: { isLoading } } = useSelector(authState);

	const [email, setEmail] = useState<string>("");
	const submitHandler = async () => {
		handleSubmit(email)
	};
	return (
		<Form fluid className="auth_Form" onSubmit={submitHandler}>
			<h1 className="auth_Form_title">Forgot Password</h1>
			<h3 className="auth_Form_title_sub">Enter your email to reset your password</h3>
			<FormGroup>
				<FormControl className="authFormInput" name="email" placeholder="Enter your email" value={email} onChange={setEmail} />
			</FormGroup>
			{errMsg.length > 0 && (
				<p className="err-msg">{errMsg}</p>
			)}
			<div className="spacedHeight"></div>
			<FormGroup>
				<ButtonToolbar>
					<Button appearance="primary" className="authBtn" disabled={isLoading} type="submit">
						Reset
					</Button>
				</ButtonToolbar>
			</FormGroup>
			<FormGroup>
				<ButtonToolbar>
					<Link to="/">
						<h2>
							<span>Log in</span>
						</h2>
					</Link>
				</ButtonToolbar>
			</FormGroup>
		</Form>
	);
};
export default ForgotPasswordSendOTP;
