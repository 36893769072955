import React, { useState } from "react";
import "../detailModal/DetailModal.css";
import { Dropdown, ButtonGroup, Button } from "rsuite";
import {
  FiArrowLeftCircle,
  FiPlus,
  FiChevronDown,
  FiMenu,
  FiEye,
  FiPrinter,
  FiMail,
  FiX,
  FiInfo,
  FiEdit
} from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";

//
import NewTemplate from "../newTemplate/NewTemplate";

// compoent
import ChatBoxWrapper from "../component/chatBox/ChatBoxWrapper";
import ChatBoxMenu from "../component/chatBox/ChatBoxMenu";
import ChatBoxBody from "../component/chatBox/ChatBoxBody";
import ChatBoxSend from "../component/chatBox/ChatBoxSend";
import ChatBoxNote from "../component/chatBox/ChatBoxNote";
import ChatBoxSearch from "../component/chatBox/ChatBoxSearch";
import ChatBoxChatBody from "../component/chatBox/ChatBoxChatBody";
import ChatBoxRightChat from "../component/chatBox/ChatBoxRightChat";
import ChatBoxLeftChat from "../component/chatBox/ChatBoxLeftChat";
import ChatBoxStatus from "../component/chatBox/ChatBoxStatus";

const JobRecipt: React.FC<{ goback: any }> = ({ goback }) => {
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [chat, setChat] = useState(false);
  return (
    <div className="DetailModal-main-middle">
    {openNewTemplate && (
      <NewTemplate close={() => setOpenNewTemplate(false)} />
    )}
    {/* main view */}
    <div className="DetailModal-main-middle-view">
      {/* main view header */}
      <div className="DetailModal-main-middle-view-header">
        <div
          className="DetailModal-main-middle-view-header-back"
          onClick={goback}
        >
          <FiArrowLeftCircle />
        </div>
        <div className="DetailModal-main-middle-view-header-title-spaced">
          <div className="DetailModal-main-middle-view-header-row">
            {/* <img
              src="/assets/bg/Group1041.png"
              className="DetailModal-main-middle-view-header-title-img"
            /> */}
            <div
              className="DetailModal-main-middle-view-header-title-text"
              style={{ borderLeft: "14px solid #2CBFD6" }}
            >
              Job Receipt <p>No. 214511</p>
            </div>
          </div>
          <div className="desk-only">
            <div className="DetailModal-main-middle-view-header-row">
              <Dropdown
                trigger={["click"]}
                className="actionDropPageHeader"
                placement="bottomEnd"
                renderTitle={() => (
                  <div className="bttn-chevrom">
                    <div>Save</div>
                    <FiChevronDown />
                  </div>
                )}
              >
                <div className="dropDownRowSpaced">
                  <div className="d-flex">
                    <FiEye />
                    <h6>Preview</h6>
                  </div>
                </div>
                <div className="dropDownRowSpaced">
                  <div className="d-flex">
                    <FiPrinter />
                    <h6>Print</h6>
                  </div>
                </div>
                <div
                  className="dropDownRowSpaced"
                  onClick={() => {
                    setChat(!chat);
                  }}
                >
                  <div className="d-flex">
                    <FiMail />
                    <h6>Send with Email</h6>
                  </div>
                </div>
                <div
                  className="dropDownRowSpaced"
                  onClick={() => {
                    setChat(!chat);
                  }}
                  style={{ borderBottom: 0 }}
                >
                  <div className="d-flex">
                    <IoLogoWhatsapp />
                    <h6>Send with Whatsapp</h6>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      {/* main view header */}
      {/* main view body */}
      <div className="DetailModal-main-middle-view-body">
        <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col-row-end">
              <img
                src="/assets/bg/ilogo.png"
                alt=""
                className="DetailModal-main-middle-view-body-header-img"
              />
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-details">
                <h6 className="DetailModal-main-middle-view-body-details-small-text">
                  Muller GmbH - PiusstraBe 123 - 50823 Koln
                </h6>
                <div className="d-flex-start">
                  <h4 className="DetailModal-main-middle-view-body-details-text">
                    Broadway Company Limited
                  </h4>
                  <div className="DetailModal-main-middle-view-body-details-text-btn">
                    <FiEdit />
                  </div>
                </div>
                <h4 className="DetailModal-main-middle-view-body-details-text">
                  Frau Andrea Stock
                </h4>
                <h4 className="DetailModal-main-middle-view-body-details-text">
                  DornstraBe 17
                </h4>
                <h4 className="DetailModal-main-middle-view-body-details-text">
                  50769 Koln
                </h4>
              </div>
            </div>
          </div>
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                User : Shankhajeet Taran
              </h4>
              <h6 className="DetailModal-main-middle-view-body-details-small-text text-right">
                18.06.2021 23:50
              </h6>
            </div>
          </div>
          <div
            className="DetailModal-main-middle-view-body-row"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <div className="DetailModal-main-middle-view-body-row-col-row-end">
              <ButtonGroup>
                <Button color="violet" appearance="ghost">
                  Brutto
                </Button>
                <Button color="violet">Netto</Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
        {/*  */}
        {/* <div className="divider-horizontal"></div> */}
        {/*  */}
        <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-row table-header ">
                <div className="DetailModal-main-middle-view-body-row-col-2">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white">
                    Description
                  </h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                    Quantity
                  </h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                    Price (Netto)
                  </h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                    MwSt.
                  </h4>
                </div>
                <div className="DetailModal-main-middle-view-body-row-col">
                  <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
                    Amount
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col-2">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder=""
                  value="iPhone X LCD"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="number"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder="0"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder="0"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder="20"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <div className="DetailModal-main-middle-view-body-row">
                  <input
                    type="text"
                    className="form-control DetailModal-main-middle-view-body-input"
                    placeholder="0"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col-2">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder=""
                  value=""
                  disabled={true}
                />
                <div
                  style={{
                    padding: 5,
                    cursor: "pointer",
                    position: "absolute",
                    right: 15,
                    top: 17,
                  }}
                >
                  <FiInfo style={{ color: "#304A5000" }} />
                </div>
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="number"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder="0"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder="0"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input"
                  placeholder="20"
                  disabled={true}
                />
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <div className="DetailModal-main-middle-view-body-row">
                  <input
                    type="text"
                    className="form-control DetailModal-main-middle-view-body-input"
                    placeholder="0"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
          <div className="DetailModal-main-middle-view-body-row">
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col">
                <input
                  type="text"
                  className="form-control DetailModal-main-middle-view-body-input-area"
                  placeholder=""
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="DetailModal-main-middle-view-body-serviceReciptContainer">
          <div className="DetailModal-main-middle-view-body-row mob-row-column ">
            <div className="DetailModal-main-middle-view-body-row-col-2">
              <Dropdown
                disabled={true}
                trigger={["click"]}
                className="actionDropPageHeader w-100"
                placement="bottomEnd"
                renderTitle={() => (
                  <button className="DetailModal-main-middle-view-body-btn-plus">
                    <FiPlus />
                  </button>
                )}
              >
                <div className="w-280">
                  <div className="dropDownProfile-Link w-100">
                    <span>Service</span>
                  </div>
                  <div className="dropDownProfile-Link w-100">
                    <span>Items</span>
                  </div>
                  <div
                    className="dropDownProfile-Link w-100"
                    style={{ border: 0 }}
                  >
                    <span>Text</span>
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col">
              <div className="DetailModal-main-middle-view-body-subtotal">
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text">
                      Subtotal
                    </h4>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                      0.00 EUR
                    </h4>
                  </div>
                </div>
                <div
                  className="DetailModal-main-middle-view-body-row"
                  style={{ marginTop: 15 }}
                >
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text">
                      <span
                        className="DetailModal-card-Link"
                        style={{ cursor: "pointer" }}
                      >
                        Add discount
                      </span>
                    </h4>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                      0.00 EUR
                    </h4>
                  </div>
                </div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text">
                      incl. MwSt. 20.00%
                    </h4>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text text-right">
                      0.00 EUR
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="DetailModal-main-middle-view-body-subtotal  "
                style={{
                  marginTop: 15,
                  border: "1px  solid rgba(39, 51, 51, 0.25)",
                }}
              >
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text due-amount">
                      Total Amount
                    </h4>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h4 className="DetailModal-main-middle-view-body-details-text text-right due-amount">
                      120,00 EUR
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {chat && (
          <div className="DetailModal-main-middle-view-body-chat-modal">
            <div className="d-flex-center ">
              <div className="DetailModal-main-middle-view-body-chat-modal-sending">
                Sending Quotation
              </div>
            </div>
            <div
              className="DetailModal-main-middle-view-body-chat-modal-close"
              onClick={() => {
                setChat(!chat);
              }}
            >
              <FiX />
            </div>
            {/* chat */}
            <ChatBoxWrapper>
              <ChatBoxMenu />
              <ChatBoxBody>
                {/* <ChatBoxSearch /> */}
                <ChatBoxChatBody>
                  <ChatBoxLeftChat />
                  <ChatBoxRightChat />
                  <ChatBoxLeftChat />
                  <ChatBoxStatus />
                  <ChatBoxNote />
                </ChatBoxChatBody>
                <ChatBoxSend />
              </ChatBoxBody>
            </ChatBoxWrapper>
            {/* chat */}
          </div>
        )}
      </div>
      {/* main view body */}
    </div>
  </div>
  );
};

export default JobRecipt;
