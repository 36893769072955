import React, { useState } from "react";
import "../detailModal/DetailModal.css";

// icon
import { FiSettings, FiCheck, FiX, FiTrash2 } from "react-icons/fi";

// component
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";

const ChangeStatusModal: React.FC<{
  close: Function;
}> = ({ close }) => {
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");

  return (
    <>
      <div className="backdrop">
        <div className="DetailModal-card-bg card_modal change_status_modal">
          <div className="DetailModal-card-header card_modal">
            <h6>Change Status</h6>
          </div>
          <div className="DetailModal-card-header">
            <div className="d-flex">
              <h6 style={{ fontSize: 14, color: "#304A50", fontWeight: 600 }}>Apple iPhone SE 2020 128 Black</h6>
              <div className="DetailModal-card-status">Express</div>
            </div>
          </div>
          <div className="d-flex" style={{ justifyContent: "flex-start" }}>
            <p style={{ fontSize: 12, color: "#304A50", fontWeight: 600 }}>IMEI: 123456789012345 | Customer: Shankhajeet Taran</p>
          </div>
          {/*  */}
          <div className="DetailModal-main-middle-view-body-row table-header " style={{ marginTop: 20 }}>
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text-white">Status</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">User</h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text-white text-center" style={{ textAlign: "left" }}>
                Notes
              </h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text-white text-center" style={{ textAlign: "left" }}>
                Priority
              </h4>
            </div>
            <div className="DetailModal-main-middle-view-body-row-col">
              <h4 className="DetailModal-main-middle-view-body-details-text-white text-center" style={{ textAlign: "left" }}>
                Send Email
              </h4>
            </div>
          </div>
          {/*  */}
          <div className="DetailModal-table-body ">
            <div className="DetailModal-progress">
              <div className="DetailModal-progress-item">
                <div className="DetailModal-progress-item-dot"></div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col" style={{ fontSize: 12, color: "#304A50" }}>
                    <h6>Booked in</h6>
                    <p>31.12.2021 | 04:53</p>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <p className="PP">Peter Stauder</p>
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <p className="PP">Lorem Ipsum is simply dummy text of the</p>
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <div className="DetailModal-card-status" style={{ marginTop: 10 }}>
                        Express
                      </div>
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <p className="PP">Yes</p>
                      <div className="PP" style={{ marginTop: 10 }}>
                        <FiTrash2 />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="DetailModal-progress-item">
                <div className="DetailModal-progress-item-dot"></div>
                <div className="DetailModal-main-middle-view-body-row">
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <h6>Booked in</h6>
                    <p>31.12.2021 | 04:53</p>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <p className="PP">Peter Stauder</p>
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <p className="PP">Lorem Ipsum is simply dummy text of the</p>
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <div className="DetailModal-card-status" style={{ marginTop: 10 }}>
                        Express
                      </div>
                    </div>
                  </div>
                  <div className="DetailModal-main-middle-view-body-row-col">
                    <div className="d-flex">
                      <p className="PP">Yes</p>
                      <div className="PP" style={{ marginTop: 10 }}>
                        <FiTrash2 />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="DetailModal-main-middle-view-body-row">
              <div className="DetailModal-main-middle-view-body-row-col">
                <div className="card_modal_input_wrapper">
                  <SelectBox selectedValue={selected} labelText="Status">
                    <div
                      className=""
                      onClick={() => {
                        setSelected("1");
                      }}
                    >
                      1
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("2");
                      }}
                    >
                      2
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected("3");
                      }}
                    >
                      3
                    </div>
                  </SelectBox>
                </div>
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <div className="card_modal_input_wrapper">
                  <SelectBox selectedValue={selected2} labelText="Priority">
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("1");
                      }}
                    >
                      1
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("2");
                      }}
                    >
                      2
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected2("3");
                      }}
                    >
                      3
                    </div>
                  </SelectBox>
                </div>
              </div>
              <div className="DetailModal-main-middle-view-body-row-col">
                <div className="card_modal_input_wrapper">
                  <SelectBox selectedValue={selected1} labelText="Channel">
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("1");
                      }}
                    >
                      1
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("2");
                      }}
                    >
                      2
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setSelected1("3");
                      }}
                    >
                      3
                    </div>
                  </SelectBox>
                </div>
              </div>
              <div className="DetailModal-main-middle-view-body-row-col-2" style={{marginTop: 5}} >
                <InputBox labelText='Information' handleChange={()=> null} />
              </div>
            </div>
          </div>

          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <button className="btn btn-settings">
              <FiSettings style={{ marginRight: 10 }} />
              <span>Settings</span>
            </button>
            <div className="d-flex">
              <button className="btn btn-secondary" onClick={() => close()}>
                Abort
              </button>
              <button className="btn btn-primary" onClick={() => close()}>
                Set Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeStatusModal;
