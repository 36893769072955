import React, { useState } from "react";
import "./DetailModal.css";
import {
  FiChevronDown,
  FiMessageCircle,
  FiHome,
  FiX,
  FiInfo,
  FiUploadCloud,
  FiEdit,
} from "react-icons/fi";

// components
import RepairFlow from "../repairFlow/RepairFlow";
import FileUpload from "../fileUpload/FileUpload";
import JobType from "../jobType/JobType";
import Reference from "../reference/Reference";
import Express from "../express/Express";
import DeviceSecurity from "../deviceSecurity/DeviceSecurity";
import Notes from "../notes/Notes";

// views
import RepairWorkflow from "../repairWorkflow/RepairWorkflow";
import NotesView from "../notesView/NotesView";
import Quotation from "../quotation/Quotation";
import Invoice from "../invoice/Invoice";
import ServiceReceipt from "../serviceReceipt/ServiceReceipt";
import Chat from "../chat/Chat";
import Information from "../information/Information";
import SetComplete from "../setComplete/SetComplete";
import ReturnToClient from "../returnToClient/ReturnToClient";
import JobRecipt from "../jobRecipt/JobRecipt";

// components
import ModalBodyAndDropShadow from "../component/modalBodyAndDropShadow/ModalBodyAndDropShadow";
import CloseBtn from "../component/modalBodyAndDropShadow/CloseBtn";
import ModalBodyWrapper from "../component/modalBodyAndDropShadow/ModalBodyWrapper";

const DetailModal: React.FC<{ openModal: boolean; onClose: Function }> = ({
  openModal,
  onClose,
}) => {
  const [tabs, setTab] = useState("initial");
  const [complete, setComplete] = useState(false);
  const [returnClient, setReturnClient] = useState(false);

  return (
    <>
      <div className="desk-only">
        <ModalBodyAndDropShadow openModal={openModal}>
          {/* <CloseBtn clickFunction={() => onClose()} /> */}
          <ModalBodyWrapper>
            {complete && (
              <SetComplete
                open={complete}
                close={() => {
                  setComplete(!complete);
                }}
              />
            )}
            {returnClient && (
              <ReturnToClient
                open={returnClient}
                close={() => {
                  setReturnClient(!returnClient);
                }}
              />
            )}
            {/* body */}
            {tabs === "initial" && (
              <RepairWorkflow
                goback={() => {
                  setTab("initial");
                }}
                setNotes={() => {
                  setTab("notes");
                }}
                setQuotation={() => {
                  setTab("quotation");
                }}
                setJobRecipt={() => {
                  setTab("JobRecipt");
                }}
                setInvoice={() => {
                  setTab("invoice");
                }}
                setServiceReceipt={() => {
                  setTab("service");
                }}
                completeModal={() => {
                  setComplete(!complete);
                }}
                returnedClient={() => {
                  setReturnClient(!returnClient);
                }}
                clickFunction={() => onClose()}
              />
            )}
            {tabs === "JobRecipt" && (
              <JobRecipt
                goback={() => {
                  setTab("initial");
                }}
              />
            )}
            {tabs === "chat" && (
              <Chat
                goback={() => {
                  setTab("initial");
                }}
              />
            )}
            {tabs === "notes" && (
              <NotesView
                goback={() => {
                  setTab("initial");
                }}
              />
            )}
            {tabs === "quotation" && (
              <Quotation
                goback={() => {
                  setTab("initial");
                }}
              />
            )}
            {tabs === "invoice" && (
              <Invoice
                goback={() => {
                  setTab("initial");
                }}
              />
            )}
            {tabs === "service" && (
              <ServiceReceipt
                goback={() => {
                  setTab("initial");
                }}
              />
            )}
            {/* body */}
            {/* right */}
            <div className="DetailModal-main-right">
              {/* security */}
              <DeviceSecurity />
              {/* Express */}
              <Express />
              {/* Repair Progress */}
              <RepairFlow />
              {/* file uploads */}
              <FileUpload
                goback={() => {
                  setTab("initial");
                }}
              />
              {/* notes */}
              <Notes
                setNotes={() => {
                  setTab("notes");
                }}
              />
            </div>
            {/* right */}
          </ModalBodyWrapper>
        </ModalBodyAndDropShadow>
      </div>
      <div className="mob-only">
        <div className={`DetailModal ${openModal ? "" : "dis-none"}`}>
          <div className="DetailModal_body">
            <div className="DetailModal-view-item">
              {/* Header */}
              <div className="DetailModal-view-item-left">
                <div className="DetailModal-view-item-left-top">
                  <div className="DetailModal-view-item-left-top-left">
                    <div className="DetailModal-view-item-left-top-left-bold">
                      #A-3 |
                    </div>
                    <div className="DetailModal-view-item-left-top-left-text">
                      Box No : 1223
                    </div>
                  </div>
                  <div className="DetailModal-view-item-left-top-right">
                    <div className="DetailModal-view-item-left-top-right-text">
                      16 / 06 / 2021
                    </div>
                  </div>
                </div>
                <div className="DetailModal-view-item-left-bottom">
                  <div className="DetailModal-view-item-left-bottom-left">
                    <div className="DetailModal-view-item-left-bottom-dot"></div>
                    <div className="DetailModal-view-item-left-bottom-text">
                      Booked in
                    </div>
                  </div>
                  <div className="DetailModal-view-item-left-bottom-right">
                    <FiChevronDown />
                  </div>
                </div>
              </div>
              <div className="DetailModal-view-item-middle">
                <div className="DetailModal-view-item-middle-top">
                  <div className="DetailModal-view-item-middle-left-bold">
                    Apple Iphone SE 2020 128 Black
                  </div>
                  <div className="DetailModal-view-item-middle-left-bage">
                    Critical
                  </div>
                  <div className="DetailModal-view-item-middle-left-bage-blue">
                    <FiHome />
                  </div>
                </div>
                <div className="DetailModal-view-item-middle-bottom">
                  IMEI: <span>123456789012345</span> | Customer:{" "}
                  <span>Shankhajeet Taran</span>
                </div>
              </div>
              <div className="DetailModal-view-item-right">
                <button className="btn-close" onClick={() => onClose()}>
                  <FiX />
                  <span>Close</span>
                </button>
              </div>
            </div>
            <div className="DetailModal-mainView">
              {complete && (
                <SetComplete
                  open={complete}
                  close={() => {
                    setComplete(!complete);
                  }}
                />
              )}
              {returnClient && (
                <ReturnToClient
                  open={returnClient}
                  close={() => {
                    setReturnClient(!returnClient);
                  }}
                />
              )}
              {tabs === "information" && (
                <Information
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
              {tabs === "fileUploads" && (
                <FileUpload
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
              {tabs === "initial" && (
                <RepairWorkflow
                  goback={() => {
                    setTab("initial");
                  }}
                  setNotes={() => {
                    setTab("notes");
                  }}
                  setQuotation={() => {
                    setTab("quotation");
                  }}
                  setJobRecipt={() => {
                    setTab("JobRecipt");
                  }}
                  setInvoice={() => {
                    setTab("invoice");
                  }}
                  setServiceReceipt={() => {
                    setTab("service");
                  }}
                  completeModal={() => {
                    setComplete(!complete);
                  }}
                  returnedClient={() => {
                    setReturnClient(!returnClient);
                  }}
                  clickFunction={() => onClose()}
                />
              )}
              {tabs === "chat" && (
                <Chat
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
              {tabs === "notes" && (
                <NotesView
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
              {tabs === "quotation" && (
                <Quotation
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
              {tabs === "invoice" && (
                <Invoice
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
              {tabs === "service" && (
                <ServiceReceipt
                  goback={() => {
                    setTab("initial");
                  }}
                />
              )}
            </div>
            <div className="DetailModal-bottom-section w-100">
              <button
                className={`DetailModal-btn-white ${tabs === "information" ? "active" : ""
                  } `}
                onClick={() => {
                  setTab("information");
                }}
              >
                <FiInfo />
              </button>
              <button
                className={`DetailModal-btn-white ${tabs === "chat" ? "active" : ""
                  } `}
                onClick={() => {
                  setTab("chat");
                }}
              >
                <FiMessageCircle />
              </button>
              <button
                className={`DetailModal-btn-white ${tabs === "fileUploads" ? "active" : ""
                  } `}
                onClick={() => {
                  setTab("fileUploads");
                }}
              >
                <FiUploadCloud />
              </button>
              <button
                className={`DetailModal-btn-white ${tabs === "notes" ? "active" : ""
                  } `}
                onClick={() => {
                  setTab("notes");
                }}
              >
                <FiEdit />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailModal;
