import React, { useState, useEffect } from "react";
import "../../../pages/services/Services.scss";
import "./statusModel.scss";
// import "./textTempletModel.scss";

import { v4 as uuid } from "uuid";

import { FiX, FiPlus } from "react-icons/fi";

// components

import InputBox from "../../template/inputBox/InputBox";


const StatusModal: React.FC<{
  Close: any;
  isEdited?: boolean;
  editedId?: string;
  isEdit?: boolean;
}> = ({ Close, isEdited, editedId, isEdit }) => {
  const [errors, setErrors] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [statusName, setStatusName] = useState<string>("");
  const [selectedCategory, setselectedCategory] = useState("Receptis");
  const [selectedColor, setSelectedColor] = useState("#00000");
  const [state, setState] = useState<any>({
    id: uuid(),
    selectedColor: "",
  });

  const handelSelectedCategory = (value: string, key: string) => {
    setselectedCategory(value);
  };
  const [receptis, setReceptis] = React.useState([
    {
      id: "1",
      name: "Receptis1",
    },
    {
      id: "2",
      name: "Receptis2",
    },
  ]);

  const handleClick = async () => {};

  const resetState = () => {
    setStatusName("");
  };

  const handleClose = () => {
    resetState();
    Close();
  };

  console.log(isEdited, "isEdited");

  const handelSelectColor = (e: any) => {
    setSelectedColor(e.target.value);
  };

  return (
    <>
      <div className="desk-only">
        <div className="statusSettingModelSection">
        <div className="CustomerContactModal">
          <div className="CustomerContactModal_inner">
            <div className="CustomerContactModal_inner_top">
              <div className="CustomerContactModal_inner_flex_start">
                <div className="col">
                  <h5 className="">
                    {" "}
                    {isEdit !== true ? "Add Status" : "Edit Status"}{" "}
                  </h5>
                </div>
                <div className="close" onClick={handleClose}>
                  <FiX />
                </div>
              </div>
            </div>
            <div className="text_templet_model_body">
              <div className="text_templet_model_contant">
                {isEdit !== true ? (
                  <InputBox
                    labelText="Status Name"
                    valueProp={statusName}
                    handleChange={setStatusName}
                    isError={errors.statusName?.length > 0}
                  />
                ) : (
                  <InputBox
                    labelText="Status Name"
                    valueProp={statusName}
                    handleChange={setStatusName}
                    isError={errors.statusName?.length > 0}
                    disabledInput
                  />
                )}
              </div>
              <div className="input-container color-picker-container">
                <InputBox
                  labelText="HEX-Color"
                  valueProp={selectedColor}
                  handleChange={setSelectedColor}
                  isError={errors.statusName?.length > 0}
                />
                <input
                  className="colorpicker"
                  type="color"
                  id="colorpicker"
                  onChange={handelSelectColor}
                />
              </div>
            </div>

            <div className="d-flex CustomerContactModal_btns">
              <div
                className="bt-secondary"
                style={{ flex: 0 }}
                onClick={handleClose}
              >
                Abort
              </div>
              <button
                className={btnLoading ? "bt-secondary" : "bt-primary"}
                style={{ flex: 0 }}
                onClick={handleClick}
                disabled={btnLoading}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default StatusModal;
