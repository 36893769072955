import React, { ReactNode } from "react";
import ReactExport from "react-export-excel";
// import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelExport: React.FC<{
	children: ReactNode;
	columns: any[];
	data: any[];
	filename: String;
	fileExtension: String
}> = ({children, columns, data, filename, fileExtension}) => {
	return (
		<ExcelFile 
			element={children} 
			filename={filename} 
			fileExtension={fileExtension}
		>
			<ExcelSheet data={data} name="Sheet 1">
				{columns.map((col: any, i:number)=>(
					<ExcelColumn label={col.label} value={col.value} key={i}/>
				))}
			</ExcelSheet>
		</ExcelFile>
	);
}
export default ExcelExport;