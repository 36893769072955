import React, { useState } from "react";

// icons
import {
  FaRunning,
  FaBarcode,
  FaTag,
  FaUserAlt,
  FaExclamationTriangle,
  FaPrint,
  FaTabletAlt,
  FaShippingFast,
  FaHeadSideCough,
} from "react-icons/fa";
import { FiX, FiUser } from "react-icons/fi";

// component
import Step1 from "../step1/Step1";
import Step2 from "../step2/Step2";
import Step3 from "../step3/Step3";
import Step4 from "../step4/Step4";
import Step5 from "../step5/Step5";
import Step6 from "../step6/Step6";
import Step7 from "../step7/Step7";
import Step8 from "../step8/Step8";
import Step9 from "../step9/Step9";

const JobModalSteps: React.FC<{}> = ({}) => {
  const [step, setStep] = useState(1);

  return (
    <>
      <div className="desk-only">
        <div className="JobModal_top">
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 1 ? "active" : ""
              } ${step > 1 ? "prev" : ""}`}
            >
              <FaRunning color="#fff" />
            </div>
            <p
              className={`${step === 1 ? "active" : ""} ${
                step > 1 ? "prev" : ""
              }`}
            >
              Job Type
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 1 ? "active" : ""} ${
              step > 1 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 2 ? "active" : ""
              } ${step > 2 ? "prev" : ""}`}
            >
              <FaTabletAlt color="#fff" />
            </div>
            <p
              className={`${step === 2 ? "active" : ""} ${
                step > 2 ? "prev" : ""
              }`}
            >
              Device Type
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 2 ? "active" : ""} ${
              step > 2 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 3 ? "active" : ""
              } ${step > 3 ? "prev" : ""}`}
            >
              <FaTag color="#fff" />
            </div>
            <p
              className={`${step === 3 ? "active" : ""} ${
                step > 3 ? "prev" : ""
              }`}
            >
              Model
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 3 ? "active" : ""} ${
              step > 3 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 4 ? "active" : ""
              } ${step > 4 ? "prev" : ""}`}
            >
              <FaBarcode color="#fff" />
            </div>
            <p
              className={`${step === 4 ? "active" : ""} ${
                step > 4 ? "prev" : ""
              }`}
            >
              Device
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 4 ? "active" : ""} ${
              step > 4 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 5 ? "active" : ""
              } ${step > 5 ? "prev" : ""}`}
            >
              <FiUser color="#fff" />
            </div>
            <p
              className={`${step === 5 ? "active" : ""} ${
                step > 5 ? "prev" : ""
              }`}
            >
              Contact
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 5 ? "active" : ""} ${
              step > 5 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${
                step === 6 ? "active" : ""
              } ${step > 6 ? "prev" : ""}`}
            >
              <FaExclamationTriangle color="#fff" />
            </div>
            <p
              className={`${step === 6 ? "active" : ""} ${
                step > 6 ? "prev" : ""
              }`}
            >
              Defect
            </p>
          </div>
          <div
            className={`JobModal_top_item_line ${step === 6 ? "active" : ""} ${
              step > 6 ? "prev" : ""
            }`}
          />
          {/*  */}
          <div className="JobModal_top_item">
            <div
              className={`JobModal_top_item_icon ${step >= 7 ? "active" : ""}`}
            >
              <FaPrint color="#fff" />
            </div>
            <p
              className={`${step >= 7 ? "active" : ""} ${
                step > 7 ? "prev" : ""
              }`}
            >
              Job Type
            </p>
          </div>
          {/*  */}
        </div>
        <div className="JobModal_bottom">
          {step === 1 && (
            <Step1
              ChangeStep={() => {
                setStep(2);
              }}
            />
          )}
          {step === 2 && (
            <Step2
              ChangeStep={() => {
                setStep(3);
              }}
            />
          )}
          {step === 3 && (
            <Step3
              ChangeStep={() => {
                setStep(4);
              }}
            />
          )}
          {step === 4 && (
            <Step4
              ChangeStep={() => {
                setStep(5);
              }}
            />
          )}
          {step === 5 && (
            <Step5
              ChangeStep={() => {
                setStep(6);
              }}
              ChangeStepBack={() => {
                setStep(4);
              }}
            />
          )}
          {step === 6 && (
            <Step6
              ChangeStep={() => {
                setStep(7);
              }}
              ChangeStepBack={() => {
                setStep(5);
              }}
            />
          )}
          {step === 7 && (
            <Step7
              ChangeStep={() => {
                setStep(8);
              }}
              ChangeStepBack={() => {
                setStep(6);
              }}
            />
          )}
          {step === 8 && (
            <Step8
              ChangeStep={() => {
                setStep(9);
              }}
              ChangeStepBack={() => {
                setStep(7);
              }}
            />
          )}
          {step === 9 && (
            <Step9
              ChangeStep={() => {
                setStep(9);
              }}
              ChangeStepBack={() => {
                setStep(8);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default JobModalSteps;
