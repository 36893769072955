import { gql } from "@apollo/client";

export const GET_ALL_SERVICES = gql`
  query GetServices($limit: Int!, $offset: Int!, $where:services_bool_exp, $order:[services_order_by!]) {
    services(limit: $limit, offset: $offset, where: $where, order_by: $order) {
      id
      name
      service_id
      category
      category_rel {
        id
        name
      }
      vat
      description
      manufacturer
      manufacturer_rel {
        id
        name
      }
      model
      model_rel {
        id
        name
      }
      price_incl_vat
      price_excl_vat
      show_on_homepage
      images
      tags
      active
      created_at
      items_aggregate(where: {active: {_eq: true}}) {
        aggregate {
          count
        }
      }
    }
    services_aggregate(where: $where) {
      aggregate {
        count(columns: active)
      }
    }
  }
`;

export const GET_ALL_ITEMS_BY_SERVICE = gql`
  query GetItemsByService($service_id: uuid) {
    services_items_map(where: {active: {_eq: true}, service_id: {_eq: $service_id}}, order_by: {created_at: desc}) {
      active
      id
      item {
        id
        name
        item_no
        stock
        stock_unit
        manufacturer_no
        physical_location
        conditions
        vat
        description
        purchase_price_excl_vat
        purchase_price_incl_vat
        selling_price_excl_vat
        selling_price_incl_vat
        supplier_prices
        barcodes
        serial_numbers
        images
        stock_manage_on
        stock_alert_on
        minimum_stock
        reminder_type
        manufacturer
        manufacturer_rel {
          id
          name
        }
        category
        category_rel {
          id
          name
        }
        color
        color_rel {
          id
          name
        }
        created_at
      }
    }
  }
`;