import React, { useState } from "react";
import {v4 as uuid} from "uuid";
// icons
import { FiTrash2, FiPlus } from "react-icons/fi";

// component
import InputBox from "../../template/inputBox/InputBox";
import PlusButton from "../../template/plusButton/PlusButton";

//types
import { BarcodeType } from "./StockItems.type";

const StockItemsModalBarcode: React.FC<{
  onChange: Function;
  barcodes: BarcodeType[]
}> = ({ onChange, barcodes }) => {

  const handleAddBarcode = () =>{
    let _barcodes:BarcodeType[]  = [...barcodes]
    _barcodes.push({
      id: uuid(),
      value: ""
    });
    onChange(_barcodes);
  }
  const handleRemoveBarcode = (id:string) =>{
    let _barcodes = barcodes.filter((barcode: BarcodeType)=> barcode.id !== id)
    onChange(_barcodes);
  }
  const handleChangeValue = (id:string, value:string) =>{
    let _barcodes:BarcodeType[]  = [...barcodes];
    let index = barcodes.findIndex(price => price.id === id);
    _barcodes[index].value = value;
    onChange(_barcodes);
  }

  return (
    <>
      <div className="StockItemsModal_inner_flex_start">
        <div className="col">
          <h5 className="StockItemsModal_tab_body_header">Barcodes</h5>
        </div>
      </div>
      {barcodes.map((barcode: BarcodeType, i: number) => (
        <div
          className="StockItemsModal_inner_flex_start"
          style={{ flexWrap: "wrap" }}
          key={i}
        >
          <div className="" style={{ marginBottom: 15, width: 386 }}>
            <InputBox 
              labelText={`Barcode ${i+1}`} 
              handleChange={(value) => handleChangeValue(barcode.id, value)}
              valueProp={barcode.value}
            />
          </div>
          <div style={{ width: 50 }} onClick={()=>handleRemoveBarcode(barcode.id)}>
            <div className="StockItemsClose">
              <FiTrash2 />
            </div>
          </div>
        </div>
      ))}
      <div className="StockItemsModal_inner_flex_start">
        <div className="col">
        <PlusButton
          labelText={"Add Barcode"}
          btnClicks={handleAddBarcode}
        />
        </div>
      </div>
    </>
  );
};

export default StockItemsModalBarcode;
