import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

import { FiMessageSquare, FiSend, FiPaperclip } from "react-icons/fi";

const ChatBoxNote: React.FC<{}> = ({}) => {
  return (
    <div className="DetailModal-main-middle-view-chat-right-note">
      <div className="d-flex table-header-chat ">
        <div className="DetailModal-main-middle-view-body-row-col-2">
          <h4 className="DetailModal-main-middle-view-body-details-text-white">
            Article
          </h4>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
            Qty
          </h4>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-white text-center">
            Price
          </h4>
        </div>
      </div>
      <div className="d-flex">
        <div className="DetailModal-main-middle-view-body-row-col-2">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat">
            Samsung S10 akklausch
          </h4>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat text-center">
            1
          </h4>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat text-center">
            168,00
          </h4>
        </div>
      </div>
      <div className="d-flex">
        <div className="DetailModal-main-middle-view-body-row-col-2">
          <p>
            This is when text apears from receipt. Lot of textwhen you type
            somthing
          </p>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat text-center">
            {/* 1 */}
          </h4>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat text-center">
            {/* 168,00 */}
          </h4>
        </div>
      </div>
      <div className="d-flex">
        <div className="DetailModal-main-middle-view-body-row-col-2">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat">
            <span className="DetailModal-card-Link">
              Download PDf-Quotation
            </span>
          </h4>
        </div>
        <div className="DetailModal-main-middle-view-body-row-col">
          <h4 className="DetailModal-main-middle-view-body-details-text-chat-bold text-center">
            gesamt inkl. MwSt. <span> € 168,00 </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxNote;
