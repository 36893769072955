import React, { useState } from "react";
import "../../detailModal/DetailModal.css";

const ChatBoxRightChat: React.FC<{}> = ({}) => {
  return (
    <div className="DetailModal-main-middle-view-chat-right-body-right">
      <div className="DetailModal-main-middle-view-chat-right-body-left-dp">
        <div className="DetailModal-main-middle-view-chat-right-body-left-dp-circle">
          <img src="/assets/bg/5.png" alt="" />J
        </div>
        <h5>Jake Jung</h5>
        <h6>Technichian</h6>
      </div>
      <div className="DetailModal-main-middle-view-chat-right-body-left-msg">
        <h6>Praesent consectetur ornare urna ut.</h6>
        <div
          className="d-flex"
          style={{
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <div className="DetailModal-main-middle-view-chat-right-body-left-msg-time">
            Yesterday
          </div>
          <div className="d-flex">
            <div className="DetailModal-main-middle-view-chat-right-body-left-msg-time">
              15:35
            </div>
            <img
              src="/assets/bg/8.png"
              alt=""
              style={{ width: 15, height: 15, marginLeft: 10 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxRightChat;
