import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

// icons
import { AiOutlineEnter, AiFillPlusCircle } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";

// component
import InputBox from "../../template/inputBox/InputBox";
import SelectBox from "../../template/selectBox/SelectBox";
import StockItemsModalSuppliersItems from "./StockItemsModal.suppliersItems";
import PlusButton from "../../template/plusButton/PlusButton";

//types
import { SupplierPriceType, SupplierType } from "./StockItems.type";

//redux
import { useSelector } from "react-redux";
import { authState } from "../../../toolkit/auth/auth.slice";

//graphql
import { GET_ALL_CUSTOMER } from "../../../gql/customers/queries";
import { useQuery } from "@apollo/client";

const StockItemsModalSuppliers: React.FC<{
  onChange: Function;
  supplierPrices: SupplierPriceType[];
}> = ({ onChange, supplierPrices }) => {
  const {
    auth: { user },
  } = useSelector(authState);
  const defaultCondition = {
    created_by: {_eq: user.id}, 
    active: {_eq: true}, 
    user_type: {_eq: "supplier"}
  }

  const [selectedSupplier, setSelectedSupplier] = useState<SupplierType | null>(
    null
  );
  const [priceExclVat, setPriceExclVat] = useState("");
  const [priceInclVat, setPriceInclVat] = useState("");
  const [add, setAdd] = useState(false);
  const [supplier, setSupplier] = useState<SupplierType[]>([]);

  const { data, refetch, error, loading } = useQuery(GET_ALL_CUSTOMER, {
    variables: { limit: 30, offset: 0, where: defaultCondition },
  });

  const resetSupplierInputForm = () => {
    setAdd(false);
    setSelectedSupplier(null);
    setPriceExclVat("");
    setPriceInclVat("");
  };
  const handleAddSupplier = () => {
    let _supplierPrices: SupplierPriceType[] = [...supplierPrices];
    _supplierPrices.push({
      id: uuid(),
      supplier_id: `${selectedSupplier?.id}`,
      favourite: false,
      updated_on: new Date(),
      price_excl_vat: priceExclVat,
      price_incl_vat: priceInclVat,
      supplier_name: `${selectedSupplier?.organization}`,
      supplier_organization: `${selectedSupplier?.first_name} ${selectedSupplier?.last_name}`,
    });
    onChange(_supplierPrices);
    resetSupplierInputForm();
  };
  const handleRemoveSupplier = (id: string) => {
    let __supplierPrices = supplierPrices.filter(
      (supplier: SupplierPriceType) => supplier.id !== id
    );
    onChange(__supplierPrices);
  };
  const handleToggleFavourite = (id: string) => {
    let _supplierPrices: SupplierPriceType[] = [...supplierPrices];
    let index = supplierPrices.findIndex((price) => price.id === id);
    _supplierPrices[index].favourite = !_supplierPrices[index].favourite;
    onChange(_supplierPrices);
  };

  useEffect(() => {
    if (Array.isArray(data?.customers)) {
      setSupplier(data.customers);
    }
  }, [data]);
  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <br />
      <div className="StockItemsModal_Supplier_header">
        <div className="col_2">Supplier</div>
        <div className="col">Default</div>
        <div className="col">Last Change</div>
        <div className="col">Price (Excl. VAT)</div>
        <div className="col">Price (Incl. VAT)</div>
        <div style={{ width: 50 }}></div>
      </div>
      {supplierPrices.map((item: SupplierPriceType, i: number) => (
        <StockItemsModalSuppliersItems
          item={item}
          onDelete={handleRemoveSupplier}
          onToggleFavourite={handleToggleFavourite}
        />
      ))}
      {add === false ? (
        <PlusButton
          labelText={"Add Supplier Price"}
          btnClicks={() => {
            setAdd(!add);
          }}
        />
      ) : (
        <div className="StockItemsModal_Supplier_item">
          <div className="col_2">
            <SelectBox
              selectedValue={
                selectedSupplier
                  ? `${selectedSupplier.organization ||
                  `${selectedSupplier.first_name} ${selectedSupplier.last_name}`
                  }`
                  : "Select Supplier"
              }
              labelText="Supplier"
            >
              {supplier.map((item: SupplierType, i: number) => (
                <div
                  onClick={() => {
                    setSelectedSupplier(item);
                  }}
                  key={i}
                >
                  <div className="d-flex-start">{`${item.first_name} ${item.last_name}`}</div>
                </div>
              ))}
            </SelectBox>
          </div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col">
            <InputBox
              labelText={""}
              handleChange={setPriceExclVat}
              valueProp={priceExclVat}
            />
          </div>
          <div className="col">
            <InputBox
              labelText={""}
              handleChange={setPriceInclVat}
              valueProp={priceInclVat}
            />
          </div>
          <div
            className="StockItemsModal_Supplier_item_btn"
            onClick={handleAddSupplier}
          >
            <AiOutlineEnter />
          </div>
        </div>
      )}
    </>
  );
};

export default StockItemsModalSuppliers;
