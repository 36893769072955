import { gql } from "@apollo/client";

export const CREATE_SERVICE_MUTATION = gql`
  mutation InsertServiceOne($object: services_insert_input!) {
    insert_services_one(object: $object) {
     id
    }
  }
`;

export const UPDATE_SERVICE_MUTATION = gql`
  mutation UpdateService($object: services_set_input!, $id: uuid!) {
    update_services(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        name
      }
    }
  }
`;

export const CREATE_SERVICE_ITEM_MUTATION = gql`
  mutation InsertServiceItems($objects: [services_items_map_insert_input!]!) {
    insert_services_items_map(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SERVICE_ITEM_MUTATION = gql`
  mutation UpdateServiceItems($object: services_items_map_set_input!, $where: services_items_map_bool_exp!) {
    update_services_items_map(where: $where, _set: $object) {
      returning {
        id
      }
    }
  }
`;