import React, { useState } from "react";
import { Toggle, InputGroup, Input, Dropdown } from "rsuite";
// icons
import {
  FiChevronRight,
  FiChevronLeft,
  FiUploadCloud,
  FiX,
  FiSearch,
} from "react-icons/fi";

// component
import InputBox from "../../template/inputBox/InputBox";
import SelectedServicesSection from "../selectedServicesSection/SelectedServicesSection";

const NewStep5: React.FC<{
  ChangeStep: Function;
  ChangeStepBack: Function;
}> = ({ ChangeStep, ChangeStepBack }) => {
  return (
    <>
      <div className="desk-only">
        <div className="stepFormWrapper start">
        <SelectedServicesSection />
          <div className="stepFormWrapper-right">
            <>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <InputBox
                    labelText="Physical location"
                    handleChange={() => null}
                  />
                </div>
              </div>
              <div className="JobModal_row" style={{ marginBottom: 15 }}>
                <div className="col">
                  <div className="stepFormWrapper-right-search">
                    <div className="flex-1">Email confirmation</div>
                    <Toggle style={{ marginLeft: 10 }} />
                  </div>
                </div>
                <div className="col">
                  <div className="stepFormWrapper-right-search">
                    <div className="flex-1">File upload </div>
                    <FiUploadCloud />
                  </div>
                </div>
              </div>
              <div
                className="JobModal_row"
                style={{ marginBottom: 15, alignItems: "flex-start" }}
              >
                <div className="col">
                  <img src="/assets/qr.png" alt="" style={{ width: "100%" }} />
                  <p style={{ width: "100%", textAlign: "center" }}>
                    Scan this QR Code to upload images
                  </p>
                </div>
                <div className="col">
                  <div className="d-flex-start" style={{ flexWrap: "wrap" }}>
                    <div className="DetailModal-uploads-img">
                      <img src="/assets/bg/upload1.png" alt="" />
                      <button>
                        <FiX />
                      </button>
                    </div>
                    <div className="DetailModal-uploads-img">
                      <img src="/assets/bg/upload1.png" alt="" />
                      <button>
                        <FiX />
                      </button>
                    </div>
                    <div className="DetailModal-uploads-img">
                      <img src="/assets/bg/upload1.png" alt="" />
                      <button>
                        <FiX />
                      </button>
                    </div>
                    <div className="DetailModal-uploads-img">
                      <img src="/assets/bg/upload1.png" alt="" />
                      <button>
                        <FiX />
                      </button>
                    </div>
                    <div className="DetailModal-uploads-img">
                      <img src="/assets/bg/upload1.png" alt="" />
                      <button>
                        <FiX />
                      </button>
                    </div>
                    <div className="DetailModal-uploads-img">
                      <img src="/assets/bg/upload1.png" alt="" />
                      <button>
                        <FiX />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="JobModal_row">
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStepBack();
                  }}
                >
                  <FiChevronLeft style={{ marginRight: 5 }} />
                  Back
                </div>
              </div>
              <div className="col">
                <div
                  className="bt-primary JobModal-btn"
                  onClick={() => {
                    ChangeStep();
                  }}
                >
                  Next
                  <FiChevronRight style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-only"></div>
    </>
  );
};

export default NewStep5;
