import React, { useState, useEffect } from "react";
import "../../../pages/services/Services.scss";
import "./textTempletModel.scss";
import { useMutation } from "@apollo/client";
import { v4 as uuid } from "uuid";

// icons
// import { AiFillPlusCircle } from "react-icons/ai";
import { FiX, FiPlus } from "react-icons/fi";

// components
import SelectBox from "../../template/selectBox/SelectBox";
import InputBox from "../../template/inputBox/InputBox";
import TextAreaBox from "../../template/textAreaBox/TextAreaBox";

import PlusButton from "../../template/plusButton/PlusButton";

import { showAlert } from "../../../helpers";
import SearchDropDown from "../../template/searchDropDown/SearchDropDown";

const TextTempletModel: React.FC<{
  Close: any;
  isEdited?: boolean;
  editedId?: string;
}> = ({ Close, isEdited, editedId }) => {
  const [errors, setErrors] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");
  const [newName, setNewName] = useState(false);
  const [tab, setTab] = useState(1);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [customerNo, setCustomerNo] = useState<string>("");
  const [nickName, setNickName] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [iban, setIban] = useState<string>("");
  const [bic, setBic] = useState<string>("");
  const [paymentCondition, setPaymentCondition] = useState<string>("");
  const [discount, setDiscount] = useState<string>("");
  const [discountType, setDiscountType] = useState<string>("");
  const [vatId, setVatId] = useState<string>("");
  const [selectedCategory, setselectedCategory] = useState("Receptis");
  const [state, setState] = useState<any>({
    id: uuid(),
    name: "",
    service_id: "",
    category: "",
    vat: "0",
    description: "",
    model: "",
    price_excl_vat: "0",
    price_incl_vat: "0",
    show_on_homepage: false,
    items: [],
    images: [],
    manufacturer: "",
  });

  const handelSelectedCategory = (value: string, key: string) => {
    setselectedCategory(value);
  };
  const [receptis, setReceptis] = React.useState([
    {
      id: "1",
      name: "Receptis1",
    },
    {
      id: "2",
      name: "Receptis2",
    },
  ]);

  const handleClick = async () => {};

  const resetState = () => {
    setSelected1("");
    setFirstName("");
    setLastName("");
    setDiscountType("");
    setDiscount("");
    setPaymentCondition("");
    setBic("");
    setIban("");
    setDob("");
    setNotes("");
    setOrganization("");
    setPosition("");
    setCustomerNo("");
    setSelected("");
    setSelected2("");
  };

  const handleClose = () => {
    resetState();
    Close();
  };

  console.log(isEdited, "isEdited");

  return (
    <>
      <div className="desk-only">
        <div className="CustomerContactModal">
          <div className="Add_Text_Templet_inner">
            <div className="CustomerContactModal_inner_top">
              <div className="CustomerContactModal_inner_flex_start">
                <div className="col">
                  <h5 className="">Add Text Templet </h5>
                </div>
                <div className="close" onClick={handleClose}>
                  <FiX />
                </div>
              </div>
            </div>
            <div className="text_templet_model_body">
              <div className="text_templet_model_contant">
                <InputBox
                  labelText="Tempalte Name"
                  valueProp={customerNo}
                  handleChange={setCustomerNo}
                  isError={errors.customerNo?.length > 0}
                />
              </div>
              <div className="input-container">
                {/* <SelectBox
                  selectedValue={selectedCategory}
                  labelText="Category"
                >
                  {receptis.map((item, i: number) => (
                    <div
                      className="selectBox-option"
                      onClick={() => {
                        handelSelectedCategory(item.name, "country");
                      }}
                      key={i}
                    >
                      {item.name}
                    </div>
                  ))}
                </SelectBox> */}
                <SearchDropDown
                        labelText="Category"
                        data={receptis}
                        selectedOption={setSelected}
                        onMouseLeave={() => null}
                      />
              </div>
              <div className="templet_textarea">
                <TextAreaBox
                  labelText="Text"
                  heightValue={100}
                  handleChange={(value: string) =>
                    setState({ ...state, description: value })
                  }
                  valueProp={state.description}
                />
              </div>
            </div>

            <div className="d-flex CustomerContactModal_btns">
              <div
                className="bt-secondary"
                style={{ flex: 0 }}
                onClick={handleClose}
              >
                Abort
              </div>
              <button
                className={btnLoading ? "bt-secondary" : "bt-primary"}
                style={{ flex: 0 }}
                onClick={handleClick}
                disabled={btnLoading}
              >
                Save
              </button>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TextTempletModel;
